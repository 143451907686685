import { Box } from "@mui/material";
import React, { useState } from "react";
import ListPage from "../../../components/list-page/ListPage";
import SearchFilter from "../../../components/filter/SearchFilter";
import RemoveFilters from "../../../components/filter/RemoveFilters";

const filters = {
  projectName: {
    element: "input",
    type: "text",
    placeholder: "Search By project name",
    label: "Project Name",
    name: "projectName",
  },
  packageName: {
    element: "input",
    type: "text",
    placeholder: "Search by package name",
    label: "Package Name",
    name: "packageName",
  },
  startDate: {
    element: "input",
    type: "text",
    placeholder: "Search By Start date",
    label: "Start date",
    name: "startDate",
  },
  endDate: {
    element: "input",
    type: "text",
    placeholder: "Search By End date",
    label: "End date",
    name: "endDate",
  },
  status: {
    element: "radio",
    label: "Status",
    name: "status",
    options: [
      { id: "created", label: "Created" },
      { id: "closed", label: "Closed" },
    ],
  },
};
const VendorParticipationEvents = React.memo((props: any) => {
  const {  searchQuery, setSearchQuery } = props;
  const [appliedFilters, setAppliedFilters] = useState({});

  const handleSearchFilter = (query: any) => {
    setSearchQuery(query);
  };

  const handleFilterChange = (appliedParams: any) => {
    setAppliedFilters(appliedParams);
  };
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <SearchFilter
          handleFilterChange={handleFilterChange}
          filters={filters}
          appliedFilters={appliedFilters}
          showFilters={true}
          showSearch={true}
          query={searchQuery}
          handleSearchFilter={handleSearchFilter}
        />
      </Box>
      <Box>
        <RemoveFilters
          handleFilterChange={handleFilterChange}
          filters={filters}
          appliedFilters={appliedFilters}
        />
      </Box>

      <ListPage
        rows={props?.rows || []}
        columns={props.columns}
        page={props?.page}
        size={props?.size}
        totalElements={props?.totalElements}
        sortColumn={props?.sortColumn}
        sortDirection={props?.sortDirection}
        handlePagination={props?.handlePagination}
        handleSort={props?.handleSort}
        handleCallBack={props?.handleCallBack}
        hideColumns={props?.hideColumns}
      />
    </Box>
  );
});

export default VendorParticipationEvents;
