import {Grid, Typography} from "@mui/material";
import {getPriceInFormatted} from "../../../../utils/Common";
import React from "react";

const CategoryBlock = (props: any) => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Typography sx={{padding: "0 8px", fontWeight: 600, fontSize: "12px"}}>
                    {props.category?.name}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={{padding: "0 8px", fontWeight: 600, fontSize: "12px"}}>
                    Total Skus: {props.category.totalSkus}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{padding: "0 8px", fontWeight: 600, fontSize: "12px"}}
                            marginRight={"2rem"}>
                    Total Amount:{" "} {getPriceInFormatted(props.category.total, props.project.country)}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default CategoryBlock;