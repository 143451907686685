import {useEffect, useState} from "react";
import {api} from "../../utils/Axios";
import {useCheckboxValues} from "../../hooks/useCheckboxValues";
import PageHeading from "../page-heading/PageHeading";
import {Box} from "@mui/material";
import BuildForm from "../input-form/BuildForm";
import Loader from "../Loader";

const EditUserComponent = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const {setSelectedCheckboxValue} = useCheckboxValues();
    const [user, setUser] = useState<any>([]);

    const onSubmitHandler = (form: any, callback: any) => {
        props.onSubmitHandler(form, callback);
    };

    const updatedFormFields = formFields.map((field) => {
        if (field.name === 'roles') {
            return {
                ...field,
                options:
                    props.roles &&
                    props.roles?.map((role: any) => {
                        return {
                            id: role.id,
                            label: role.name,
                        };
                    }),
            };
        }
        return field;
    });

    const loadUser = () => {
        setIsLoading(true);
        api
            .get(`/authentication/user/${props.id}`)
            .then((response) => response.data)
            .then((response: any) => {
                // Update form fields based on user data
                formFields.forEach((formField: any) => {
                    if (formField.name === 'username') {
                        formField.value = response.username;
                    }
                    if (formField.name === 'name') {
                        formField.value = response.name;
                    }
                    if (formField.name === 'status') {
                        formField.value = response.status;
                    }
                    if (formField.name === 'email') {
                        formField.value = response.email;
                    }
                    if (formField.name === 'roles') {
                        let options: any[] = [];
                        props.roles.forEach((item: any) => {
                            options.push({
                                id: item.id,
                                label: item.name,
                            });
                        });
                        formField.options = options;
                        const selectedPermissions = response?.roles.map(
                            (permission: any) => permission.id
                        );
                        setSelectedCheckboxValue(selectedPermissions);
                        formField.value = selectedPermissions;
                    }
                });
                setUser(response);
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            })
    };

    useEffect(() => {
        loadUser();
    }, [props.id]);

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <Box className={'common-space-container'}>
            <PageHeading title={`${props.pageName} - ${user?.name}`}/>
            <Box>
                <Box className={'center-aligned'}>
                    <Box sx={{width: '50%'}}>
                        <BuildForm
                            buttons={[
                                {
                                    type: 'submit',
                                    title: 'Save',
                                    permissions: ['edit-users'],
                                },
                            ]}
                            formArr={updatedFormFields}
                            onSubmit={onSubmitHandler}
                            handleFormChange={(form: any) => console.log(form)}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default EditUserComponent;

const formFields = [
    {
        element: 'input',
        label: 'Name',
        placeholder: 'Name',
        name: 'name',
        type: 'text',
    },
    {
        element: 'input',
        label: 'Username',
        placeholder: 'Username',
        name: 'username',
        type: 'text',
        required: true,
    },
    {
        element: 'input',
        label: 'Email',
        placeholder: 'Email',
        name: 'email',
        type: 'text',
        format: 'no-space',
    },
    {
        element: 'radio',
        label: 'Status',
        placeholder: 'Select Status',
        name: 'status',
        options: [
            {id: true, label: "ACTIVE"},
            {id: false, label: "INACTIVE"},
        ],
    },
    {
        element: 'checkbox',
        label: 'Roles',
        placeholder: 'Select Permissions',
        name: 'roles',
        fullWidth: true,
        options: [],
    },
];