import * as React from 'react';
import {DataGrid, GridColDef} from '@mui/x-data-grid';

const projectColumns: GridColDef[] = [
    {field: 'name', headerName: 'Project Name', flex: 1.5, sortable: false, resizable: true},
    {
        field: 'packages', headerName: 'Packages', flex: 1, sortable: false,
        valueGetter: (params: any) => params.row.data?.created
    },
    {field: 'events', headerName: 'Events', flex: 1, sortable: false, valueGetter: (params: any) => params.row.data?.event},
    {field: 'pr', headerName: 'PR', flex: 1, sortable: false, valueGetter: (params: any) => params.row.data?.pr}
];

const columns1: GridColDef[] = [
    {field: 'name', headerName: 'Item Category', sortable: false, resizable: true, flex: 1},
    {field: 'totalSkus', headerName: 'Total SKU', sortable: false, resizable: true, flex: 1},
    {field: 'totalRcSkus', headerName: 'With RC', sortable: false, resizable: true, flex: 1},
    {
        field: 'withoutRcSkus', headerName: 'Without RC', sortable: false, resizable: true,
        flex: 1,
        valueGetter: (params: any) => parseInt(params.row.totalSkus) - parseInt(params.row.totalRcSkus)
    }
];

export default function TableOverview(props: any) {
    let columns = projectColumns;
    if (props.source == 'catalog') {
        columns = columns1;
    }

    return (
        <div className={props.classElem}
             style={{height: 'auto', width: props.width ? props.width : '85%', margin: props.margin ? props.margin : '0 auto'}}>
            <DataGrid
                rows={props?.data}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {page: 0, pageSize: props.source == 'catalog' ? 100 : 5},
                    },
                }}
                pageSizeOptions={[]}
                checkboxSelection={false}
                hideFooter={props.hideFooter}
                disableColumnMenu
                showCellVerticalBorder
                showColumnVerticalBorder
                sx={(theme) => ({
                    fontSize: '0.85rem',
                    fontFamily: 'Lato',
                    '&, [class^=MuiDataGrid]': {borderRadius: 0, borderRight: 0},
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeadersInner': {
                        backgroundColor: theme.palette.info.contrastText,
                        fontSize: '0.9rem',
                    },
                    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                        borderRight: 1,
                        borderColor: 'divider',
                    },
                    '& .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox': {
                        // px: 0,
                    },
                    '.MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '.MuiDataGrid-footerContainer': {
                        borderRight: 1,
                        borderColor: 'divider',
                    },
                    '.custom-column-header': {
                        fontFamily: 'Lato',
                        fontSize: '12px',
                        fontWeight: '700 !important',
                        lineHeight: '14.4px',
                        textAlign: 'left',
                        color: '#1E2B51',
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 700,
                    },
                    '.MuiDataGrid-cellContent': {
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.4px',
                        textAlign: 'left',
                    },

                })}
            />
        </div>
    );
}