import {Box, Button, Grid, Modal, styled, Typography} from "@mui/material";
import React, {ChangeEvent, useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import PageHeading from "../../../components/page-heading/PageHeading";
import CloseIcon from "@mui/icons-material/Close";
import {getContentUrl} from "../../../utils/Common";
import {api} from "../../../utils/Axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {openSnackbar} from "../../../components/common/features/snackbarSlice";
import {ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";
import {useAppDispatch} from "../../../app/hooks";
import {getStyle} from "../../../utils/ModalUtils";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const UploadCatalogue = (props: any) => {
    const style = getStyle(70, 75);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [data, setData] = useState([]);
    const [showSave, setShowSave] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<any>("");
    const [open, setOpen] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const dispatch = useAppDispatch();
    const [successMessage, setSuccessMessage] = useState("")

    const handleClose = () => {
        setOpen(false);
        setSuccessMessage("")
        props.handleUploadPopupCallBack({event: 'close'});
    }

    const handleConfirmationDialogClose = () => {
        setOpenConfirmationDialog(false);
    }

    const handleConfirmationPopup = () => {
        setOpenConfirmationDialog(true);
    }

    const handleSavePrices = () => {
        setErrors([]);
        setData([]);
        setShowSave(false);
        setIsLoading(true);
        setOpenConfirmationDialog(false);
        const formData = new FormData();
        formData.append('file', uploadedFile);
        api
            .post('/catalogue/sku/upload-prices', formData)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                dispatch(openSnackbar({message: 'Catalogue pricing updated successfully', backgroundColor: SUCCESS_COLOR}))
                setSuccessMessage("Prices uploaded successfully")
            })
            .catch((ex) => {
                const res = ex.response?.data;
                if (res && res.hasOwnProperty('data')) {
                    setErrors(res.data);
                } else {
                    dispatch(openSnackbar({message: 'Something went wrong! Please check all the values are valid before submitting.', backgroundColor: ERROR_COLOR}));
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleHeaderButtonClick = (data: any) => {
        if (data == 'download') {
            window.location.href = `${getContentUrl()}/innov8/Sku-Price-Update-Format.csv`;
        }
    }

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        setSuccessMessage("")
        if (event.target.files) {
            setIsLoading(true);
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            setUploadedFile(file);
            setErrors([]);
            setData([]);
            setShowSave(false);
            api
                .post('/catalogue/sku/verify-upload-prices', formData)
                .then((response) => {
                    return response.data;
                })
                .then((res) => {
                    setData(res.data);
                    setShowSave(true);
                    // dispatch(openSnackbar({message: 'Catalogue pricing updated successfully', backgroundColor: SUCCESS_COLOR}))
                    // setSuccessMessage("Prices uploaded successfully")
                })
                .catch((ex) => {
                    const res = ex.response?.data;
                    if (res && res.hasOwnProperty('data')) {
                        setErrors(res.data);
                    } else {
                        dispatch(openSnackbar({message: 'Something went wrong! Please check all the values are valid before submitting.', backgroundColor: ERROR_COLOR}));
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                    event.target.value = '';
                });
        }
    };


    useEffect(() => {
        if (props.openPopup == true) {
            setOpen(true);
        }
    }, [props.openPopup]);
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header} style={{height: '10%'}}>
                        <Box sx={style.headerContainer}>
                            <Box sx={{width: '95%'}}>
                                <PageHeading
                                    title={"Upload Catalogue Prices"}
                                    showButtons={true}
                                    buttons={[
                                        {id: 'download', label: 'Download Template'},
                                    ]}
                                    handleHeaderButtonClick={handleHeaderButtonClick}
                                />
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            {isLoading &&
                                <Loader/>
                            }
                            <Box sx={{py: 2}}>
                                <Box display="flex" justifyContent="center" sx={{pt: 1}}>
                                    <Button
                                        component="label"
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon/>}
                                    >
                                        Upload Price file
                                        <VisuallyHiddenInput type="file" onChange={handleFileUpload} accept={".csv"}/>
                                    </Button>
                                </Box>
                                <Box display="flex" justifyContent="center" margin={'24px 0'}>
                                    <Typography
                                        sx={{fontSize: '14px', fontWeight: 600, color: 'green'}}>{successMessage ? successMessage : ''}</Typography>
                                </Box>
                                {showSave &&
                                    <Box className={"center-aligned"}>
                                        <Box sx={{width: '70%'}}>
                                            <Grid container spacing={2}>
                                                {data.map((skuData: any, index: number) => (
                                                    <Grid item xs={6} key={"sku-data-" + index}>
                                                        <Typography sx={{color: 'success.main'}}>{skuData}</Typography>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </Box>
                                }
                                {errors.length > 0 &&
                                    <Box sx={{py: 4, overflow: 'hidden'}}>
                                        {errors.map((error: string, index: number) => (
                                            <Typography sx={{color: 'error.main'}}
                                                        key={"error-" + index}>{error}</Typography>
                                        ))}
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                    {showSave &&
                        <Box sx={style.footer} style={{height: '10%'}} className="sticky-footer">
                            <Grid
                                container
                                sx={{px: 2, height: "100%"}}
                                className={"center-aligned"}
                            >
                                <Grid item xs/>
                                <Grid item>
                                    <Button variant="outlined" onClick={handleClose}
                                            sx={{marginRight: '4px'}}>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" onClick={handleConfirmationPopup}
                                            sx={{marginLeft: '4px'}}>
                                        Continue Upload Prices
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </Box>
                {openConfirmationDialog &&
                    <Dialog
                        open={true}
                        onClose={handleConfirmationDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Confirm Price Update"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to update Sku prices?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleConfirmationDialogClose} color="primary">
                                No
                            </Button>
                            <Button
                                variant={"contained"}
                                onClick={handleSavePrices}
                                color="primary"
                                autoFocus
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </Box>
        </Modal>
    )
}

export default UploadCatalogue;