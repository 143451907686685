import {configureStore} from "@reduxjs/toolkit";
import userReducer from "../modules/user/features/userSlice";
import tabReducer from "../modules/project/plan/features/tabSlice";
import categoryReducer from "../modules/project/features/categoriesSlice";
import catalogReducer from "../modules/catalogue/features/catalogSlice";
import snackbarReducer from "../components/common/features/snackbarSlice";
import packageReducer from "../components/package-tabs/features/packageSlice";
import eventsReducer from "../modules/project/event/features/eventSlice";
import packageDetailsReducer from "../modules/project/package/features/packageDetailsSlice";
import vendorReducer from "../modules/vendor/features/vendorSlice";
import estimationReducer from "../modules/project/plan/tabs/estimation-tabs/features/estimationSlice"
import selectedSkuReducer from "../modules/project/plan/tabs/estimation-tabs/features/selectedSkuSlice"
import boqSummaryReducer from "../modules/project/plan/tabs/boq-tabs/features/boqSummarySlice"
import commonSummaryReducer from '../components/summay/features/commonSummarySlice'
import skuPricesReducer from '../components/skus/features/skuSlice'
import authReducer from '../features/authSlice';
import projectReducer from '../modules/project/features/projectSlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        tabs: tabReducer,
        categories: categoryReducer,
        catalog: catalogReducer,
        snackbar: snackbarReducer,
        package: packageReducer,
        events: eventsReducer,
        packageDetails: packageDetailsReducer,
        vendor: vendorReducer,
        estimation: estimationReducer,
        auth: authReducer,
        selectedSku: selectedSkuReducer,
        boqSummary: boqSummaryReducer,
        commonSummary: commonSummaryReducer,
        skuPrices: skuPricesReducer,
        project: projectReducer
    },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
