import {Box, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import FormFields from "./FormFields";
import {useCheckboxValues} from "../../../hooks/useCheckboxValues";

const Fields = (props: any) => {
    const {formArr, initialForm, group, disableFields} = props;
    const [form, setForm] = useState(props.initialForm);
    const {setSelectedCheckboxValue} = useCheckboxValues()

    const onChangeHandlerMultiple = (e: any, option: any) => {
        const {checked, name} = e.target;
        if (checked) {
            setForm((prevForm: any) => {
                return ({
                    ...prevForm,
                    [name]: [...(prevForm[name] || []), option.id],
                })
            });
        } else {
            setForm((prevForm: any) => ({
                ...prevForm,
                [name]: (prevForm[name] || []).filter((item: any) => {
                    return item !== option.id
                }),
            }));
        }
        setSelectedCheckboxValue((prev: any) => {
            if (checked) {
                return [...prev, option.id];
            } else {
                return prev.filter((item: any) => item !== option.id);
            }
        });
    };

    const onChangeHandler = (e: any) => {
        setForm((prevForm: any) => ({...prevForm, [e.target.name]: e.target.value}));
    }

    // useEffect(() => {
    //     setForm(props.initialForm)
    // }, [props.initialForm]);

    useEffect(() => {
        props.onFormDataChange(form);
    }, [form, formArr]);

    return (
        <>
            {(group != undefined && group == true) &&
                formArr?.map((groupForm: any, index: number) => {

                    return (
                        <Box sx={{ border: (groupForm?.noBorder && groupForm.noBorder) ? 0 : 1, borderColor: 'divider', mb: 4}} key={"group-form-" + index}>
                            {groupForm.groupName != undefined &&
                                <Typography sx={{backgroundColor: "#F9FAFF", fontWeight: 700, p: 2}}>
                                    {groupForm.groupName}
                                </Typography>
                            }
                            <Box sx={{p: 2}}>
                                <FormFields fields={groupForm.fields} onChangeHandler={onChangeHandler}
                                            onChangeHandlerMultiple={onChangeHandlerMultiple}
                                            disableFields={disableFields}/>
                            </Box>
                        </Box>
                    )
                })
            }
            {group == undefined &&
                <FormFields fields={formArr} onChangeHandler={onChangeHandler}
                            onChangeHandlerMultiple={onChangeHandlerMultiple}
                            disableFields={disableFields}/>
            }
        </>
    )
}
export default Fields;