// actions.ts
import {createAsyncThunk} from "@reduxjs/toolkit";
import {api} from "../../../../utils/Axios";

interface PackageDetailsItem {
    id: string;
    name: string;
}

interface ApiResponse {
    id: number | string;
    projectId: string;
    total: number;
    items: PackageDetailsItem[];
    vendor: any;
    domainGsvCharges: any;
    domainCogsCharges: any;
    totalElements: number;
    name: string;
    status: string
}

interface LoadPackageDetailsParams {
    id: string;
    page: number;
    size: number;
    sortDirection?: string;
    sortColumn?: string;
}

export const loadPackageDetails = createAsyncThunk<
    ApiResponse,
    string
>(
    "packageDetails/loadPackageDetails",
    async (
               id) => {
        try {
            const response = await api.get(`/procurement/package/${id}`);
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || "Failed to fetch package details");
        }
    }
);


export const approvePackageRequest = createAsyncThunk(
    "packageDetails/approve-pr",
    async (
        id: string) => {
        try {
            const response = await api.get(`/procurement/package/approve-pr/${id}`);
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || "Failed to fetch package details");
        }
    }
);


export const revokePackage = createAsyncThunk(
    "packageDetails/revoke-package",
    async (
        id: string) => {
        try {
            const response = await api.get(`/procurement/package/revoke/${id}`);
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || "Failed to fetch package details");
        }
    }
);
