import {CircularProgress, Stack} from "@mui/material";

export default function Loader() {
    return (
        <Stack sx={{color: 'grey.500'}} spacing={2} direction="row">
            <CircularProgress color="success" size={100} sx={{
                position: 'absolute',
                top: '50%',
                left: 'calc(50% - 50px)',
                zIndex: 1,
            }}/>
        </Stack>
    );
}