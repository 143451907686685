import { createSlice } from "@reduxjs/toolkit";
import { getAcceptBid, loadEventDetails, postBreakBidsValues } from "./actions";

const eventSlice = createSlice({
  name: "event",
  initialState: {
    pending: false,
    eventDetails: {
      id: '',
      status:'',
      eventPackages: [{
        items: [],
        name: ""
      }],
      project: {},
      eventVendors: [],
      totalElements: 0,
      totalVendors: 0,
      totalResponse: 0,
      name: '',
      createdAt: '',
      startDate: '',
      endDate: '',
    },
    acceptBidResponse: {},
    postBreakBidsResponse: {},
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadEventDetails.pending, (state: any) => {
        state.pending = true;
        state.error = "";
      })
      .addCase(loadEventDetails.fulfilled, (state: any, action) => {
        state.pending = false;
        state.eventDetails = action.payload;
        state.error = "";
      })
      .addCase(loadEventDetails.rejected, (state: any, action) => {
        state.pending = false;
        state.error = action.payload;
      })
      .addCase(getAcceptBid.pending, (state: any) => {
        state.pending = true;
        state.error = "";
      })
      .addCase(getAcceptBid.fulfilled, (state: any, action) => {
        state.pending = false;
        state.acceptBidResponse = action.payload;
        state.error = "";
      })
      .addCase(getAcceptBid.rejected, (state: any, action) => {
        state.pending = false;
        state.error = action.payload;
      })
      .addCase(postBreakBidsValues.pending, (state: any) => {
        state.pending = true;
        state.error = "";
      })
      .addCase(postBreakBidsValues.fulfilled, (state: any, action) => {
        state.pending = false;
        state.acceptBidResponse = action.payload;
        state.error = "";
      })
      .addCase(postBreakBidsValues.rejected, (state: any, action) => {
        state.pending = false;
        state.error = action.payload;
      });
  },
});

export default eventSlice.reducer;
