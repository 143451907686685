import { createSlice } from "@reduxjs/toolkit";
import { getEstimationDetails } from "./actions";


const initialState = {
        pending: false,
        estimationDetailsData: [],
        error: ""
}
const commonSummarySlice = createSlice({
        name: "commonSummary",
        initialState: initialState,
        reducers: {},
        extraReducers: (builder) => {
                builder
                .addCase(getEstimationDetails.pending, (state: any) => {
                        state.pending = true
                })
                .addCase(getEstimationDetails.fulfilled, (state: any, action) => {
                        state.pending = false
                        state.estimationDetailsData = action.payload
                })
                .addCase(getEstimationDetails.rejected, (state: any, action: any) => {
                        state.pending = false
                        state.error = action.payload
                })
        }
})

export default commonSummarySlice.reducer