import {getStyle} from "../../utils/ModalUtils";
import {Box, Grid, Modal, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useEffect, useState} from "react";
import {api} from "../../utils/Axios";
import Loader from "../Loader";
import {getPriceInFormatted} from "../../utils/Common";

const SelectEventRcVendor = (props: any) => {
    const style = getStyle(60, 70);
    const {selectedItem, project, openPopup} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [entityPrices, setEntityPrices] = useState([]);

    const handleClose = () => {
        setOpen(false);
        props.handleRCVendorPopupCallBack({event: "close"});
    };

    const selectEntity = (entityPrice: any) => {
        let form = {
            packageItemId: selectedItem.id,
            entityId: entityPrice.entityId,
            rcPrice: entityPrice.rcPrice,
            sellingPrice: entityPrice.sellingPrice,
            entityType: entityPrice?.entityName
        };
        api.post(
            `/procurement/package/select-rc-entity`, form)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                props.handleRCVendorPopupCallBack({event: "save"});
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    }

    const loadSkuVendors = () => {
        api.get(
            `/catalogue/sku/get-entity-prices/${selectedItem.itemDetail?.sku?.id}/${project.location}/${project.country}`)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                setEntityPrices(res);
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        if (openPopup == true) {
            setOpen(true);
            loadSkuVendors();
        }
    }, [openPopup]);


    if (isLoading) {
        return <Loader/>;
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    {/*Select RC Vendors*/}
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            <Box sx={{py: 2}}>
                                <Grid container
                                      sx={{p: 2, border: 1, borderColor: "divider", backgroundColor: "primary.light", fontWeight: '700'}}>
                                    <Grid item xs>Item Category</Grid>
                                    <Grid item xs>Item</Grid>
                                    <Grid item xs>Sku Name</Grid>
                                    <Grid item xs>Sku Code</Grid>
                                    <Grid item xs sx={{textAlign: 'right'}}>Total RC Entities</Grid>
                                </Grid>
                                <Grid
                                    container
                                    sx={{p: 2, border: 1, borderTop: 0, borderColor: "divider"}}
                                    alignItems={"stretch"}
                                >
                                    <Grid item xs>{selectedItem.itemDetail.itemCategory.name}</Grid>
                                    <Grid item xs>{selectedItem.itemDetail.item.name}</Grid>
                                    <Grid item xs>{selectedItem.itemDetail.sku.name}</Grid>
                                    <Grid item xs>{selectedItem.itemDetail.sku.code}</Grid>
                                    <Grid item xs
                                          sx={{textAlign: 'right'}}>{selectedItem.itemDetail.sku.rcVendors}</Grid>
                                </Grid>
                            </Box>
                            <Grid container
                                  sx={{p: 2, border: 1, borderColor: "divider", backgroundColor: "primary.light", fontWeight: '700'}}>
                                <Grid item xs={2.5}>Entity Name</Grid>
                                <Grid item xs={2}>Entity Type</Grid>
                                <Grid item xs={1.5}>Quantity</Grid>
                                <Grid item xs={2.5}>Entity COGS Price</Grid>
                                <Grid item xs={2.5} sx={{textAlign:'center'}}>Total</Grid>
                                <Grid item xs={1} sx={{textAlign: 'right'}}>Action</Grid>
                            </Grid>
                            {entityPrices.map((entityPrice: any, index: number) => {
                                return (
                                    <Grid
                                        container
                                        sx={{p: 2, border: 1, borderTop: 0, borderColor: "divider"}}
                                        alignItems={"stretch"}
                                        key={"vendor-prices-" + index}
                                    >
                                        <Grid item xs={2.5}>{entityPrice.entityObjectName}</Grid>
                                        <Grid item xs={2} sx={{textTransform:'capitalize'}}>{entityPrice.entityName}</Grid>
                                        <Grid item xs={1.5}>{selectedItem?.quantity}</Grid>
                                        <Grid item xs={2.5}>
                                            {getPriceInFormatted(entityPrice?.rcPrice, project.country)}
                                        </Grid>
                                        <Grid item xs={2.5} sx={{textAlign:'center'}}>
                                            {getPriceInFormatted((entityPrice?.rcPrice * selectedItem?.quantity), project.country)}
                                        </Grid>
                                        <Grid item xs={1} sx={{textAlign: 'right'}}>
                                            <Typography
                                                onClick={() => selectEntity(entityPrice)}
                                                sx={{cursor: 'pointer', color: 'primary.main'}}
                                            >Select</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default SelectEventRcVendor;