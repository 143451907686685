import { Box } from "@mui/material";
import React, {useEffect, useState} from "react";
import SummaryCategoryWidget from "./SummaryCategoryWidget";

const SummarySectionItemsWidget = (props: any) => {
    const [estimationCategories, setEstimationCategories] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);

    useEffect(() => {
        const items: any[] = [];
        if (props.section?.items && props.section.items.length > 0) {
            props.section.items.forEach((item: any) => {
                props.estimations.map((estimationDetail: any) => {
                    estimationDetail.estimationItems.map((estimationItem: any) => {
                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                            if (estimationItemSku.id == item.id) {
                                let pushedToArray = false;
                                items.forEach((selectedItem: any) => {
                                    if (selectedItem.estimationItem.id == estimationItem.id) {
                                        selectedItem.totalSkus += 1;
                                        selectedItem.total += (estimationItemSku.sellingPrice + estimationItemSku?.gsvDomainChargesPerUnit?.total) * estimationItemSku.quantity;
                                        selectedItem.estimationItem.estimationItemSkus.push(estimationItemSku);
                                        pushedToArray = true;
                                    }
                                });
                                if (!pushedToArray) {
                                    items.push({
                                        total: (estimationItemSku.sellingPrice + estimationItemSku?.gsvDomainChargesPerUnit?.total) * estimationItemSku.quantity,
                                        totalSkus: 1,
                                        materialSubCategoryId: estimationDetail.materialSubCategoryId,
                                        estimationItem: {
                                            "id": estimationItem.id,
                                            "itemCategoryId": estimationItem.itemId,
                                            "itemId": estimationItem.itemCategoryId,
                                            "totalQuantity": estimationItem.totalQuantity,
                                            "totalSkus": estimationItem.totalSkus,
                                            "total": estimationItem.total,
                                            "item": estimationItem.item,
                                            "itemCategory": estimationItem.itemCategory,
                                            estimationItemSkus: [estimationItemSku]
                                        }
                                    });
                                }
                            }
                        });
                    });
                });
            });
        }

        const updatedCategories = props.categories.map((category: any) => {
            let total: number = 0;
            let totalSkus: number = 0;
            category.subCategories.map((subCategory: any) => {
                items.map((item: any) => {
                    if (item.materialSubCategoryId === subCategory.id) {
                        total = total + parseFloat(item.total);
                        totalSkus = totalSkus + parseInt(item.totalSkus);
                    }
                });
            });
            const subCategories = category.subCategories.filter((subCategory: any) => {
                return items?.find((item: any) => {
                    if (item.materialSubCategoryId === subCategory.id) {
                        return subCategory;
                    }
                })
            });

            category.total = total;
            category.totalSkus = totalSkus;
            return {...category, subCategories};
        });

        setSelectedItems(items);
        setEstimationCategories(updatedCategories);
    }, [props.section]);
    return (
        <>
            {estimationCategories.map((category: any, categoryIndex: number) => {
                if (category.subCategories.length > 0) {
                    return (
                        <Box sx={{p: 1}} key={"right-category-index-" + categoryIndex}>
                            <SummaryCategoryWidget
                                showUpdatedCharges={props.showUpdatedCharges}
                                section={props.section}
                                category={category}
                                project={props.project}
                                selectedItems={selectedItems}
                                handleRemoveSectionItem={props.handleRemoveSectionItem}
                            />
                        </Box>
                    )
                }
            })}
        </>
    )
}

export default SummarySectionItemsWidget;