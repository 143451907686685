import {getFormattedDate, getPriceInFormatted} from "../../../../utils/Common";

export const pageConfig = {
    eventDetails: [
        {
            field: "createdAt",
            headerName: "Created On",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            valueGetter: (params: any) => getFormattedDate(params.row?.createdAt),
        },
        {
            field: "startDate",
            headerName: "Start Date",
            headerClassName: "data-grid-header",
            flex: 1,
            valueGetter: (params: any) => getFormattedDate(params.row?.startDate),
        },
        {
            field: "endDate",
            headerName: "End Date",
            headerClassName: "data-grid-header",
            flex: 1,
            valueGetter: (params: any) => getFormattedDate(params.row?.endDate),
        },
        {
            field: "totalVendors",
            headerName: "Total Vendors",
            headerClassName: "data-grid-header",
            flex: 1,
        },
        {
            field: "totalResponse",
            headerName: "Response Received",
            headerClassName: "data-grid-header",
            flex: 1
        },
        {
            field: "status",
            headerName: "Total Vendors",
            headerClassName: "data-grid-header",
            flex: 1,
        }
    ],
    packageDetails: [
        {
            field: 'id' ,
            headerName: 'Sr. No.',
            sortable: false,
            maxWidth: 60,
            filterable: false,
            renderCell: (params: any) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
        },
        {
            field: "itemCategory",
            headerName: "Item Category",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 200,
            valueGetter: (params: any) => params.row?.itemDetail?.itemCategory?.name + "-" + params.row?.itemDetail?.item?.name
        },
        {
            field: "selectedItem",
            headerName: "Selected Sku",
            headerClassName: "data-grid-header",
            flex: 1,
            sortable: false,
            maxWidth: 150,
            renderCell: (params: any) => (
                <a
                    href={`/catalogue/skus/details/${params.row?.itemDetail?.sku?.id}`}
                    rel="noreferrer"
                    target={"_blank"}
                >
                    {params.row?.itemDetail?.sku.name}
                </a>
            )
        },
        {
            field: "uom",
            headerName: "UOM",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 100,
            valueGetter: (params: any) => params.row?.itemDetail?.sku?.uom,
        },
        {
            field: "quantity",
            headerName: "Quantity",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 150,
        },
        {
            field: "ratePerUom",
            headerName: "Rate per UOM",
            headerClassName: "data-grid-header",
            sortable: false,
            maxWidth: 150,
            flex: 1,
            valueGetter: (params: any) => getPriceInFormatted(params.row?.gsv, params.row.country),
        },
        {
            field: "targetPrice",
            // headerName: "Target Price",
            headerName: "Planned COGS",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 150,
            valueGetter: (params: any) => getPriceInFormatted(params.row?.cogsPrice, params.row.country)
        },
        {
            field: "targetTotal",
            headerName: "Target Total",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 150,
            valueGetter: (params: any) => getPriceInFormatted(params.row?.quantity * params.row?.cogsPrice, params.row.country)
        },
        {
            field: "action",
            headerName: "Action",
            headerClassName: "data-grid-header",
            flex: 1,
            show: 'button',
            sortable: false,
            buttons: [
                {
                    type: 'revoke',
                    title: 'Revoke',
                    variant:'outlined',
                    permissions: ['edit-projects'],
                    condition: (row: any) => {return row.packageStatus == 'EVENT'}
                },
                {
                    type: 'select-rc',
                    title: 'Select RC Price',
                    permissions: ['edit-projects'],
                    condition: (row: any) => (row?.itemDetail?.sku?.rcVendors > 0 && row.packageStatus == 'EVENT')
                },
            ]
        }
    ],
    closedEventPackageDetails: [
        {
            field: 'id' ,
            headerName: 'Sr. No.',
            sortable: false,
            maxWidth: 60,
            filterable: false,
            renderCell: (params: any) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
        },
        {
            field: "itemCategory",
            headerName: "Item Category",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            // maxWidth: 200,
            valueGetter: (params: any) => params.row?.itemDetail?.itemCategory?.name + "-" + params.row?.itemDetail?.item?.name
        },
        {
            field: "selectedItem",
            headerName: "Selected Sku",
            headerClassName: "data-grid-header",
            flex: 1,
            sortable: false,
            // maxWidth: 150,
            renderCell: (params: any) => (
                <a
                    href={`/catalogue/skus/details/${params.row?.itemDetail?.sku?.id}`}
                    rel="noreferrer"
                    target={"_blank"}
                >
                    {params.row?.itemDetail?.sku.name}
                </a>
            )
        },
        {
            field: "uom",
            headerName: "UOM",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 100,
            valueGetter: (params: any) => params.row?.itemDetail?.sku?.uom,
        },
        {
            field: "quantity",
            headerName: "Quantity",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 150,
        },
        {
            field: "ratePerUom",
            headerName: "Rate per UOM",
            headerClassName: "data-grid-header",
            sortable: false,
            // maxWidth: 150,
            flex: 1,
            valueGetter: (params: any) => getPriceInFormatted(params.row?.gsv, params.row.country),
        },
        {
            field: "targetPrice",
            // headerName: "Target Price",
            headerName: "Planned COGS",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            // maxWidth: 150,
            valueGetter: (params: any) => getPriceInFormatted(params.row?.cogsPrice, params.row.country)
        },
        {
            field: "targetTotal",
            headerName: "Target Total",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            // maxWidth: 150,
            valueGetter: (params: any) => getPriceInFormatted(params.row?.quantity * params.row?.cogsPrice, params.row.country)
        },
    ]
};
