import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Drawer } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterDrawer from "./FilterDrawer";

const SearchFilter = (props: any) => {
  const [searchQuery, setSearchQuery] = useState<string>(props.query || "");
  const [filterDrawerState, setFilterDrawerState] = useState(false);

  useEffect(() => {
    setSearchQuery(props.query || "");
  }, [props.query]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    props.handleSearchFilter(searchQuery);
  };

  const toggleFilterDrawer = (open: boolean) => {
    setFilterDrawerState(open);
  };

  const handleFilterApply = (data: any) => {
    if (data.event === "submit") {
      const newFilters = Object.fromEntries(
        Object.entries(data.form).filter(
          ([, value]: any) =>
            (typeof value === "string" && value.trim() !== "") ||
            (typeof value === "number" && value !== null) ||
            (Array.isArray(value) && value.length > 0)
        )
      );
      const filtersWithSearch = { ...newFilters, searchQuery };

      // updateURL(filtersWithSearch);
      props.handleFilterChange(newFilters);
    }
    toggleFilterDrawer(false);
  };

  const updateURL = (filters: any) => {
    const params = new URLSearchParams();

    Object.entries(filters).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          value.forEach((val) => params.append(`${key}[]`, val));
        } else {
          params.append(key, value.toString());
        }
      }
    });

    const queryString = params.toString();
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${queryString}`
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
        {props.showFilters && (
            <>
                <Button
                    variant={"outlined"}
                    sx={{ mr: 2, color: "primary.main" }}
                    startIcon={<FilterAltIcon />}
                    onClick={() => toggleFilterDrawer(true)}
                >
                    Filters
                </Button>
                <Drawer
                    anchor={"right"}
                    open={filterDrawerState}
                    onClose={() => toggleFilterDrawer(false)}
                    sx={{ width: "100%", zIndex: "9999" }}
                >
                    <FilterDrawer
                        filters={props.filters}
                        handleFilterApply={handleFilterApply}
                        appliedFilters={props.appliedFilters}
                        range={props.range ? props.range : [0, 100]}
                        minDistance={props.minDistance ? props.minDistance : 10}
                    />
                </Drawer>
            </>
        )}
        {props.showSearch && (
            <Box sx={{ maxWidth: "250px" }}>
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={searchQuery}
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                    placeholder={"Search..."}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" onClick={handleSearch} sx={{ cursor: "pointer" }}>
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        )}
    </Box>
);
};

export default SearchFilter;
