import PageHeading from "../../../components/page-heading/PageHeading";
import {Box, Button, Grid, Modal, styled, Typography} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {ChangeEvent, useEffect, useState} from "react";
import {api} from "../../../utils/Axios";
import Loader from "../../../components/Loader";
import CloseIcon from "@mui/icons-material/Close";
import {getContentUrl} from "../../../utils/Common";
import {openSnackbar} from "../../../components/common/features/snackbarSlice";
import {ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";
import {useAppDispatch} from "../../../app/hooks";


const style = {
    main: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        height: '62%',
        minHeight: '300px',
        overflow: 'scroll',
        border: 0,
        backgroundColor: 'background.paper',
        boxShadow: 1
    },
    header: {
        position: 'sticky', borderBottom: 1, borderColor: 'info.light'
    }
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const RegisterVendor = (props: any) => {
    const [errors, setErrors] = useState([]);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("")
    const dispatch = useAppDispatch();

    const handleClose = () => {
        setOpen(false);
        setSuccessMessage("")
        props.handleRegisterPopupCallBack({event: 'close'});
    }

    const handleHeaderButtonClick = (data: any) => {
        if (data == 'download') {
            window.location.href = `${getContentUrl()}/innov8/Vendor-Registration-Form-For-Innov8.csv`;
        }
    }
    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
        setErrors([]);
        setIsLoading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        api.post("/procurement/vendor/register", formData)
            .then(response => response.data)
            .then(res => {
                dispatch(openSnackbar({message: 'Vendor registered successfully', backgroundColor: SUCCESS_COLOR}));
                setSuccessMessage("Registered Successfully!")
            })
            .catch(ex => {
                const res = ex.response?.data;
                if (res.hasOwnProperty("data")) {
                    setErrors(res.data);
                } else {
                    dispatch(openSnackbar({message: 'Something went wrong', backgroundColor: ERROR_COLOR}));
                }
            })
            .finally(() => {
                setIsLoading(false);
                event.target.value = '';
            });
    }
};
    useEffect(() => {
        if (props.openPopup == true) {
            setOpen(true);
        }
    }, [props.openPopup]);

    console.log("successMessage", successMessage)

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                {isLoading &&
                    <Loader/>
                }
                <Box className={"common-space-container"}>
                    <Box sx={style.header}>
                        <Grid container spacing={2} sx={{p: 2}} className={"center-aligned"}>
                            <Grid item xs>
                                <PageHeading
                                    title={"Register A New Vendor"}
                                    showButtons={true}
                                    buttons={[
                                        {id: 'download', label: 'Download Template'},
                                    ]}
                                    handleHeaderButtonClick={handleHeaderButtonClick}
                                />
                            </Grid>
                            <Grid item className={"center-aligned"}>
                                <CloseIcon sx={{cursor: 'pointer'}} onClick={handleClose}/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Box display="flex" justifyContent="center" sx={{pt: 4}}>
                            <Button
                                component="label"
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon/>}
                            >
                                Upload file
                                <VisuallyHiddenInput type="file" onChange={handleFileUpload} accept={".csv"}/>
                            </Button>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" margin={'24px 0'}>
                            <Typography sx={{fontSize: '14px', fontWeight: 600, color: 'green'}}>{successMessage ? successMessage : ''}</Typography>
                        </Box>
                        {errors.length > 0 &&
                            <Box sx={{py: 4}}>
                                {errors.map((error: string, index: number) => (
                                    <Typography sx={{color: 'error.main'}} key={"error-" + index}>{error}</Typography>
                                ))}
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}
export default RegisterVendor;