import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSku: {},
};

const selectedSkuSlice = createSlice({
  name: 'selectedSku',
  initialState,
  reducers: {
    setSelectedSku(state, action) {
      state.selectedSku = action.payload;
    },
    clearSelectedSku(state) {
      state.selectedSku = {};
    },
  },
});

export const { setSelectedSku, clearSelectedSku } = selectedSkuSlice.actions;
export default selectedSkuSlice.reducer;
