import {Box, Typography} from "@mui/material";
import {ReactNode} from "react";

interface TabPanelProps {
    children?: ReactNode;
    index: string;
    value: string;
    width?: string;
}

export const CustomTabPanel = (props: TabPanelProps) => {
    const {children, width, value, index, ...other} = props;
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`custom-tabpanel-${index}`}
            aria-labelledby={`custom-tab-${index}`}
            style={{ width: width !== undefined ? width : 'inherit' }}
            {...other}
        >
            {value === index && (
                <Box className={"tab-panel-details"}>
                    <Box className={"tab-panel-main"}>{children}</Box>
                </Box>
            )}
        </Box>
    );
}

export const a11yProps = (index: string, horizontal: boolean) => {
    if (!horizontal) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        }
    }
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
