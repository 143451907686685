import {Box, Grid, Typography} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {getPriceInFormatted} from "../../../../utils/Common";
import {useDrag} from "react-dnd";
import {ItemTypes} from "../../../../modules/project/plan/tabs/boq-tabs/ItemTypes";
import {useEffect, useState} from "react";

const SkuWidget = (props: any) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [{isDragging}, skuDrag] = useDrag({
        type: ItemTypes.SKU,
        item: {type: ItemTypes.SKU, id: props.estimationItemSku.id},
        canDrag: !isDisabled,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    let skuTotal = props.estimationItemSku?.sellingPrice + props.estimationItemSku?.gsvDomainChargesPerUnit?.total;

    useEffect(() => {
        setIsDisabled(props.estimationItemSku?.disabled !== false);
    }, [props]);

    return (
        <Box>
            <Grid container alignItems={"center"} justifyContent={"center"} ref={skuDrag} sx={{
                border: "1px solid #ccc",
                borderRadius: 1,
                backgroundColor: "#fff",
                cursor: isDisabled ? "not-allowed" : "move",
                opacity: isDisabled || isDragging ? 0.5 : 1,
                py:0.5
            }}>
                <Grid item xs={1} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <DragIndicatorIcon sx={{fontSize: "20px"}}/>
                </Grid>
                <Grid item xs>
                    <Typography sx={{fontSize: "10px", fontWeight: 600}}>
                        {props.estimationItemSku?.sku?.name ? props.estimationItemSku.sku.name : "Misc Item"}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography sx={{fontSize: "10px", fontWeight: 600,}}>
                        {props.estimationItemSku?.sku?.code ? props.estimationItemSku.sku.code : props.estimationItemSku?.sku?.id}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography sx={{fontSize: "10px", fontWeight: 600}}>
                        {props.estimationItemSku?.sku?.uom}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography sx={{fontSize: "10px", fontWeight: 600}}>
                        {props.estimationItemSku?.quantity}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={{fontSize: "10px", fontWeight: 600}}>
                        {getPriceInFormatted(skuTotal, props.project.country)}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SkuWidget;