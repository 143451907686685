import { useEffect, useState } from "react";
import { Box, Grid, Modal, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PageHeading from "../../components/page-heading/PageHeading";
import Loader from "../../components/Loader";
import { api } from "../../utils/Axios";

const styles = {
    main: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '50%',
        minHeight: '300px',
        overflow: 'scroll',
        border: 0,
        backgroundColor: 'background.paper',
        boxShadow: 1,
    },
    header: {
        position: 'sticky',
        borderBottom: 1,
        borderColor: 'info.light',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5rem 1rem',
    },
};

const ViewBrandDetails = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [brand, setBrand] = useState<any>({});
    const [open, setOpen] = useState(props.openPopup);

    const handleClose = (data: any) => {
        setOpen(false);
        props.handleViewDetailsCallback(data);
    };

    const loadBrand = () => {
        setIsLoading(true);
        api.get(`/procurement/brand/${props.viewBrandId}`)
            .then((response) => response.data)
            .then((response: any) => {
                setBrand(response);
                setIsLoading(false);
                setOpen(true);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (props.viewBrandId) {
            loadBrand();
        }
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Modal
            open={open}
            onClose={() => handleClose({ event: 'close' })}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={styles.main}>
                <Box sx={styles.header}>
                    <PageHeading
                        title={`Brand Details: ${brand?.name}`}
                        showButtons={false}
                    />
                    <IconButton onClick={() => handleClose({ event: 'close' })}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className="common-space-container">
                    <Box className="center-aligned">
                        <Box sx={{ width: '80%', mt: 2 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Box sx={{ py: 1 }}>
                                        <Typography component="span" sx={{ fontSize: '15px', fontWeight: 700 }}>
                                            Name
                                        </Typography>
                                        <Box component="span" sx={{ mt: 1, display: 'block' }}>
                                            <Typography component="span">{brand?.name}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ py: 1 }}>
                                        <Typography component="span" sx={{ fontSize: '15px', fontWeight: 700 }}>
                                            Contact Person Name
                                        </Typography>
                                        <Box component="span" sx={{ mt: 1, display: 'block' }}>
                                            <Typography component="span">{brand?.contactPersonName}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ py: 1 }}>
                                        <Typography component="span" sx={{ fontSize: '15px', fontWeight: 700 }}>
                                            Contact Person Email
                                        </Typography>
                                        <Box component="span" sx={{ mt: 1, display: 'block' }}>
                                            <Typography component="span">{brand?.contactPersonEmail}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ py: 1 }}>
                                        <Typography component="span" sx={{ fontSize: '15px', fontWeight: 700 }}>
                                            Contact Person Mobile
                                        </Typography>
                                        <Box component="span" sx={{ mt: 1, display: 'block' }}>
                                            <Typography component="span">{brand?.contactPersonMobile}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ py: 1 }}>
                                        <Typography component="span" sx={{ fontSize: '15px', fontWeight: 700 }}>
                                            City
                                        </Typography>
                                        <Box component="span" sx={{ mt: 1, display: 'block' }}>
                                            <Typography component="span">{brand?.city}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ py: 1 }}>
                                        <Typography component="span" sx={{ fontSize: '15px', fontWeight: 700 }}>
                                            Status
                                        </Typography>
                                        <Box component="span" sx={{ mt: 1, display: 'block' }}>
                                            <Typography component="span">{brand?.status}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default ViewBrandDetails;
