import {useEffect, useState} from "react";
import BuildForm from "../input-form/BuildForm";
import {Box} from "@mui/material";
import {submitForm} from "../../modules/project/plan/features/actions";
import {useAppDispatch} from "../../app/hooks";

const deepClone = (obj: any) => {
    return JSON.parse(JSON.stringify(obj));
};

const ParameterFormTab = (props: any) => {
    const dispatch = useAppDispatch();
    const [updatedFormFields, setUpdatedFormFields] = useState<any>([]);
    const {project, categoryName} = props;
    const [currentIndex, setCurrentIndex] = useState<any>(0);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [valueCondition, setValueCondition] = useState<any>({});

    const [buttons, setButtons] = useState<any[]>([
        {
            type: 'submit',
            color: 'primary.main',
            variant: 'contained',
            title: 'Save',
            permissions: ['edit-projects'],
        }
    ])


    const onSubmitHandler = async (form: any, callback: () => void) => {
        try {
            const response = await dispatch(submitForm({
                form: form,
                callback,
                categoryName,
                projectId: project.id
            })).unwrap();

            setSaveDisabled(true);
            props.updateFieldChange(false);

            props.allParameters[currentIndex] = response;

            props.handleNextButton();
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    const handleClickButton = (type: string) => {
        props.handleNextButton();
    }

    const enableSaveButton = () => {
        setSaveDisabled(false);
    }

    useEffect(() => {
        setUpdatedFormFields(deepClone(props.formFields));
    }, [props.formFields, props.categoryName]);

    useEffect(() => {
        let showContinueButton = false;
        setUpdatedFormFields(deepClone(props.formFields));
        //let newFormFields = [...props.formFields];
        
        console.log("Parameters Length", props.allParameters.length);
        if (props.allParameters.length > 0) {
            let newFormFields = deepClone(props.formFields);
            let newValueCondition: { [k: string]: any } = {};
            props.allParameters.forEach((parameter: any, index: number) => {
                if(parameter.categoryName === "c-and-i-common") {
                    
                    Object.keys(parameter.data).forEach((key: string) => {
                        if(key === "type-of-dx-details-to-be-considered") {
                            newValueCondition[key] = parameter.data[key];
                        }
                    });
                    setValueCondition(newValueCondition);
                }

                if (parameter.categoryName === props.categoryName) {
                    setCurrentIndex(index);
                    if(props.categoryName == 'ltCable') {
                        showContinueButton = true;
                    }
                    
                    Object.keys(parameter.data).forEach((key: string) => {
                        newFormFields.forEach((formField: any) => {

                            formField.fields.forEach((field: any) => {
                                if (field.name === key) {
                                    if (field.name === "repeaterPanel" && newValueCondition.hasOwnProperty("type-of-dx-details-to-be-considered") && newValueCondition["type-of-dx-details-to-be-considered"] == "Design Strong Option") {
                                        console.log("Found and setting the value for "+ field.name);
                                        field.value = "Active";
                                    } else {
                                        field.value = parameter.data[key];
                                    }

                                }
                            });
                        });
                    });
                    setSaveDisabled(true);
                    props.updateFieldChange(false);
                }
            });

            console.log("newFormFields", newFormFields);
            setUpdatedFormFields(newFormFields);

            console.log("setUpdatedFormFields", updatedFormFields);
        }

        // Update the buttons state
        const newButtons: any = [
            {
                type: 'submit',
                disabledButton: true,
                color: 'primary.main',
                variant: 'contained',
                title: 'Save',
                permissions: ['edit-projects'],
            }
        ];

        if (showContinueButton) {
            newButtons.push({
                type: 'continue',
                disabledButton: false,
                color: 'common.main',
                variant: 'outlined',
                title: 'Submit & Continue',
                permissions: ['edit-projects'],
            });
        }

        setButtons(newButtons);
        
        props.updateFieldChange(false);


    }, [props.allParameters]);

    return (
        <Box id="parameter-container">
            {updatedFormFields &&
                <BuildForm
                    formArr={updatedFormFields}
                    buttons={buttons}
                    group={true}
                    onSubmit={onSubmitHandler}
                    onButtonClick={handleClickButton}
                    handleFormChange={(form: any) => enableSaveButton()}
                    saveDisabled={saveDisabled}
                    updateFieldChange={props.updateFieldChange}
                    buttonRef={props.buttonRef}
                    valueCondition={valueCondition}
                />
            }
        </Box>
    );
}

export default ParameterFormTab;
