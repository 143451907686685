import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {getPriceInFormatted} from "../../utils/Common";
import React from "react";

const EntitySkuPriceTable = (props: any) => {
    return (
        <Box>
            <Typography variant={"h5"} sx={{py: 2}}>Sku Prices</Typography>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow
                            sx={{
                                th: {
                                    border: 1,
                                    borderColor: "secondary.light",
                                    backgroundColor: "primary.light",
                                    fontWeight: "700",
                                },
                            }}
                        >
                            <TableCell>Region</TableCell>
                            <TableCell>Rate</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.skuPrices.map((skuPrice: any, index: number) => {
                            let region = skuPrice.region.replaceAll(", ", ",").replaceAll(",", ", ");
                            return (
                                <TableRow
                                    key={`sku-pricing-${index}`}
                                    sx={{
                                        td: {border: 1, borderColor: "secondary.light"},
                                    }}
                                >
                                    <TableCell sx={{textTransform: "capitalize"}}>{region}</TableCell>
                                    <TableCell>
                                        {getPriceInFormatted(
                                            skuPrice.rcPrice,
                                            skuPrice?.country
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default EntitySkuPriceTable;