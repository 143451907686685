import React, {useEffect, useState} from "react";
import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {Doughnut} from "react-chartjs-2";
import {getPriceInFormatted} from "../../utils/Common";
import {api} from "../../utils/Axios";
import Loader from "../Loader";
import {getChartOptionsWithinDisplay} from "../../utils/ChartUtils";

const BoqOverallSummary = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState<any>({});
    const [chartOptions, setChartOptions] = useState<any>({});
    const backgroundColors: string[] = ["#fbd474", "#A74462", "#048cdc", "#3F3B4E", "#fdb67a", "#5E33FF", "#33FF6B", "#FF5733",];
    const [mainText, setMainText] = useState("Overall");

    const [boqBreakupDetails, setBoqBreakupDetails] = useState<any[]>([]);
    const [summaryDetails, setSummaryDetails] = useState<any[]>([]);

    const loadProjectDetails = () => {
        setIsLoading(true);
        api.get(`/procurement/project/project-details/${props.project.id}`).then((response) => {
            return response.data;
        }).then(res => {
            props.project.projectDetails = res;
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        let overAllTotal = 0;
        const updatedChartData: any[] = [];
        const charges: any[] = [];

        let dactotal = props.project.carpetArea * props.project.projectDetails?.designConsultancyRate;
        let prelimCharges = props.project.carpetArea * props.project.projectDetails?.prelimsRate;
        overAllTotal += dactotal;
        overAllTotal += prelimCharges;
        updatedChartData.push({name: 'Design and Consultancy Charges', total: dactotal});
        updatedChartData.push({name: 'Prelim Charges', total: prelimCharges});
        charges.push({
            id: 'dcc',
            backgroundColor: "transparent",
            isCategory: false,
            name: "Design and Consultancy Charges",
            gsv: dactotal
        });

        charges.push({
            id: 'prelims',
            backgroundColor: "transparent",
            isCategory: false,
            name: "Prelim Charges",
            gsv: prelimCharges
        });

        boqBreakupDetails.map((boqDetails: any) => {
            let boqCategoryTotal = boqDetails.total;
            charges.push({
                id: boqDetails.id,
                backgroundColor: "transparent",
                isCategory: true,
                name: boqDetails.name,
                gsv: boqCategoryTotal
            });
            updatedChartData.push({name: boqDetails.name, total: boqCategoryTotal});
            overAllTotal += boqCategoryTotal
        });

        setChartData(
            {
                labels: updatedChartData.map((chartData: any) => chartData.name),
                datasets: [{
                    data: updatedChartData.map((chartData: any) => ((chartData.total as number) / (overAllTotal as number) * 100).toFixed(1)),
                    backgroundColor: backgroundColors,
                    borderColor: backgroundColors,
                    borderWidth: 0.2
                }]
            }
        );

        charges.push({
            id: 'total',
            backgroundColor: "info.contrastText",
            isCategory: false,
            name: "Total",
            gsv: overAllTotal
        });

        setSummaryDetails(charges);
    }, [boqBreakupDetails, props.project]);

    useEffect(() => {
        if (props.categories.length > 0) {
            const updatedCategories = props.categories.map((category: any) => {
                let boqTotal = 0;
                const subCategories = category.subCategories.map((subCategory: any) => {
                    const subCategoryBoq = props.boq.boqItems?.filter((boqItem: any) =>
                        boqItem.materialSubCategoryId === subCategory.id
                    );
                    if (subCategoryBoq && subCategoryBoq.length > 0) {
                        let subCategoryTotal = 0;
                        subCategoryBoq.map((boqItem: any) => {
                            subCategoryTotal += boqItem.boqSkus.reduce((acc: number, item: any) => acc + ((item.sellingPrice * item.quantity) + (parseFloat(item?.domainGsvCharges?.total) * parseInt(item.quantity))), 0);
                        });

                        boqTotal += subCategoryTotal;
                        return {
                            ...subCategory,
                            total: subCategoryTotal
                        };
                    }
                    return null;
                }).filter((subCategory: any) => subCategory !== null);
                if (subCategories.length > 0) {
                    return {
                        ...category,
                        total: boqTotal,
                        subCategories,
                    };
                }

                return null;
            }).filter((category: any) => category !== null);
            setBoqBreakupDetails(updatedCategories);

            if (props.project.projectDetails == null) {
                loadProjectDetails();
            }
        }
    }, [props.categories]);

    useEffect(() => {
        setChartOptions(getChartOptionsWithinDisplay());
    }, [chartData]);

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <Box sx={{py: 4}}>
            <Grid container spacing={5}>
                <Grid item xs={4}>
                    {Object.keys(chartData).length > 0 && Object.keys(chartOptions).length > 0 &&
                        <Box className="chart-container" sx={{position: 'relative', px: 2}}>
                            <Typography style={{
                                textAlign: "center",
                                position: 'absolute',
                                top: '50%',
                                left: 0,
                                right: 0,
                                marginLeft: props.mleft,
                                marginTop: props.mtop
                            }}>
                                <Typography component={"span"}
                                            sx={{
                                                width: '45%',
                                                fontSize: '20px',
                                                fontWeight: 600,
                                                display: 'inline-block',
                                                wordWrap: 'break-word'
                                            }}>
                                    {mainText}
                                </Typography>
                            </Typography>
                            <Doughnut
                                id={"sm-doughnut-chart-project"}
                                data={chartData} options={chartOptions}
                            />
                        </Box>
                    }
                </Grid>
                <Grid item xs>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead sx={{backgroundColor: "info.contrastText", border: 1, borderColor: 'divider'}}>
                                <TableRow>
                                    <TableCell
                                        sx={{fontSize: "14px", fontWeight: "600"}}
                                    >
                                        Name
                                    </TableCell>
                                    <TableCell
                                        sx={{fontSize: "14px", fontWeight: "600"}}
                                    >
                                        Total
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {summaryDetails.map((summaryDetail: any, summaryIndex: number) => {
                                    return (
                                        <TableRow sx={{backgroundColor: summaryDetail.backgroundColor, border: 1, borderColor: 'divider'}}
                                                  key={"summary-boq-" + summaryIndex}>
                                            <TableCell sx={{p: 2}}>
                                                <Typography sx={{fontSize: "15px", fontWeight: summaryDetail.id == 'total'? 600 : 500}}>
                                                    {summaryDetail.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{p: 2}}>
                                                <Typography sx={{fontSize: "15px", fontWeight: summaryDetail.id == 'total'? 600 : 500}}>
                                                    {getPriceInFormatted(summaryDetail.gsv, props.project.country)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    )
}

export default BoqOverallSummary;