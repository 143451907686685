import {useNavigate} from "react-router-dom";
import PageHeading from "../../components/page-heading/PageHeading";
import {Box} from "@mui/material";
import UserList from "../../components/users/UserList";

const User = () => {
    const navigate = useNavigate();

    const handleHeaderButtonClick = (data: any) => {
        if (data == 'create') {
            navigate("/users/create")
        }
        if (data == 'role') {
            navigate("/users/roles")
        }
    }

    const handleCallback = (rowData: any, buttonData: any) => {
        if (buttonData.type == 'edit') {
            navigate(`/users/edit/${rowData.id}`);
        }
    }

    return (
        <Box className={"common-space-container"}>
            <PageHeading
                title={"Users"}
                showButtons={true}
                buttons={[
                    {id: 'create', label: 'Create User', permissions: ["edit-users"]},
                    {id: 'role', label: 'Roles', permissions: ['edit-users']},
                ]}
                handleHeaderButtonClick={handleHeaderButtonClick}/>
            <Box>
                <UserList
                    listEndpoint={`/authentication/user`}
                    handleCallback={handleCallback}
                />
            </Box>
        </Box>
    )
}
export default User;