import BuildForm from "../../../../components/input-form/BuildForm";
import {api} from "../../../../utils/Axios";
import {useEffect, useState} from "react";
import Loader from "../../../../components/Loader";
import {getCurrencySymbol} from "../../../../utils/Common";
import {Box} from "@mui/material";

const deepClone = (obj: any) => {
    return JSON.parse(JSON.stringify(obj));
};

const ProjectDetailsTab = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);

    const [formFields, setFormFields] = useState<any>(deepClone(initialFormFields));
    const [saveDisabled, setSaveDisabled] = useState(false);

    const [buttons, setButtons] = useState([
        {
            type: 'submit',
            color: 'primary.main',
            variant: 'contained',
            title: 'Save and Continue',
            permissions: ['edit-projects']
        }
    ]);

    const updateFormFields = () => {
        const currencySymbol = getCurrencySymbol(props.project?.country);
        const updatedFields = formFields.map((formGroup: any) => ({
            ...formGroup,
            fields: formGroup.fields.map((field: any) => {
                if (props.project.status == 'CLOSED') {
                    field.readonly = true;
                }
                if (field.name === 'projectName' && props.project?.name) {
                    field.value = props.project.name;
                }
                if (field.name === 'projectLocation' && props.project?.location) {
                    field.value = props.project.location;
                }
                if (field.name === 'estimationType' && props.project?.estimationType) {
                    field.value = props.project.estimationType;
                }
                if (field.name === 'carpetArea' && props.project?.carpetArea) {
                    field.value = props.project.carpetArea;
                }
                if (field.name === 'carpetAreaInSqm' && props.project?.carpetAreaInSqm) {
                    field.value = props.project.carpetAreaInSqm;
                }
                if (field.name === 'areaUnderTerrace' && props.project?.areaUnderTerrace) {
                    field.value = props.project.areaUnderTerrace;
                }
                if (field.name === 'designConsultancyRate' || field.name === 'prelimsRate') {
                    field.prefix = currencySymbol;
                }
                if(field.name == 'isAuthorityApprovalCharges' && ["Mumbai", "Pune"].includes(props.project.projectStudio)) {
                    field.value = "true";
                }
                let domainDetailsReadOnly = true;
                if(props.project.status == 'CREATED') {
                    domainDetailsReadOnly = false;
                }
                if (props.project.projectDetails) {
                    switch (field.name) {
                        case 'tentativeProjectTimeline':
                            if (props.project.projectDetails.tentativeProjectTimelineInMonths) {
                                field.value = props.project.projectDetails.tentativeProjectTimelineInMonths;
                            }
                            break;
                        case 'fireApproval':
                            if (props.project.projectDetails.fireApproval) {
                                field.value = props.project.projectDetails.fireApproval;
                            }
                            break;
                        case 'sustainability':
                            if (props.project.projectDetails.sustainability) {
                                field.value = props.project.projectDetails.sustainability;
                            }
                            break;
                        case 'proposedFloors':
                            if (props.project.projectDetails.proposedFloors) {
                                field.value = props.project.projectDetails.proposedFloors;
                            }
                            break;
                        case 'totalProposedFloors':
                            if (props.project.projectDetails.totalProposedFloors) {
                                field.value = props.project.projectDetails.totalProposedFloors;
                            }
                            break;
                        case 'totalFloors':
                            if (props.project.projectDetails.totalFloors) {
                                field.value = props.project.projectDetails.totalFloors;
                            }
                            break;
                        case 'builtUpArea':
                            if (props.project.projectDetails.builtUpArea) {
                                field.value = props.project.projectDetails.builtUpArea;
                            }
                            break;
                        case 'designConsultancyRate':
                            if (props.project.projectDetails.designConsultancyRate) {
                                field.value = props.project.projectDetails.designConsultancyRate;
                            }
                            break;
                        case 'prelimsRate':
                            if (props.project.projectDetails.prelimsRate) {
                                field.value = props.project.projectDetails.prelimsRate;
                            }
                            break;
                        case 'waterAndPower':
                            if (props.project.projectDetails.waterAndPower) {
                                field.value = props.project.projectDetails.waterAndPower;
                            }
                            field.readonly = domainDetailsReadOnly;
                            break;
                        case 'workingType':
                            if (props.project.projectDetails.workingType) {
                                field.value = props.project.projectDetails.workingType;
                            }
                            field.readonly = domainDetailsReadOnly;
                            break;
                        case 'areaSlabDiscount':
                            field.value = props.project.projectDetails.areaSlabDiscount;
                            field.readonly = domainDetailsReadOnly;
                            break;
                        case 'premiumCharges':
                            field.value = props.project.projectDetails.premiumCharges;
                            field.readonly = domainDetailsReadOnly;
                            break;
                        case 'unionCharges':
                            field.value = props.project.projectDetails.unionCharges;
                            field.readonly = domainDetailsReadOnly;
                            break;
                        case 'serviceLift':
                            field.value = props.project.projectDetails.serviceLift;
                            field.readonly = domainDetailsReadOnly;
                            break;
                        case 'outstationProjectLocation':
                            field.value = props.project.projectDetails.outstationProjectLocation;
                            field.readonly = domainDetailsReadOnly;
                            break;
                        case 'totalHeadCount':
                            if (props.project.projectDetails.totalHeadCount) {
                                field.value = props.project.projectDetails.totalHeadCount;
                            }
                            break;
                        case 'headCountFromClient':
                            if (props.project.projectDetails.headCountFromClient) {
                                field.value = props.project.projectDetails.headCountFromClient;
                            }
                            break;
                        case 'isAuthorityApprovalCharges':
                            //if (props.project.projectDetails.isAuthorityApprovalCharges) {
                            field.value = props.project.projectDetails.isAuthorityApprovalCharges;
                            //}
                            break;
                        case 'authorityApprovalCharges':
                            if (props.project.projectDetails.authorityApprovalCharges) {
                                field.value = props.project.projectDetails.authorityApprovalCharges;
                            }
                            break;
                        case 'bocwApplicable':
                            //if (props.project.projectDetails.totalHeadCount) {
                            field.value = props.project.projectDetails.bocwApplicable;
                            //}
                            break;
                        case 'bocwApplicableRate':
                            //if (props.project.projectDetails.bocwApplicableRate) {
                            field.value = props.project.projectDetails.bocwApplicableRate;
                            //}
                            break;

                        case 'floorPlanLength':
                            if (props.project.projectDetails?.projectOfficeDetail?.floorPlanLength) {
                                field.value = props.project.projectDetails.projectOfficeDetail.floorPlanLength;
                            }
                            break;
                        case 'floorPlanWidth':
                            if (props.project.projectDetails.projectOfficeDetail?.floorPlanWidth) {
                                field.value = props.project.projectDetails.projectOfficeDetail.floorPlanWidth;
                            }
                            break;
                        case 'openCeilingOfficeArea':
                            if (props.project.projectDetails.projectOfficeDetail?.openCeilingOfficeArea) {
                                field.value = props.project.projectDetails.projectOfficeDetail.openCeilingOfficeArea;
                            }
                            break;
                        case 'closedCeilingOfficeArea':
                            if (props.project.projectDetails.projectOfficeDetail?.closedCeilingOfficeArea) {
                                field.value = props.project.projectDetails.projectOfficeDetail.closedCeilingOfficeArea;
                            }
                            break;
                        case 'falseFloorOfficeArea':
                            if (props.project.projectDetails.projectOfficeDetail?.falseFloorOfficeArea) {
                                field.value = props.project.projectDetails.projectOfficeDetail.falseFloorOfficeArea;
                            }
                            break;
                        case 'greenwall':
                            if (props.project.projectDetails.projectOfficeDetail?.greenwall) {
                                field.value = props.project.projectDetails.projectOfficeDetail.greenwall;
                            }
                            break;
                        /*case 'downLightsProposedArea':
                            if (props.project.projectDetails.projectOfficeDetail?.downLightsProposedArea) {
                                field.value = props.project.projectDetails.projectOfficeDetail.downLightsProposedArea;
                            }
                            break;
                            */
                        case 'corridorLength':
                            if (props.project.projectDetails.projectOfficeDetail?.corridorLength) {
                                field.value = props.project.projectDetails.projectOfficeDetail.corridorLength;
                            }
                            break;
                    }
                    setSaveDisabled(true);
                }
                return field;
            })
        }));
        setFormFields(updatedFields);
    };

    const onFormButtonHandler = (type: any, form: any, callback: any) => {
        props.proceedTabChange("site-condition", {type: props.project.estimationType});
    };

    const onSubmitHandler = (form: any, callback: any) => {
        if (props.project.projectDetails != null) {
            form.id = props.project.projectDetails?.id;
        }

        var officeDetails: any = {};
        officeDetails.floorPlanLength = form.floorPlanLength;
        officeDetails.floorPlanWidth = form.floorPlanWidth;
        officeDetails.openCeilingOfficeArea = form.openCeilingOfficeArea;
        officeDetails.closedCeilingOfficeArea = form.closedCeilingOfficeArea;
        officeDetails.falseFloorOfficeArea = form.falseFloorOfficeArea;
        officeDetails.greenwall = form.greenwall;
        //officeDetails.downLightsProposedArea = form.downLightsProposedArea;
        officeDetails.corridorLength = form.corridorLength;
        form.projectOfficeDetail = officeDetails;

        props.handleDisableNextTabs("site-condition", true);
        setIsLoading(true);
        api.put(`/procurement/project/update-details/${props.project.id}`, form).then((response) => {
            return response.data;
        }).then(res => {
            props.project.status = res.status;
            props.project.stage = res.stage;
            props.project.estimationType = res.estimationType;
            props.project.projectDetails = res.projectDetails;
            setIsLoading(false);
            props.proceedTabChange("site-condition", {type: res.estimationType});
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    };

    const loadProjectDetails = async () => {
        setIsLoading(true);
        api.get(`/procurement/project/project-details/${props.project.id}`).then((response) => {
            return response.data;
        }).then(res => {
            props.project.projectDetails = res;
            setFormFields(deepClone(initialFormFields));
            updateFormFields();
            setIsLoading(false);
            const updatedButtons: any[] = [];
            if (props.project.status == 'CLOSED') {
                updatedButtons.push(
                    {
                        type: 'continue',
                        color: 'common.main',
                        disabledButton: false,
                        variant: 'outlined',
                        title: 'Continue',
                        permissions: ['edit-projects']
                    }
                );
            } else {
                updatedButtons.push(
                    {
                        type: 'submit',
                        color: 'primary.main',
                        disabledButton: true,
                        variant: 'contained',
                        title: 'Save',
                        permissions: ['edit-projects']
                    }
                );

                if (props.project?.projectDetails) {
                    updatedButtons.push(
                        {
                            type: 'continue',
                            color: 'common.main',
                            disabledButton: false,
                            variant: 'outlined',
                            title: 'Submit & Continue',
                            permissions: ['edit-projects']
                        }
                    );
                }
            }

            setButtons(updatedButtons);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    };

    const handleFormChange = (form: any) => {
        setSaveDisabled(false);
    }

    useEffect(() => {
        loadProjectDetails();
    }, [props.project.id]);

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <Box>
            <BuildForm
                formArr={formFields}
                buttons={buttons}
                group={true}
                onSubmit={onSubmitHandler}
                onButtonClick={onFormButtonHandler}
                handleFormChange={handleFormChange}
                saveDisabled={saveDisabled}
            />
        </Box>
    );
};

const initialFormFields = [
    {
        groupName: "Project Details",
        fields: [
            {
                element: 'input',
                label: "Project Name",
                name: "projectName",
                required: true,
                mainContainerWidth: 6,
                width: 8
            },
            {
                element: 'select',
                label: "Project Location",
                name: "projectLocation",
                required: true,
                mainContainerWidth: 6,
                width: 8,
                options: [
                    {id: 'north', label: 'North'},
                    {id: 'south', label: 'South'},
                    //{id: 'east', label: 'East'},
                    {id: 'west', label: 'West'},
                    {id: 'South-Chennai', label: 'South Chennai'},
                    {id: 'Non Office location', label: 'Non Office Location'},
                    {id: 'South-Others', label: 'South Others'},
                ]
            },
            {
                element: 'select',
                label: "Method",
                name: "estimationType",
                required: true,
                mainContainerWidth: 6,
                readonly: true,
                width: 8,
                options: [
                    {id: 'scratch', label: 'Create From Scratch'},
                    {id: 'engine', label: 'Generate Estimate'},
                    {id: 'clone', label: 'Clone Project'},
                ]
            }
        ]
    },
    {
        groupName: 'Carpet Area',
        fields: [
            {
                element: 'input',
                label: "Carpet area of Project(Sqft)",
                placeholder: 'Carpet area of Project(Sqft)',
                name: "carpetArea",
                required: true,
                type: "number",
                mainContainerWidth: 6,
                width: 8
            }
        ],
    },
    {
        groupName: "Built up Area",
        fields: [
            {
                element: 'input',
                label: "Built Up Area(Sqft)",
                placeholder: 'Built Up Area(Sqft)',
                name: "builtUpArea",
                required: true,
                type: "number",
                mainContainerWidth: 6,
                width: 8
            }
        ]
    },
    {
        groupName: 'Design & Consultancy Rate ',
        fields: [
            {
                element: 'input',
                label: "Design Consultancy per Sqft",
                placeholder: "Design Consultancy per Sqft",
                name: "designConsultancyRate",
                required: true,
                type: "number",
                mainContainerWidth: 6,
                width: 8
            },
            {
                element: 'input',
                label: "Prelims Cost Per Sqft",
                name: "prelimsRate",
                required: true,
                type: "number",
                mainContainerWidth: 6,
                width: 8
            }
        ],
    },
    {
        groupName: 'Floors',
        fields: [
            {
                element: 'input',
                label: "Proposed Floor",
                name: "proposedFloors",
                required: true,
                info: "Please enter comma separated values",
                mainContainerWidth: 6,
                width: 8,
                type: "text",
                pattern: "^[0-9,]*$"
            },
            {
                element: 'input',
                label: "No. of floors in the building",
                name: "totalFloors",
                required: true,
                type: "number",
                mainContainerWidth: 6,
                width: 8
            },
            {
                element: 'input',
                label: "Number of floors to be fitted out",
                name: "totalProposedFloors",
                required: true,
                value: "1",
                mainContainerWidth: 6,
                width: 8,
                type: "text",
                pattern: "^[0-9,]*$"
            },
        ],
    },
    {
        groupName: 'Office Details',
        fields: [
            {
                element: 'input',
                label: 'Floor Plan Length (Mtr.)',
                placeholder: 'Floor Plan Length (Mtr.)',
                name: 'floorPlanLength',
                type: 'number',
                value: 0,
                width: 2.4,
                required: true,
            },
            {
                element: 'input',
                label: 'Floor Plan Width (Mtr.)',
                placeholder: 'Floor Plan Width (Mtr.)',
                name: 'floorPlanWidth',
                type: 'number',
                value: 0,
                width: 2.4,
                required: true,
            },
            {
                element: 'input',
                label: 'Open Ceiling Office Area (Sqm)',
                placeholder: 'Open Ceiling Office Area (Sqm)',
                name: 'openCeilingOfficeArea',
                type: 'number',
                value: 0,
                width: 2.4,
                required: true,
            },
            {
                element: 'input',
                label: 'Closed Ceiling Office Area (Sqm)',
                placeholder: 'Closed Ceiling Office Area (Sqm)',
                name: 'closedCeilingOfficeArea',
                type: 'number',
                value: 0,
                width: 2.4,
                required: true,
            },
            {
                element: 'input',
                label: 'False Floor Office Area (Sqm)',
                placeholder: 'False Floor Office Area (Sqm)',
                name: 'falseFloorOfficeArea',
                type: 'number',
                value: 0,
                width: 2.4,
                required: true,
            },
            {
                element: 'input',
                label: 'Greenwall (Live) (No.s)',
                placeholder: 'Greenwall (Live) (No.s)',
                name: 'greenwall',
                type: 'number',
                value: 0,
                width: 2.4,
                required: true,
            },
            /*{
                element: 'input',
                label: 'Downlights proposed area',
                placeholder: 'Downlights proposed area',
                name: 'downLightsProposedArea',
                type: 'number',
                value: 0,
                width: 2.4,
                required: true,
            },*/
            {
                element: 'input',
                label: 'Corridor Length (Mtr)',
                placeholder: 'Corridor Length (Mtr)',
                name: 'corridorLength',
                type: 'number',
                value: 0,
                width: 2.4,
                required: true,
            }
        ],
    },
    {
        groupName: 'Sustainability',
        fields: [
            {
                element: 'select',
                label: "Scope",
                name: 'sustainability',
                mainContainerWidth: 6,
                width: 8,
                value: "Leed & Well",
                options: [
                    {id: "None", label: 'None'},
                    {id: 'Leed', label: 'Leed'},
                    {id: 'Well', label: 'Well'},
                    {id: 'Leed & Well', label: 'Leed & Well'},
                ]
            },
        ]
    },
    {
        groupName: 'Fire Approval',
        fields: [
            {
                element: 'select',
                label: "Scope",
                name: "fireApproval",
                mainContainerWidth: 6,
                width: 8,
                value: 'UL&FM',
                options: [
                    {id: 'UL', label: 'UL'},
                    {id: 'UL&FM', label: 'UL&FM'},
                ]
            },
        ]
    },
    {
        groupName: 'Head Count',
        fields: [
            {
                element: 'input',
                label: "Total Head Count",
                name: "totalHeadCount",
                disableOn: true,
                mainContainerWidth: 6,
                width: 8,
                type: 'number',
            },
            {
                element: 'select',
                label: "Head count/design details from client",
                name: "headCountFromClient",
                mainContainerWidth: 6,
                disableField: "totalHeadCount",
                disableOnValue: "NO",
                width: 8,
                value: 'YES',
                options: [
                    {id: 'YES', label: 'YES'},
                    {id: 'NO', label: 'NO'},
                ]
            }
        ]
    },
    {
        groupName: 'Project Timeline Months',
        fields: [
            {
                element: 'input',
                label: "Tentative project timeline in months",
                name: 'tentativeProjectTimeline',
                mainContainerWidth: 6,
                width: 8,
                value: 12,
                type: 'number',
                options: [
                    {id: '3', label: '3 Months'},
                    {id: '6', label: '6 Months'},
                    {id: '9', label: '9 Months'},
                    {id: '12', label: '12 Months'},
                    {id: '15', label: '15 Months'},
                    {id: '18', label: '18 Months'},
                    {id: '21', label: '21 Months'},
                    {id: '24', label: '24 Months'}
                ]
            },
        ]
    },
    {
        groupName: 'Project Domain Details',
        fields: [
            {
                element: 'select',
                label: "Water and Power",
                name: 'waterAndPower',
                mainContainerWidth: 6,
                width: 8,
                value: "waterChargesForFromUs",
                options: [
                    {id: "waterChargesForFromClient", label: 'Water & Power FOC from client'},
                    {
                        id: "waterChargesForChargeableFromClient",
                        label: 'Water & Power - FROM CLIENT ON CHARGABLE BASIS'
                    },
                    {
                        id: "waterChargesForFromUs",
                        label: 'Water & Power need to be arranged by SM at site (DG + Water tanker)'
                    }
                ]
            },
            {
                element: 'select',
                label: "Working Type",
                name: 'workingType',
                mainContainerWidth: 6,
                width: 8,
                value: "nightShiftAndWeekend",
                options: [
                    {id: "allWorkingDays", label: '24 x 7'},
                    {id: "nightShift", label: 'Only Night shifts'},
                    {id: "nightShiftAndWeekend", label: 'Night shift + Weekends'}
                ]
            },
            {
                element: 'select',
                label: "Apply Union Charges",
                name: 'unionCharges',
                mainContainerWidth: 6,
                width: 8,
                value: "false",
                options: [
                    {id: "false", label: 'No'},
                    {id: "true", label: 'Yes'}
                ]
            },
            {
                element: 'select',
                label: "Apply Area Slab Discount",
                name: 'areaSlabDiscount',
                mainContainerWidth: 6,
                width: 8,
                options: [
                    {id: "false", label: 'No'},
                    {id: "true", label: 'Yes'}
                ]
            },
            {
                element: 'select',
                label: "Apply Premium Charges",
                name: 'premiumCharges',
                mainContainerWidth: 6,
                width: 8,
                options: [
                    {id: "false", label: 'No'},
                    {id: "true", label: 'Yes'}
                ]
            },
            {
                element: 'select',
                label: "Service Lift",
                name: 'serviceLift',
                mainContainerWidth: 6,
                width: 8,
                value: "false",
                options: [
                    {id: "false", label: 'Not Provided'},
                    {id: "true", label: 'Provided'}
                ]
            },
            {
                element: 'select',
                label: "Outstation Project Location",
                name: 'outstationProjectLocation',
                info: 'Outstation Project Location value is Yes then 3% will be applied',
                mainContainerWidth: 6,
                width: 8,
                value: "false",
                options: [
                    {id: "false", label: 'No'},
                    {id: "true", label: 'Yes'}
                ]
            }

        ]
    },
    {
        groupName: 'Authority Approval Charges',
        fields: [
            {
                element: 'select',
                label: "Is Local Authority Approval Charges Applicable?",
                name: 'isAuthorityApprovalCharges',
                mainContainerWidth: 6,
                width: 8,
                disableField: "authorityApprovalCharges",
                disableOnValue: false,
                value: false,
                options: [
                    {id: false, label: 'No'},
                    {id: true, label: 'Yes'}
                ]
            },
            {
                element: 'input',
                label: "If yes, charges for the overall project",
                name: "authorityApprovalCharges",
                mainContainerWidth: 6,
                width: 8,
                type: "number",
                pattern: "^[0-9,]*$",
                disableOn: true,
            }
        ]
    },
    {
        groupName: 'BOCW Details',
        fields: [
            {
                element: 'select',
                label: "Is BOCW appicable?",
                name: 'bocwApplicable',
                mainContainerWidth: 6,
                width: 8,
                disableField: "bocwApplicableRate",
                disableOnValue: false,
                value: false,
                options: [
                    {id: false, label: 'No'},
                    {id: true, label: 'Yes'}
                ]
            },
            {
                element: 'input',
                label: "If yes, what is the applicable rate?",
                name: "bocwApplicableRate",
                mainContainerWidth: 6,
                width: 8,
                value: "1.2",
                disableOn: true,
                type: "text",
                pattern: "^[0-9,]*$"
            },
        ]
    }
];

export default ProjectDetailsTab;