import {Typography} from "@mui/material";
import {getPriceInFormatted} from "../../../../utils/Common";

export const packageColumns = [
    {field: 'id', headerName: 'ID', headerClassName: 'data-grid-header', flex: 1},
    {
        field: 'itemCategoryName',
        headerName: "Item Category",
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 140,
        valueGetter: (params: any) => params.row?.itemCategory?.name
    },
    {
        field: 'selectedItem',
        headerName: 'Selected Item',
        headerClassName: 'data-grid-header',
        flex: 1,
        sortable: false,
        renderCell: (params: any) => <a href={`/catalogue/skus/details/${params.row?.skuId}`}
                                        target={"_blank"}>{params.row?.sku?.name}</a>
    },
    {
        field: 'skuUom',
        headerName: "UOM",
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 80,
        valueGetter: (params: any) => params.row?.sku?.uom
    },
    {
        field: 'quantity',
        headerName: 'Quantity',
        headerClassName: 'data-grid-header',
        flex: 1,
        sortable: false,
        maxWidth: 100
    },
    {
        field: 'skuSellingPrice',
        headerName: 'Rate Per UOM',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 150,
        renderCell: (params: any) => {
            let total = parseFloat(params.row?.sellingPrice) + parseFloat(params.row?.domainGsvCharges?.total);
            return (
                <>{getPriceInFormatted(total, params.row.country)}</>
            )
        }
    },
    {
        field: 'skuRcVendors',
        headerName: 'RC',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        maxWidth: 80,
        renderCell: (params: any) => params.row?.sku?.rcVendors
    },
    {
        field: 'skuTotal',
        headerName: 'Total',
        type: 'price',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        renderCell: (params: any) => {
            let total = (parseFloat(params.row?.sellingPrice) * parseInt(params.row?.quantity)) + (parseInt(params.row?.quantity) * parseFloat(params.row.domainGsvCharges.total));
            return (<>{getPriceInFormatted(total, params.row.country)}</>)
        }
    },
    {
        field: 'packageStatus',
        headerName: 'Package Status',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        renderCell:
            (params: any) => {
                let status =  params.row?.status == undefined && params.row.remainingQuantity == params.row.quantity ? "Not Created" : params.row?.status == 'created' ? 'Not Created' : params.row?.remainingQuantity == 0 ? 'Created' : 'Partially Created'
                return (
                    <Typography>
                        {status}
                        <Typography component={"span"}
                                    sx={{pl: 2}}>{params.row?.quantity - params.row?.remainingQuantity}
                            <Typography component={"span"}
                                        sx={{color: '#CBCBCB'}}> of {params.row?.quantity}</Typography>
                        </Typography>
                    </Typography>
                )
            }
    }
];