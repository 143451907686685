import {useEffect, useState} from "react";
import {api} from "../../utils/Axios";
import {useAppDispatch} from "../../app/hooks";
import {Box} from "@mui/material";
import ListItemCategoryAndItems from "../estimation/ListItemCategoryAndItems";
import ListEstimationSkusPopup from "../estimation/sku-popup/ListEstimationSkusPopup";
import Loader from "../Loader";
import {handleRemoveItemFromECatalog} from "../../modules/project/plan/tabs/estimation-tabs/features/actions";
import {openSnackbar} from "../common/features/snackbarSlice";
import EstimationSkuSearchList from "../estimation/sku-popup/EstimationSkuSearchList";
import {
    ERROR_COLOR,
    REMOVED_ITEM_CATEGORY_FAILED,
    REMOVED_ITEM_CATEGORY_SUCCESSFULLY,
    SUCCESS_COLOR
} from "../../constants";

const ECatalogItemListing = (props: any) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [vendorEntities, setVendorEntities] = useState<any>([]);
    const [brandEntities, setBrandEntities] = useState<any>([]);
    const [itemCategories, setItemCategories] = useState<any>([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [skuSelectionItem, setSkuSelectionItem] = useState({});

    const loadActiveEntities = (entityType: string) => {
        //setIsLoading(true);
        let url = `/procurement/vendor/get-vendors`;
        if (entityType == 'brand') {
            url = `/procurement/brand/get-brands`;
        }

        api.get(url).then((response) => {
            return response.data;
        }).then((response: any) => {
            if (entityType == 'brand') {
                setBrandEntities(response);
            } else {
                setVendorEntities(response);
            }
            //setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            //setIsLoading(false);
        });
    }

    const loadItemCategories = (selectedItems: any) => {
        //setIsLoading(true);

        const params = new URLSearchParams({});
        if (Object.keys(props.appliedFilters).length > 0) {
            Object.entries(props.appliedFilters).map(([name, value]: any) => {
                params.append(name, value);
            });
        }

        let url = `/catalogue/item-category/get-for-all/${props.project.country}/${props.project.location}/${props.subCategoryId}`;
        api.get(url, {
                params: params,
            }).then((response) => {
            return response.data;
        }).then((response: any) => {
            if (typeof selectedItems == "object" && selectedItems.length > 0) {
                response.map((itemCategory: any) => {
                    selectedItems.map((selectedItem: any) => {
                        if (selectedItem.itemCategoryId == itemCategory.id) {
                            itemCategory.items.map((item: any) => {
                                if (item.id == selectedItem.itemId) {
                                    item.totalSelectedSkus = selectedItem.totalSkus;
                                    item.totalQuantity = selectedItem.totalQuantity;
                                    item.total = selectedItem.total;
                                }
                            });
                        }
                    });
                });
            }
            setItemCategories(response);
            //setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            //setIsLoading(false);
        });
    }

    const loadSelectedItems = () => {
        setIsLoading(true);
        let url = `/procurement/e-catalog/get-selected-items/${props.project.id}/${props.subCategoryId}`;

        api.get(url).then((response) => {
            return response.data;
        }).then((response: any) => {
            setItemCategories([]);
            loadItemCategories(response);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const handleSkuPopupCallBack = (eventData: any) => {
        setOpenPopup(false);
    };

    const handleSkuSelection = (item: any, itemCategoryId: string) => {
        item.itemCategoryId = itemCategoryId;
        setSkuSelectionItem(item);
        setOpenPopup(true);
    }

    const deleteItem = async (toDeleteItem: any) => {
        try {
            const response = await dispatch(
                handleRemoveItemFromECatalog({
                    projectId: props?.project.id,
                    materialSubCategoryId: props?.subCategoryId,
                    itemId: toDeleteItem.id,
                })
            );
            if (response.payload) {
                itemCategories.map((itemCategory: any) => {
                    itemCategory.items.map((item: any) => {
                        if (item.id == toDeleteItem.id) {
                            delete item.total;
                            delete item.totalQuantity;
                            delete item.totalSelectedSkus;
                        }
                    });
                });
                setItemCategories(itemCategories);

                dispatch(
                    openSnackbar({
                        message: `${toDeleteItem.id}:${REMOVED_ITEM_CATEGORY_SUCCESSFULLY}`,
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
            }
        } catch (err: any) {
            dispatch(
                openSnackbar({
                    message: REMOVED_ITEM_CATEGORY_FAILED,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    }

    useEffect(() => {
        loadActiveEntities("vendor");
        loadActiveEntities("brand");
    }, [props.type]);
/*
    useEffect(() => {
        console.log(props.categoryId);
        loadSelectedItems();
    }, [props.subCategoryId]);
    */

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <>
            <Box className={"e-catalog-block"} sx={{}}>
                {<EstimationSkuSearchList
                    vendorEntities={vendorEntities}
                    brandEntities={brandEntities}
                    tab={"e-catalog"}
                    subCategoryId={props.subCategoryId}
                    category={props.category}
                    project={props.project}
                    appliedFilters={props.appliedFilters}
                    searchQuery={props.searchQuery}
                    handleSkuPopupCallBack={handleSkuPopupCallBack}
                    updateAssignedQty={props.updateAssignedQty}
                    updateApiLoading={props.updateApiLoading}
                />}
            </Box>            
        </>
    )
}

export default ECatalogItemListing;