export const USER_ROLES = "v0.0_scopes"

export const SUCCESS_COLOR = 'green'

export const ERROR_COLOR = 'red'

// Constants for Event Details Popup
export const ACCEPT_BID_SUCCESS = "Bid accepted succesffuly";
export const ACCEPT_BID_FAILED = "Failed to accept the bids!";


// Constants for Event Details Popup
export const SAVE_BIDS_SUCCESS_MESSAGE = "Successfully saved the bids.";
export const SAVE_BIDS_FAILURE_MESSAGE = "Failed to save the bids!";
export const SELECT_ALL_VALUES_ERROR_MESSAGE = "Please select all values.";


// Constants for ESTIMATE details
export const REMOVED_ITEM_CATEGORY_SUCCESSFULLY = "Item Category deleted";
export const REMOVED_ITEM_CATEGORY_FAILED = "Error! Couldn't delete Item category";

// Constants for Save Draft details
export const DRAFT_SAVED_SUCCESSFULLY = "Draft Saved Successfully";
export const DRAFT_SAVED_FAILED = "Error! Draft couldn't be saved!";


// Constants for Approve Purchase Request  details
export const APPROVE_PR_SUCCESS = "Purchase Request Approved";
export const APPROVE_PR_FAILED = "Error! Purchase Request Couldn't be Approved!";

// Constants for Save Publishing details
export const PART_PUBLISHED_SUCCESSFULLY = "Part Successfully Published";
export const PART_PUBLISHED_FAILED= "Error! Part cannot be published!";

// Constants for Save Part Draft details
export const DRAFT_PART_SUCCESSFULLY = "Part Successfully Drafted";
export const DRAFT_PART_FAILED= "Error! Part cannot be drafted!";
export const DUPLICATE_PART_NAME= "Error! Part name already exist!";

// Constants for Save Part Draft details
export const REMOVED_ITEM_SUCCESS = "Item Successfully removed";
export const REMOVED_PART_SUCCESS = "Part Successfully removed";
export const REMOVED_ITEM_FAILURE= "Error! Item couldn't be removed!";
export const REMOVED_PART_FAILURE= "Error! Part couldn't be removed!";

// SKU Price Removal
export const REMOVED_SKU_PRICE_SUCCESSFULLY = "SKU price removed successfully!";
export const REMOVED_SKU_PRICE_FAILED = "SKU price removal failed!";

export const COGS_UPDATED_SUCCESSFULLY = 'COGS Price updated successfully.'
export const COGS_UPDATED_FAILURE  = 'COGS Price updated failure.'

export const RC_PRICE_UPDATED_SUCCESSFULLY = 'Rate Contract Price updated successfully.'
export const RC_PRICE_UPDATED_FAILURE  = 'Rate Contract Price updated failure.'

export const APPROVE_ESTIMATION_SUCCESS = "Estimation published successfully";
export const APPROVE_ESTIMATION_ERROR = "Estimation publish failed";

export const CLONE_ESTIMATION_REVISION_SUCCESS = "Estimation revision created successfully";
export const CLONE_ESTIMATION_REVISION_ERROR = "Estimation revision creation failed";


