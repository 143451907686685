import { Button, Grid, Typography } from "@mui/material";
import { getPriceInFormatted } from "../../../../utils/Common";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

const SubCategoryBlock = (props: any) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={4} container alignItems="center">
        <Typography
          sx={{
            padding: "0 8px",
            fontWeight: 600,
            fontSize: "12px",
          }}
        >
          {props.subCategory?.name}
        </Typography>
      </Grid>
      <Grid item xs={2} container alignItems="center">
        <Typography
          sx={{
            padding: "0 8px",
            fontWeight: 600,
            fontSize: "12px",
          }}
        >
          Total Skus: {props.totalSkus}
        </Typography>
      </Grid>
      <Grid item xs={5} container alignItems="center">
        <Typography
          sx={{
            padding: "0 8px",
            fontWeight: 600,
            fontSize: "12px",
            marginRight: "2rem",
          }}
        >
          Total Amount:{" "}
          {getPriceInFormatted(props.total, props.project.country)}
        </Typography>
      </Grid>
      <Grid item xs={1} container alignItems="center">
        <Button
          onClick={() => props.handleExpand()}
          sx={{
            py: 0,
            minWidth: "auto",
          }}
        >
          {props.expandSubCategory ? (
            <KeyboardArrowUpIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SubCategoryBlock;
