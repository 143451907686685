import {pageConfig} from "../../modules/user/config/users";
import {useEffect, useState} from "react";
import {api} from "../../utils/Axios";
import Loader from "../Loader";
import ListPage from "../list-page/ListPage";
import SearchFilter from "../filter/SearchFilter";
import {Box} from "@mui/material";

const UserList = (props: any) => {
    const pageConfigs: any = pageConfig;
    const [isLoading, setIsLoading] = useState(false);
    const [rows, rowChange] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, pageChange] = useState(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);

    const handelSearchFilter = (query: any) => {
        setSearchQuery(query);
    }

    useEffect(() => {
        loadUsers();
    }, [sortColumn, sortDirection, page, size, searchQuery]);

    const handleSort = (sortModel: any) => {
        setSortColumn(sortModel.field);
        setSortDirection(sortModel?.sort);
        pageChange(0);
    }

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    }

    const loadUsers = () => {
        setIsLoading(true);
        api.get(props.listEndpoint, {
            params: {
                pageNo: page,
                pageSize: size,
                sort: sortDirection?.toUpperCase(),
                sortByColumn: sortColumn,
                searchQuery: searchQuery
            }
        }).then((response) => {
            return response.data;
        }).then((response: any) => {
            rowChange(response.content);
            setTotalElements(response.totalElements);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const handleCallback = (rowData: any, buttonData: any) => {
        props.handleCallback(rowData, buttonData);
    }


    if (isLoading) {
        return <Loader/>
    }

    return (
        <>
            <Box sx={{pb: 2}} className={'right-align-content'}>
                <SearchFilter showSearch={true} query={searchQuery} handleSearchFilter={handelSearchFilter}/>
            </Box>
            <ListPage
                rows={rows}
                columns={pageConfigs.columns}
                page={page}
                size={size}
                totalElements={totalElements}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                handlePagination={handlePagination}
                handleSort={handleSort}
                handleCallBack={handleCallback}
            />
        </>
    )
}

export default UserList;