import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import styles from "./EstimationSummary.module.css";
import SummaryContainer from "./SummaryContainer";
import {api} from "../../../utils/Axios";
import LeftContainer from "./LeftContainer";
import {ItemTypes} from "../../../modules/project/plan/tabs/boq-tabs/ItemTypes";
import {
    postDraftSection,
    removeItemsFromSummaryDetails,
    removeSummary
} from "../../../modules/project/plan/tabs/boq-tabs/features/actions";
import {openSnackbar} from "../../common/features/snackbarSlice";
import {
    DRAFT_PART_FAILED,
    DRAFT_PART_SUCCESSFULLY,
    DUPLICATE_PART_NAME,
    ERROR_COLOR,
    REMOVED_ITEM_FAILURE,
    REMOVED_ITEM_SUCCESS,
    REMOVED_PART_FAILURE,
    REMOVED_PART_SUCCESS,
    SUCCESS_COLOR
} from "../../../constants";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import BoqBreakupSummary from "../BoqBreakupSummary";
import PartBreakupSummary from "../PartBreakupSummary";
import OverallSummary from "../OverallSummary";
import EstimationLoader from "../EstimationLoader";
import NewClientSummary from "../client-summary/NewClientSummary";

const EstimationSummary = (props: any) => {
    let counter = 0;
    const dispatch: any = useAppDispatch();
    const isError = useAppSelector((state: any) => state.boqSummary.error);
    const [isLoading, setIsLoading] = useState(false);
    const [isSummaryLoading, setIsSummaryLoading] = useState(false);
    const [estimations, setEstimations] = useState<any>([]);
    const [estimationSummary, setEstimationSummary] = useState<any>([]);
    const [estimationError, setEstimationError] = useState(false);
    const [newSectionName, setNewSectionName] = useState("");
    const [sections, setSections] = useState<any>([]);
    const [publishedParts, setPublishedParts] = useState<any>([]);
    const [activeTab, setActiveTab] = useState({type: 'left', index: 0});

    const leftAlignedTabs = [
        {
            label: 'Overall Summary',
            content: <OverallSummary project={props.project} estimations={estimations} categories={props.categories}
                                     showUpdatedCharges={props.showUpdatedCharges}/>
        },
        {
            label: 'BOQ Breakup',
            content: <BoqBreakupSummary project={props.project} estimations={estimations} categories={props.categories}
                                        showUpdatedCharges={props.showUpdatedCharges}/>
        },
    ];

    const rightAlignedTabs = [
        {
            label: 'Client Summary',
            content: <NewClientSummary project={props.project} revision={props.revision} publishedParts={publishedParts}
                                       estimations={estimations} categories={props.categories}
                                       showUpdatedCharges={props.showUpdatedCharges}
                                       refreshRevision={props.refreshRevision}/>
        },
        {
            label: 'Client Breakup',
            content: <PartBreakupSummary
                publishedParts={publishedParts} project={props.project}
                estimations={estimations} categories={props.categories}
                showUpdatedCharges={props.showUpdatedCharges}
            />
        },
    ];

    const handleDrop = async (itemType: any, section: any) => {
        const items = [...section.items];
        const data: any[] = [];

        switch (itemType.type) {
            case ItemTypes.CATEGORY:
                props.categories.map((category: any) => {
                    if (category.id == itemType.id) {
                        category.subCategories.map((subCategory: any) => {
                            estimations?.map((estimation: any) => {
                                if (estimation.materialSubCategoryId === subCategory.id) {
                                    estimation.estimationItems.map((estimationItem: any) => {
                                        const estimationItemSkuIds: any[] = [];
                                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                            let partAvailable = false;
                                            if (items.length > 0) {
                                                items.map((partItem: any) => {
                                                    if (partItem.id == estimationItemSku.id) {
                                                        partAvailable = true;
                                                    }
                                                });
                                            }
                                            if (!partAvailable) {
                                                estimationItemSkuIds.push(estimationItemSku.id);
                                                estimationItemSku.disabled = true;
                                                items.push({
                                                    ...estimationItemSku, materialSubCategoryId: subCategory.id
                                                });
                                            }
                                        });

                                        data.push({
                                            id: estimationItem.id,
                                            skus: estimationItemSkuIds
                                        });
                                    });
                                }
                            });
                        });
                    }
                });
                break;
            case ItemTypes.SUBCATEGORY:
                props.categories.map((category: any) => {
                    category.subCategories.map((subCategory: any) => {
                        if (subCategory.id == itemType.id) {
                            estimations?.map((estimation: any) => {
                                if (estimation.materialSubCategoryId === subCategory.id) {
                                    estimation.estimationItems.map((estimationItem: any) => {
                                        const estimationItemSkuIds: any[] = [];
                                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                            let partAvailable = false;
                                            if (items.length > 0) {
                                                items.map((partItem: any) => {
                                                    if (partItem.id == estimationItemSku.id) {
                                                        partAvailable = true;
                                                    }
                                                });
                                            }
                                            if (!partAvailable) {
                                                estimationItemSkuIds.push(estimationItemSku.id);
                                                estimationItemSku.disabled = true;
                                                items.push(estimationItemSku);
                                            }
                                        });
                                        data.push({
                                            id: estimationItem.id,
                                            skus: estimationItemSkuIds
                                        });
                                    });
                                }
                            });
                        }
                    });
                });
                break;
            case ItemTypes.ITEM:
                estimations?.map((estimation: any) => {
                    estimation.estimationItems.map((estimationItem: any) => {
                        if (estimationItem.id == itemType.id) {
                            const estimationItemSkuIds: any[] = [];
                            estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                let partAvailable = false;
                                if (items.length > 0) {
                                    items.map((partItem: any) => {
                                        if (partItem.id == estimationItemSku.id) {
                                            partAvailable = true;
                                        }
                                    });
                                }
                                if (!partAvailable) {
                                    estimationItemSkuIds.push(estimationItemSku.id);
                                    estimationItemSku.disabled = true;
                                    items.push(estimationItemSku);
                                }
                            });
                            data.push({
                                id: estimationItem.id,
                                skus: estimationItemSkuIds
                            })
                        }
                    });
                });
                break;
            case ItemTypes.SKU:
                estimations?.map((estimation: any) => {
                    estimation.estimationItems.map((estimationItem: any) => {
                        const estimationItemSkuIds: any[] = [];
                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                            if (estimationItemSku.id == itemType.id) {
                                let partAvailable = false;
                                if (items.length > 0) {
                                    items.map((partItem: any) => {
                                        if (partItem.id == estimationItemSku.id) {
                                            partAvailable = true;
                                        }
                                    });
                                }
                                if (!partAvailable) {
                                    estimationItemSkuIds.push(estimationItemSku.id);
                                    estimationItemSku.disabled = true;
                                    items.push(estimationItemSku);
                                }
                            }
                        });
                        if (estimationItemSkuIds.length > 0) {
                            data.push({
                                id: estimationItem.id,
                                skus: estimationItemSkuIds
                            });
                        }
                    });
                });
                break;
        }
        if (items.length > 0) {
            const currentSection = await handleSaveDraft(section.id, section.name, data);
            fetchPartSummaryDetails();
            const sectionIndex = sections.findIndex(
                (currentSection: any) => currentSection.id === section.id
            );

            if (sectionIndex !== -1) {
                const updatedSections = [...sections];
                updatedSections[sectionIndex] = {
                    id: currentSection.id,
                    name: currentSection.name,
                    items: items,
                    status: currentSection.status
                };
                setSections(updatedSections);
            }
        }
    }

    const handleRemoveSectionItem = async (itemType: any, section: any) => {
        let items = [...section.items];
        const data: any[] = [];
        switch (itemType.type) {
            case ItemTypes.CATEGORY:
                props.categories.map((category: any) => {
                    if (category.id == itemType.id) {
                        category.subCategories.map((subCategory: any) => {
                            estimations?.map((estimation: any) => {
                                if (estimation.materialSubCategoryId === subCategory.id) {
                                    estimation.estimationItems.map((estimationItem: any) => {
                                        const estimationItemSkuIds: any[] = [];
                                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                            if (items.length > 0) {
                                                items = items.filter((partItem: any) => {
                                                    if (partItem.id !== estimationItemSku.id) {
                                                        return true;
                                                    }
                                                    estimationItemSku.disabled = false;
                                                    estimationItemSku.estimationSummaryDetailId = null;
                                                    estimationItemSkuIds.push(estimationItemSku.id);
                                                    return false;
                                                });
                                            }
                                        });
                                        if (estimationItemSkuIds.length > 0) {
                                            data.push({
                                                id: estimationItem.id,
                                                skus: estimationItemSkuIds
                                            });
                                        }
                                    });
                                }
                            });
                        });
                    }
                });
                break;
            case ItemTypes.SUBCATEGORY:
                props.categories.map((category: any) => {
                    category.subCategories.map((subCategory: any) => {
                        if (subCategory.id == itemType.id) {
                            estimations?.map((estimation: any) => {
                                if (estimation.materialSubCategoryId === subCategory.id) {
                                    estimation.estimationItems.map((estimationItem: any) => {
                                        const estimationItemSkuIds: any[] = [];
                                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                            if (items.length > 0) {
                                                items = items.filter((partItem: any) => {
                                                    if (partItem.id !== estimationItemSku.id) {
                                                        return true;
                                                    }
                                                    estimationItemSku.disabled = false;
                                                    estimationItemSku.estimationSummaryDetailId = null;
                                                    estimationItemSkuIds.push(estimationItemSku.id);
                                                    return false;
                                                });
                                            }
                                        });
                                        if (estimationItemSkuIds.length > 0) {
                                            data.push({
                                                id: estimationItem.id,
                                                skus: estimationItemSkuIds
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                });
                break;
            case ItemTypes.ITEM:
                estimations?.map((estimation: any) => {
                    estimation.estimationItems.map((estimationItem: any) => {
                        if (estimationItem.id == itemType.id) {
                            const estimationItemSkuIds: any[] = [];
                            estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                if (items.length > 0) {
                                    items = items.filter((partItem: any) => {
                                        if (partItem.id !== estimationItemSku.id) {
                                            return true;
                                        }
                                        estimationItemSku.disabled = false;
                                        estimationItemSku.estimationSummaryDetailId = null;
                                        estimationItemSkuIds.push(estimationItemSku.id);
                                        return false;
                                    });
                                }
                            });
                            if (estimationItemSkuIds.length > 0) {
                                data.push({
                                    id: estimationItem.id,
                                    skus: estimationItemSkuIds
                                });
                            }
                        }
                    });
                });
                break;
            case ItemTypes.SKU:
                estimations?.map((estimation: any) => {
                    estimation.estimationItems.map((estimationItem: any) => {
                        const estimationItemSkuIds: any[] = [];
                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                            if (items.length > 0 && estimationItemSku.id == itemType.id) {
                                items = items.filter((partItem: any) => {
                                    if (partItem.id !== estimationItemSku.id) {
                                        return true;
                                    }
                                    estimationItemSku.disabled = false;
                                    estimationItemSku.estimationSummaryDetailId = null;
                                    estimationItemSkuIds.push(estimationItemSku.id);
                                    return false;
                                });
                            }
                        });
                        if (estimationItemSkuIds.length > 0) {
                            data.push({
                                id: estimationItem.id,
                                skus: estimationItemSkuIds
                            });
                        }
                    });
                });
                break;
        }

        const currentSection = await handleRemoveFromSummary(section, data);
        if (currentSection.hasOwnProperty("id") && currentSection.id != null) {
            fetchPartSummaryDetails();
            const sectionIndex = sections.findIndex(
                (currentSection: any) => currentSection.id === section.id
            );

            if (sectionIndex !== -1) {
                const updatedSections = [...sections];
                updatedSections[sectionIndex] = {
                    id: currentSection.id,
                    name: currentSection.name,
                    items: items,
                    status: currentSection.status
                };
                setSections(updatedSections);
            }
        }
    }

    const handleRemoveFromSummary = async (section: any, removedItemsData: any) => {
        try {
            const response = await dispatch(
                removeItemsFromSummaryDetails({
                    projectId: props.project.id,
                    removedItems: removedItemsData,
                    summaryDetailId: section.id
                })
            ).unwrap();

            if (response) {
                dispatch(
                    openSnackbar({
                        message: REMOVED_ITEM_SUCCESS,
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
                return response;
            } else if (isError) {
                dispatch(
                    openSnackbar({
                        message: REMOVED_ITEM_FAILURE,
                        backgroundColor: ERROR_COLOR,
                    })
                );
            }
        } catch (err) {
            dispatch(
                openSnackbar({
                    message: REMOVED_ITEM_FAILURE,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    };

    const handleSaveDraft = async (sectionId: any, sectionName: string, data: any[]) => {
        const publishedPartPayload = {
            id: sectionId,
            name: sectionName,
            items: data,
        };
        try {
            const response = await dispatch(
                postDraftSection({
                    publishedSectionsPayload: publishedPartPayload,
                    revisionId: props.revision.id,
                })
            ).unwrap();


            if (response && response.id) {
                dispatch(
                    openSnackbar({
                        message: DRAFT_PART_SUCCESSFULLY,
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
                return response;
            } else if (isError) {
                dispatch(
                    openSnackbar({
                        message: DRAFT_PART_FAILED,
                        backgroundColor: ERROR_COLOR,
                    })
                );
            }
        } catch (err) {
            dispatch(
                openSnackbar({
                    message: DRAFT_PART_FAILED,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    }

    const handleCreateSection = () => {
        if (newSectionName.trim()) {
            let sectionExists = false;
            sections.map((section: any) => {
                if (!sectionExists && section.name.toLowerCase() == newSectionName.toLowerCase()) {
                    sectionExists = true;
                }
            });
            if (!sectionExists) {
                counter += 1;
                setSections((prevSection: any) => [{
                    id: counter,
                    name: newSectionName,
                    status: 'DRAFT',
                    items: []
                }, ...prevSection]);
                setNewSectionName("");
            } else {
                dispatch(openSnackbar({
                    message: DUPLICATE_PART_NAME,
                    backgroundColor: ERROR_COLOR,
                }))
            }
        }
    };

    const handleRemovePart = async (id: any) => {
        const response = await dispatch(removeSummary(id)).unwrap();
        if (response) {
            dispatch(
                openSnackbar({
                    message: REMOVED_PART_SUCCESS,
                    backgroundColor: SUCCESS_COLOR,
                })
            );
            fetchPartSummaryDetails();
            let filter = sections.filter((section: any) => section.id !== id);
            setSections(filter);
        } else if (isError) {
            dispatch(
                openSnackbar({
                    message: REMOVED_PART_FAILURE,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    }

    const fetchPartSummaryDetails = async () => {
        if (props.revision.id) {
            setIsSummaryLoading(true);

            api.get(`/procurement/estimation/get-summary/${props.revision.id}`).then((response) => {
                return response.data;
            }).then((response: any) => {
                setEstimationSummary(response);
            }).catch(ex => {
                console.log(ex);
            }).finally(() => {
                setIsSummaryLoading(false);
            });
        }
    };

    const loadEstimationDetails = () => {
        setEstimations([]);
        setIsLoading(true);

        api.get(`/procurement/estimation/details/${props.revision.id}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            setEstimations(response);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setEstimationError(true);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        loadEstimationDetails();
        fetchPartSummaryDetails();
    }, [props.revision]);

    useEffect(() => {
        if (estimations.length > 0) {
            estimations.map((estimation: any) => {
                estimation.estimationItems.map((estimationItem: any) => {
                    estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                        if (!estimationItemSku.hasOwnProperty("disabled")) {
                            estimationItemSku.disabled = estimationItemSku.estimationSummaryDetailId != null;
                        }
                    });
                });
            });
        }
    }, [estimations]);

    useEffect(() => {
        setPublishedParts([]);
        setSections([]);
        if (Object.keys(estimationSummary).length > 0) {
            const savedSections: any[] = [];
            const publishedSections: any[] = [];
            estimationSummary.parts.map((section: any) => {
                // if (props.revision.status == 'APPROVED') {
                publishedSections.push({
                    id: section.id,
                    name: section.name,
                    status: section.status,
                    items: section.items,
                    total: section.total,
                    totalSkus: section.totalSkus
                });
                // }
                savedSections.push({
                    id: section.id,
                    name: section.name,
                    status: section.status,
                    items: section.items,
                    total: section.total,
                    totalSkus: section.totalSkus
                });
            });
            setSections(savedSections);
            setPublishedParts(publishedSections)
        }
    }, [estimationSummary]);

    if (isLoading || isSummaryLoading) {
        return <EstimationLoader estimationError={estimationError}/>
    }

    const getActiveContent = () => {
        if (activeTab.type === 'left') {
            return leftAlignedTabs[activeTab.index].content;
        } else {
            return rightAlignedTabs[activeTab.index].content;
        }
    };

    return (
        <Box>
            <Box>
                <Box display={"flex"} justifyContent="space-between" alignItems="center">
                    <Box padding={"16px 0"}>
                        <Typography
                            sx={{fontSize: "18px", fontWeight: 600, lineHeight: "21px"}}
                        >
                            Estimation Details
                        </Typography>
                    </Box>
                    {props.revision.status == 'PENDING' &&
                        <Box display={"flex"} mb={2}>
                            <TextField
                                variant="outlined"
                                size={"small"}
                                value={newSectionName}
                                onChange={(e) => setNewSectionName(e.target.value)}
                                placeholder=""
                                autoFocus
                                sx={{
                                    "& .MuiInputBase-root": {mr: 2, display: "flex", alignItems: "center"},
                                    "& .MuiInputBase-input": {textAlign: "start", fontSize: "12px"},
                                }}
                            />
                            <Button onClick={handleCreateSection} variant={"contained"} size={"small"}>
                                Add New Part +
                            </Button>
                        </Box>
                    }
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs className={`left-summary-container`} sx={{overflow: 'auto'}}>
                        <Box className={styles.section}>
                            {estimations.length > 0 &&
                                <LeftContainer
                                    showUpdatedCharges={props.showUpdatedCharges}
                                    project={props.project}
                                    categories={props.categories}
                                    estimations={estimations}
                                />
                            }
                        </Box>
                    </Grid>
                    <Grid item xs className={`right-summary-container`} sx={{overflow: 'auto'}}>
                        <Box className={styles.section}>
                            <Box sx={{display: 'block', width: '100%'}}>
                                {estimations.length > 0 && sections.map((section: any, sectionIndex: number) => {
                                    return (
                                        <SummaryContainer
                                            key={"right-container-items" + sectionIndex}
                                            section={section}
                                            index={sectionIndex}
                                            project={props.project}
                                            categories={props.categories}
                                            estimations={estimations}
                                            showUpdatedCharges={props.showUpdatedCharges}
                                            handleRemovePart={handleRemovePart}
                                            handleDrop={handleDrop}
                                            handleRemoveSectionItem={handleRemoveSectionItem}
                                        />
                                    )
                                })}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{margin: "32px 0"}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Box sx={{display: 'flex'}}>
                            {leftAlignedTabs.map((tab, index) => (
                                <Button
                                    key={index}
                                    onClick={() => setActiveTab({type: 'left', index})}
                                    sx={{
                                        borderRadius: '0',
                                        borderTopLeftRadius: index === 0 ? '0.5rem' : '0',
                                        borderTopRightRadius: index === leftAlignedTabs.length - 1 ? '0.5rem' : '0',
                                        borderBottomLeftRadius: index === 0 ? '0.5rem' : '0',
                                        borderBottomRightRadius: index === leftAlignedTabs.length - 1 ? '0.5rem' : '0',
                                        bgcolor: activeTab.type === 'left' && activeTab.index === index ? 'primary.main' : 'background.default',
                                        color: activeTab.type === 'left' && activeTab.index === index ? 'primary.contrastText' : 'text.primary',
                                        '&:hover': {
                                            bgcolor: activeTab.type === 'left' && activeTab.index === index ? 'primary.dark' : 'background.paper',
                                        },
                                        '&.active': {
                                            fontWeight: 'bold',
                                        },
                                        '&:not(:last-child)': {
                                            marginRight: '1px',
                                        },
                                    }}
                                    variant={activeTab.type === 'left' && activeTab.index === index ? 'contained' : 'outlined'}
                                    className={activeTab.type === 'left' && activeTab.index === index ? 'active' : ''}
                                >
                                    {tab.label}
                                </Button>
                            ))}
                        </Box>
                        <Box sx={{display: 'flex'}}>
                            {rightAlignedTabs.map((tab, index) => (
                                <Button
                                    key={index}
                                    onClick={() => setActiveTab({type: 'right', index})}
                                    sx={{
                                        borderRadius: '0',
                                        borderTopLeftRadius: index === 0 ? '0.5rem' : '0',
                                        borderTopRightRadius: index === rightAlignedTabs.length - 1 ? '0.5rem' : '0',
                                        borderBottomLeftRadius: index === 0 ? '0.5rem' : '0',
                                        borderBottomRightRadius: index === rightAlignedTabs.length - 1 ? '0.5rem' : '0',
                                        bgcolor: activeTab.type === 'right' && activeTab.index === index ? 'primary.main' : 'background.default',
                                        color: activeTab.type === 'right' && activeTab.index === index ? 'primary.contrastText' : 'text.primary',
                                        '&:hover': {
                                            bgcolor: activeTab.type === 'right' && activeTab.index === index ? 'primary.dark' : 'background.paper',
                                        },
                                        '&.active': {
                                            fontWeight: 'bold',
                                        },
                                        '&:not(:last-child)': {
                                            marginRight: '1px',
                                        },
                                    }}
                                    variant={activeTab.type === 'right' && activeTab.index === index ? 'contained' : 'outlined'}
                                    className={activeTab.type === 'right' && activeTab.index === index ? 'active' : ''}
                                >
                                    {tab.label}
                                </Button>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '1rem',
                            width: '100%',
                            pr: 1,
                            py: 1,
                            backgroundColor: 'background.paper'
                        }}>
                        {getActiveContent()}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default EstimationSummary;