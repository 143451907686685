import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {closeSnackbar, openSnackbar} from "../../../components/common/features/snackbarSlice";
import {getVendorRoles, updateVendorUser} from "../../user/features/actions";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import EditUserComponent from "../../../components/users/EditUserComponent";
import {ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";
import {getBasicVendor} from "../features/actions";

const EditVendorUser = () => {
    const {vendorId, id} = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const roles = useAppSelector((state) => state.user.vendorRoles);
    const isCreatedVendorUser = useAppSelector((state) => state.user.vendorFormResponse);
    const errorMessage = useAppSelector((state) => state.user.error);
    const vendor = useAppSelector((state) => state.vendor.basicVendor)

    const onSubmitHandler = (form: any, callback: any) => {
        dispatch(updateVendorUser({vendorId, id, form, callback}));
        if (isCreatedVendorUser) {
            dispatch(openSnackbar({message: 'Vendor User updated successfully', backgroundColor: SUCCESS_COLOR}));
            navigate(`/vendors/user/${vendorId}`);
        } else if (errorMessage) {
            dispatch(openSnackbar({
                message: 'Oops. Something went wrong, please try again',
                backgroundColor: ERROR_COLOR
            }));
        }
    }


    useEffect(() => {
        dispatch(closeSnackbar());
        dispatch(getVendorRoles());
    }, []);

    useEffect(() => {
        dispatch(getBasicVendor(String(vendorId)));
    }, [vendorId]);

    return (
        <EditUserComponent
            pageName={"Edit User For Vendor: " + vendor?.name}
            id={id}
            roles={roles}
            onSubmitHandler={onSubmitHandler}
        />
    )
}
export default EditVendorUser;