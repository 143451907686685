import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {api} from "../../utils/Axios";
import Loader from "../Loader";
import {eventListColumns} from "../../modules/project/plan/config/PackageListColumns";
import ListPage from "../list-page/ListPage";
import EventDetailsPopup from "./popup/EventDetailsPopup";

const EventList = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, rowChange] = useState([]);
    const [page, pageChange] = useState(0);
    const [openPopup, setOpenPopup] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState({});
    const [sortColumn, setSortColumn] = useState('id');
    const [sortDirection, setSortDirection] = useState<any>('desc');
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);
    const navigate = useNavigate();

    const handleDetailsCallBack = (data: any) => {
        setOpenPopup(false);
    }

    const handleSort = (sortModel: any) => {
        if (sortModel && sortModel.field) {
            setSortColumn(sortModel.field);
            setSortDirection(sortModel.sort);
            pageChange(0);
        }
    };
    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    };

    const loadEvents = () => {
        setIsLoading(true);
        api.get(`/procurement/event/list/${props.project.id}`, {
            params: {
                pageNo: page,
                pageSize: size,
                sort: sortDirection?.toUpperCase(),
                sortByColumn: sortColumn
            }
        }).then((response) => {
            return response.data;
        }).then((response: any) => {
            rowChange(response.content);
            setTotalElements(response.totalElements);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const handleCallback = (rowData: any, buttonData: any) => {
        if (buttonData.type === 'anchor') {
            setSelectedEvent(rowData);
            setOpenPopup(true);
        }
    }

    useEffect(() => {
        loadEvents();
    }, [props.project.id]);

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <>
            <ListPage
                rows={rows}
                columns={eventListColumns}
                page={page}
                size={size}
                totalElements={totalElements}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                handlePagination={handlePagination}
                handleSort={handleSort}
                handleCallBack={handleCallback}
            />
            {openPopup &&
                <EventDetailsPopup showUpdatedCharges={props.showUpdatedCharges} openPopup={openPopup} event={selectedEvent} project={props.project} categories={props.categories} refreshPage={loadEvents} handleCallBack={handleDetailsCallBack}/>
            }
        </>
    )
}

export default EventList;