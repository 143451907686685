export const headerMenus: any[] = [
    {name: 'Dashboard', uri: '/', permissions: ['view-users', 'edit-users', 'designer', 'vendor-super-admin', 'vendor-admin', 'view-projects', 'edit-projects', 'view-skus', 'edit-skus']},
    {name: 'Catalogue', uri: '/catalogue', permissions: ['view-skus', 'edit-skus']},
    {name: 'Projects', uri: '/projects', permissions: ['view-projects', 'edit-projects']},
    {name: 'Vendors', uri: '/vendors', permissions: ['view-vendors', 'edit-vendors']},
    {name: 'Brands', uri: '/brands', permissions: ['view-brands', 'edit-brands']},
    {name: 'Users', uri: '/users', permissions: ['view-users', 'edit-users']},
    {name: 'Events', uri: '/vendor/events', permissions: ['vendor-super-admin', 'vendor-admin']},
    {name: 'Catalogue', uri: '/vendor/catalogue', permissions: ['vendor-super-admin', 'vendor-admin']},
    {name: 'Reports', uri: '/reports/all', permissions: ['super-admin']},
];