import {getStyle} from "../../../utils/ModalUtils";
import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from "react";
import {useAppDispatch} from "../../../app/hooks";
import {getContentUrl} from "../../../utils/Common";
import {Box, Button, Modal, styled, Tab, Tabs, Typography} from "@mui/material";
import PageHeading from "../../../components/page-heading/PageHeading";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../components/Loader";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {api} from "../../../utils/Axios";
import {openSnackbar} from "../../../components/common/features/snackbarSlice";
import {ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {a11yProps, CustomTabPanel} from "../../../utils/TabsUtils";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const UploadCogs = (props: any) => {
    const style = getStyle(70, 75);
    const [isLoading, setIsLoading] = useState(false);
    const [tabValue, setTabValue] = useState("with-nsv");

    const [uploadedFile, setUploadedFile] = useState<any>("");
    const [open, setOpen] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [successMessage, setSuccessMessage] = useState("")
    const [errors, setErrors] = useState([]);

    const dispatch = useAppDispatch();

    const [tabs, setTabs] = useState(
        [
            {id: "with-nsv", name: "Upload With NSV"},
            {id: "with-gsv", name: "Upload With GSV"},
        ]
    );

    const handleTabChange = (event: SyntheticEvent, newValue: string) => {
        setSuccessMessage("");
        setErrors([]);
        setTabValue(newValue);
    };

    const handleClose = () => {
        setOpen(false);
        setSuccessMessage("");
        props.handleUploadPopupCallBack({event: 'close'});
    }

    const handleConfirmationDialogClose = () => {
        setOpenConfirmationDialog(false);
    }

    const handleConfirmationPopup = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            setUploadedFile(file);
            setOpenConfirmationDialog(true);
        }
    }

    const handleHeaderButtonClick = (data: any) => {
        let url = `${getContentUrl()}/innov8/Sku-COGS-Price-Update-Format.csv`;
        if (tabValue == 'with-gsv') {
            url = `${getContentUrl()}/innov8/Sku-COGS-With-GSV-Price-Update-Format.csv`;
        }

        if (data == 'download') {
            window.location.href = url
        }
    }

    const handleFileUpload = () => {
        setSuccessMessage("")
        setOpenConfirmationDialog(false);
        if (uploadedFile) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("type", tabValue);
            formData.append('file', uploadedFile);

            setErrors([]);
            api
                .post('/catalogue/sku/upload-cogs-prices', formData)
                .then((response) => {
                    return response.data;
                })
                .then((res) => {
                    dispatch(openSnackbar({
                        message: 'Catalogue Cogs pricing updated successfully',
                        backgroundColor: SUCCESS_COLOR
                    }))
                    setSuccessMessage("Cogs Prices uploaded successfully")
                })
                .catch((ex) => {
                    const res = ex.response?.data;
                    if (res && res.hasOwnProperty('data')) {
                        setErrors(res.data);
                    } else {
                        dispatch(openSnackbar({
                            message: 'Something went wrong! Please check all the values are valid before submitting.',
                            backgroundColor: ERROR_COLOR
                        }));
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (props.openPopup == true) {
            setOpen(true);
        }
    }, [props.openPopup]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header} style={{height: '10%'}}>
                        <Box sx={style.headerContainer}>
                            <Box sx={{width: '95%'}}>
                                <PageHeading
                                    title={"Upload Cogs"}
                                    showButtons={true}
                                    buttons={[
                                        {id: 'download', label: 'Download Template'},
                                    ]}
                                    handleHeaderButtonClick={handleHeaderButtonClick}
                                />
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            {isLoading &&
                                <Loader/>
                            }
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Project Plan Tabs"
                                textColor="primary"
                                sx={{borderBottom: 1, borderColor: "divider", color: 'common.black'}}
                                TabIndicatorProps={{
                                    sx: {
                                        backgroundColor: "primary.main",
                                    },
                                }}
                            >
                                {tabs.map((tab: any, index: number) => (
                                    <Tab
                                        key={tab.id + "_" + index}
                                        {...a11yProps(tab.id, true)}
                                        value={tab.id}
                                        label={tab.name}
                                        wrapped
                                    />
                                ))}
                            </Tabs>
                            {tabValue == 'with-nsv' &&
                                <CustomTabPanel value={tabValue} index={tabValue}>
                                    <Box sx={{py: 2}}>
                                        <Box display="flex" justifyContent="center" sx={{pt: 1}}>
                                            <Button
                                                component="label"
                                                variant="contained"
                                                tabIndex={-1}
                                                startIcon={<CloudUploadIcon/>}
                                            >
                                                Upload NSV Price file
                                                <VisuallyHiddenInput type="file" onChange={handleConfirmationPopup}
                                                                     accept={".csv"}/>
                                            </Button>
                                        </Box>
                                        <Box display="flex" justifyContent="center" margin={'24px 0'}>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    color: 'green'
                                                }}>{successMessage ? successMessage : ''}</Typography>
                                        </Box>
                                        {errors.length > 0 &&
                                            <Box sx={{py: 4, overflow: 'hidden'}}>
                                                {errors.map((error: string, index: number) => (
                                                    <Typography sx={{color: 'error.main'}}
                                                                key={"error-" + index}>{error}</Typography>
                                                ))}
                                            </Box>
                                        }
                                    </Box>
                                </CustomTabPanel>
                            }
                            {tabValue == 'with-gsv' &&
                                <CustomTabPanel value={tabValue} index={tabValue}>
                                    <Box sx={{py: 2}}>
                                        <Box display="flex" justifyContent="center" sx={{pt: 1}}>
                                            <Button
                                                component="label"
                                                variant="contained"
                                                tabIndex={-1}
                                                startIcon={<CloudUploadIcon/>}
                                            >
                                                Upload GSV Price file
                                                <VisuallyHiddenInput type="file" onChange={handleConfirmationPopup}
                                                                     accept={".csv"}/>
                                            </Button>
                                        </Box>
                                        <Box display="flex" justifyContent="center" margin={'24px 0'}>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    color: 'green'
                                                }}>{successMessage ? successMessage : ''}</Typography>
                                        </Box>
                                        {errors.length > 0 &&
                                            <Box sx={{py: 4, overflow: 'hidden'}}>
                                                {errors.map((error: string, index: number) => (
                                                    <Typography sx={{color: 'error.main'}}
                                                                key={"error-" + index}>{error}</Typography>
                                                ))}
                                            </Box>
                                        }
                                    </Box>
                                </CustomTabPanel>
                            }
                        </Box>
                    </Box>
                </Box>
                {openConfirmationDialog &&
                    <Dialog
                        open={true}
                        onClose={handleConfirmationDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Confirm Cogs Price Update"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to update Sku Cogs prices?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleConfirmationDialogClose} color="primary">
                                No
                            </Button>
                            <Button
                                variant={"contained"}
                                onClick={handleFileUpload}
                                color="primary"
                                autoFocus
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </Box>
        </Modal>
    )
}

export default UploadCogs;