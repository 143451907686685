import {createAsyncThunk} from '@reduxjs/toolkit';
import {api} from '../../../utils/Axios';

interface ApiResponse {
    // Define the properties of the API response here
}

interface PackageDetails {
    name: string;
    boqId: string;
    projectId: string;
    packageDetails: {
        id: string;
        quantity: number
    }[];
}

interface UpdatePackageDetails {
    id: string;
    packageDetails: PackageDetails;
}

export const createPackage = createAsyncThunk<ApiResponse, PackageDetails>(
    'procurement/package/create',
    async (packageDetails) => {
        try {
            // Perform the API call
            const response = await api.post('/procurement/package/create', packageDetails);
            return response.data as ApiResponse;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);

export const updatePackage = createAsyncThunk<ApiResponse, UpdatePackageDetails>(
    'procurement/package/update',
    async ({id, packageDetails}: UpdatePackageDetails) => {
        try {
            // Perform the API call
            const response = await api.put(`/procurement/package/update/${id}`, packageDetails);
            return response.data as ApiResponse;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);

export const loadPackageItems = createAsyncThunk<ApiResponse, PackageDetails>(
    'procurement/package/get-items',
    async (id: any) => {
        try {
            console.log(id);
            // Perform the API call
            const response = await api.get(`/procurement/package/items/${id}`);
            return response.data as ApiResponse;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);
