import {createAsyncThunk} from '@reduxjs/toolkit';
import {api} from '../../../utils/Axios';

interface Role {
    id: string;
    name: string;
}

export const getVendorRoles = createAsyncThunk<
    Role[],
    void,
    { rejectValue: string }
>('user/getVendorRoles', async (_, thunkAPI) => {
    try {
        const response = await api.get('/authentication/role/all/vendor');
        const rolesArr = response.data.map((elem: any) => ({
            id: elem.id,
            name: elem.name,
        })) as Role[];

        return rolesArr;
    } catch (error) {
        return thunkAPI.rejectWithValue('Failed to fetch roles');
    }
});

// Action creator using createAsyncThunk
export const getAllRoles = createAsyncThunk<
    Role[],
    void,
    { rejectValue: string }
>('user/getAllRoles', async (_, thunkAPI) => {
    try {
        const response = await api.get('/authentication/role/all');
        const rolesArr = response.data.map((elem: any) => ({
            id: elem.id,
            name: elem.name,
        })) as Role[];
        return rolesArr;
    } catch (error) {
        return thunkAPI.rejectWithValue('Failed to fetch roles');
    }
});

export const submitForm = createAsyncThunk<
    void,
    { form: any; callback: () => void }
>('user/submitUserForm', async ({form, callback}) => {
    try {
        // Perform the API call
        const response = await api.post('/authentication/user', form);
        // Call the callback function after successful API call
        callback();
        return response.data;
    } catch (error: any) {
        // Handle errors
        console.error(error.message);
        return error.message; // Propagate the error to be handled in the rejected case
    }
});

export const submitVendorForm = createAsyncThunk<
    any,
    { vendorId: string, form: any; callback: () => void }
>('user/submitVendorForm', async ({vendorId, form, callback}) => {
    try {
        form.status = true;
        // Perform the API call
        const response = await api.post(`authentication/user/create-entity-user/vendor/${vendorId}`, form);
        // Call the callback function after successful API call
        callback();
        return response.data;
    } catch (error: any) {
        // Handle errors
        console.error(error.message);
        return error.message; // Propagate the error to be handled in the rejected case
    }
});

export const updateVendorUser = createAsyncThunk<
    void,
    { vendorId: any, id: any, form: any; callback: () => void }
>('user/updateVendorUser', async ({vendorId, id, form, callback}) => {
    try {
        //form.status = true;
        // Perform the API call
        const response = await api.put(`authentication/user/update-entity-user/vendor/${vendorId}/${id}`, form);
        // Call the callback function after successful API call
        callback();
        return response.data;
    } catch (error: any) {
        // Handle errors
        console.error(error.message);
        return error.message; // Propagate the error to be handled in the rejected case
    }
});