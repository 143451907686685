import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {approvePackageRequest, loadPackageDetails, revokePackage} from "./actions";

// Define the interface for a package detail item
interface PackageDetailsItem {
    id: string;
    // Add other properties as needed
}

// Define the interface for the API response data
interface ApiResponse {
    id: number | string;
    projectId: string,
    total: number;
    items: PackageDetailsItem[];
    vendor: any;
    domainGsvCharges: any;
    domainCogsCharges: any;
    totalElements: number;
    name: string; // Add the name property
    status: string; // Add the name property
    // Add other properties if needed
}

// Define the initial state interface
interface PackageDetailsState {
    packageDetails: ApiResponse;
    loading: boolean;
    error: string | null;
    approvePrResponse: any,
    revokePackageResponse: any,
}

const initialState: PackageDetailsState = {
    packageDetails: {
        id: '',
        projectId: '',
        total: 0,
        items: [],
        vendor: null,
        domainGsvCharges: null,
        domainCogsCharges: null,
        totalElements: 0,
        name: "",
        status: "",
        // createdAt: ""
    },
    loading: false,
    approvePrResponse: {},
    revokePackageResponse: {},
    error: null,
};

const packageDetailsSlice = createSlice({
    name: "packageDetails",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadPackageDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                loadPackageDetails.fulfilled,
                (state, action: PayloadAction<ApiResponse>) => {
                    state.loading = false;
                    state.packageDetails = action.payload;
                }
            )
            .addCase(loadPackageDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to fetch package details";
            })
            .addCase(approvePackageRequest.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                approvePackageRequest.fulfilled,
                (state: any, action) => {
                    state.loading = false;
                    state.approvePrResponse = action.payload;
                }
            )
            .addCase(approvePackageRequest.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to fetch package details";
            })
            .addCase(revokePackage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                revokePackage.fulfilled,
                (state: any, action) => {
                    state.loading = false;
                    state.revokePackageResponse = action.payload;
                }
            )
            .addCase(revokePackage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to revoke package";
            });
    },
});

export default packageDetailsSlice.reducer;
