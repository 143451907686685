import {Box, Button, Grid, Typography} from "@mui/material";
import {getPriceInFormatted} from "../../../../utils/Common";
import {ItemTypes} from "../../../../modules/project/plan/tabs/boq-tabs/ItemTypes";
import React from "react";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";

const SummarySkuWidget = (props: any) => {
    let skuTotal = props.estimationItemSku?.sellingPrice + props.estimationItemSku?.gsvDomainChargesPerUnit?.total;

    return (
        <Box>
            <Grid container alignItems={"center"} justifyContent={"center"} sx={{
                border: "1px solid #ccc",
                borderRadius: 1,
                backgroundColor: "#fff",
                py: props.section.status === 'DRAFT' ? 0.5 : 1
            }}>
                <Grid item xs={1} sx={{p: 0}}>
                    {props.section.status === 'DRAFT' &&
                        <Button
                            onClick={() => props.handleRemoveSectionItem({
                                type: ItemTypes.SKU,
                                id: props.estimationItemSku?.id
                            })}
                            sx={{minWidth: "auto", padding: "4px"}}
                        >
                            <DoDisturbOnOutlinedIcon
                                sx={{fontSize: "16px", color: "#CB5A42", cursor: "pointer"}}
                            />
                        </Button>
                    }
                </Grid>
                <Grid item xs>
                    <Typography sx={{fontSize: "10px", fontWeight: 600}}>
                        {props.estimationItemSku?.sku?.name}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography sx={{fontSize: "10px", fontWeight: 600,}}>
                        {props.estimationItemSku?.sku?.code}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography sx={{fontSize: "10px", fontWeight: 600}}>
                        {props.estimationItemSku?.sku?.uom}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography sx={{fontSize: "10px", fontWeight: 600}}>
                        {props.estimationItemSku?.quantity}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={{fontSize: "10px", fontWeight: 600}}>
                        {getPriceInFormatted(skuTotal, props.project.country)}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SummarySkuWidget;