import {a11yProps, CustomTabPanel} from "../../utils/TabsUtils";
import {Tab, Tabs, Typography} from "@mui/material";
import {SyntheticEvent, useEffect, useState} from "react";

const SubCategoryTab = (props: any) => {
    const [subCategoryTabValue, setSubCategoryTabValue] = useState("");

    const handleSubCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setSubCategoryTabValue(newValue);
        props.handleSubCategoryTabChange(event, newValue);
    };

    useEffect(() => {
        if(props.subCategoryTabValue != "") {
            setSubCategoryTabValue(props.subCategoryTabValue);
        }
    }, [props.subCategoryTabValue]);

    return (
        <>
            {props.subCategories.length > 0 &&
                <CustomTabPanel
                    width={'100'}
                    value={props.categoryTabValue}
                    index={props.categoryTabValue}
                >
                    <Tabs
                        variant={'scrollable'}
                        value={subCategoryTabValue}
                        onChange={handleSubCategoryTabChange}
                        aria-label="Sub Category Tabs"
                        sx={{
                            display: "flex",
                            mb: 0,
                            borderBottom: props?.noBotttomBorder ? 0 : 1, 
                            borderColor: 'divider',
                            "& .MuiTabs-indicator": {
                                display: 'none',
                            },
                            "& .MuiTabs-scroller": {
                                display: "flex !important",
                            },
                        }}
                    >
                        {props.subCategories.map((subCategory: any, subCategoryIndex: number) => {
                            return (
                                <Tab
                                    className="tab-subcategory"
                                    sx={{
                                        borderBottom: 'none!important',
                                        alignItems: 'center',
                                        '&.Mui-selected .MuiTypography-root': {
                                            color: '#3854E4',
                                            fontWeight: '700 !important',
                                        },
                                    }}
                                    key={'sub-category-tab-' + subCategoryIndex}
                                    value={subCategory.id}
                                    {...a11yProps(subCategory.id, true)}
                                    label={
                                        <Typography className="tab-label" sx={{color: 'info.dark'}}>
                                            {subCategory.name}
                                            {props.showCount &&
                                                <Typography component={"sup"} sx={{fontSize: '10px'}}>&nbsp;({subCategory.totalSkus})</Typography>
                                            }
                                        </Typography>
                                    }
                                    wrapped
                                />
                            );
                        })}
                    </Tabs>
                </CustomTabPanel>
            }
        </>
    )
}

export default SubCategoryTab;