import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react';
import {deleteItem, getItem} from '../../utils/LocalStorage';
import {Avatar, Grid, Menu, MenuItem} from '@mui/material';
import {getUserEmail} from '../../utils/Users';
import {Link, useLocation} from 'react-router-dom';
import {hasAccess} from '../../utils/Access';
import {headerMenus} from "./Menus";
import BlueLogo from '../../assets/bf-logo-main.jpg';

import ListItemIcon from '@mui/material/ListItemIcon';
import {Logout, Settings} from '@mui/icons-material';

const Header = (props: any) => {
    const location = useLocation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState(getUserEmail);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [activePage, setActivePage] = useState('');

    const handleLogout = (event: any) => {
        deleteItem('auth');
        deleteItem('user_name');
        window.location.reload();
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        let firstPath = window.location.pathname.split('/')[1];
        let secondPath = window.location.pathname.split('/')[2];
        if (firstPath == '') {
            firstPath = 'dashboard';
        }
        if (secondPath != undefined && secondPath == 'events') {
            setActivePage(secondPath);
        } else {
            setActivePage(firstPath);
        }
    }, [location.pathname]);

    useEffect(() => {
        const initials = getItem('user_name')
            .split(' ')
            .slice(0, 1)
            .join(' ')
            .match(/\b\w/g)
            .join('');
        setName(initials);
    }, ['']);

    return (
        <>
            <AppBar
                component="nav"
                // color={'#fff'}
                elevation={0}
                position={'sticky'}
                sx={{borderBottom: 1, backgroundColor: 'common.white', color: 'common.black', borderColor: '#D6DCE1', fontFamily: 'Lato'}}
            >
                <Toolbar>
                    <Box sx={{flexGrow: 1, maxWidth: '20%'}}>
                        <Link to={'/'}>
                            <Box component="img" src={`${process.env.REACT_APP_CONTENT_URL}/innov8/bigfish_logo/3xLogonav.png`}  sx={{width: '140px', margin: '0 auto'}}></Box>
                        </Link>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {headerMenus.map((headerMenu: any, index: number) => {
                            let color = '';
                            let backgroundColor = '';
                            if (activePage == headerMenu.name.toLowerCase()) {
                                color = 'info.main';
                                backgroundColor = 'primary.light';
                            }
                            if (hasAccess(headerMenu.permissions)) {
                                return (
                                    <Link
                                        key={headerMenu.name + index}
                                        to={headerMenu.uri}
                                        style={{textDecoration: 'none', color: 'unset'}}
                                    >
                                        <Typography
                                            component={"span"}
                                            sx={{
                                                px: 4,
                                                py: 3,
                                                fontFamily: 'Lato',
                                                backgroundColor: backgroundColor,
                                                color: color,
                                                textDecoration: 'none'
                                            }}
                                        >
                                            {headerMenu.name}
                                        </Typography>
                                    </Link>
                                )
                            }
                        })}


                    </Box>
                    <Box sx={{flexGrow: 0, maxWidth: '20%'}}>
                        <Grid container
                              onClick={handleMenu}
                              color="inherit"
                              sx={{
                                  bgcolor: 'common.white',
                                  cursor: 'pointer'
                              }}
                        >
                            <Grid item className={"center-aligned"}>
                                <Typography
                                    variant={"body2"}
                                    sx={{
                                        pr: 2,
                                        display: {xs: 'none', md: 'inline'},
                                        textOverflow: "ellipsis",
                                        overflow: "hidden"
                                    }}
                                >
                                    {email}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Avatar sx={{bgcolor: '#D6DCE1'}}>
                                    <Typography sx={{color: 'primary.main'}}>{name}</Typography>
                                </Avatar>
                            </Grid>
                        </Grid>

                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            {/*<MenuItem onClick={handleClose}>*/}
                            {/*    <Avatar /> Profile*/}
                            {/*</MenuItem>*/}
                            {/*<MenuItem onClick={handleClose}>*/}
                            {/*    <Avatar /> My account*/}
                            {/*</MenuItem>*/}
                            {/*<Divider />*/}
                            {hasAccess(['super-admin']) &&
                                <MenuItem>
                                    <Link to={"/projects/domain-details"}
                                          style={{textDecoration: "none", color: 'inherit'}}
                                          className={"vertically-center-aligned"}>
                                        <ListItemIcon>
                                            <Settings fontSize="small"/>
                                        </ListItemIcon>
                                        Project Domain Setting
                                    </Link>
                                </MenuItem>
                            }
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <Logout fontSize="small"/>
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};
export default Header;
