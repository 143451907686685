import React from 'react';
import { Typography } from '@mui/material'
import TextTransition, { presets } from 'react-text-transition';


const TEXTS = ['Loading E-Catalog', 'Preparing Catalog', 'Rendering Results'];

function ECatalogTextSwiper(props:any) {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000, 
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <Typography className="estimationSliderTextOuter" sx={{textAlign: 'center', margin: '5px 0 20px'}}>
      <TextTransition className="estimationSliderText" springConfig={presets.gentle}>{TEXTS[index % TEXTS.length]}</TextTransition>
    </Typography>
  );
};

export default ECatalogTextSwiper;