import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Backdrop from "../../assets/Backdrop.png";
import LOGO from "../../assets/sm-main-logo.png";
import LoginCarousel from "./LoginCarousel";
import TextSwiper from "./TextSwiper";
import "./Login.css";
import { api } from "../../utils/Axios";
import Loader from "../../components/Loader";
import { useAppDispatch } from "../../app/hooks";
import { openSnackbar } from "../../components/common/features/snackbarSlice";
import { ERROR_COLOR } from "../../constants";

function ResetPassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [invalidToken, setInvalidToken] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    doNotMatchErr: "",
    emptyPasswordError: "",
    apiResponseError: "",
  });

  const token = searchParams.get("token");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    if (password !== confirmPassword) {
      setErrorMsg({
        doNotMatchErr:
          "The passwords you entered do not match. Please ensure both fields are identical.",
        emptyPasswordError: "",
        apiResponseError: "",
      });
      setIsLoading(false);
      return;
    }

    setErrorMsg({
      doNotMatchErr: "",
      emptyPasswordError: "",
      apiResponseError: "",
    });

    if (password && confirmPassword) {
      try {
        const response = await api.post("authentication/reset-password", {
          password: password,
          token: token,
        });
        if (response.data === "success") {
          setInvalidToken(false);
          setSuccessMsg(true)
        } else {
          setInvalidToken(true);
          let error = "Invalid token, please request forget password again.";
          if (response.data === "error") {
            error =
              "An error occurred while resetting your password. Please try again later.";
          }
          setErrorMsg({
            doNotMatchErr: "",
            emptyPasswordError: "",
            apiResponseError: error,
          });
        }
        setIsLoading(false);
      } catch (err) {
        setErrorMsg({
          doNotMatchErr: "",
          emptyPasswordError: "",
          apiResponseError:
            "An error occurred while resetting your password. Please try again later.",
        });
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setErrorMsg({
        doNotMatchErr: "",
        emptyPasswordError:
          "Both password fields are required. Please enter your new password and confirmation.",
        apiResponseError: "",
      });
    }
  };

  useEffect(() => {
    if (!token) {
      dispatch(
        openSnackbar({
          message:
            "Invalid token, please request a password reset again. Re-directing you to login page",
          backgroundColor: ERROR_COLOR,
        })
      );
      setTimeout(() => {
        // navigate("/login");
      }, 5000);
    }
  }, [token, dispatch, navigate]);

  return (
    <Container component="section" disableGutters maxWidth={false}>
      <Grid container alignItems={"stretch"} sx={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          md={7}
          sx={{ bgcolor: "transparent", display: { xs: "none", md: "block" } }}
        >
          <Box
            sx={{
              height: "100vh",
              minWidth: "550px",
              margin: "0 auto",
              backgroundImage: "url(" + Backdrop + ")",
              backgroundSize: "contain",
              backgroundPosition: "center bottom",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Box sx={{ margin: "20px auto 0" }}>
              <Box
                component="img"
                src={LOGO}
                sx={{ width: "85px", margin: "0 0 20px 50px" }}
              ></Box>
              <LoginCarousel />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          className={"center-aligned"}
          sx={{
            backgroundColor: "#0388dd",
            position: "relative",
            minHeight: "100vh",
          }}
        >
          {isLoading && <Loader />}
          {!token ? (
            <Box
              className={"login-box-left"}
              sx={{
                width: { xs: "90%", sm: "70%", lg: "67%" },
                m: "auto",
                maxWidth: { xs: "300px", sm: "380px" },
              }}
            >
              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", md: "center" },
                  fontFamily: "Work Sans",
                }}
              >
                <Box sx={{ margin: { xs: "0 auto 10px", md: "0 auto 20px" } }}>
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_CONTENT_URL}/innov8/bigfish_logo/3xBflogo.png`}
                    sx={{
                      width: { xs: "150px", md: "180px" },
                      margin: "0 auto",
                    }}
                  />
                </Box>
                <Box sx={{ margin: { xs: "0 auto 30px", md: "0 auto 40px" } }}>
                  <TextSwiper />
                </Box>

                <Typography
                  sx={{
                    fontFamily: "inherit",
                    color: "#fff",
                    mb: 2,
                    textAlign: "center",
                    margin: "0 auto 16px",
                    fontSize: { xs: "1.5rem", md: "2rem" },
                  }}
                >
                  Invalid Request
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                    <Link to="/login" style={{ textDecoration: "none" }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#ffffff",
                                color: "#41539b",
                            }}
                        >
                            Back to Login
                        </Button>
                    </Link>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              className={"login-box-left"}
              sx={{
                width: { xs: "90%", sm: "70%", lg: "67%" },
                m: "auto",
                maxWidth: { xs: "300px", sm: "380px" },
              }}
            >
              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", md: "start" },
                  fontFamily: "Work Sans",
                }}
              >
                <Box sx={{ margin: { xs: "0 auto 10px", md: "0 auto 20px" } }}>
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_CONTENT_URL}/innov8/bigfish_logo/3xBflogo.png`}
                    sx={{
                      width: { xs: "150px", md: "180px" },
                      margin: "0 auto",
                    }}
                  />
                </Box>
                <Box sx={{ margin: { xs: "0 auto 30px", md: "0 auto 40px" } }}>
                  <TextSwiper />
                </Box>

                <Typography
                  sx={{
                    fontFamily: "inherit",
                    color: "#fff",
                    mb: 2,
                    textAlign: "center",
                    margin: "0 auto 16px",
                    fontSize: { xs: "1.5rem", md: "2rem" },
                  }}
                >
                  Reset your password
                </Typography>

                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1, fontFamily: "inherit", width: "100%" }}
                >
                  <Box sx={{ pt: 1 }}>
                    <Typography
                      variant={"body1"}
                      sx={{
                        mb: 0.5,
                        fontWeight: 500,
                        color: "#fff",
                        fontFamily: "inherit",
                        fontSize: { xs: "0.875rem", md: "1rem" },
                      }}
                    >
                      New Password
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      margin="dense"
                      variant="outlined"
                      className={"borderedInput"}
                      placeholder="New Password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      InputProps={{
                        style: {
                          borderRadius: "5px",
                          fontFamily: "inherit",
                          backgroundColor: "#fff",
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ pt: 1 }}>
                    <Typography
                      variant={"body1"}
                      sx={{
                        mb: 0.5,
                        fontWeight: 500,
                        color: "#fff",
                        fontFamily: "inherit",
                        fontSize: { xs: "0.875rem", md: "1rem" },
                      }}
                    >
                      Confirm Password
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      margin="dense"
                      variant="outlined"
                      className={"borderedInput"}
                      placeholder="Confirm Password"
                      name="confirm-password"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      InputProps={{
                        style: {
                          borderRadius: "5px",
                          fontFamily: "inherit",
                          backgroundColor: "#fff",
                        },
                      }}
                    />
                  </Box>

                  {errorMsg.doNotMatchErr && (
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { xs: "12px", md: "14px" },
                          fontWeight: 500,
                          lineHeight: "21px",
                          color: "#ff0000",
                        }}
                      >
                        {errorMsg.doNotMatchErr}
                      </Typography>
                    </Box>
                  )}
                  {errorMsg.emptyPasswordError && (
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "21px",
                          color: "#ff0000",
                        }}
                      >
                        {errorMsg.emptyPasswordError}
                      </Typography>
                    </Box>
                  )}

                  {errorMsg.apiResponseError && (
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "21px",
                          color: "#ff0000",
                        }}
                      >
                        {errorMsg.apiResponseError}
                      </Typography>
                    </Box>
                  )}

                  <Button
                    className={"secondary-button"}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 3,
                      mb: 2,
                      padding: "15px 14px",
                      fontFamily: "inherit",
                      backgroundColor: "#41539b",
                    }}
                  >
                    <Typography
                      variant={"body1"}
                      sx={{ fontFamily: "inherit" }}
                    >
                      Reset Password
                    </Typography>
                  </Button>
                  {successMsg || errorMsg.apiResponseError && (
                    <Box sx={{ textAlign: "center", mt: 2 }}>
                      <Link to="/login" style={{ textDecoration: "none" }}>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#ffffff",
                            color: "#41539b",
                          }}
                        >
                          Back to Login
                        </Button>
                      </Link>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default ResetPassword;
