import { useEffect, useMemo, useState, FormEvent, useRef } from "react";
import { Box, Button } from "@mui/material";
import Fields from "./fields/Fields";
import { hasAccess } from "../../utils/Access";
import ECatalogDialog from "../ecatalog/ECatalogDialog";

const prepareForm = (formArr: any, group: any) => {
    if (group !== undefined && group === true) {
        let formData = {};
        formArr.forEach((groupForm: any) => {
            let data = groupForm.fields.reduce(
                (r: any, v: any) => ({ ...r, [v.name]: v.value !== undefined ? v.value : "" }),
                {}
            );
            formData = { ...formData, ...data };
        });
        return formData;
    }
    return formArr.reduce(
        (r: any, v: any) => ({ ...r, [v.name]: v.value !== undefined ? v.value : "" }),
        {}
    );
};

const prepareValidationForm = (formArr: any, group: any) => {
    // console.log("prepareValidationForm ---");
    if (group !== undefined && group === true) {
        // console.log("prepareValidationForm group true  ---");
        let formData = {};
        formArr.forEach((groupForm: any) => {
            let data = groupForm.fields.reduce(
                (r: any, v: any) => ({ ...r, [v.name]: (v.disableField !== undefined || v.sumOf !== undefined) ? {"disableField":v?.disableField,"disableOnValue": v?.disableOnValue, "sumOf": v?.sumOf, "errorMessage": v.errorMessage} : {} }),
                {}
            );
            formData = { ...formData, ...data };
        });
        return formData;
    }
    // console.log("prepareValidationForm group false  ---");
    formArr.reduce(
        (r: any, v: any) => ({ ...r, [v.name]: (v.disableField !== undefined || v.sumOf !== undefined) ? {"disableField":v?.disableField,"disableOnValue": v?.disableOnValue, "sumOf": v?.sumOf, "errorMessage": v.errorMessage} : {} }),
        {}
    );
}

const BuildForm = (props: any) => {
    const { formArr, group, buttons } = props;    

    // Initial form values
    const initialForm = useMemo(() => prepareForm(formArr, group), [formArr]);
    const validationForm = useMemo<any>(() => prepareValidationForm(formArr, group), [formArr]);
    const [formData, setFormData] = useState(initialForm);
    const [disableFields, setDisableFields] = useState<any>({});

    const [changedFields, setChangedFields] = useState<any>({});
    const [alertError, setAlertError] = useState<any>(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState<any>("");

    const onFormDataChange = (form: any) => {
        setFormData(form);
        var updatedDisabledFields: { [k: string]: any } = {};

        let localChangedFields = changedFields;
        Object.keys(form).forEach((key) => {

            if (form[key] !== initialForm[key]) {
                localChangedFields = ({ ...localChangedFields, [key]: form[key] });
                setChangedFields((prev: any) => ({ ...prev, [key]: form[key] }));
                if(props.updateFieldChange) {
                    props.updateFieldChange(true);
                }
            } else {
                const { [key]: _, ...rest } = localChangedFields;
                localChangedFields = rest;
                setChangedFields((prev: any) => {
                    const { [key]: _, ...rest } = prev; // Remove the key if it's reverted back to initial
                    return rest;
                });
            }
        });
        

        const changedFormData = { ...initialForm, ...localChangedFields };
        
        Object.keys(changedFormData).forEach((key) => {
            if(validationForm != undefined && validationForm.hasOwnProperty(key) != undefined && validationForm[key].hasOwnProperty("disableField") && validationForm[key]["disableField"]) {
                let checkValue = validationForm[key]["disableOnValue"];
                let filedName = validationForm[key]["disableField"];
                
                // console.log("checkValue", checkValue);
                // console.log("filedName", filedName);
                if(changedFormData[key] == checkValue) {
                    if(Array.isArray(filedName)) {
                        filedName.map((fld:any) => {
                            updatedDisabledFields[fld] = true;
                        });
                    } else {
                        updatedDisabledFields[filedName] = true;
                    }
                } 
            }

            if(validationForm != undefined && validationForm.hasOwnProperty(key) != undefined && validationForm[key].hasOwnProperty("sumOf") && validationForm[key]["sumOf"]) {
                if(changedFormData[key] && changedFormData[key] != "") {
                    let currentFieldValue = parseInt(changedFormData[key]);
                    let sumVal = 0;
                    let showError = true;
                    let filedNames = validationForm[key]["sumOf"];
                    filedNames.map((fld:any) => {
                        if(changedFormData[fld] && changedFormData[fld] != "") {
                            sumVal += parseInt(changedFormData[fld]);  
                        } else {
                            showError = false;
                        }
                    });

                    console.log("currentFieldValue", currentFieldValue);
                    console.log("sumVal", sumVal);

                    if(currentFieldValue != sumVal && showError) {
                        setAlertErrorMessage(validationForm[key]["errorMessage"]);
                        setAlertError(true);
                    }
                }
            }
        });
        
    
        setDisableFields(updatedDisabledFields);
        props.handleFormChange(form);
    };

    const onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const completeFormData = { ...initialForm, ...changedFields };
        props.onSubmit(completeFormData, () => setFormData(initialForm));
    };

    const handleClickButton = (type: string, event: any) => {
        if (type !== 'submit' && type !== 'continue') {
            props.onButtonClick(type, formData, () => setFormData(initialForm));
        } else if(type === 'continue') {
            event.preventDefault();
            const completeFormData = { ...initialForm, ...changedFields };
            props.onSubmit(completeFormData, () => setFormData(initialForm));
        }
    };
    const hideAlertError = (value:any, tabValue:any) => {
        setAlertError(false);
    }

    return (
        <>
            <ECatalogDialog
                            open={alertError}
                            onClose={hideAlertError}
                            confirmText={alertErrorMessage}
                            nextTabValue={""}
                          />
            <Box component={"form"} onSubmit={onSubmitHandler}>
                {group !== undefined && group === true && (
                    <Fields formArr={formArr} initialForm={initialForm} onFormDataChange={onFormDataChange} group={group} disableFields={disableFields}/>
                )}
                {(group === undefined || group === false) && (
                    <Fields formArr={formArr} initialForm={initialForm} onFormDataChange={onFormDataChange} group={group} disableFields={disableFields}/>
                )}
                <Box sx={{ pt: 4 }}
                    ref={props.buttonRef}
                >
                    {buttons.map((button: any, index: number) => {
                        if (button?.permissions && hasAccess(button.permissions)) {
                            return (
                                <Button
                                    disabled={props.saveDisabled ? (button?.disabledButton ? true : false) : false}
                                    key={"form-button-" + index}
                                    type={button.type === 'submit' ? button.type : "button"}
                                    variant={button?.variant ? button.variant : 'contained'}
                                    sx={{ backgroundColor: button?.color ? button.color : 'primary.main', mr: 2}}
                                    onClick={(e) => handleClickButton(button.type, e)}
                                >
                                    {button.title}
                                </Button>
                            );
                        }
                    })}
                </Box>
            </Box>
        </>
    );
};

export default BuildForm;
