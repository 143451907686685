import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/Axios";

interface ApiResponse {}

interface VendorPackageDetailsResponse {}

type VendorPackageDetailsId = string | number;

interface GetVendorPackageListParams {
  pageNo: number;
  pageSize: number;
  sort: string;
  sortByColumn: string;
  searchQuery: string;
}

export const loadVendor = createAsyncThunk<ApiResponse, string>(
  "vendor/loadVendor",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`/procurement/vendor/${id}`);
      return response.data as ApiResponse;
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch vendor details");
    }
  }
);

export const submitVendorForm = createAsyncThunk<
  ApiResponse,
  { id: any; form: any; callback: () => void }
>("vendor/submitForm", async ({ id, form, callback }, { rejectWithValue }) => {
  try {
    const response = await api.put(`/procurement/vendor/${id}`, form);
    callback();
    return response.data as ApiResponse;
  } catch (error: any) {
    return rejectWithValue(error.message || "Failed to submit vendor form");
  }
});

export const getBasicVendor = createAsyncThunk<ApiResponse, string>(
  "vendor/getBasicVendor",
  async (vendorId: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`/procurement/vendor/basic/${vendorId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.message || "Failed to fetch basic vendor details"
      );
    }
  }
);

export const getVendorPackageList = createAsyncThunk<
  ApiResponse,
  GetVendorPackageListParams
>(
  "package/vendor/list",
  async (params: GetVendorPackageListParams, { rejectWithValue }) => {
    try {
      const response = await api.get(`/procurement/package/vendor/list`, {
        params: {
          pageNo: params.pageNo,
          pageSize: params.pageSize,
          sort: params.sort,
          sortByColumn: params.sortByColumn,
          searchQuery: params.searchQuery,
        },
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(
        err.message || "Failed to fetch vendor package list"
      );
    }
  }
);

export const vendorPackageDetails = createAsyncThunk<
  VendorPackageDetailsResponse,
  VendorPackageDetailsId
>(
  "procurement/package/vendor/details",
  async (id: VendorPackageDetailsId, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/procurement/package/vendor/details/${id}`
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(
        err.message || "Failed to fetch vendor package details"
      );
    }
  }
);

export const updateCogsPrice = createAsyncThunk<ApiResponse, any>(
  "catalogue/sku/update-cogs",
  async ({ skuId, cogsPayload }) => {
    try {
      const response = await api.post(
        `/catalogue/sku/update-cogs/${skuId}`,
        cogsPayload
      );
      return response.data as ApiResponse;
    } catch (err: any) {
      throw new Error(err.message || "Failed to update cogs");
    }
  }
);

export const updateRateContractPrice = createAsyncThunk<ApiResponse, any>(
  "vendor/catalogue/sku/update-rc-price",
  async function ({ skuId, rcPayload }) {
    try {
      const response = await api.post(
        `/catalogue/sku/rc-update/${skuId}`,
        rcPayload
      );
      return response.data as ApiResponse;
    } catch (err: any) {
      throw new Error(err.message || "Failed to update cogs");
    }
  }
);
