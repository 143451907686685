import {createAsyncThunk} from "@reduxjs/toolkit";
import {api} from "../../../../../../utils/Axios";

interface ApiResponse {
    // Define the properties of the API response here
}

interface PublishedDetails {
    name: string;
    boqId: string;
    projectId: string;
    packageDetails: { id: string; quantity: number }[];
    estimateId: string;
}

export const postDraftSection = createAsyncThunk<
    ApiResponse,
    { publishedSectionsPayload: any; revisionId: string }
>(
    "procurement/estimation/draft-estimation",
    async ({publishedSectionsPayload, revisionId}) => {
        try {
            const response = await api.post(
                `/procurement/estimation/draft-estimation/${revisionId}`,
                publishedSectionsPayload
            );
            return response.data as ApiResponse;
        } catch (error) {
            console.error("API error", error);
            throw error;
        }
    }
);

export const getPartSummaryDetails = createAsyncThunk<ApiResponse, any>(
    "get-summary",
    async (revisionId) => {
        try {
            const response = await api.get(
                `/procurement/estimation/get-summary/${revisionId}`
            );
            return response.data as ApiResponse;
        } catch (error) {
            console.error("API error", error);
            throw error;
        }
    }
);

export const removeSummary = createAsyncThunk<
    ApiResponse,
    string
>(
    "procurement/estimation/remove-part",
    async (summaryDetailId: string) => {
        try {
            const response = await api.get(`/procurement/estimation/remove-estimation-summary/${summaryDetailId}`);
            return response.data as ApiResponse;
        } catch (error) {
            console.error("API error", error);
            throw error;
        }
    }
);

export const removeItemsFromSummaryDetails = createAsyncThunk<
    ApiResponse,
    { projectId: string, removedItems: any; summaryDetailId: string }
>(
    "procurement/estimation/remove-from-estimation-summary",
    async ({projectId, removedItems, summaryDetailId}) => {
        try {
            const form = {items: removedItems, name: ""};
            const response = await api.post(
                `/procurement/estimation/remove-from-estimation/${projectId}/${summaryDetailId}`,
                form
            );
            return response.data as ApiResponse;
        } catch (error) {
            console.error("API error", error);
            throw error;
        }
    }
);

export const getEstimationRevisions = createAsyncThunk<ApiResponse, string>(
    "procurement/estimation/revision/list",
    async (projectId: string) => {
        try {
            const response = await api.get(`/procurement/estimation/revision/list/${projectId}`);
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || "Failed to fetch estimation revision list details");
        }
    }
);

export const getEstimationRevisionForNonRevisionView = createAsyncThunk<ApiResponse, string>(
    "procurement/estimation/revision/non-revision-view",
    async (projectId: string) => {
        try {
            const response = await api.get(`/procurement/estimation/revision/non-revision-view/${projectId}`);
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || "Failed to fetch estimation revision list details");
        }
    }
);


export const getEstimationRevisionDetails = createAsyncThunk<ApiResponse, string>(
    "procurement/estimation/revision",
    async (id: string) => {
        try {
            const response = await api.get(`/procurement/estimation/revision/${id}`);
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || "Failed to fetch estimation revision details");
        }
    }
);

export const approveEstimationRevision = createAsyncThunk<ApiResponse, string>(
    "procurement/estimation/revision/approve",
    async (id: string) => {
        try {
            const response = await api.get(`/procurement/estimation/revision/approve/${id}`);
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || "Failed to fetch estimation revision approve");
        }
    }
);

export const cloneRevision = createAsyncThunk<ApiResponse, string>(
    "procurement/estimation/revision/clone",
    async (id: string) => {
        try {
            const response = await api.get(`/procurement/estimation/revision/clone/${id}`);
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || "Failed to create new estimation revision");
        }
    }
);

export const getEstimationsForRevisions = createAsyncThunk<ApiResponse, any[]>(
    "procurement/estimation/revision/estimation-details",
    async (revisionIds: any[]) => {
        try {
            const form = {revisionIds};
            const response = await api.post(`/procurement/estimation/revision/estimation-details`, form);
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || "Failed to get estimation details for revisions");
        }
    }
);