import {CircularProgress, Stack, Box, Typography} from "@mui/material";
import "./EstimationLoader.css";
import EstimationTextSwiper from "./EstimationTextSwiper"
import LinearLoader from "./LinearLoader"
import calculatorImg from '../../assets/calculate.jpg';

export default function EstimationLoader(props:any) {
    return (
        <Box className="estimation-loader">
            <Box component="img" src={calculatorImg} sx={{width:"230px", margin:"20px 0 0px"}}></Box>
            {!props.estimationError && <EstimationTextSwiper />}
            {!props.estimationError && <LinearLoader />}
            {props.estimationError && 
            <Box>
                <Typography variant="body2" color="text.secondary" sx={{color: '#5e1baf', fontSize: '1rem'}}>
                    {"Something went wrong. It's taking too long to load estimation."}
                </Typography>
            </Box>}
        </Box>
    );
}