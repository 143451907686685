import {Box, Button, Typography} from "@mui/material";
import React, {useState} from "react";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useDrop} from "react-dnd";
import {ItemTypes} from "../../../modules/project/plan/tabs/boq-tabs/ItemTypes";
import SummarySectionItemsWidget from "./widgets/SummarySectionItemsWidget";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const SummaryContainer = (props: any) => {
    const partCount = 65 + parseInt(props.index);
    const [{canDrop, isOver}, drop] = useDrop({
        accept: [
            ItemTypes.CATEGORY,
            ItemTypes.SUBCATEGORY,
            ItemTypes.ITEM,
            ItemTypes.SKU
        ],
        drop: (item) => handleDrop(item),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const handleDrop = (item: any) => {
        if (props.section.status == 'DRAFT')
            props.handleDrop(item, props.section);
    }

    const [expandPart, setExpandPart] = useState(true);
    const handleExpandPart = () => {
        setExpandPart(!expandPart);
    }

    const handleRemoveSectionItem = (itemType: any) => {
        props.handleRemoveSectionItem(itemType, props.section);
    }


    return (
        <Box sx={{width: '100%', p: 2}} className={"summary-drop"}>
            <Box className={"drag-section"} ref={drop} sx={{border: 1, borderColor: 'divider', borderRadius: 1}}>
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{backgroundColor: "info.light", borderRadius: 1, p: 1}}
                >
                    {props.section.status == 'DRAFT' &&
                        <Button
                            onClick={() => props.handleRemovePart(props.section?.id)}
                            sx={{minWidth: "auto", padding: "4px"}}
                        >
                            <DoDisturbOnOutlinedIcon
                                sx={{fontSize: "16px", color: "#CB5A42", cursor: "pointer"}}
                            />
                        </Button>
                    }
                    {props.section.status == 'PUBLISHED' &&
                        <CheckCircleOutlineIcon sx={{fontSize: "22px", color: "green"}}/>
                    }
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{width: "520px"}}
                    >
                        <Typography
                            sx={{padding: "8px", fontWeight: 500, fontSize: "12px"}}
                            marginRight={"1rem"}
                        >
                            {`Part ${String.fromCharCode(partCount)}`}
                        </Typography>
                        <Typography
                            sx={{padding: "0 8px", fontWeight: 600, fontSize: "15px"}}
                            marginRight={"1rem"}
                        >
                            {props.section?.name || "Unnamed Section"}
                        </Typography>
                        <Typography
                            sx={{padding: "0 8px", fontWeight: 600, fontSize: "12px"}}
                            marginRight={"2rem"}
                        ></Typography>
                    </Box>

                    <Button
                        onClick={() => handleExpandPart()}
                        sx={{minWidth: "auto", padding: "4px"}}
                    >
                        {!expandPart ? (
                            <KeyboardArrowUpIcon/>
                        ) : (
                            <ExpandMoreIcon/>
                        )}
                    </Button>
                </Box>
                <Box>
                    {props.section?.items?.length > 0 ? (
                        <Box>
                            {expandPart &&
                                <>
                                    <Box>
                                        <SummarySectionItemsWidget
                                            showUpdatedCharges={props.showUpdatedCharges}
                                            categories={props.categories}
                                            section={props.section}
                                            estimations={props.estimations}
                                            project={props.project}
                                            handleRemoveSectionItem={handleRemoveSectionItem}
                                        />
                                    </Box>
                                </>
                            }
                        </Box>
                    ) : (
                        <>
                            {
                                props.section.status == 'DRAFT' &&
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "90px",
                                        p: 2,
                                        m: 4,
                                        border: 2,
                                        borderStyle: 'dashed',
                                        borderColor: "#ccc",
                                        borderRadius: 1,
                                        backgroundColor: "#f9f9f9",
                                    }}
                                >
                                    <Typography variant="body1" align="center">
                                        Drag & drop items here to customize Parts
                                    </Typography>
                                </Box>
                            }
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
export default SummaryContainer;