import {getItem} from './LocalStorage';
import {jwtDecode} from "jwt-decode";

export const hasAccess = (permissionsList: any) => {
    if (Array.isArray(permissionsList)) {
        for (let i = 0; i <= permissionsList.length; i++) {
            const isAccessFlag = isAccess(permissionsList[i]);
            if (isAccessFlag) {
                return true;
            }
        }
        return false;
    } else {
        return isAccess(permissionsList);
    }
};

const isAccess = (permission: string) => {
    const auth = getItem("auth");
    const permissions: any[] = [];
    if (auth != null) {
        const decodedAuth: any = jwtDecode(auth);
        if (decodedAuth.hasOwnProperty("permissions")) {
            decodedAuth.permissions.map((permission: any) => {
                permissions.push(permission.authority);
            });
        }
    }

    return permission && permissions?.includes(permission);
};
