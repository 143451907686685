import {Box, Button, Drawer, Grid, IconButton, TextField, Typography,} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {useEffect, useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {createPackage, updatePackage} from "./features/actions";
import Loader from "../Loader";
import {openSnackbar} from "../common/features/snackbarSlice";
import {isValidNumberInput} from "../../utils/regexTest";
import {ERROR_COLOR, SUCCESS_COLOR} from "../../constants";
import WarningIcon from '@mui/icons-material/Warning';

const CreatePackageDrawer = (props: any) => {
    const {packageItems, boq, selectedPackage} = props;
    const [totalItems, setTotalItems] = useState(0);
    const [state, setState] = useState(false);
    const [packageName, setPackageName] = useState("");
    const [isValidQty, setIsValidQty] = useState(true);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [editPackage, setEditPackage] = useState(false);
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state: any) => state.package.loading);

    const toggleDrawer = (open: boolean) => {
        setState(open);
    };

    const handlePackageName = (e: any) => {
        setPackageName(e.target.value);
    };

    const handleInputChange = (index: number, item: any, value: any) => {
        setIsValidQty(false);
        const isValidNumber = isValidNumberInput(value);
        if (isValidNumber) {
            let availableQuantity: number = parseInt(item?.remainingQuantity);
            if (editPackage) {
                availableQuantity += parseInt(item.existingQuantity);
            }

            if (value > availableQuantity) {
                dispatch(
                    openSnackbar({
                        message: "Quantity should not be greater than remaining quantity",
                        backgroundColor: ERROR_COLOR
                    })
                );
            } else {
                setIsValidQty(true);
            }
            item.selectedQuantity = value;
        } else {
            dispatch(
                openSnackbar({
                    message: "Put only numeric or float values",
                    backgroundColor: ERROR_COLOR
                })
            );
        }
    };

    const handleSubmitCreatePackage = (event: any) => {
        event.preventDefault();
        const formData = {
            name: packageName,
            boqId: boq.id,
            isEvent: rcNotAvailable ? 1 : 0,
            projectId: boq.projectId,
            packageDetails: selectedItems?.map((item: any, idx: number) => ({
                id: item.id,
                quantity: item.selectedQuantity,
            })),
        };

        if (packageName && formData && isValidQty) {
            let flag = true;
            if (editPackage) {
                const id = selectedPackage.id;
                dispatch(updatePackage({id, packageDetails: formData})).then((res: any) => {
                    if (res.payload) {
                        if (res.payload?.status == 'error') {
                            flag = flag;
                            dispatch(
                                openSnackbar({
                                    message: res.payload?.errors[0],
                                    backgroundColor: ERROR_COLOR
                                })
                            );
                        } else {
                            dispatch(
                                openSnackbar({
                                    message: "Package Updated Successfully!",
                                    backgroundColor: SUCCESS_COLOR
                                })
                            );
                            props.onPackageCreated(); // Call the callback function
                            toggleDrawer(false);
                        }
                    }
                });
            } else {
                dispatch(createPackage(formData)).then((res: any) => {
                    if (res.payload) {
                        if (res.payload?.status == 'error') {
                            flag = flag;
                            dispatch(
                                openSnackbar({
                                    message: res.payload?.errors[0],
                                    backgroundColor: ERROR_COLOR
                                })
                            );
                        } else {
                            dispatch(
                                openSnackbar({
                                    message: "Package Created Successfully!",
                                    backgroundColor: SUCCESS_COLOR
                                })
                            );
                            props.onPackageCreated(); // Call the callback function
                            toggleDrawer(false);
                        }
                    }
                });
            }
        } else {
            dispatch(
                openSnackbar({
                    message: "Cannot Create package! Check input values",
                    backgroundColor: ERROR_COLOR
                })
            );
        }
    };

    const [hasMultipleRCVendors, setHasMultipleRCVendors] = useState(false);
    const [rcNotAvailable, setRcNotAvailable] = useState(false);

    useEffect(() => {
        let count = 0;
        const data: any[] = [];
        setHasMultipleRCVendors(false);
        setRcNotAvailable(false);
        Object.keys(packageItems).map((subCategoryId: any) => {
            packageItems[subCategoryId].map((sku: any) => {
                count++;
                boq.boqItems.map((boqItem: any) => {
                    boqItem.boqSkus.map((boqSku: any) => {
                        if (sku.id == boqSku.id) {
                            if (boqSku.sku.rcVendors == 0) {
                                setRcNotAvailable(true);
                            }
                            if (boqSku.sku.rcVendors > 1) {
                                setHasMultipleRCVendors(true);
                            }
                            if (sku.quantity == "" && boqSku.remainingQuantity < 1) {
                                setIsValidQty(false);
                            }
                            boqSku.existingQuantity = sku.quantity;
                            boqSku.selectedQuantity = sku.quantity > 0 ? sku.quantity : boqSku.remainingQuantity;
                            data.push(boqSku);
                        }
                    });
                })
            });
        });
        setSelectedItems(data);
        setTotalItems(count);
    }, [packageItems]);

    useEffect(() => {
        if (Object.keys(selectedPackage).length > 0) {
            setEditPackage(true);
            setPackageName(selectedPackage.name);
        }
    }, [selectedPackage]);

    const listDrawer = () => {
        return (
            <form onSubmit={handleSubmitCreatePackage}>
                <Box role="presentation">
                    <Box
                        className={"drawerHeader"}
                        sx={{p: 2, borderBottom: 1, borderColor: "divider"}}
                    >
                        <Box className={"common-space-container"}>
                            <Grid container>
                                <Grid item xs className={"vertically-center-aligned"}>
                                    <Box sx={{display: "flex", flexDirection: "column"}}>
                                        <Typography variant={"h4"}>Create Package</Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginTop: "16px",
                                            }}
                                        >
                                            <Typography
                                                variant={"body1"}
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: 600,
                                                    lineHeight: "14.4px",
                                                    textAlign: "left",
                                                    marginRight: "16px",
                                                }}
                                            >
                                                Package Name
                                            </Typography>
                                            <TextField
                                                required
                                                variant="outlined"
                                                value={packageName}
                                                onChange={handlePackageName}
                                                sx={{
                                                    "& .MuiOutlinedInput-input": {
                                                        padding: "6px",
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid>
                                    <IconButton
                                        aria-label="close drawer"
                                        onClick={() => toggleDrawer(false)}
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box>
                        {selectedItems.map((packageItem: any, index: number) => (
                            <Grid container key={"selected-item-" + index}>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        height: "107px",
                                        background: index % 2 === 0 ? "#F9FAFF" : "transparent",
                                        borderBottom: "1px solid",
                                        borderRight: "1px solid",
                                        borderColor: "divider",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "start",
                                        padding: "0 24px",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            lineHeight: "14.4px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {packageItem.sku.name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            lineHeight: "14.4px",
                                            textAlign: "left",
                                        }}
                                    >
                                        RC-{packageItem.sku.rcVendors}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={8}
                                    sx={{
                                        height: "107px",
                                        borderBottom: "1px solid",
                                        borderColor: "divider",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "start",
                                    }}
                                >
                                    <table>
                                        <thead>
                                        <tr>
                                            <Typography
                                                component="th"
                                                variant="body1"
                                                sx={{
                                                    fontFamily: "Lato",
                                                    fontSize: "12px",
                                                    fontWeight: 400,
                                                    lineHeight: "14.4px",
                                                    textAlign: "left",
                                                    padding: "0 24px",
                                                }}
                                            >
                                                Total Qty
                                            </Typography>
                                            <Typography
                                                component="th"
                                                variant="body1"
                                                sx={{
                                                    fontFamily: "Lato",
                                                    fontSize: "12px",
                                                    fontWeight: 400,
                                                    lineHeight: "14.4px",
                                                    textAlign: "left",
                                                    paddingRight: "24px",
                                                }}
                                            >
                                                Qty Left
                                            </Typography>
                                            <Typography
                                                component="th"
                                                variant="body1"
                                                sx={{
                                                    fontFamily: "Lato",
                                                    fontSize: "12px",
                                                    fontWeight: 400,
                                                    lineHeight: "14.4px",
                                                    textAlign: "left",
                                                    paddingRight: "0 24px",
                                                }}
                                            >
                                                Package Qty
                                            </Typography>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <Typography
                                                component="td"
                                                variant="body1"
                                                sx={{
                                                    fontFamily: "Lato",
                                                    fontSize: "12px",
                                                    fontWeight: 600,
                                                    lineHeight: "14.4px",
                                                    textAlign: "left",
                                                    padding: "0 24px",
                                                }}
                                            >
                                                {packageItem?.quantity}
                                            </Typography>
                                            <Typography
                                                component="td"
                                                variant="body1"
                                                sx={{
                                                    fontFamily: "Lato",
                                                    fontSize: "12px",
                                                    fontWeight: 600,
                                                    lineHeight: "14.4px",
                                                    textAlign: "left",
                                                    paddingRight: "24px",
                                                }}
                                            >
                                                {packageItem?.remainingQuantity}
                                            </Typography>
                                            <td>
                                                <TextField
                                                    required
                                                    variant="outlined"
                                                    onChange={(e) =>
                                                        handleInputChange(index, packageItem, e.target.value)
                                                    }
                                                    defaultValue={packageItem.selectedQuantity}
                                                    inputProps={{
                                                        min: 0,
                                                        max: packageItem?.quantity,
                                                        step: 1,
                                                    }}
                                                    sx={{
                                                        "& .MuiOutlinedInput-input": {
                                                            padding: "8px",
                                                            width: "118px",
                                                            fontFamily: "Lato",
                                                            fontSize: "12px",
                                                            fontWeight: 600,
                                                            lineHeight: "14.4px",
                                                        },
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                    <Box
                        className={"drawerFooter"}
                        sx={{
                            p: 2,
                            borderTop: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Grid item xs className={"vertically-center-aligned"}></Grid>
                        <Grid width={"100%"}>
                            {(hasMultipleRCVendors && !rcNotAvailable) &&
                                <Typography
                                    variant={"body2"}
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        lineHeight: "14.4px",
                                        textAlign: "left",
                                        color: "#EAD940",
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <WarningIcon/> Multiple RC Vendors Active
                                </Typography>}
                        </Grid>

                        <Box className={"common-space-container"}>
                            <Box className={"right-align-content"}>
                                <Button
                                    variant={"text"}
                                    sx={{mr: 1, color: "#E53449", fontSize: '0.8rem'}}
                                    onClick={() => toggleDrawer(false)}
                                >
                                    Cancel
                                </Button>
                                <Button variant={"contained"} color={"primary"} type="submit" sx={{fontSize: '0.8rem'}}>
                                    {rcNotAvailable ? "Create Event Package" : "Create Package"}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </form>
        );
    };

    return (
        <Box>
            <Box sx={{position: "fixed", bottom: 0, right: "4%"}}>
                <Button
                    color={"info"}
                    variant={"contained"}
                    sx={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}
                    onClick={() => toggleDrawer(true)}
                    endIcon={<ExpandLessIcon/>}
                >
                    Create Package ({totalItems} Selected)
                </Button>
            </Box>
            <Drawer
                anchor={"bottom"}
                open={state}
                onClose={() => toggleDrawer(false)}
                sx={{width: "100%"}}
                PaperProps={{
                    sx: {
                        width: 600,
                        left: "unset",
                        right: "4%",
                    },
                }}
            >
                {isLoading && <Loader/>}
                {listDrawer()}
            </Drawer>
        </Box>
    );
};
export default CreatePackageDrawer;
