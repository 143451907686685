import React, {useEffect, useState} from "react";
import {Box, Button, Tabs} from "@mui/material";
import SubCategoryBlock from "./SubCategoryBlock";
import {ItemTypes} from "../../../../modules/project/plan/tabs/boq-tabs/ItemTypes";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";
import {CustomTabPanel} from "../../../../utils/TabsUtils";
import SummaryEstimationItemsTabWidget from "./SummaryEstimationItemsTabWidget";
import SummarySkuWidget from "./SummarySkuWidget";

const SummarySubCategoryWidget = (props: any) => {
    const [expandSubCategory, setExpandSubCategory] = useState(false);
    const [total, setTotal] = useState(0);
    const [totalSkus, setTotalSkus] = useState(0);
    const [estimationItems, setEstimationItems] = useState<any>([]);
    const [activeTab, setActiveTab] = useState<any>("");
    const handleExpand = () => {
        setExpandSubCategory(!expandSubCategory);
    }

    const handleRemoveItemTab = (itemType: any) => {
        if (itemType.id == activeTab) {
            setActiveTab("");
        }
        props.handleRemoveSectionItem(itemType);
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        setEstimationItems([]);
        let total = 0;
        let totalSkus = 0;
        const selectedEstimationItems: any[] = [];
        props.estimations.map((estimation: any) => {
            if (estimation.materialSubCategoryId == props.subCategory.id) {
                if (activeTab == "") {
                    setActiveTab(estimation.estimationItem.id);
                }
                selectedEstimationItems.push(estimation.estimationItem);
                total += estimation.total;
                totalSkus += estimation.totalSkus;
            }
        });
        let subCategoryTotal = total;
        setTotal(subCategoryTotal);
        setTotalSkus(totalSkus);
        setEstimationItems(selectedEstimationItems);
    }, [props]);

    return (
        <Box>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{backgroundColor: '#eee', borderRadius: 1, p: 1.2, cursor: "move", width: '100%'}}
            >
                {props.section.status === 'DRAFT' &&
                    <Button
                        onClick={() => props.handleRemoveSectionItem({
                            type: ItemTypes.SUBCATEGORY,
                            id: props.subCategory?.id
                        })}
                        sx={{minWidth: "auto", padding: "4px"}}
                    >
                        <DoDisturbOnOutlinedIcon
                            sx={{fontSize: "16px", color: "#CB5A42", cursor: "pointer"}}
                        />
                    </Button>
                }
                <SubCategoryBlock
                    subCategory={props.subCategory}
                    expandSubCategory={expandSubCategory}
                    total={total}
                    totalSkus={totalSkus}
                    project={props.project}
                    handleExpand={handleExpand}
                />
            </Box>
            {(expandSubCategory && activeTab != '') &&
                <>
                    <Box sx={{width: '100%', px: 2}}>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            aria-label="Subcategory Tabs"
                            variant="scrollable"
                            scrollButtons="auto"
                            textColor="primary"
                            indicatorColor="primary"
                            sx={{
                                borderBottom: 1,
                                borderColor: "red",
                                display: "flex",
                                mb: 0,
                                "& .MuiTabs-indicator": {
                                    backgroundColor: "primary.main",
                                },
                                "& .MuiTabs-scroller": {
                                    display: "flex !important",
                                },
                            }}
                        >
                            {estimationItems.map((estimationItem: any, itemIndex: any) => (
                                <SummaryEstimationItemsTabWidget
                                    section={props.section}
                                    estimationItem={estimationItem}
                                    project={props.project}
                                    key={estimationItem.id}
                                    itemIndex={itemIndex}
                                    onChange={handleTabChange}
                                    handleRemoveItemTab={handleRemoveItemTab}
                                    value={estimationItem?.id}
                                />
                            ))}
                        </Tabs>
                        {estimationItems.map((estimationItem: any, estimationItemIndex: number) => {
                            return (
                                <CustomTabPanel
                                    value={activeTab}
                                    index={estimationItem.id}
                                    key={`estimation-tab-` + estimationItem.id}
                                >
                                    {estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                        return (
                                            <Box sx={{pt: 2, px: 1}}>
                                                <SummarySkuWidget
                                                    showUpdatedCharges={props.showUpdatedCharges}
                                                    categoryCode={props.categoryCode}
                                                    section={props.section}
                                                    estimationItemSku={estimationItemSku}
                                                    key={estimationItemSku.id}
                                                    project={props.project}
                                                    handleRemoveSectionItem={props.handleRemoveSectionItem}
                                                />
                                            </Box>
                                        )
                                    })}
                                </CustomTabPanel>
                            )
                        })}
                    </Box>
                </>
            }
        </Box>
    )
}
export default SummarySubCategoryWidget;