import {getItem} from "./LocalStorage";
import {jwtDecode} from "jwt-decode";

export const getUserEmail = () => {
    const userDetails: any = getUserDetails();
    if (userDetails != "")
        return userDetails?.userAttributes?.email;
    else
        return "";
}

export const getUserEntityId = () => {
    const userDetails: any = getUserDetails();
    if (userDetails != "")
        return userDetails?.userAttributes?.entityId;
    else
        return "";
}

const getUserDetails = () => {
    const token = getItem("auth");
    if (token != undefined && token != "") {
        return token && jwtDecode(token);
    }
    return "";
}