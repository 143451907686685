import {Box, Grid, Modal} from "@mui/material";
import Loader from "../../components/Loader";
import PageHeading from "../../components/page-heading/PageHeading";
import CloseIcon from "@mui/icons-material/Close";
import BuildForm from "../../components/input-form/BuildForm";
import {useEffect, useState} from "react";
import {api} from "../../utils/Axios";

const style = {
    main: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '80%',
        minHeight: '300px',
        overflow: 'scroll',
        border: 0,
        backgroundColor: 'background.paper',
        boxShadow: 1
    },
    header: {
        position: 'sticky', borderBottom: 1, borderColor: 'info.light'
    }
};
const EditBrandPopup = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClose = (data: any) => {
        setOpen(false);
        props.handleEditPopupCallBack(data);
    }

    const onSubmitHandler = (form: any, callback: any) => {
        setIsLoading(true);
        api.put(`/procurement/brand/update/${props.row.id}`, form).then((response) => {
            setIsLoading(false);
            handleClose({event: 'save'});
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
        callback();
    }

    useEffect(() => {
        if (props.openPopup == true) {
            formFields.map((field: any) => {
                if (field.name === "name") {
                    field.value = props.row?.name;
                }
                if (field.name === "contactPerson") {
                    field.value = props.row?.contactPersonName;
                }
                if (field.name === "contactPersonEmail") {
                    field.value = props.row?.contactPersonEmail;
                }
                if (field.name === "contactPersonMobile") {
                    field.value = props.row?.contactPersonMobile;
                }
                if (field.name === "city") {
                    field.value = props.row?.city;
                }
                if (field.name === "country") {
                    field.value = props.row?.country;
                }
                if (field.name === "status") {
                    field.value = props.row?.status;
                }
            })
            setOpen(true);
        }
    }, [props.openPopup]);

    return (
        <Modal
            open={open}
            onClose={() => handleClose({event: 'close'})}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                {isLoading &&
                    <Loader/>
                }
                <Box className={"common-space-container"}>
                    <Box sx={style.header}>
                        <Grid container spacing={2} sx={{px: 2}} className={"center-aligned"}>
                            <Grid item xs sx={{p: 0}}>
                                <PageHeading
                                    title={"Edit Brand: " + props.row?.name}
                                    showButtons={false}
                                />
                            </Grid>
                            <Grid item className={"center-aligned"}>
                                <CloseIcon sx={{cursor: 'pointer'}} onClick={() => handleClose({event: 'close'})}/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{p: 2, overflow: "auto", height: "70%"}}>
                        <BuildForm
                            buttons={[
                                {
                                    type: 'submit',
                                    title: 'Save',
                                    permissions: ['edit-vendors']
                                },
                            ]}
                            formArr={formFields}
                            onSubmit={onSubmitHandler}
                            handleFormChange={(form: any) => console.log(form)}
                        />
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

const formFields = [
    {
        element: "input",
        label: "Brand Name",
        placeholder: "Brand Name",
        name: "name",
        type: "text",
        required: true
    },
    {
        element: "input",
        label: "Contact Person Name",
        placeholder: "Contact Person Name",
        name: "contactPerson",
        type: "text",
        required: true
    },
    {
        element: "input",
        label: "Contact Person Email",
        placeholder: "Contact Person Email",
        name: "contactPersonEmail",
        type: "email",
        required: true
    },
    {
        element: "input",
        label: "Contact Person Mobile",
        placeholder: "Contact Person Mobile",
        name: "contactPersonMobile",
        type: "number",
        min: 10,
        max: 10,
        required: true
    },
    {
        element: "input",
        label: "City",
        placeholder: "City",
        name: "city",
        type: "text",
        required: true
    },
    {
        element: "input",
        label: "Country",
        placeholder: "Country",
        name: "country",
        type: "text",
        required: true
    },
    {
        element: "radio",
        label: "Status",
        name: "status",
        options: [
            {id: "ACTIVE", label: "ACTIVE"},
            {id: "INACTIVE", label: "INACTIVE"},
        ],
        required: true
    },
];

export default EditBrandPopup;