export const getChartOptionsWithinDisplay = () => {
    return {
        responsive: true,
        cutout: "55%",
        plugins: {
            datalabels: {
                color: "#fff",
                font: {
                    weight: "bold",
                    size: 12,
                },
                formatter: (value: any, context: any) => {
                    const index = context.dataIndex;
                    const label = context.chart.data.datasets[0].data[index];
                    const labelValue = parseFloat(label);
                    if (labelValue < 3) {
                        return "";
                    }
                    // return context.chart.data.labels[index] as string + " \n" + label + "%";
                    return label + "%";
                },
                anchor: "center",
                align: "center",
                offset: -10,
                clip: true,
                display: function (context: any) {
                    const value = context.dataset.data[context.dataIndex];
                    return value > 2;
                },
            },
            legend: {
                display: false,
                position: 'right',
                align: 'center',
                labels: {
                    usePointStyle: true
                }
            }
        }
    }
}