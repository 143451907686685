import { Link, useParams } from "react-router-dom";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { api } from "../../../utils/Axios";
import Loader from "../../../components/Loader";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  styled,
  Typography,
} from "@mui/material";
import PageHeading from "../../../components/page-heading/PageHeading";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  getContentUrl,
  getFormattedDate,
  getPriceInFormatted,
} from "../../../utils/Common";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { useAppDispatch } from "../../../app/hooks";
import { openSnackbar } from "../../../components/common/features/snackbarSlice";
import { ERROR_COLOR, SUCCESS_COLOR } from "../../../constants";
import ReadMore from "../../../components/read-more/ReadMore";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const styles = {
  leftBorder: {
    p: 2,
    borderLeft: 1,
    borderColor: "divider",
    height: "100%",
  },
  rightBorder: {
    p: 2,
    borderRight: 1,
    borderColor: "divider",
    height: "100%",
  },
  xBorder: {
    p: 2,
    borderRight: 1,
    borderLeft: 1,
    borderColor: "divider",
    height: "100%",
  },
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const VendorEventDetails = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [termFile, setTermFile] = useState(
    `${getContentUrl()}/innov8/terms-and-conditions-for-use-of-website.pdf`
  );
  const [comment, setComment] = useState("");
  const [file, setFile] = useState<any>("");
  const [fileName, setFileName] = useState("No file chosen");
  const [event, setEvent] = useState<any>({});
  const [vendorPrices, setVendorPrices] = useState<any>([]);
  const [vendorEventDetail, setVendorEventDetail] = useState<any>({});
  const [vendorResponded, setVendorResponded] = useState(false);
  const [openTermPopup, setOpenTermPopup] = useState(false);
  const dispatch = useAppDispatch();
  const [accepted, setAccepted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState<any>("");
  const [isEventExpired, setIsEventExpired] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const endDate = new Date(event.endDate);
      const timeDifference = endDate.getTime() - now.getTime();

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeRemaining({
          days,
          hours,
          minutes,
          seconds,
        });
      } else {
        setIsEventExpired(true);
        setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [event.endDate]);

  const loadEvent = () => {
    setIsLoading(true);
    api
      .get(`/procurement/event/details-for-vendor/${id}`)
      .then((response) => {
        return response.data;
      })
      .then((response: any) => {
        const eventVendor = response?.eventVendor;
        if (!eventVendor.termAccepted) {
          setOpenTermPopup(true);
        }
        const endDate = new Date(response.endDate);
        const today = new Date();
        endDate.setHours(23, 59, 59, 0);
        today.setHours(23, 59, 59, 0);
        if (today > endDate) {
          setIsEventExpired(true);
        }
        setEvent(response);
        setVendorEventDetail(eventVendor);
        eventVendor.hasOwnProperty("eventVendorDetails") &&
        eventVendor?.eventVendorDetails.length > 0
          ? setVendorResponded(true)
          : setVendorResponded(false);
        setIsLoading(false);
      })
      .catch((ex) => {
        console.log(ex);
        setIsLoading(false);
      });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    if (file) formData.append("file", file);
    if (comment) formData.append("comment", comment);
    formData.append("data", JSON.stringify(vendorPrices));
    api
      .post(`/procurement/event/vendor-bidding/${id}`, formData)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        dispatch(
          openSnackbar({
            message: "Successfully submitted the bid",
            backgroundColor: SUCCESS_COLOR,
          })
        );
        setIsLoading(false);
        loadEvent();
      })
      .catch((ex) => {
        console.log(ex);
        setIsLoading(false);
      });
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const maxAllowedSize = 2 * 1024 * 1024;
      if (event.target.files[0].size > maxAllowedSize) {
        dispatch(
          openSnackbar({
            message: "Maximum 2MB file is allowed to upload",
            backgroundColor: ERROR_COLOR,
          })
        );
        return;
      }
      setFile(event.target.files[0]);
      setFileName(event.target.files[0].name);
    }
  };

  const onCommentChange = (e: any) => {
    setComment(e.target.value);
  };

  const onChangeHandler = (e: any, item: any) => {
    const updatedPrice = e.target.value;
    const itemId = item.id;

    setVendorPrices((prevPrices: any) => {
      const existingItemIndex = prevPrices.findIndex(
        (priceItem: any) => priceItem.itemId === itemId
      );

      if (existingItemIndex >= 0) {
        const updatedPrices = prevPrices.map((priceItem: any, index: number) =>
          index === existingItemIndex
            ? { ...priceItem, price: updatedPrice }
            : priceItem
        );
        return updatedPrices;
      } else {
        return [...prevPrices, { itemId, price: updatedPrice }];
      }
    });
  };

  const getSubmittedPrice = (item: any) => {
    if (!vendorResponded) return "";

    let price = 0;
    vendorEventDetail?.eventVendorDetails.map((eventDetail: any) => {
      if (eventDetail.packageItemId == item.id) {
        price = eventDetail.price;
      }
    });
    return price;
  };

  const handleAccept = () => {
    setIsLoading(true);
    const form = { termFile, eventVendorId: vendorEventDetail.id };
    api
      .post(`/procurement/event/accept-terms/${id}`, form)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setIsLoading(false);
        setOpenTermPopup(false);
        loadEvent();
      })
      .catch((ex) => {
        dispatch(
          openSnackbar({
            message: ex?.response.data,
            backgroundColor: ERROR_COLOR,
          })
        );
        console.log(ex);
        setIsLoading(false);
      });
  };

  const handleTermCheckbox = (event: any) => {
    const checked = event.target.checked;
    if (checked) {
      setAccepted(true);
    } else {
      setAccepted(false);
    }
  };

  useEffect(() => {
    loadEvent();
  }, [id]);

  if (isLoading) {
    return <Loader />;
  }

  const TimeBox = ({ value, label }: any) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
        border: "2px solid #8BB8E0",
        borderRadius: "8px",
        minWidth: "50px",
        minHeight: "60px",
        marginRight: "8px",
        backgroundColor: "background.paper",
      }}
    >
      <Typography sx={{ fontSize: "20px", fontWeight: 600, color: "#8BB8E0" }}>
        {value}
      </Typography>
      <Typography sx={{ fontSize: "12px", color: "#8BB8E0" }}>{label}</Typography>
    </Box>
  );

  return (
    <Box className={"common-space-container"}>
      {event.hasOwnProperty("eventPackages") && !openTermPopup && (
        <>
          <PageHeading title={"Event Details"} />
          <Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "60%" }}>
                <Grid
                  container
                  sx={{
                    backgroundColor: "primary.light",
                    borderTop: 1,
                    borderColor: "divider",
                  }}
                >
                  <Grid item xs>
                    <Typography sx={styles.leftBorder}>Created On</Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography sx={{ p: 2 }}>Start Date</Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography sx={{ p: 2 }}>End Date</Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography sx={styles.rightBorder}>Responded?</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Grid item xs>
                    <Typography sx={styles.xBorder}>
                      {getFormattedDate(event?.startDate)}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography sx={styles.rightBorder}>
                      {getFormattedDate(event?.startDate)}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography sx={styles.rightBorder}>
                      {getFormattedDate(event?.endDate)}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography sx={styles.rightBorder}>
                      {vendorResponded ? "Yes" : "No"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Grid
                container
                sx={{
                  width: "40%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <TimeBox value={timeRemaining.days} label="Days" />
                  <TimeBox value={timeRemaining.hours} label="Hours" />
                  <TimeBox value={timeRemaining.minutes} label="Mins" />
                  <TimeBox value={timeRemaining.seconds} label="Secs" />
                </Box>
              </Grid>
            </Box>

            <Box sx={{ py: 4 }}>
              <Box component={"form"} onSubmit={handleSubmit}>
                {event?.eventPackages.map(
                  (eventPackage: any, index: number) => {
                    return (
                      <Box key={"event-package-" + index}>
                        <Typography variant={"h2"} sx={{ pb: 2 }}>
                          {event?.eventPackage?.name}
                        </Typography>
                        <Grid
                          container
                          sx={{
                            backgroundColor: "primary.light",
                            borderTop: 1,
                            borderColor: "divider",
                          }}
                        >
                          <Grid item xs={1.2}>
                            <Typography
                              sx={{
                                p: 2,
                                borderLeft: 1,
                                borderColor: "divider",
                                fontWeight: 700,
                              }}
                            >
                              Item Category
                            </Typography>
                          </Grid>
                          <Grid item xs={1.2}>
                            <Typography sx={{ p: 2, fontWeight: 700 }}>
                              Selected Item
                            </Typography>
                          </Grid>
                          <Grid item xs={2.4}>
                            <Typography sx={{ p: 2, fontWeight: 700 }}>
                              Sku Description
                            </Typography>
                          </Grid>
                          <Grid item xs={1.2}>
                            <Typography sx={{ p: 2, fontWeight: 700 }}>
                              UOM
                            </Typography>
                          </Grid>
                          <Grid item xs={1.2}>
                            <Typography sx={{ p: 2, fontWeight: 700 }}>
                              Quantity
                            </Typography>
                          </Grid>
                          <Grid item xs={1.2}>
                            <Typography sx={{ p: 2, fontWeight: 700 }}>
                              Rate Per UOM
                            </Typography>
                          </Grid>
                          <Grid item xs={1.2}>
                            <Typography sx={{ p: 2, fontWeight: 700 }}>
                              Total
                            </Typography>
                          </Grid>
                          <Grid item xs={2.4}>
                            <Typography
                              sx={{
                                p: 2,
                                borderRight: 1,
                                borderColor: "divider",
                                fontWeight: 700,
                              }}
                            >
                              Your Bid (Per Unit)
                            </Typography>
                          </Grid>
                        </Grid>
                        {eventPackage.packageItems.map(
                          (packageItem: any, index: number) => {
                            return (
                              <Grid
                                key={"vendor-event-details-" + index}
                                container
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                <Grid item xs={1.2}>
                                  <Typography sx={styles.xBorder}>
                                    {
                                      packageItem?.itemDetail?.itemCategory
                                        ?.name
                                    }
                                  </Typography>
                                </Grid>
                                <Grid item xs={1.2}>
                                  <Typography sx={styles.rightBorder}>
                                    <Link
                                      to={`/vendor/catalogue/skus/details/${packageItem.itemDetail.sku.id}`}
                                    >
                                      {packageItem.itemDetail.sku.name}
                                    </Link>
                                  </Typography>
                                </Grid>
                                <Grid item xs={2.4}>
                                  <Box sx={styles.rightBorder}>
                                    <ReadMore
                                      text={
                                        packageItem.itemDetail.sku.description
                                      }
                                      maxChars={200}
                                      className={"sku-desc"}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={1.2}>
                                  <Typography sx={styles.rightBorder}>
                                    {packageItem?.itemDetail?.sku?.uom}
                                  </Typography>
                                </Grid>
                                <Grid item xs={1.2}>
                                  <Typography sx={styles.rightBorder}>
                                    {packageItem?.quantity}
                                  </Typography>
                                </Grid>
                                <Grid item xs={1.2}>
                                  <Typography sx={styles.rightBorder}>
                                    {getPriceInFormatted(
                                      packageItem?.itemDetail?.targetPrice,
                                      ""
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={1.2}>
                                  <Typography sx={styles.rightBorder}>
                                    {getPriceInFormatted(
                                      packageItem?.quantity *
                                        packageItem?.itemDetail?.targetPrice,
                                      ""
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2.4}>
                                  <Box sx={styles.rightBorder}>
                                    {isEventExpired ? (
                                      <Typography>Closed</Typography>
                                    ) : (
                                      <TextField
                                        fullWidth
                                        size={"small"}
                                        variant={"outlined"}
                                        className={"inputBox"}
                                        type={"number"}
                                        id={packageItem?.id}
                                        name={"price"}
                                        placeholder={"Price"}
                                        required={true}
                                        defaultValue={getSubmittedPrice(
                                          packageItem
                                        )}
                                        onChange={(e) =>
                                          onChangeHandler(e, packageItem)
                                        }
                                        InputProps={{
                                          style: {
                                            borderRadius: "5px",
                                            borderColor: "primary.light",
                                          },
                                        }}
                                      />
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            );
                          }
                        )}
                      </Box>
                    );
                  }
                )}
                {!vendorResponded && !isEventExpired && (
                  <>
                    <Grid container spacing={2} sx={{ p: 4 }}>
                      <Grid item xs></Grid>
                      <Grid item xs></Grid>
                      <Grid item xs>
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          sx={{ pb: 1 }}
                        >
                          Any Bidding Comments
                        </InputLabel>
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            size={"medium"}
                            variant={"outlined"}
                            className={"inputBox"}
                            type={"text"}
                            id={"comment"}
                            name={"comment"}
                            placeholder={"Comments"}
                            onChange={(e) => onCommentChange(e)}
                            InputProps={{
                              style: {
                                borderRadius: "5px",
                                borderColor: "primary.light",
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs>
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          sx={{ pb: 1 }}
                        >
                          Terms And Condition
                        </InputLabel>
                        <Box display="flex" justifyContent="" sx={{}}>
                          <Button
                            component="label"
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload file
                            <VisuallyHiddenInput
                              type="file"
                              onChange={handleFileUpload}
                              accept={".pdf, .doc, .docx"}
                            />
                          </Button>
                        </Box>
                        <Typography sx={{ mt: 1, ml: 0 }}>
                          {fileName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box sx={{ py: 2 }} className={"right-align-content"}>
                      <Button variant={"contained"} type={"submit"}>
                        Submit Bids
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}

      {openTermPopup && (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 2 }}>
            <DialogTitle id="alert-dialog-title">
              <Typography variant={"h5"} sx={{ px: 0.4 }}>
                Accept Terms and Conditions
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <Box>
                  <Typography sx={{ lineHeight: 1.24, px: 0.4 }}>
                    All documents and information that follow are the sole
                    property of the organiser (Space Matrix), and can not be
                    copied, distributed or used for purposes other than the
                    request or auction where you have been invited. By accepting
                    to participate in the bidding process you confirm to
                    maintain the confidentiality. You also understand and agree
                    that your participation to the request or auction does not
                    commit Space Matrix in anyway.
                  </Typography>
                  <Box sx={{ pt: 2 }}>
                    <Typography className={"vertically-center-aligned"}>
                      <ArticleOutlinedIcon color={"primary"} />
                      <Link
                        style={{ marginLeft: 10, color: "primary" }}
                        to={termFile}
                        target={"_blank"}
                      >
                        View Terms and Conditions
                      </Link>
                    </Typography>
                  </Box>

                  <FormControlLabel
                    sx={{ px: 0.2 }}
                    label={
                      <Typography sx={{ color: "common.black" }}>
                        I agree to Space Matrix terms of use and privacy policy
                      </Typography>
                    }
                    control={
                      <Checkbox
                        onChange={(e: any) => handleTermCheckbox(e)}
                        name={"terms"}
                        value={1}
                      />
                    }
                  />
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 3 }}>
              <Button
                onClick={() => handleAccept()}
                color="primary"
                variant={"contained"}
                disabled={!accepted}
              >
                Agree
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
    </Box>
  );
};

export default VendorEventDetails;
