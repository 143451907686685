import {Box, Button} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDrag} from "react-dnd";
import {ItemTypes} from "../../../../modules/project/plan/tabs/boq-tabs/ItemTypes";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SubCategoryWidget from "./SubCategoryWidget";
import CategoryBlock from "./CategoryBlock";

const CategoryWidget = (props: any) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [expandCategory, setExpandCategory] = useState(false);

    const [{isDragging}, categoryDrag] = useDrag({
        type: ItemTypes.CATEGORY,
        item: {type: ItemTypes.CATEGORY, id: props.category.id},
        canDrag: !isDisabled,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const handleExpand = () => {
        setExpandCategory(!expandCategory);
    }

    useEffect(() => {
        setIsDisabled(false);
        let isAllItemsDragged = true;
        props.category.subCategories.map((subCategory: any) => {
            props.estimations.map((estimation: any) => {
                if (estimation.materialSubCategoryId == subCategory.id) {
                    estimation.estimationItems.map((estimationItem: any) => {
                        estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                            if (estimationItemSku?.disabled == false) {
                                isAllItemsDragged = false;
                            }
                        });
                    });
                }
            })
        });

        setIsDisabled(isAllItemsDragged);
    }, [props]);

    return (
        <Box key={"left-category-index-" + props.categoryIndex}>
            <Box
                ref={categoryDrag}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    backgroundColor: "info.contrastText", borderRadius: 1, p: 1.5, width: '100%',
                    cursor: isDisabled ? "not-allowed" : "move",
                    opacity: isDisabled || isDragging ? 0.5 : 1
                }}
            >
                <CategoryBlock category={props.category} project={props.project} showUpdatedCharges={props.showUpdatedCharges}/>
                <Button
                    onClick={() => handleExpand()} sx={{py: 0}}
                >
                    {expandCategory ? (
                        <KeyboardArrowUpIcon/>
                    ) : (
                        <ExpandMoreIcon/>
                    )}
                </Button>
            </Box>
            {expandCategory && props.category.subCategories.map((subCategory: any, subCategoryIndex: number) => (
                <Box sx={{p: 1}}>
                    <SubCategoryWidget
                        key={"sub-category-widget-" + subCategoryIndex}
                        subCategory={subCategory}
                        project={props.project}
                        estimations={props.estimations}
                        showUpdatedCharges={props.showUpdatedCharges}
                        categoryCode={props.category.code}
                    />
                </Box>
            ))}
        </Box>
    )
}

export default CategoryWidget;