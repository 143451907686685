import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import PageHeading from "../../../components/page-heading/PageHeading";
import { Box } from "@mui/material";
import BuildForm from "../../../components/input-form/BuildForm";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { loadVendor, submitVendorForm } from "../features/actions";

interface VendorData {
  name: string;
  year: number;
  city: string;
  status: string;
  // Add any additional properties if necessary
}

const EditVendor = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.vendor.pending);
  const vendor = useAppSelector((state) => state.vendor.vendorDetails);
  const [updatedFormFields, setUpdatedFormFields] = useState<any[]>([]); // State to hold updated form fields
  const [formState, setFormState] = useState<any>({});

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const response = await dispatch(loadVendor(id));
          if ("payload" in response && typeof response.payload === "object") {
            const vendorData = response.payload as VendorData;
            const updatedFields = formFields.map((field) => {
              if (field.name === "name") {
                return { ...field, value: vendorData.name };
              }
              if (field.name === "year") {
                return { ...field, value: vendorData.year };
              }
              if (field.name === "city") {
                return { ...field, value: vendorData.city };
              }

              if (field.name === "status" && (vendorData.status == 'ACTIVE' || vendorData.status == 'INACTIVE')) {
                return { ...field, value: vendorData.status };
              } else {
                return {...{}};
              }
              return field;
            });
            setFormState(vendorData);
            setUpdatedFormFields(updatedFields);
          } else {
            console.error("Invalid response payload:", response.payload);
          }
        } catch (error) {
          console.error("Error fetching vendor:", error);
        }
      }
    };

    fetchData();
  }, [id, dispatch]);

  const onSubmitHandler = async (form: any, callback: () => void) => {
    const formData: any = { ...formState, ...form }; // Merge formState and form
    const response = await dispatch(submitVendorForm({ id, form: formData, callback }));
    if (response) navigate("/vendors");
  };

  if (isLoading || !formState) {
    return <Loader />;
  }

  return (
    <Box className={"common-space-container"}>
      <PageHeading title={`Edit Vendor ${vendor?.owner}`} />
      <Box>
        <Box className={"center-aligned"}>
          <Box sx={{ width: "50%" }}>
            <BuildForm
              buttons={[
                {
                  type: "submit",
                  title: "Save",
                  permissions: ["edit-vendors"],
                },
              ]}
              formArr={updatedFormFields} // Use updatedFormFields here
              onSubmit={onSubmitHandler}
              handleFormChange={(form: any) => console.log(form)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const currentYear = new Date().getFullYear();

const formFields = [
  {
    element: "input",
    label: "Company Name",
    placeholder: "Company Name",
    name: "name",
    type: "text",
  },
  {
    element: "input",
    label: "Year",
    placeholder: "Year",
    name: "year",
    type: "number",
    format: "no-space",
    validation: {
      min: 1950,
      max: currentYear,
      required: true,
    },
  },
  {
    element: "input",
    label: "City",
    placeholder: "City",
    name: "city",
    type: "text",
    format: "no-space",
  },
  {
    element: "radio",
    label: "Status",
    name: "status",
    width: 7,
    value: 1,
    options: [
      { id: "ACTIVE", label: "ACTIVE" },
      { id: "INACTIVE", label: "INACTIVE" },
    ],
  },
];

export default EditVendor;
