import {Box, Tab, Tabs} from "@mui/material";
import React, {SyntheticEvent, useState} from "react";
import PageHeading from "../../../components/page-heading/PageHeading";
import {a11yProps, CustomTabPanel} from "../../../utils/TabsUtils";
import PlatformDetails from "./PlatformDetails";

const DomainDetails = () => {
    const [tabValue, setTabValue] = useState("platform");

    const [tabs, setTabs] = useState(
        [
            {id: "platform", name: "Platform Domain Details"},
            {id: "client", name: "Client Domain Details"},
        ]
    );

    const handleTabChange = (event: SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    return (
        <Box className={"common-space-container"}>
            <PageHeading
                title={"Project Domain Setting"}
                showButtons={false}
            />
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Project Plan Tabs"
                textColor="primary"
                sx={{borderBottom: 1, borderColor: "divider", color: 'common.black'}}
                TabIndicatorProps={{
                    sx: {
                        backgroundColor: "primary.main",
                    },
                }}
            >
                {tabs.map((tab: any, index: number) => (
                    <Tab
                        key={tab.id + "_" + index}
                        {...a11yProps(tab.id, true)}
                        value={tab.id}
                        label={tab.name}
                        wrapped
                    />
                ))}
            </Tabs>

            <CustomTabPanel value={tabValue} index={tabValue}>
                <PlatformDetails type={tabValue}/>
            </CustomTabPanel>
        </Box>
    )
}

export default DomainDetails;