import {getFormattedDate, getPriceInFormatted} from "../../../../utils/Common";
import {Typography} from "@mui/material";

export const pageConfig = {
    packageDetail: [
        {
            field: "name",
            headerName: "Package Name",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 200,
        },
        {
            field: "vendorLink",
            headerName: "Vendor/Brand",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 200,
            renderCell: (params: any) => {
                const vendor = params.row.vendor;
                const brand = params.row.brand;

                return (
                    vendor != null ?
                        <a className={"break-word-anchor"}
                           href={`/vendors/details/${vendor?.id}`}
                           rel="noreferrer"
                           target={"_blank"}
                        >
                            {vendor.name}
                        </a>
                        : brand != null ? <a
                            href={`/brnad/details/${brand?.id}`}
                            rel="noreferrer"
                            target={"_blank"}
                        >
                            {brand.name}
                        </a> : "NA"
                )
            }
        },
        {
            field: "createdAt",
            headerName: "Created On",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            valueGetter: (params: any) => getFormattedDate(params.row?.createdAt),
        },
        {
            field: "total",
            headerName: "Total Value",
            type: "price",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
        },
        {
            field: "status",
            headerName: "Status",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            renderCell: (params: any) => {
                let color = '253, 182, 122';
                let name = params.row.status;
                if (name == 'CREATED') {
                    color = '223, 149, 113';
                }
                if (name == 'PR') {
                    color = '223, 112, 113';
                }
                if (name == 'EVENT') {
                    color = '223, 149, 149';
                }
                if (name == 'CLOSED') {
                    color = '55, 214, 112';
                }

                return (
                    <Typography sx={{
                        textTransform: 'capitalize',
                        border: 1,
                        borderColor: `rgba(${color}, 1)`,
                        borderRadius: 1,
                        p: 0.6,
                        backgroundColor: `rgba(${color}, 0.5)`,
                        minWidth: '100px',
                        textAlign: 'left'
                    }}>{name.toLowerCase()}</Typography>
                )
            }
        },
    ],
    packageDetails: [
        {
            field: 'id',
            headerName: 'Sr. No.',
            sortable: false,
            filterable: false,
            renderCell: (params: any) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
        },
        {
            field: "itemCategory",
            headerName: "Item Category",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            valueGetter: (params: any) => params.row?.itemDetail?.itemCategory?.name + "-" + params.row?.itemDetail?.item?.name
        },
        {
            field: "selectedItem",
            headerName: "Selected Item",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <a
                        href={`/catalogue/skus/details/${params.row?.itemDetail?.sku?.id}`}
                        rel="noreferrer"
                        target={"_blank"}
                    >
                        {params.row?.itemDetail?.sku?.name}
                    </a>
                )
            }
        },
        {
            field: "uom",
            headerName: "UOM",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            valueGetter: (params: any) => params.row?.itemDetail?.sku?.uom
        },
        {
            field: "quantity",
            headerName: "Quantity",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1
        },
        {
            field: "gsv",
            headerName: "Rate per UOM",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            valueGetter: (params: any) => {
                return getPriceInFormatted(params.row?.gsv > 0 ? params.row?.gsv + params.row?.domainGsvCharges.total : 0, params.row?.country)
            }
        },
        {
            field: "cogsPrice",
            headerName: "Planned COGS",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            valueGetter: (params: any) => {
                let total = params.row?.cogsPrice + params.row?.domainCogsCharges.total;
                if (params.row.showUpdatedCharges) {
                    if (params.row?.categoryCode == "ff") {
                        total = total + ((total * 2) / 100);
                    } else {
                        total = total + ((total * 6) / 100);
                    }
                }
                return getPriceInFormatted(total, params.row?.country)
            }
        },
    ],
    packageComparingDetails: [
        {
            field: 'id',
            headerName: 'Sr. No.',
            sortable: false,
            filterable: false,
            renderCell: (params: any) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
        },
        {
            field: "itemCategory",
            headerName: "Item Category",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            valueGetter: (params: any) => params.row?.itemDetail?.itemCategory?.name + "-" + params.row?.itemDetail?.item?.name
        },
        {
            field: "selectedItem",
            headerName: "Selected Item",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            renderCell: (params: any) => (
                <a
                    href={`/catalogue/skus/details/${params.row?.itemDetail?.sku?.id}`}
                    rel="noreferrer"
                    target={"_blank"}
                >
                    {params.row?.itemDetail?.sku?.name}
                </a>
            ),
        },
        {
            field: "uom",
            headerName: "UOM",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            valueGetter: (params: any) => params.row?.itemDetail?.sku?.uom
        },
        {
            field: "quantity",
            headerName: "Quantity",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1
        },
        {
            field: "plannedNsv",
            headerName: "Planned NSV",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            valueGetter: (params: any) => getPriceInFormatted(
                params.row?.quantity * params.row?.itemDetail.sellingPrice -
                params.row?.quantity * params.row?.itemDetail.targetPrice,
                ""
            ),
        },
        {
            field: "actualNsv",
            headerName: "Actual NSV",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            valueGetter: (params: any) => {
                let total = (parseInt(params.row.quantity) * parseFloat(params.row.gsv)) + (parseInt(params.row.quantity) * parseFloat(params.row.domainGsvCharges.total));
                let totalCogs = (parseInt(params.row.quantity) * parseFloat(params.row.cogsPrice)) + (parseInt(params.row.quantity) * parseFloat(params.row.domainCogsCharges.total));
                return getPriceInFormatted(
                    total - totalCogs,
                    ""
                );
            },
        },
    ],
};
