import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { clearAll, getItem } from "./utils/LocalStorage";
import { api } from "./utils/Axios";
import { CheckboxValueProvider } from "./utils/CheckboxContext";
import { Provider } from "react-redux";
import store from './app/store'

api.interceptors.request.use((request) => {
	request.headers.source = "sm-innov8";
	const token = getItem("auth");
	if (token) {
		request.headers.Authorization = `Bearer ${token}`;
	}
	return request;
});

api.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const { status } = error.response;
		console.log(`error from interceptor`, error);
		if (status == 401) {
			clearAll();
			window.location.replace("/login");
		}
		return Promise.reject(error);
	}
);

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<CheckboxValueProvider>
				<App />
			</CheckboxValueProvider>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
