import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { Box } from "@mui/material";
import Header from "../components/header/Header";

const ProtectedRoutes = () => {
  const auth = useAppSelector((state) => state.auth.token);
  return auth ? (
    <Box component={"main"}>
      <Header />
      <Outlet />
    </Box>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoutes;
