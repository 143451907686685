import React, {useEffect, useState} from "react";
import {api} from "../../../../utils/Axios";
import Loader from "../../../../components/Loader";
import ListPage from "../../../../components/list-page/ListPage";
import {packagePrListColumns} from "../config/PackageListColumns";
import {Box} from "@mui/material";
import PackageDetails from "../../../../components/package-tabs/popup/PackageDetails";

const PurchaseRequestTab = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [sortColumn, setSortColumn] = useState('id');
    const [sortDirection, setSortDirection] = useState('desc');
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const [openPackageDetailPopup, setOpenPackageDetailPopup] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState<any>({});

    const handlePackageDetailCallBack = (data: any) => {
        setOpenPackageDetailPopup(false);
        setSelectedPackage({});
    }

    const handleSort = (sortModel: any) => {
        if (sortModel && sortModel.field) {
            setSortColumn(sortModel.field);
            setSortDirection(sortModel.sort);
            setPage(0);
        }
    };

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        setPage(newPagination.page);
    };

    const handleCallBack = (rowData: any, buttonData: any) => {
        if (buttonData.type === 'anchor') {
            setSelectedPackage(rowData);
            setOpenPackageDetailPopup(true);
        }
    };

    const loadPrs = () => {
        setIsLoading(true);
        api.get(`/procurement/package/get-pr/${props.project.id}`, {
            params: {
                pageNo: page,
                pageSize: size,
                sort: sortDirection.toUpperCase(),
                sortByColumn: sortColumn
            }
        }).then((response) => {
            return response.data
        }).then((data: any) => {
            setRows(data.content);
            setTotalElements(data.totalElements);
            setIsLoading(false);
        }).catch((error) => {
            console.error("Error loading PRs:", error);
            setIsLoading(false);
        });
    };

    const handleApprovePr = () => {
        loadPrs();
    }

    useEffect(() => {
        loadPrs();
    }, [props.project.id, sortColumn, sortDirection, page, size]);

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <>
            {rows ? (
                <>
                    <ListPage
                        rows={rows}
                        columns={packagePrListColumns}
                        page={page}
                        size={size}
                        totalElements={totalElements}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        handlePagination={handlePagination}
                        handleSort={handleSort}
                        handleCallBack={handleCallBack}
                        isLoading={isLoading}
                    />
                    {openPackageDetailPopup &&
                        <PackageDetails
                            showUpdatedCharges={props.showUpdatedCharges}
                            aPackage={selectedPackage}
                            project={props.project}
                            categories={props.categories}
                            openPopup={openPackageDetailPopup}
                            handleCallBack={handlePackageDetailCallBack}
                            handleApprovePr={handleApprovePr}
                        />
                    }
                </>
            ) : (
                <Box sx={{height: '100%', minHeight: "300px"}} className={"center-aligned"}>
                    <Loader/>
                </Box>
            )}
        </>
    );
};

export default PurchaseRequestTab;
