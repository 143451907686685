import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {getPriceInFormatted} from "../../../utils/Common";
import skus from "../../../modules/catalogue/skus/Skus";

const ChangeEntityPrice = (props: any) => {
    const {vendorEntities, brandEntities, sku} = props;

    vendorEntities.forEach((entity: any) => {
        sku?.entityPrices.map((entityPrice: any) => {
            if (entityPrice.entityId == entity.id) {
                entityPrice.name = entity.name;
            }
        })
    });

    brandEntities.forEach((entity: any) => {
        sku?.entityPrices.map((entityPrice: any) => {
            if (entityPrice.entityId == entity.id) {
                entityPrice.name = entity.name;
            }
        })
    });


    const handlePriceSelection = (entityPrice: any) => {
        sku.selectedEntity = entityPrice;
        sku.sellingPrice = entityPrice.sellingPrice;
        props.handleVendorSelection(sku, entityPrice);
    }
    return (
        <Box
            sx={{py: "2rem", border: 1, borderTop: 0, borderColor: 'divider'}}>
            <Box className={'center-aligned'}>
                <Box sx={{width: '50%'}}>
                    <Typography variant={"h4"} sx={{pb:2}}>Select Entity</Typography>
                    <Box>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{backgroundColor: "info.light",}}>
                                        <TableCell>Entity Name</TableCell>
                                        <TableCell>Entity Type</TableCell>
                                        <TableCell>Selling Price</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{border: 1, borderColor: 'divider'}}>
                                    {sku?.entityPrices.map((entityPrice: any) => {
                                        let entityName = "Vendor"
                                        if(entityPrice.entityName == 'brand')
                                            entityName = "Brand";
                                        let selected = false;
                                        if (entityPrice.id == props.sku.selectedEntity.id) {
                                            selected = true;
                                        }
                                        return (
                                            <TableRow
                                                key={entityPrice.name}
                                                sx={{border: 1, borderColor: 'divider'}}
                                            >
                                                <TableCell
                                                    sx={{border: 1, borderColor: 'divider'}}>{entityPrice.name}</TableCell>
                                                <TableCell sx={{border: 1, borderColor: 'divider'}}>
                                                    {entityName}
                                                </TableCell>
                                                <TableCell sx={{border: 1, borderColor: 'divider'}}>
                                                    {getPriceInFormatted(entityPrice.sellingPrice, "")}
                                                </TableCell>
                                                <TableCell sx={{border: 1, borderColor: 'divider'}}>
                                                    {selected ? (
                                                        <Typography>Currently Selected</Typography>
                                                    ) : (
                                                        <Typography
                                                            onClick={() => handlePriceSelection(entityPrice)}
                                                            sx={{cursor: 'pointer', color: 'primary.main'}}>
                                                            Select {entityName}
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ChangeEntityPrice;