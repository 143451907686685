import {Box} from "@mui/material";
import PageHeading from "../../components/page-heading/PageHeading";
import {useEffect, useState} from "react";
import CreateBrandPopup from "./CreateBrandPopup";
import {api} from "../../utils/Axios";
import Loader from "../../components/Loader";
import ListPage from "../../components/list-page/ListPage";
import {pageConfig} from "./config/PageConfig";
import EditBrandPopup from "./EditBrandPopup";
import {hasAccess} from "../../utils/Access";
import {useNavigate} from "react-router-dom";
import SearchFilter from "../../components/filter/SearchFilter";
import BrandUpload from './BrandUpload'
import ViewBrandDetails from './ViewBrandDetails'
import RemoveFilters from "../../components/filter/RemoveFilters";

const filters = {
    name: {
        element: "input",
        type: "text",
        label: "Brand Name",
        placeholder: "Brand Name",
        name: "name",
    },
    city: {
        element: "input",
        type: "text",
        label: "City",
        placeholder: "City",
        name: "city",
    },
    country: {
        element: "input",
        type: "text",
        label: "Country",
        placeholder: "Country",
        name: "country",
    },
};


const Brand = () => {
    const [openCreatePopup, setOpenCreatePopup] = useState(false);
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const [openUploadPopup, setOpenUploadPopup] = useState(false);
    const [viewBrandDetails, setViewBrandDetails] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState<any>({});

    const [selectedRow, setSelectedRow] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [rows, rowChange] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, pageChange] = useState(0);
    const [sortColumn, setSortColumn] = useState("_id");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);
    const hideColumns = {id: false};
    const [viewBrandId, setViewBrandId] = useState("")
    const navigate = useNavigate();

    const handelSearchFilter = (query: any) => {
        setSearchQuery(query);
    }

    const handleCallBack = (rowData: any, buttonData: any) => {
        if (buttonData.type === 'edit') {
            setSelectedRow(rowData);
            setOpenEditPopup(true);
        }
        if (buttonData.type === 'view') {
            setViewBrandId(rowData?.id)
            setViewBrandDetails(true)
        }
    }

    const handleHeaderButtonClick = (data: any) => {
        if (data === 'create') {
            setOpenCreatePopup(true);
        } else if (data == 'upload') {
            setOpenUploadPopup(true);
        }
    }

    const handleCreatePopupCallBack = (data: any) => {
        setOpenCreatePopup(false);
        if (data?.event != 'close')
            window.location.reload();
    }

    const handleEditPopupCallBack = (data: any) => {
        setOpenEditPopup(false);
        if (data?.event != 'close') {
            window.location.reload();
            setSelectedRow({});
        }
    }

    const handleUploadPopupCallBack = () => {
        setOpenUploadPopup(false);
        loadVendors();
    }

    const handleViewDetailsCallback = () => {
        setViewBrandDetails(false);
    }

    const loadVendors = () => {
        setIsLoading(true);
        const filterParams = Object.keys(appliedFilters).reduce((acc: any, key: any) => {
            acc[key] = appliedFilters[key];
            return acc;
        }, {});
        api.get("/procurement/brand", {
            params: {
                pageNo: page,
                pageSize: size,
                sort: sortDirection?.toUpperCase(),
                sortByColumn: sortColumn,
                searchQuery: searchQuery,
                ...filterParams
            }
        }).then((response) => {
            return response.data;
        }).then((response: any) => {
            rowChange(response.content);
            setTotalElements(response.totalElements);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const handleSort = (sortModel: any) => {
        setSortColumn(sortModel.field);
        setSortDirection(sortModel?.sort);
        pageChange(0);
    }

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    }

    const handleFilterChange = (appliedParams: any) => {
        setAppliedFilters(appliedParams);
    };


    useEffect(() => {
        loadVendors();
    }, [sortColumn, sortDirection, page, size, searchQuery, appliedFilters]);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Box className={"common-space-container"}>
            <PageHeading
                title={"Brands"}
                showButtons={true}
                buttons={[
                    {id: 'create', label: 'Create Brand', permissions: ['edit-brands']},
                    {id: 'upload', label: 'Upload Brands', permissions: ['edit-brands']},
                ]}
                handleHeaderButtonClick={handleHeaderButtonClick}/>
            <Box sx={{py: 2}} className={'right-align-content'}>
                <SearchFilter
                    query={searchQuery}
                    handleFilterChange={handleFilterChange}
                    filters={filters}
                    appliedFilters={appliedFilters}
                    handleSearchFilter={handelSearchFilter}
                    showSearch={true}
                    showFilters={true}
                    showSearchInput={true}
                />
            </Box>
            <Box>
                <RemoveFilters handleFilterChange={handleFilterChange} filters={filters}
                               appliedFilters={appliedFilters}/>
            </Box>
            <Box>
                <ListPage
                    rows={rows}
                    columns={pageConfig}
                    page={page}
                    size={size}
                    totalElements={totalElements}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    handlePagination={handlePagination}
                    handleSort={handleSort}
                    handleCallBack={handleCallBack}
                    hideColumns={hideColumns}
                />
            </Box>

            {(openCreatePopup && hasAccess(['edit-brands'])) &&
                <CreateBrandPopup openPopup={openCreatePopup} handleCreatePopupCallBack={handleCreatePopupCallBack}/>
            }
            {(openEditPopup && hasAccess(['edit-brands'])) &&
                <EditBrandPopup openPopup={openEditPopup} row={selectedRow}
                                handleEditPopupCallBack={handleEditPopupCallBack}/>
            }
            {(openUploadPopup && hasAccess(['edit-brands'])) &&
                <BrandUpload openPopup={openUploadPopup} handleRegisterPopupCallBack={handleUploadPopupCallBack}/>
            }
            {(viewBrandDetails && hasAccess(['view-brands'])) &&
                <ViewBrandDetails openPopup={viewBrandDetails} handleViewDetailsCallback={handleViewDetailsCallback}
                                  viewBrandId={viewBrandId}/>
            }
        </Box>
    )
}
export default Brand;