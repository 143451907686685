import {useAppDispatch} from '../../app/hooks';
import FormTabMain from "./FormTabMain";
import ParameterFormTab from "./ParameterFormTab";

const ElectricalTab = (props: any) => {
    const {formState, setFormState } = props;
    const handleNextButton = () => {
        props.handleNext(props.categoryName)
    }
    let buttons = [
        {
            type: 'submit',
            color: 'primary.main',
            variant: 'contained',
            title: 'Save',
            permissions: ['edit-projects'],
        },
    ];

    return (
        <ParameterFormTab project={props.project} formState={formState} setFormState={setFormState} formFields={formFields} categoryName={props.categoryName} allParameters={props.allParameters} buttons={buttons} handleNextButton={handleNextButton} updateFieldChange={props.updateFieldChange} buttonRef={props.buttonRef}/>
    );
};

const formFields = [
    {
        groupName: 'Electrical',
        fields: [
            {
                element: 'select',
                label: 'Workspace Operation',
                name: 'workspaceOperation',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: 'Laptop+Dual Monitor',
                options: [
                    {id: 'Laptop+Single Monitor', label: 'Laptop+Single Monitor'},
                    {id: 'Laptop+Dual Monitor', label: 'Laptop+Dual Monitor'},
                    {id: 'Dual Monitor+Dual Eq.', label: 'Dual Monitor+Dual Eq.'},
                    {id: 'Laptop', label: 'Laptop'},
                ],
            },
            {
                element: 'select',
                label: 'Workspace Operation ration (Laptop: 1Monitor: 2Monitor)',
                name: 'workspaceOperationRation',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value:'50 : 20 : 30',
                options: [
                    {id: '100 : 0 : 0', label: '100 : 0 : 0'},
                    {id: '0 : 100 : 0', label: '0 : 100 : 0'},
                    {id: '0 : 0 : 100', label: '0 : 0 : 100'},
                    {id: '0 : 50 : 50', label: '0 : 50 : 50'},
                    {id: '0 : 70 : 30', label: '0 : 70 : 30'},
                    {id: '50 : 20 : 30', label: '50 : 20 : 30'},
                    {id: '50 : 30 : 20', label: '50 : 30 : 20'},
                    {id: '50 : 50 : 0', label: '50 : 50 : 0'},
                    {id: '70 : 20 : 10', label: '70 : 20 : 10'},
                ],
            },
        ],
    },
    {
        groupName: 'Scope',
        fields: [
            {
                element: 'radio',
                label: 'HAT',
                name: 'scope-hat',
                width: 4,
                mainContainerWidth: 12,
                value: 'HAT-Yes',
                options: [
                    {id: 'HAT-Yes', label: 'HAT-Yes'},
                    {id: 'HAT-No', label: 'HAT-No'},
                ],
            },
            {
                element: 'radio',
                label: 'Add-on RP Soc',
                name: 'add-on-rp-soc',
                width: 4,
                mainContainerWidth: 12,
                value: "Yes",
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'No', label: 'No'}
                ],
            },
            {
                element: 'select',
                label: 'USB',
                name: 'scopeUsb',
                width: 4,
                mainContainerWidth: 12,
                value: '2 Port USB-Yes',
                options: [
                    {id: '1 Port USB-Yes', label: '1 Port USB-Yes'},
                    {id: '1 Port USB-No', label: '1 Port USB-No'},
                    {id: '2 Port USB-Yes', label: '2 Port USB-Yes'},
                    {id: '2 Port USB-No', label: '2 Port USB-No'},
                ],
            },
        ],
    },
    {
        fields: [
            {
                element: 'input',
                label: 'Floor Tap-off no.',
                name: 'floorTapOffNo',
                required: true,
                type: 'number',
                width: 4,
                value: 2,
                mainContainerWidth: 12,
            },
            {
                element: 'select',
                label: 'UPS Requirement',
                name: 'upsRequirement',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: '100% to Office with N',
                options: [
                    {id: '100% to Office with N', label: '100% to Office with N'},
                    {id: '50% to Office with N', label: '50% to Office with N'},
                    {id: 'No UPS for Office', label: 'No UPS for Office'},
                ],
            },
            {
                element: 'select',
                label: 'Conduit Type',
                name: 'conduitType',
                required: true,
                value: 'MS',
                width: 4,
                mainContainerWidth: 12,
                options: [
                    {id: 'PVC', label: 'PVC'},
                    {id: 'MS', label: 'MS'},
                ],
            },
            {
                element: 'select',
                label: 'Lighting Design',
                name: 'lightingDesign',
                required: true,
                value: 'HIGH',
                width: 4,
                mainContainerWidth: 12,
                options: [
                    {id: 'HIGH', label: 'HIGH'},
                    {id: 'ECO', label: 'ECO'},
                    {id: 'MID', label: 'MID'},
                ],
            },
            {
                element: 'select',
                label: 'Lighting Control System',
                name: 'lightingControlSystem',
                required: true,
                value: 'LMS-Yes',
                width: 4,
                mainContainerWidth: 12,
                options: [
                    {id: 'Daylight Harvest+PIR', label: 'Daylight Harvest+PIR'},
                    {id: 'LMS-Yes', label: 'LMS-Yes'},
                    {id: 'LMS-No', label: 'LMS-No'},
                    {id: 'PIR', label: 'PIR'},
                ],
            },
            {
                element: 'select',
                label: 'AH Netbox',
                name: 'ah_netbox_inScope',
                required: false,
                value: 'AH Netbox-Workspace+Meeting Room',
                width: 4,
                mainContainerWidth: 12,
                options: [
                    {id: 'NA', label: 'NA'},
                    {id: 'AH Netbox-Workspace', label: 'AH Netbox-Workspace'},
                    {id: 'AH Netbox-Meeting Room', label: 'AH Netbox-Meeting Room'},
                    {id: 'AH Netbox-Workspace+Meeting Room', label: 'AH Netbox-Workspace+Meeting Room'}
                ],
            },
        ],
    },
];

export default ElectricalTab;
