import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import {Box} from "@mui/material";


function ECatalogDialog(props:any) {
  const { onClose, open, confirmText, nextTabValue } = props;

  const handleClose = (value:any) => {
    onClose(value, props.nextTabValue);
  };

  return (
    <Dialog onClose={handleClose} open={open} sx={{marginRight: '0'}}>
      <Box sx={{padding: '20px 30px', textAlign:'center'}}>
        <DialogTitle sx={{fontSize: '16px', fontWeight: '400', letterSpacing: "0.8px"}}>{props?.confirmText ? props.confirmText : "Are you sure you want to proceed to Estimation?"}</DialogTitle>
        <Box sx={{margin: '0 auto'}}>
          <Button variant={"outlined"} sx={{mr: 2}} onClick={() => handleClose("proceed")}>OK</Button>
          <Button variant={"contained"} type="submit" onClick={() => handleClose("")}>Cancel</Button>
        </Box>
      </Box>
    </Dialog>
  );
}

ECatalogDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  confirmText: PropTypes.string.isRequired,
  nextTabValue: PropTypes.string.isRequired,
};

export default ECatalogDialog;