import React from "react";
import EstimationRevisionView from "../../../../components/estimation/revision/EstimationRevisionView";

const EstimationTab = (props: any) => {
    return (
        <>
            <EstimationRevisionView showUpdatedCharges={props.showUpdatedCharges} categories={props.categories} project={props.project} proceedTabChange={props.proceedTabChange}/>
        </>
    )
}
export default EstimationTab;