import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { loadCategories, loadItem, loadItemCategories, loadSubCategories } from './actions';

// Define the types for the fetched data
interface Category {
    id: string;
    name: string;
    // Add other properties as needed
}

interface Item {
    id: string;
    name: string;
    // Add other properties as needed
}

// Define the types for the state
interface CategoryState {
    loading: boolean;
    categories: Category[] | null;
    subCategories: Category[] | null;
    itemCategories: Category[] | null;
    items: Item[] | null;
    error: string;
}


const initialState: CategoryState = {
    loading: false,
    categories: null,
    subCategories: null,
    itemCategories: null,
    items: null,
    error: '',
};

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadCategories.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(loadCategories.fulfilled, (state: any, action) => {
                state.loading = false;
                state.categories = action.payload;
                state.error = '';
            })
            .addCase(loadCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
                    ? action.payload.toString()
                    : 'Failed to fetch categories';
            })
            .addCase(loadSubCategories.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(loadSubCategories.fulfilled, (state: any, action) => {
                state.loading = false;
                state.subCategories = action.payload;
                state.error = '';
            })
            .addCase(loadSubCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
                    ? action.payload.toString()
                    : 'Failed to fetch subcategories';
            })
            .addCase(loadItemCategories.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(loadItemCategories.fulfilled, (state: any, action) => {
                state.loading = false;
                state.itemCategories = action.payload;
                state.error = '';
            })
            .addCase(loadItemCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
                    ? action.payload.toString()
                    : 'Failed to fetch item categories';
            })
            .addCase(loadItem.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(loadItem.fulfilled, (state: any, action) => {
                state.loading = false;
                state.items = action.payload;
                state.error = '';
            })
            .addCase(loadItem.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
                    ? action.payload.toString()
                    : 'Failed to fetch items';
            });
    },
});

// Selectors
export const selectCategories = (state: any) => state.categories.category.categories;
export const selectSubCategories = (state: any) => state.categories.category.subCategories;
export const selectItemCategories = (state: any) => state.categories.category.itemCategories;
export const selectItems = (state: any) => state.categories.category.items;

export default categorySlice.reducer;
