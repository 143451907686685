import { createSlice } from "@reduxjs/toolkit";

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: {
        message: '',
        open: false,
        backgroundColor: '',
    },
    reducers: {
        openSnackbar : (state, action) => {
            state.open = true
            state.backgroundColor = action.payload.backgroundColor
            state.message = action.payload.message
        },
        closeSnackbar: (state) => {
            state.open =  false
        }
    }
})
export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export const selectSnackbarMessage = (state: any) => state.snackbar.message;
export const selectSnackbarOpen = (state: any) => state.snackbar.open;
export const selectSnackbarColor = (state: any) => state.snackbar.backgroundColor;

export default snackbarSlice.reducer;