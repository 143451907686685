import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {api} from '../../../utils/Axios';
import Loader from '../../../components/Loader';
import {closeSnackbar} from "../../../components/common/features/snackbarSlice";
import {getAllRoles} from "../features/actions";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import EditUserComponent from "../../../components/users/EditUserComponent";

const EditUser = () => {
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const roles = useAppSelector((state) => state.user.roles);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(closeSnackbar());
        dispatch(getAllRoles());
    }, []);

    const onSubmitHandler = (form: any, callback: any) => {
        setIsLoading(true);
        api.put(`/authentication/user/${id}`, form).then((response) => {
            setIsLoading(false);
            navigate('/users');
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
        callback();
    };

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <EditUserComponent
            pageName={"Edit User"}
            id={id}
            roles={roles}
            onSubmitHandler={onSubmitHandler}
        />
    );
};

export default EditUser;
