import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import {Box, Grid, Typography} from "@mui/material";
import PageHeading from "../../../components/page-heading/PageHeading";
import SkuOptionsTable from "../../../components/skus/SkuOptionsTable";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReadMore from "../../../components/read-more/ReadMore";
import {api} from "../../../utils/Axios";
import VendorRateContractForm from "./VendorRateContractForm";
import {getUserEntityId} from "../../../utils/Users";
import {hasAccess} from "../../../utils/Access";
import SkuPricesTable from "../../../components/skus/SkuPricesTable";
import EntitySkuPriceTable from "../../../components/skus/EntitySkuPriceTable";

const styles = {
    label: {
        fontSize: "14px",
        fontWeight: 600,
        p: 0.5,
    },
    value: {
        fontSize: "14px",
        p: 0.5,
        textAlign: "start",
    },
    valueWithCopy: {
        fontSize: "14px",
        p: 0.5,
        textAlign: "start",
        display: "flex",
        alignItems: "center",
    },
};

const SkuDetails = () => {
    let {id} = useParams();
    const vendorId = getUserEntityId();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<any>();
    const [primaryImage, setPrimaryImage] = useState("");
    const [open, setOpen] = useState(false);

    const getSku = () => {
        setIsLoading(true);
        api
            .get(`/catalogue/sku/details/vendor/${vendorId}/${id}`)
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                setData(response);
                if (response?.skuImages.length > 0) {
                    setPrimaryImage(response.skuImages[0]?.url);
                }
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getSku();
    }, [id]);

    const handleOpenCogsModal = () => {
        setOpen(true);
    };
    const handleCloseCogsModal = (data: string) => {
        setOpen(false);
        if (data == "save") {
            window.location.reload();
        }
    };

    if (isLoading) {
        return <Loader/>;
    }
    return (
        <Box className={"common-space-container"}>
            {/*<Box>*/}
            {/*    <PageHeading*/}
            {/*        title={"SKU Detail: " + data?.name}*/}
            {/*        showButtons={true}*/}
            {/*        buttons={[{id: "rateContract", label: "Update Rate Contract"}]}*/}
            {/*        handleHeaderButtonClick={handleOpenCogsModal}*/}
            {/*    />*/}
            {/*</Box>*/}
            <Box className={"loto-family"} sx={{py: 4}}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>Material Category</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.value}>{data?.category.name}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>
                                    Material Category Code
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.valueWithCopy}>
                                    {data?.category.code} &nbsp;
                                    <ContentCopyIcon
                                        fontSize={"inherit"}
                                        sx={{cursor: "pointer"}}
                                        onClick={() => {
                                            navigator.clipboard.writeText(data?.category.code);
                                        }}
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>Material Sub-Category</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.value}>
                                    {data?.subCategory.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>
                                    Material Sub-Category Code
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.valueWithCopy}>
                                    {data?.subCategory.code} &nbsp;
                                    <ContentCopyIcon
                                        fontSize={"inherit"}
                                        sx={{cursor: "pointer"}}
                                        onClick={() => {
                                            navigator.clipboard.writeText(data?.subCategory.code);
                                        }}
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>Item Category</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.value}>
                                    {data?.itemCategory.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>Item Category Code</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.valueWithCopy}>
                                    {data?.itemCategory.code} &nbsp;
                                    <ContentCopyIcon
                                        fontSize={"inherit"}
                                        sx={{cursor: "pointer"}}
                                        onClick={() => {
                                            navigator.clipboard.writeText(data?.itemCategory.code);
                                        }}
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>Item Name</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.value}>{data?.item.name}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>Item Code</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.valueWithCopy}>
                                    {data?.item.code} &nbsp;
                                    <ContentCopyIcon
                                        fontSize={"inherit"}
                                        sx={{cursor: "pointer"}}
                                        onClick={() => {
                                            navigator.clipboard.writeText(data?.item.code);
                                        }}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={3}>
                                <Typography sx={styles.label}>Sku Name</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={styles.value}>{data?.name}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={styles.label}>Sku Code</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={styles.valueWithCopy}>
                                    {data?.code} &nbsp;
                                    <ContentCopyIcon
                                        fontSize={"inherit"}
                                        sx={{cursor: "pointer"}}
                                        onClick={() => {
                                            navigator.clipboard.writeText(data?.code);
                                        }}
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={styles.label}>UOM</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={styles.value}>{data?.uom || "NA"}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={styles.label}>Make</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={styles.value}>{data?.make || "NA"}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={styles.label}>Model</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={styles.value}>{data?.model || "NA"}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={styles.label}>Status</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={styles.value}>
                                    {data?.status || "NA"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography sx={styles.label}>Description</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Box sx={styles.value}>
                                    {data &&
                                        data?.description &&
                                        data?.description.length > 0 && (
                                            <ReadMore
                                                text={data?.description}
                                                maxChars={200}
                                                classNameText={"sku-desc-readmore"}
                                            />
                                        )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={3}>
                        <Box>
                            {primaryImage != "" && (
                                <Box className={"center-aligned"}>
                                    <img
                                        src={primaryImage}
                                        className={"sku-image"}
                                        style={{width: "180px"}}
                                        alt={data.name}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{pt: 5, pb: 10}}>
                    <Grid container spacing={4}>
                        {data?.skuOptions.length > 0 && (
                            <Grid item xs={6}>
                                <SkuOptionsTable skuOptions={data?.skuOptions}/>
                            </Grid>
                        )}
                        {data?.skuPrices.length > 0 &&
                            <Grid item xs={6}>
                                <EntitySkuPriceTable skuPrices={data?.skuPrices}/>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
            {open && (
                <VendorRateContractForm
                    skuId={data.id}
                    openPopup={open}
                    handleCloseCogsModal={handleCloseCogsModal}
                />
            )}
        </Box>
    );
};
export default SkuDetails;
