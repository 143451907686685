import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const SelectBox = (props: any) => {
    const {formField} = props;

    return (
        <FormControl fullWidth>
            <Select
                labelId="demo-simple-select-label"
                id={formField.name}
                // size={"small"}
                name={formField.name}
                required={formField?.required ? formField.required : false}
                onChange={(event: any) => props.onChangeHandler(event)}
                defaultValue={formField.value != undefined ? formField.value : ""}
                disabled={(formField?.disableOn && props?.disableFields?.hasOwnProperty(formField.name)) ? true : false}
                readOnly={formField?.readonly}
                style={{height: '40px'}}
            >
                {formField.options.map((option: any, index: number) => (
                    <MenuItem key={"build-form-select-" + index}
                              value={option.id}>{option.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
export default SelectBox;