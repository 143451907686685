import { Typography } from "@mui/material";

export const pageConfig = {
  columns: [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "data-grid-header",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "data-grid-header",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "data-grid-header",
      flex: 1,
      renderCell: (params: any) => (
        <Typography sx={{ textTransform: "capitalize" }}>
          {params.row?.status}
        </Typography>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      headerClassName: "data-grid-header",
      flex: 1,
      renderCell: (params: any) => (
        <Typography sx={{ textTransform: "capitalize" }}>
          {params.row?.type}
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "data-grid-header",
      sortable: false,
      buttons: [
        {
          type: "details",
          title: "Estimation PackageDetails",
          permissions: ["edit-projects"],
          condition: (row: any) => true 
        },
      ],
    },
  ],
};

export const projectDeliveryAreaList = [
  "Lab",
  "Hub Room",
  "Server Room",
  "UPS & Battery Room",
  "AHU",
  "Electrical Room",
  "Shaft Areas",
  "NAP Room",
  "Mothers Room",
  "MALE Prayer Room",
  "FEMALE Prayer Room",
  "Ablution Recreation",
  "Gym",
  "Game Zone",
  "Preheat Kitchen",
  "Live Kitchen",
  "Dishwash Area",
  "Serving Area",
  "Hand Wash",
  "Music Room",
  "Recording Room",
  "Yogaroom",
  "Collab Areas",
  "Photo Studio",
  "BMS & IT Rooms",
  "Store Room",
  "Cloak Room",
  "Waiting Lounge",
  "Arrival Area",
  "Security Room",
  "Dry Pantry",
  "Wet Pantry",
  "Tuck Shops",
  "NOC Room",
  "Board Room",
  "Workstation",
  "Repro",
  "Cafetaria",
  "Reception",
  "Glazing Wall",
];
