import {Box, Button, Checkbox, FormControlLabel, Tooltip, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const SelectCompareRevisions = (props: any) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    const wrapperRef = useRef(null);

    const handleOpenTooltip = () => {
        setOpenTooltip(!openTooltip);
    };

    const handleSave = (data: any) => {
        setOpenTooltip(false);
        props.handleSave(data);
    }

    return (
        <Box sx={{width:'80%'}}>
            <Typography sx={{pb: 1}}>
                Select Estimations to compare
            </Typography>
            <Box sx={{position: 'relative'}} ref={wrapperRef}>
                <Tooltip
                    className={"tooltip"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                p: 0,

                                width: '150%',
                                bgcolor: 'common.white',
                                boxShadow: 1,
                                borderRadius: 0,
                                border: 1,
                                borderColor: 'divider',
                                '& .MuiTooltip-arrow': {
                                    display: 'none',
                                },
                                '&.MuiTooltip-tooltip': {
                                    '&.MuiTooltip-tooltipPlacementBottom': {
                                        marginTop: '5px',
                                    },
                                }
                            },
                        },
                    }}
                    arrow
                    title={<ShowTooltip
                        revisions={props.revisions}
                        selectedRevisions={props.selectedRevisions}
                        handleSave={handleSave}
                    />}
                    placement="bottom-start"
                    open={openTooltip}>
                    <Button variant={"outlined"} onClick={handleOpenTooltip}
                            sx={{color: 'common.black', py: 1, px: 2, width: '100%', justifyContent: 'space-between'}}
                            endIcon={<KeyboardArrowDownOutlinedIcon/>}>
                        <Typography component={"span"}>
                            {props.selectedRevisions.length} selected
                        </Typography>
                    </Button>
                </Tooltip>
            </Box>
        </Box>
    )
}

const ShowTooltip = (props: any) => {
    const [formattedRevisions, setFormattedRevisions] = useState<any>([]);
    const [selectedRevisionList, setSelectedRevisionList] = useState<any>(props.selectedRevisions);

    const toNested = (data: any, pid: any) => {
        return data.reduce((r: any, e: any) => {
            if (pid == e.parentId) {
                const object = {...e}
                const children = toNested(data, e.id);

                if (children.length) {
                    object.children = children
                }

                r.push(object)
            }

            return r;
        }, [])
    }

    useEffect(() => {
        if (props.revisions.length > 0) {
            const updatedRevisions = toNested(props.revisions, null);
            setFormattedRevisions(updatedRevisions);
        }
    }, [props.revisions]);

    const onChangeCheckbox = (event: any, revision: any) => {
        const selected = event.target.checked;
        if (selected) {
            setSelectedRevisionList((prevRevisionId: any) => [...prevRevisionId, revision.id]);
        } else {
            setSelectedRevisionList((prevRevisionId: any) =>
                prevRevisionId.filter((id: any) => id !== revision.id)
            );
        }
    }

    return (
        <Box sx={{p: 2}}>
            {formattedRevisions.map((revision: any, revisionIndex: number) => {
                return (
                    <RevisionItem key={"checkbox-revision-" + revisionIndex} revision={revision} primarySpace={0}
                                  selectedRevisions={selectedRevisionList}
                                  onChangeCheckbox={onChangeCheckbox}/>
                )
            })}
            <Box className={"right-align-content"}>
                <Button variant={"contained"} onClick={() => props.handleSave(selectedRevisionList)}>Save</Button>
            </Box>
        </Box>
    )
}

const RevisionItem = (props: any) => {
    return (
        <Box sx={{
            color: 'common.black', pl: props.primarySpace, borderLeft: props.revision.hasOwnProperty("children") && props.revision.children.length > 0 ? 0 : 0, borderColor: 'divider'
        }}>
            <FormControlLabel
                label={props.revision.name}
                control={
                    <Checkbox
                        checked={props.selectedRevisions.includes(props.revision.id)}
                        onChange={(e: any) => props.onChangeCheckbox(e, props.revision)}
                        name={props.revision.name}
                        value={props.revision.id}
                    />
                }
            />
            {props.revision.hasOwnProperty("children") &&
                props.revision.children.length > 0 &&
                props.revision.children.map((nestedRevision: any, nestedRevisionIndex: number) => <RevisionItem
                    key={nestedRevisionIndex} revision={nestedRevision} primarySpace={props.primarySpace + 1.5}
                    onChangeCheckbox={props.onChangeCheckbox} selectedRevisions={props.selectedRevisions}/>)}
        </Box>
    )
}

export default SelectCompareRevisions;