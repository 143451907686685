import {createAsyncThunk} from "@reduxjs/toolkit";
import {api} from "../../../utils/Axios";

interface ApiResponse {
    // Define the properties of the API response here
}

export const loadItemCategories = createAsyncThunk<ApiResponse, string>(
    'catalogue/loadItemCategoryReport',
    async (subCategoryId) => {
        try {
            const response = await api.get('/catalogue/item-category/list', {
                params: {
                    sub_category_id: subCategoryId,
                },
            });
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || 'Failed to fetch item categories report');
        }
    }
);


export const loadCategoryReport = createAsyncThunk<ApiResponse, string>(
    'catalogue/loadCategoryReport',
    async (categoryId) => {
        try {
            const response = await api.get('/catalogue/material-master/category-report', {
                params: {
                    categoryId: categoryId,
                },
            });
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || 'Failed to fetch item categories report');
        }
    }
);


export const loadCatalogReport = createAsyncThunk<ApiResponse, boolean>(
    'catalogue/loadCatalogReport',
    async (loadVendorRegion: boolean) => {
        try {
            const response = await api.get('/catalogue/material-master/report', {
                params: {
                    loadVendorRegion: loadVendorRegion,
                },
            });
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || 'Failed to fetch item categories report');
        }
    }
);

export const updateCogsPrice = createAsyncThunk<ApiResponse, any>(
    'catalogue/sku/update-cogs', 
    async ({ skuId, cogsPayload }) => {
      try {
        const response = await api.post(`/catalogue/sku/update-cogs/${skuId}`, cogsPayload);
        return response.data as ApiResponse;
      } catch(err: any){
        throw new Error(err.message || 'Failed to update cogs');
      }
    }
  );