// import {api} from "../../../../utils/Axios";
import FormTabMain from "./FormTabMain";
import ParameterFormTab from "./ParameterFormTab";

const HvacTab = (props: any) => {
    const {formState, setFormState } = props;
    const handleNextButton = () => {
        props.handleNext(props.categoryName)
    }
    let buttons = [
        {
            type: 'submit',
            color: 'primary.main',
            variant: 'contained',
            title: 'Save',
            permissions: ['edit-projects']
        }
    ];

    return (
        <>
            <ParameterFormTab project={props.project} formState={formState} setFormState={setFormState} formFields={formFields} categoryName={props.categoryName} allParameters={props.allParameters} buttons={buttons} handleNextButton={handleNextButton} updateFieldChange={props.updateFieldChange}  buttonRef={props.buttonRef}/>
        </>
    )
}

const formFields = [
    {
        groupName: 'HVAC',
        fields: [
            {
                element: 'select',
                label: "Workspace",
                name: 'workspace',
                width: 4,
                mainContainerWidth: 12,
                value: "Tap-off+AHU+Lowside",
                options: [
                    {id: "Tap-off+AHU+Lowside", label: 'Tap-off+AHU+Lowside'},
                    {id: 'Tap-off+CSU+Lowside', label: 'Tap-off+CSU+Lowside'},
                    {id: 'Lowside', label: 'Lowside'},
                    {id: 'VRF+AHU+Lowside', label: 'VRF+AHU+Lowside'},
                    {id: 'VRF+CSU+Lowside', label: 'VRF+CSU+Lowside'},
                    {id: 'VRF+Cassette+Lowside', label: 'VRF+Cassette+Lowside'},
                    {id: 'VRF+Hybrid+Lowside', label: 'VRF+Hybrid+Lowside'},
                    {id: 'DX+CSU+Lowside', label: 'DX+CSU+Lowside'},
                    {id: 'DX+Cassette+Lowside', label: 'DX+Cassette+Lowside'},
                ]
            },
            {
                element: 'select',
                label: "24/7 area",
                name: '24/7Area',
                width: 4,
                mainContainerWidth: 12,
                value: "VRF",
                options: [
                    {id: "VRF", label: 'VRF'},
                    {id: 'DX', label: 'DX'},
                    {id: 'None', label: 'None'},
                ]
            },
            {
                element: 'select',
                label: "Dedicated",
                name: 'dedicated',
                width: 4,
                mainContainerWidth: 12,
                value: "Cafe+Gym+Others",
                options: [
                    {id: "Cafe", label: 'Cafe'},
                    {id: 'Gym', label: 'Gym'},
                    {id: 'Cafe+Gym', label: 'Cafe+Gym'},
                    {id: 'Cafe+Gym+Others', label: 'Cafe+Gym+Others'},
                    {id: 'None', label: 'None'},
                ]
            },
        ]
    },
    {
        groupName: 'Scope',
        fields: [
            {
                element: 'radio',
                label: "VAV",
                name: "vav",
                width: 4,
                mainContainerWidth: 12,
                value: 'Yes',
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'No', label: 'No'},
                ]
            },
            {
                element: 'radio',
                label: "Under deck",
                name: "underDeck",
                width: 4,
                mainContainerWidth: 12,
                value: 'Yes',
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'No', label: 'No'},
                ]
            },
        ]
    },
    {
        fields: [
            {
                element: 'input',
                label: "AHU QTY",
                name: "ahuQty",
                required: true,
                type: "number",
                width: 4,
                mainContainerWidth: 12,
            },
            {
                element: 'input',
                label: "Landlord AHU CFM",
                name: "landlordAhuCfm",
                required: true,
                type: "number",
                width: 4,
                mainContainerWidth: 12,
            },
            // {
            //     element: 'radio',
            //     label: "Toilet PackageDetails: Scope",
            //     name: "toiletDetailsScope",
            //     width: 4,
            //     mainContainerWidth: 12,
            //     value: 'Toilet in Scope',
            //     options: [
            //         {id: 'Toilet in Scope', label: 'Toilet in Scope'},
            //         {id: 'Toilet not in Scope', label: 'Toilet not in Scope'},
            //     ]
            // },
            {
                element: 'input',
                label: "AHU Wall Acoustic in Mtr",
                name: "ahuWallAcousticInMtr",
                required: true,
                type: "number",
                width: 4,
                mainContainerWidth: 12,
            },
        ]
    }
];

export default HvacTab;