import {createAsyncThunk} from "@reduxjs/toolkit";
import {api} from "../../../utils/Axios";

interface ApiResponse {
    // Define the properties of the API response here
}

export const getProject = createAsyncThunk(
    "project",
    async (
        id: string) => {
        try {
            const response = await api.get(`/procurement/project/primary-details/${id}`);
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || "Failed to fetch project details");
        }
    }
);
export const getProjectDetails = createAsyncThunk(
    "project/project-details",
    async (
        id: string) => {
        try {
            const response = await api.get(`/procurement/project/project-details/${id}`);
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || "Failed to fetch project details");
        }
    }
);

export const getProjectDomainDetails = createAsyncThunk<ApiResponse, {
    type: string,
    id: string
}>(
    "project/project-domain-details",
    async ( {type, id}) => {
        try {
            const response = await api.get(`/procurement/project/project-domain-details/${type}/${id}`);
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || "Failed to fetch project domain details");
        }
    }
);