import React from 'react';
import {Checkbox, Typography} from "@mui/material";
import {Link} from 'react-router-dom';

export const createConfig = (
    selectedRow: any,
    handleCheckboxChange: any,
    bufferValues: any,
    handleBufferChange: any
) => [
    {
        field: "select",
        headerName: "",
        sortable: false,
        flex: 0.5,
        renderCell: (params: any) => (
            <Checkbox
                checked={selectedRow === params.row.id}
                onChange={() => handleCheckboxChange(params.row.id)}
            />
        ),
    },
    {field: "id", headerName: "#", headerClassName: "data-grid-header", sortable: false, flex: 1},
    {
        field: "name",
        headerName: "Project Name",
        headerClassName: "data-grid-header",
        flex: 1,
        renderCell: (params: any) => (
            <Link
                to={`/projects/plan/${params.row.id}`}
                color="primary"
                target="_blank"
                rel="noopener noreferrer"
                style={{wordBreak: 'break-all', whiteSpace: 'normal'}}
            >
                {params.row.name}
            </Link>
        ),
    },
    {field: "projectStudio", headerName: "Project Studio", headerClassName: "data-grid-header", flex: 1},
    {
        field: "location", headerName: "Location", headerClassName: "data-grid-header", flex: 1, renderCell: (params: any) => (
            <Typography sx={{textTransform: "capitalize"}}>{params.row.location}</Typography>
        )
    },
    {field: "carpetArea", headerName: "Carpet Area", headerClassName: "data-grid-header", flex: 1},
    {
        field: "estimationType", headerName: "Method", headerClassName: "data-grid-header", flex: 1, renderCell: (params: any) => (
            <Typography
                sx={{textTransform: "capitalize"}}>{params.row.estimationType == 'scratch' ? 'Create From Scratch' : params.row.estimationType == 'engine' ? "Generate Estimate" : "Clone Project"}</Typography>
        )
    },
    // {
    //   field: "bufferQuantity",
    //   headerName: "Buffer Quantity (%)",
    //   headerClassName: "data-grid-header",
    //   flex: 1,
    //   renderCell: (params: any) => (
    //     <Box display="flex" alignItems="center">
    //       <IconButton
    //         onClick={() => handleBufferChange(params.row.id, false)}
    //         disabled={selectedRow !== params.row.id}
    //       >
    //         <RemoveIcon />
    //       </IconButton>
    //       <TextField
    //         value={bufferValues[params.row.id]}
    //         inputProps={{ readOnly: true, style: { width: 30, textAlign: "center" } }}
    //         variant="outlined"
    //         size="small"
    //         disabled={selectedRow !== params.row.id}
    //       />
    //       <IconButton
    //         onClick={() => handleBufferChange(params.row.id, true)}
    //         disabled={selectedRow !== params.row.id}
    //       >
    //         <AddIcon />
    //       </IconButton>
    //     </Box>
    //   ),
    // },
    {
        field: "action",
        headerName: "Action",
        headerClassName: "data-grid-header data-grid-right-aligned-header",
        headerAlign: "right",
        align: "right",
        sortable: false,
        flex: 1,
        show: "button",
        buttons: [
            {
                type: "select-project",
                title: "Select Project",
                variant: "outlined",
                permissions: ["edit-projects"],
                condition: (row: any) => true,
                disabled: (row: any) => {
                    return selectedRow !== row.id
                },
            },
        ],
    },
];
  