import { useEffect, useRef } from 'react';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getAllRoles, submitForm } from '../features/actions';
import {
    closeSnackbar,
    openSnackbar
} from '../../../components/common/features/snackbarSlice';
import CreateUserComponent from "../../../components/users/CreateUserComponent";
import { ERROR_COLOR, SUCCESS_COLOR } from '../../../constants';
import { useCheckboxValues } from '../../../hooks/useCheckboxValues';

const CreateUser = () => {
    const navigate = useNavigate();
    const roles = useAppSelector((state) => state.user.roles);
    const isLoading = useAppSelector((state) => state.user.loading);
    const isCreatedUser = useAppSelector((state) => state.user.formResponse);
    const errorMessage = useAppSelector((state) => state.user.error);
    const dispatch = useAppDispatch();
    const { resetSelectedValues } = useCheckboxValues()
    const timerId = useRef<NodeJS.Timeout | undefined>(undefined);
    
    const onSubmitHandler = (form: any, callback: any) => {
        clearTimeout(timerId.current!);
        dispatch(submitForm({ form, callback }));
        if (isCreatedUser) {
            dispatch(openSnackbar({message: 'User created successfully', backgroundColor: SUCCESS_COLOR}));
            navigate('/users');
        } else if (errorMessage) {
            dispatch(openSnackbar({message:'Oops. Something went wrong, please try again', backgroundColor: ERROR_COLOR}));
        }
    };

    useEffect(() => {
        dispatch(closeSnackbar());
        dispatch(getAllRoles());
    }, []);

    useEffect(() => {
        resetSelectedValues()
    }, [])

    if (isLoading) {
        return <Loader />;
    }

    return (
        <CreateUserComponent pageName={"Create User"} roles={roles} onSubmitHandler={onSubmitHandler}/>
    );
};

export default CreateUser;
