import {getParameter, submitForm} from "./actions";
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

// Define the type for the results object
interface Results extends Array<any> {
}

// Initial state
interface TabsState {
    loading: boolean;
    parameterLoading: boolean;
    results: Results | null;
    parameterResult: Results | null;
    error: string;
}

const initialState: TabsState = {
    loading: false,
    parameterLoading: false,
    results: null,
    parameterResult: null,
    error: '',
};

// VendorUser slice
const tabSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(submitForm.pending, (state: any) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(submitForm.fulfilled, (state: any, action: PayloadAction<Results>) => {
                state.loading = false;
                state.results = action.payload;
                state.error = '';
            })
            .addCase(submitForm.rejected, (state: any, action: any) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to submit form';
            })
            .addCase(getParameter.pending, (state: any) => {
                state.parameterLoading = true;
                state.error = '';
            })
            .addCase(getParameter.fulfilled, (state: any, action: PayloadAction<Results>) => {
                state.parameterLoading = false;
                state.parameterResult = action.payload;
                state.error = '';
            })
            .addCase(getParameter.rejected, (state: any, action: any) => {
                state.parameterLoading = false;
                state.error = action.error.message || 'Failed to get parameter';
            })
    },
});

export default tabSlice.reducer;
