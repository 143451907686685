import PageHeading from "../../components/page-heading/PageHeading";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {api} from "../../utils/Axios";
import Loader from "../../components/Loader";
import ListPage from "../../components/list-page/ListPage";
import {Box} from "@mui/material";
import {pageConfig} from "./config/project";
import SearchFilter from "../../components/filter/SearchFilter";
import RemoveFilters from "../../components/filter/RemoveFilters";
import {hasAccess} from "../../utils/Access";

const Project = () => {
    const [searchParams] = useSearchParams();
    const stage = searchParams.get("stage");
    const pageConfigs: any = pageConfig;
    const [isLoading, setIsLoading] = useState(false);
    const [rows, rowChange] = useState([]);
    const [page, pageChange] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortColumn, setSortColumn] = useState("id");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);
    const [appliedFilters, setAppliedFilters] = useState({});
    const hideColumns = {id: false};
    const navigate = useNavigate();

    const handelSearchFilter = (query: any) => {
        setSearchQuery(query);
    }

    const handleFilterChange = (appliedParams: any) => {
        setAppliedFilters(appliedParams);
    }

    useEffect(() => {
        loadProjects();
    }, [sortColumn, sortDirection, page, size, searchQuery, appliedFilters]);

    const handleSort = (sortModel: any) => {
        setSortColumn(sortModel.field);
        setSortDirection(sortModel?.sort);
        pageChange(0);
    }

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    }

    const loadProjects = () => {
        let isAdmin = "false";
        if(hasAccess(['super-admin'])) {
            isAdmin = "true";
        }
        setIsLoading(true);
        const params = new URLSearchParams({
            pageNo: page.toString(),
            pageSize: size.toString(),
            sort: sortDirection?.toUpperCase(),
            sortByColumn: sortColumn,
            searchQuery: searchQuery,
            isAdmin: isAdmin
        });
        if (Object.keys(appliedFilters).length > 0) {
            Object.entries(appliedFilters).map(([name, value]: any) => {
                if (name == 'stage') {
                    params.append(name, value.toUpperCase());
                } else {
                    params.append(name, value);
                }
            });
        }
        api.get("/procurement/project", {
            params: params
        }).then((response) => {
            return response.data;
        }).then((response: any) => {
            rowChange(response.content);
            setTotalElements(response.totalElements);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const handleCallBack = (rowData: any, buttonData: any) => {
        if (buttonData.type == 'edit') {
            navigate(`/projects/edit/${rowData.id}`);
        }
        if (buttonData.type == 'details') {
            navigate(`/projects/plan/${rowData.id}`);
        }
    }

    useEffect(() => {
        if (stage != null) {
            setAppliedFilters({stage: stage});
        }
    }, [stage]);

    if (isLoading) {
        return <Loader/>
    }
    const handleHeaderButtonClick = (data: any) => {
        if (data == 'create') {
            navigate("/projects/create")
        }
    }

    return (
        <Box className={"common-space-container"}>
            <PageHeading
                title={"Projects"}
                showButtons={true}
                buttons={[
                    {id: 'create', label: 'Create Project'},
                ]}
                handleHeaderButtonClick={handleHeaderButtonClick}/>
            <Box sx={{py: 2}} className={'right-align-content'}>
                <SearchFilter
                    handleFilterChange={handleFilterChange}
                    filters={filters}
                    appliedFilters={appliedFilters}
                    showFilters={true}
                    showSearch={true}
                    query={searchQuery}
                    handleSearchFilter={handelSearchFilter}
                />
            </Box>
            <Box>
                <RemoveFilters handleFilterChange={handleFilterChange} filters={filters}
                               appliedFilters={appliedFilters}/>
            </Box>
            <Box>
                <ListPage
                    rows={rows}
                    columns={pageConfigs.columns}
                    page={page}
                    size={size}
                    totalElements={totalElements}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    handlePagination={handlePagination}
                    handleSort={handleSort}
                    handleCallBack={handleCallBack}
                    hideColumns={hideColumns}
                />
            </Box>
        </Box>
    );
}

const filters = {
    'name': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Name',
        label: 'Project Name',
        name: 'name'
    },
    'studio': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Studio',
        label: 'Project Studio',
        name: 'studio'
    },
    'method': {
        element: 'select',
        type: 'text',
        placeholder: 'Search By Method',
        label: 'Project Method',
        name: 'method',
        options: [
            {id: 'scratch', label: 'Create From Scratch'},
            {id: 'engine', label: 'Generate Estimate'},
            {id: 'clone', label: 'Clone BoQ'},
        ]
    },
    'location': {
        element: 'select',
        placeholder: 'Project Location',
        label: 'Project Location',
        name: 'location',
        options: [
            {id: 'north', label: 'North'},
            {id: 'south', label: 'South'},
            {id: 'east', label: 'East'},
            {id: 'west', label: 'West'},
        ]
    },
    'stage': {
        element: 'select',
        placeholder: 'Project Stage',
        label: 'Project Stage',
        name: 'stage',
        options: [
            {id: 'created', label: 'Created'},
            {id: 'details', label: 'Details'},
            {id: 'parameter', label: 'Parameter'},
            {id: 'headcount', label: 'Head Count'},
            {id: 'catalog', label: 'E-Catalog'},
            {id: 'estimation', label: 'Estimation'},
            {id: 'boq', label: 'BoQ'},
            {id: 'package', label: 'Package'},
            {id: 'pr', label: 'PR'},
            {id: 'completed', label: 'Completed'},
        ]
    },
};
export default Project;