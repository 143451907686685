import {InputAdornment} from "@mui/material";
import TextField from "@mui/material/TextField";

const InputBox = (props: any) => {
    const {formField} = props;
    const handleKeyDown = (e: any, field: any) => {
        if (field?.format != undefined) {
            if (field.format == 'no-space' && e.keyCode == 32) {
                e.preventDefault();
            }
        }
    }

    const onChange = (e: any) => {
        props.onChangeHandler(e);
    }

    return (
        <TextField
            fullWidth
            size={"small"}
            variant={"outlined"}
            className={"inputBox"}
            type={formField?.type}
            id={formField.name}
            onWheel={(e: any) => formField.type == 'number' ? e.target.blur() : ''}
            name={formField.name}
            disabled={(formField?.readonly || (formField?.disableOn && props?.disableFields?.hasOwnProperty(formField.name))) ? true : false}
            placeholder={formField?.placeholder ? formField.placeholder : formField.label}
            required={formField?.required ? formField.required : false}
            defaultValue={formField.value != undefined ? formField.value : ""}
            onKeyDown={(e) => handleKeyDown(e, formField)}
            onChange={(e) => props.onChangeHandler(e)}
            inputProps={{
                pattern: formField?.pattern
            }}
            InputProps={{
                style: {
                    borderRadius: "5px",
                    borderColor:
                        'primary.light'
                },
                startAdornment: formField.prefix ?
                    <InputAdornment position="start">{formField.prefix}</InputAdornment> : "",
                inputProps: { min: 0 }
            }}
        />
    )
}

export default InputBox;