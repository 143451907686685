import {Box, Grid, Typography} from "@mui/material";
import {getPriceInFormatted} from "../../utils/Common";
import {useEffect, useState} from "react";

import {ArcElement, Chart, Legend, Tooltip} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {Doughnut} from "react-chartjs-2";
import BoqSummarySubCategoryListing from "./BoqSummarySubCategoryListing";

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PartBreakupSummary = (props: any) => {
    const {publishedParts, project, categories, estimations} = props;
    const [partBreakupDetails, setPartBreakupDetails] = useState<any[]>([]);
    const [selectedPart, setSelectedPart] = useState<any>({});
    const [clientTotal, setClientTotal] = useState(0);
    const [chartData, setChartData] = useState<any>({});
    const [chartOptions, setChartOptions] = useState<any>({});
    const backgroundColors: string[] = ["#048cdc", "#fdb67a", "#fbd474", "#e49494", "#0f8edc", "#a48cdc", "#048cee", "048cff"];
    const [mainText, setMainText] = useState("Overall");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [defaultGraphSelected, setDefaultGraphSelected] = useState("boq");

    const handleGraphClick = (event: any, element: any) => {
        const label = chartData.labels[element[0].index];
        if (defaultGraphSelected == 'boq') {
            partBreakupDetails.map((part: any) => {
                if (part.name == label) {
                    setSelectedPart(part);
                }
            });
        } else {
            selectedPart.subCategories.map((subCategory: any) => {
                if (subCategory.name == label) {
                    setSelectedSubCategory(subCategory);
                    handleSelectedSubCategoryGraph(subCategory.id);
                }
            });
        }
    }

    const handleSelectedSubCategory = (isSelected: boolean, subCategoryId: string) => {
        if (isSelected) {
            handleSelectedSubCategoryGraph(subCategoryId);
        } else {
            handleSelectedCategoryGraph();
        }
    }

    const handleSelectedSubCategoryGraph = (subCategoryId: string) => {
        if (subCategoryId == "") {
            handleSelectedCategoryGraph();
        } else {
            setDefaultGraphSelected("sub-category");
            selectedPart.subCategories.map((subCategory: any) => {
                if (subCategory.id == subCategoryId) {
                    setSelectedSubCategory(subCategory);
                    const estimationItemsAllSkus: any = [];
                    subCategory.estimationItems.map((estimationItem: any) => {
                        if (estimationItem.estimationItemSkus && estimationItem.estimationItemSkus.length > 0) {
                            estimationItem.estimationItemSkus.map((sku: any) => {
                                estimationItemsAllSkus.push(sku);
                            });
                        }
                    });
                    setMainText(subCategory.name);

                    setChartData(
                        {
                            labels: estimationItemsAllSkus.map((estimationSku: any) => estimationSku.sku.name),
                            datasets: [{
                                label: '',
                                data: estimationItemsAllSkus.map((estimationSku: any) => (((estimationSku.sellingPrice * estimationSku.quantity) as number) / (subCategory.total as number) * 100).toFixed(1)),
                                backgroundColor: backgroundColors,
                                borderColor: backgroundColors,
                                borderWidth: 0.2
                            }]
                        }
                    );
                }
            })
        }
    }

    const handleSelectedCategoryGraph = () => {
        if (Object.keys(selectedPart).length > 0) {
            setDefaultGraphSelected("category");
            setMainText(selectedPart.name);
            setChartData(
                {
                    labels: selectedPart.subCategories.map((subCategory: any) => subCategory.name),
                    datasets: [{
                        label: '',
                        data: selectedPart.subCategories.map((subCategory: any) => ((subCategory.total as number) / (selectedPart.total as number) * 100).toFixed(1)),
                        backgroundColor: backgroundColors,
                        borderColor: backgroundColors,
                        borderWidth: 0.2
                    }]
                }
            );
        } else {
            setDefaultGraphSelected("boq");
            setMainText("Overall");
            setChartData(
                {
                    labels: publishedParts.map((part: any) => part.name),
                    datasets: [{
                        label: '',
                        data: publishedParts.map((part: any) => ((part.total as number) / (clientTotal as number) * 100).toFixed(1)),
                        backgroundColor: backgroundColors,
                        borderColor: backgroundColors,
                        borderWidth: 0.2
                    }]
                }
            );
        }
    }

    const handlePartSelection = (part: any) => {
        if (selectedPart.id == part.id) {
            setSelectedPart({});
        } else {
            setSelectedPart(part);
        }
    }

    useEffect(() => {
        handleSelectedCategoryGraph();
    }, [selectedPart]);

    useEffect(() => {
        let total: number = 0;
        if (publishedParts.length > 0) {
            const structuredDetails: any[] =
                publishedParts &&
                publishedParts.length > 0 &&
                publishedParts.map((part: any) => {
                    let partTotal: number = 0;
                    total += parseFloat(part.total);
                    const combinedSubCategories: any[] =
                        categories &&
                        categories.length > 0 &&
                        categories.flatMap((category: any) => {
                            return (
                                category.subCategories &&
                                category.subCategories.length > 0 &&
                                category.subCategories.flatMap((subCategory: any) => {
                                    const estimation: any = estimations.find(
                                        (estimation: any) =>
                                            estimation.materialSubCategoryId === subCategory.id
                                    );

                                    if (estimation) {
                                        let subCategoryTotal: number = 0;
                                        let subCategoryTotalSkus: number = 0;
                                        let totalCogs = 0;
                                        const matchedEstimationItems: any[] =
                                            estimation &&
                                            estimation.estimationItems &&
                                            estimation.estimationItems.length > 0 &&
                                            estimation.estimationItems.flatMap(
                                                (estimationItem: any) => {
                                                    const matchedSkus: any[] =
                                                        estimationItem.estimationItemSkus.filter(
                                                            (sku: any) =>
                                                                part &&
                                                                part.items &&
                                                                part.items.length > 0 &&
                                                                part.items.some(
                                                                    (item: any) => item.skuId === sku.skuId
                                                                )
                                                        );

                                                    if (matchedSkus.length > 0) {
                                                        subCategoryTotal += parseFloat(estimationItem.total) + parseFloat(estimationItem.domainCharges.total);
                                                        subCategoryTotalSkus += matchedSkus.length;
                                                        return {
                                                            ...estimationItem,
                                                            estimationItemSkus:
                                                                matchedSkus &&
                                                                matchedSkus.length > 0 &&
                                                                matchedSkus.map((sku: any) => {
                                                                    totalCogs += ((sku.quantity * sku.targetPrice) + (sku.quantity * sku.cogsDomainChargesPerUnit.total));
                                                                    return sku;
                                                                }),
                                                        };
                                                    }

                                                    return [];
                                                }
                                            );

                                        if (matchedEstimationItems.length > 0) {
                                            partTotal += subCategoryTotal;
                                            return {
                                                id: subCategory.id,
                                                name: subCategory.name,
                                                code: subCategory.code,
                                                status: subCategory.status,
                                                total: subCategoryTotal,
                                                totalCogs: totalCogs,
                                                estimationTotalSkus: subCategoryTotalSkus,
                                                estimationItems: matchedEstimationItems,
                                            };
                                        }
                                    }

                                    return [];
                                })
                            );
                        });

                    return {
                        id: part.id,
                        name: part.name,
                        status: part.status,
                        total: parseFloat(String(partTotal)),
                        totalSkus: parseInt(part.totalSkus),
                        subCategories: combinedSubCategories,
                    };
                });
            setPartBreakupDetails(structuredDetails);
            setClientTotal(total);
            setChartData(
                {
                    labels: publishedParts.map((part: any) => part.name),
                    datasets: [{
                        data: publishedParts.map((part: any) => ((part.total as number) / (total as number) * 100).toFixed(1)),
                        backgroundColor: backgroundColors,
                        borderColor: backgroundColors,
                        borderWidth: 0.2
                    }]
                }
            );
        }
    }, [publishedParts]);

    // useEffect(() => {
    //     setChartOptions(getChartOptionsWithinDisplay());
    // }, [chartData]);

    return (
        <>
            {partBreakupDetails.length > 0 ? <Grid container spacing={2}>
                    <Grid item xs={4}>
                        {Object.keys(chartData).length > 0 && partBreakupDetails.length > 0 &&
                            <Box className="chart-container" sx={{position: 'relative', px: 2}}>
                                <Typography style={{
                                    textAlign: "center",
                                    position: 'absolute',
                                    top: '50%',
                                    left: 0,
                                    right: 0,
                                    marginLeft: props.mleft,
                                    marginTop: props.mtop
                                }}>
                                    <Typography component={"span"}
                                                sx={{
                                                    width: '45%',
                                                    fontSize: '20px',
                                                    fontWeight: 600,
                                                    display: 'inline-block',
                                                    wordWrap: 'break-word'
                                                }}>
                                        {mainText}
                                    </Typography>
                                </Typography>
                                <Doughnut
                                    id={"sm-doughnut-chart-project"}
                                    data={chartData} options={
                                    {
                                        responsive: true,
                                        cutout: "55%",
                                        onClick: handleGraphClick,
                                        plugins: {
                                            datalabels: {
                                                color: "#fff",
                                                font: {
                                                    weight: "bold",
                                                    size: 12,
                                                },
                                                formatter: (value: any, context: any) => {
                                                    const index = context.dataIndex;
                                                    const label = context.chart.data.datasets[0].data[index];
                                                    const labelValue = parseFloat(label);
                                                    if (labelValue < 3) {
                                                        return "";
                                                    }
                                                    // return context.chart.data.labels[index] as string + " \n" + label + "%";
                                                    return label + "%";
                                                },
                                                anchor: "center",
                                                align: "center",
                                                offset: -10,
                                                clip: true,
                                                display: function (context: any) {
                                                    const value = context.dataset.data[context.dataIndex];
                                                    return value > 2;
                                                },
                                            },
                                            legend: {
                                                display: false,
                                                position: 'right',
                                                align: 'center',
                                                labels: {
                                                    usePointStyle: true
                                                }
                                            }
                                        }
                                    }
                                }
                                />
                            </Box>
                        }
                    </Grid>
                    <Grid item xs={8}>
                        <Box sx={{pl: 1}}>
                            <Grid container spacing={1}>
                                {partBreakupDetails.map((part: any) => {
                                    return (
                                        <Grid item key={part.id} xs={3}
                                              onClick={() => handlePartSelection(part)}
                                        >
                                            <Box sx={{
                                                border: 1,
                                                borderRadius: 1,
                                                borderColor: 'divider',
                                                p: 2, cursor: 'pointer',
                                                backgroundColor: selectedPart.id == part.id ? "#E5F3FB" : "common.white"
                                            }}>
                                                <Box display={"flex"} justifyContent={"space-between"} sx={{pb: 2}}>
                                                    <Typography sx={{
                                                        fontSize: "13px",
                                                        wordBreak: "break-word",
                                                        overflowWrap: "break-word",
                                                        fontWeight: 600,
                                                        color: "#535983",
                                                    }}>
                                                        {part.name}
                                                    </Typography>
                                                    <Typography>
                                                        {((part.total as number) / (clientTotal as number) * 100).toFixed(1)}%
                                                    </Typography>
                                                </Box>
                                                <Box display={"flex"} justifyContent={"space-between"}>
                                                    <Box sx={{
                                                        fontSize: "11px",
                                                        wordBreak: "break-word",
                                                        overflowWrap: "break-word",
                                                        opacity: 0.5,
                                                        width: '75%'
                                                    }}>
                                                        <Typography
                                                            sx={{fontSize: 'inherit', fontWeight: 600}}>Total
                                                            GSV</Typography>
                                                        <Typography
                                                            sx={{fontSize: 'inherit'}}>{getPriceInFormatted(part.total, props.project.country)}</Typography>
                                                    </Box>
                                                    <Box sx={{
                                                        textAlign: 'right',
                                                        fontSize: "11px",
                                                        wordBreak: "break-word",
                                                        overflowWrap: "break-word",
                                                        opacity: 0.5,
                                                    }}>
                                                        <Typography
                                                            sx={{fontSize: 'inherit', fontWeight: 600}}>Sku(s)</Typography>
                                                        <Typography sx={{fontSize: 'inherit'}}>
                                                            {part.totalSkus}
                                                        </Typography>
                                                    </Box>
                                                    <Box></Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            {Object.keys(selectedPart).length > 0 &&
                                <BoqSummarySubCategoryListing category={selectedPart} subCategory={selectedSubCategory}
                                                              project={props.project}
                                                              showUpdatedCharges={props.showUpdatedCharges}
                                                              handleSelectedSubCategory={handleSelectedSubCategory}/>
                            }
                        </Box>
                    </Grid>
                </Grid> :
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                    }}
                >
                    <Typography variant="h2" fontWeight={600}>
                        Client Breakup not found!
                        <br/>
                    </Typography>
                </Box>
            }
        </>
    )
}
export default PartBreakupSummary;