import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import {api} from "../../../utils/Axios";
import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import PageHeading from "../../../components/page-heading/PageHeading";

const VendorDetails = () => {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [vendor, setVendor] = useState<any>({});
    const [showApproveButton, setShowApproveButton] = useState(false);

    const navigate = useNavigate();
    const buttons = [];

    const handleHeaderButtonClick = (data: any) => {
        if (data === 'approve') {
            approveVendor();
            setShowApproveButton(false);
        }
        if (data === 'users') {
            navigate(`/vendors/user/${id}`);
        }
        if (data === 'catalogue') {
            navigate(`/vendors/catalogue/${id}`);
        }
    }

    const approveVendor = () => {
        setIsLoading(true);
        api.get(`/procurement/vendor/approve/${id}`)
            .then((response) => response.data)
            .then((response: any) => {
                setIsLoading(false);
                loadVendor();
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    }

    const loadVendor = () => {
        setIsLoading(true);
        api
            .get(`/procurement/vendor/${id}`)
            .then((response) => response.data)
            .then((response: any) => {
                if (response?.status === 'CREATED') {
                    setShowApproveButton(true);
                }

                setVendor(response);
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        loadVendor();
    }, [id]);

    if (vendor?.status === 'ACTIVE') {
        buttons.push({id: 'users', label: 'Vendor Users', variant: 'outlined', permissions: ['edit-vendors']});
        buttons.push({id: 'catalogue', label: 'View Catalogue', variant: 'outlined', permissions: ['edit-vendors']});
    }
    if (showApproveButton) {
        buttons.push({id: 'approve', label: 'Approve Vendor', variant: 'contained', permissions: ['approve-vendors']});
    }

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <Box className={"common-space-container"} sx={{display: 'flex', flexDirection: 'column'}}>
            <PageHeading
                title={"Vendor Details: " + vendor.name}
                showButtons={true}
                buttons={buttons}
                handleHeaderButtonClick={handleHeaderButtonClick}
            />
            <Box sx={{display: 'flex', justifyContent: 'center', mt: 5}}>
                <Grid container spacing={2} sx={{maxWidth: '800px'}}>
                    <Grid item xs={6}>
                        <Box>
                            <Typography variant={"body2"} sx={{fontWeight: 700}}>Company Name</Typography>
                            <Typography variant={"body1"}>{vendor?.name}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box>
                            <Typography variant={"body2"} sx={{fontWeight: 700}}>City</Typography>
                            <Typography variant={"body1"}>{vendor?.city}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box>
                            <Typography variant={"body2"} sx={{fontWeight: 700}}>Owner</Typography>
                            <Typography variant={"body1"}>{vendor?.owner}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box>
                            <Typography variant={"body2"} sx={{fontWeight: 700}}>Owner Contact Email</Typography>
                            <Typography variant={"body1"}>{vendor?.primaryContactEmail}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box>
                            <Typography variant={"body2"} sx={{fontWeight: 700}}>Owner Contact No.</Typography>
                            <Typography variant={"body1"}>{vendor?.primaryContactNo}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box>
                            <Typography variant={"body2"} sx={{fontWeight: 700}}>Vendor Status</Typography>
                            <Typography variant={"body1"}>{vendor?.status}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {vendor?.attributes && vendor?.attributes.length > 0 && 
                <Box sx={{py: 5, width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Box sx={{width: '80%'}}>
                        <Typography variant={"h4"}>Vendor Attributes</Typography>
                        <TableContainer sx={{py: 2}}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{
                                        'th': {
                                            border: 1,
                                            borderColor: 'secondary.light',
                                            backgroundColor: 'info.contrastText',
                                            fontWeight: '700'
                                        }
                                    }}>
                                        <TableCell>
                                            Attribute Name
                                        </TableCell>
                                        <TableCell>Attribute Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vendor?.attributes.map((attribute: any, index: number) => {
                                            return (
                                                <TableRow
                                                    key={"vendor-attributes-" + index}
                                                    sx={{'td': {border: 1, borderColor: 'secondary.light'}}}
                                                >
                                                    <TableCell>{attribute.name}</TableCell>
                                                    <TableCell>{attribute.value}</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            }
        </Box>
    )
}
export default VendorDetails;
