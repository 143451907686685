import {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import {Box, Button} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

const ConfirmPopupAction = (props: any) => {
    const [openConfirmBox, setOpenConfirmBox] = useState(false);

    const handleClose = () => {
        setOpenConfirmBox(false);
        props.handleButtonClick(0);
    }

    const confirm = () => {
        handleClose();
        props.handleButtonClick(1);
    }

    useEffect(() => {
        setOpenConfirmBox(true);
    }, [props.openConfirmBox]);

    return(
        <>
            {openConfirmBox &&
                <Dialog onClose={handleClose} open={openConfirmBox} sx={{marginRight: '0'}}>
                    <Box sx={{padding: '20px 30px', textAlign: 'center'}}>
                        <DialogTitle sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            letterSpacing: "0.8px"
                        }}>{props?.confirmText ? props.confirmText : "Do you want to continue"}</DialogTitle>
                        <Box sx={{margin: '0 auto'}}>
                            <Button variant={"contained"} sx={{mr: 2}} onClick={() => confirm()}>OK</Button>
                            <Button variant={"outlined"} onClick={() => handleClose()}>Cancel</Button>
                        </Box>
                    </Box>
                </Dialog>
            }
        </>
    )
}

export default ConfirmPopupAction;