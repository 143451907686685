import {createSlice, PayloadAction} from '@reduxjs/toolkit';


import {getAllRoles, getVendorRoles, submitForm, submitVendorForm, updateVendorUser} from './actions';

// Define the type for the response data
interface Role {
    id: string;
    name: string;
}


// Initial state
interface UserState {
    loading: boolean;
    roles: Role[]; // Define the type for roles array
    vendorRoles: Role[]; // Define the type for roles array
    formResponse: [],
    vendorFormResponse: [],
    error: string;
}

const initialState: UserState = {
    loading: false,
    roles: [],
    vendorRoles: [],
    formResponse: [],
    vendorFormResponse: [],
    error: '',
};

// VendorUser slice
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllRoles.pending, (state) => {
                state.loading = true;
                state.error = ''; // Reset error state
            })
            .addCase(getAllRoles.fulfilled, (state, action: PayloadAction<Role[]>) => {
                state.loading = false;
                state.roles = action.payload;
                state.error = ''; // Reset error state
            })
            .addCase(getAllRoles.rejected, (state, action) => {
                state.loading = false;
                state.roles = [];
                state.error = action.payload || 'Failed to fetch roles';
            })
            .addCase(getVendorRoles.pending, (state) => {
                state.loading = true;
                state.error = ''; // Reset error state
            })
            .addCase(getVendorRoles.fulfilled, (state, action: PayloadAction<Role[]>) => {
                state.loading = false;
                state.vendorRoles = action.payload;
                state.error = ''; // Reset error state
            })
            .addCase(getVendorRoles.rejected, (state, action) => {
                state.loading = false;
                state.vendorRoles = [];
                state.error = action.payload || 'Failed to fetch roles';
            })
            .addCase(submitForm.pending, (state) => {
                state.loading = true;
                state.error = ''; // Reset error state
            })
            .addCase(submitForm.fulfilled, (state: any, action) => {
                state.loading = false;
                state.formResponse = action.payload
                state.error = ''; // Reset error state
            })
            .addCase(submitForm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to submit form';
            })
            .addCase(submitVendorForm.pending, (state) => {
                state.loading = true;
                state.error = ''; // Reset error state
            })
            .addCase(submitVendorForm.fulfilled, (state: any, action) => {
                state.loading = false;
                state.vendorFormResponse = action.payload
                state.error = ''; // Reset error state
            })
            .addCase(submitVendorForm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to submit form';
            }).addCase(updateVendorUser.pending, (state) => {
            state.loading = true;
            state.error = ''; // Reset error state
        }).addCase(updateVendorUser.fulfilled, (state: any, action) => {
            state.loading = false;
            state.vendorFormResponse = action.payload
            state.error = ''; // Reset error state
        }).addCase(updateVendorUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'Failed to submit form';
        });
    },

});

export default userSlice.reducer;
