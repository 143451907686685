import {createSlice} from "@reduxjs/toolkit";
import {handleRemoveItem, handleRemoveItemFromECatalog} from "./actions";

const initialState = {
    loading: false,
    results: {},
    saveDraftResponse: {},
    error: "",
};
const estimationSlice = createSlice({
    name: "estimate",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(handleRemoveItem.pending, (state: any) => {
                state.loading = true;
                state.error = "";
            })
            .addCase(handleRemoveItem.fulfilled, (state: any, action) => {
                state.loading = false;
                state.results = action.payload;
            })
            .addCase(handleRemoveItem.rejected, (state: any, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(handleRemoveItemFromECatalog.pending, (state: any) => {
                state.loading = true;
                state.error = "";
            })
            .addCase(handleRemoveItemFromECatalog.fulfilled, (state: any, action) => {
                state.loading = false;
                state.results = action.payload;
            })
            .addCase(handleRemoveItemFromECatalog.rejected, (state: any, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

export default estimationSlice.reducer;
