import {createAsyncThunk} from '@reduxjs/toolkit';
import {api} from '../../../../utils/Axios';

interface ApiResponse {
    // Define the properties of the API response here
}

export const submitForm = createAsyncThunk<ApiResponse, {
    form: any;
    callback: () => void;
    categoryName: string,
    projectId: string
}>(
    'procurement/project/parameter/submitForm',
    async ({form, callback, categoryName, projectId}) => {
        try {
            // Perform the API call
            const response = await api.post(`/procurement/project/parameter/${categoryName}/${projectId}`, form);
            callback();
            return response.data as ApiResponse;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);

export const getParameter = createAsyncThunk<ApiResponse, {
    categoryId: string,
    projectId: string
}>(
    '/procurement/project/parameter/get',
    async ({categoryId, projectId}) => {
        try {
            // Perform the API call
            const response = await api.get(`/procurement/project/parameter/get/${categoryId}/${projectId}`);
            return response.data as ApiResponse;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);
