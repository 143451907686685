import {Box, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {api} from "../../utils/Axios";
import Loader from "../../components/Loader";
import DoughnutChart from "./DoughnutChart";
import TableOverview from "./TableOverview";
import StackedBarChart from "./StackedBarChart";
import NextIcon from "@mui/icons-material/ArrowForwardIos";
import './Home.css';
import {hasAccess} from "../../utils/Access";
import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {loadCatalogReport} from "../catalogue/features/action";
import ProjectDonutChart from "./ProjectDonutChart";

function Home() {
    const [isLoading, setIsLoading] = useState(false);
    const catalogCount = useAppSelector((state) => state.catalog.catalogCount);
    const [projectReport, setProjectReport] = useState<any>({});
    const [projectReportGraph, setProjectReportGraph] = useState([]);
    const dispatch = useAppDispatch();

    const loadRecentProjectReports = () => {
        setIsLoading(true);
        api.get("/procurement/report/procurement",).then((response) => {
            return response.data;
        }).then((res) => {
            setProjectReport(res);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        loadRecentProjectReports();
        dispatch(loadCatalogReport(true));
    }, []);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Box className={"common-space-container"}
             sx={{minHeight: '80vh', fontFamily: 'Lato', py: 10, paddingTop: '50px', paddingBottom: '50px'}}>
            {hasAccess(["vendor-admin"]) ? (
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '80vh'}}>
                    <Typography variant={"h2"} sx={{fontWeight: 700}}>
                        Welcome to Innov8 Space Matrix
                    </Typography>
                </Box>
            ) : (
                <Box sx={{pb: 20}}>
                    <Typography sx={{fontSize: '1.5rem', marginBottom: '30px', fontWeight: '600'}}>Welcome
                        Back,</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs>
                            <Box
                                sx={{border: 1, borderColor: 'divider', boxShadow: 1, borderRadius: '7px'}}>
                                <Box className="home-box-title-outer">
                                    <Typography className="home-box-title">Project Stage Breakup</Typography>
                                </Box>
                                <Box sx={{px: 6, pt: 2}}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography className="home-box-elem-title" sx={{cursor: 'pointer'}}>ACTIVE
                                                PROJECTS <NextIcon className="red-next-icon"/></Typography>
                                            <Typography
                                                className="home-box-elem-count">{projectReport?.activeProjects || 0}</Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Link to={"/projects?stage=completed"} style={{textDecoration: 'none'}}>
                                                <Typography className="home-box-elem-title">
                                                    COMPLETED PROJECTS <NextIcon className="red-next-icon"/>
                                                </Typography>
                                            </Link>
                                            <Typography
                                                className="home-box-elem-count">{projectReport?.completedProjects || 0}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{px: 6, pt: 2}}>
                                    {(projectReport && Object.keys(projectReport).length > 0) &&
                                        <Box sx={{margin: '0 auto', height: '350px'}}
                                             className="pie-container">
                                            <ProjectDonutChart project={projectReport}/>
                                        </Box>
                                    }
                                </Box>
                                <Box sx={{
                                    borderTop: '1px solid #f2f0f0',
                                    height: '70px',
                                    marginTop: '40px',
                                    textAlign: 'left'
                                }}>
                                    <Link to={"/projects"} style={{textDecoration: 'none', display: 'block'}}
                                          className="home-box-bottom-title">View All Projects <NextIcon
                                        className="blue-next-icon"/></Link>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs>
                            <Box
                                sx={{border: 1, borderColor: 'divider', boxShadow: 1, borderRadius: 1}}>
                                <Box className="home-box-title-outer">
                                    <Typography className="home-box-title">Package And PR Overview</Typography>
                                </Box>
                                <Box sx={{padding: '32px 45px'}}>
                                    <Box sx={{
                                        minHeight: '50px',
                                        textAlign: 'left',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '85%',
                                        margin: '0 auto 35px'
                                    }}>
                                        <Box sx={{flex: '30%'}}>
                                            <Typography className="home-box-elem-title">TOTAL BOQ</Typography>
                                            <Typography
                                                className="home-box-elem-count">{projectReport?.boq || 0}</Typography>
                                        </Box>
                                        <Box sx={{flex: '42%'}}>
                                            <Typography className="home-box-elem-title">PACKAGES CREATED</Typography>
                                            <Typography
                                                className="home-box-elem-count">{projectReport?.totalPackagesInCreatedState || 0}</Typography>
                                        </Box>
                                        <Box sx={{flex: '34%'}}>
                                            <Typography className="home-box-elem-title">ACTIVE EVENTS</Typography>
                                            <Typography
                                                className="home-box-elem-count">{projectReport?.totalPackagesInEventState || 0}</Typography>
                                        </Box>
                                        <Box sx={{flex: '30%'}}>
                                            <Typography className="home-box-elem-title">PR CREATED</Typography>
                                            <Typography
                                                className="home-box-elem-count">{projectReport?.totalPackagesInPrState || 0}</Typography>
                                        </Box>
                                    </Box>
                                    {(projectReport.recentProjects && projectReport?.recentProjects.length > 0) &&
                                        <Box sx={{width: '100%', height: '330px'}} className="customised_table_view">
                                            <TableOverview source={"home"} data={projectReport?.recentProjects}
                                                           hideFooter={false} classElem={"home_summary_table"}/>
                                        </Box>
                                    }
                                </Box>
                                <Box sx={{
                                    borderTop: '1px solid #f2f0f0',
                                    height: '70px',
                                    marginTop: '-10px',
                                    textAlign: 'left'
                                }}>
                                    <Link to={"/projects"} style={{textDecoration: 'none'}}>
                                        <Typography className="home-box-bottom-title">View All Projects <NextIcon
                                            className="blue-next-icon"/></Typography>
                                    </Link>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} sx={{marginTop: '0px'}}>
                        <Grid item xs>
                            <Box
                                sx={{border: 1, borderColor: 'divider', boxShadow: 1, borderRadius: 1}}>
                                <Box className="home-box-title-outer">
                                    <Typography className="home-box-title">Vendors Overview</Typography>
                                </Box>
                                <Box sx={{padding: '32px 45px'}}>
                                    <Box sx={{
                                        minHeight: '50px',
                                        marginBottom: '35px',
                                        textAlign: 'left',
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}>
                                        <Box sx={{flex: '20%'}}>
                                            <Typography className="home-box-elem-title">TOTAL VENDORS</Typography>
                                            <Typography
                                                className="home-box-elem-count">{projectReport?.totalVendors || 0}</Typography>
                                        </Box>
                                        <Box sx={{flex: '50%'}}>
                                            <Typography className="home-box-elem-title">RC VENDORS</Typography>
                                            <Typography
                                                className="home-box-elem-count">{catalogCount?.catalogData?.rcVendors || 0}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="stack-chart-container" sx={{width: '85%', margin: '0 10%'}}>
                                        <StackedBarChart totalVendors={projectReport?.totalVendors || 0}
                                                         vendors={catalogCount?.vendorRegions}/>
                                    </Box>
                                </Box>
                                {hasAccess(["view-projects", "edit-projects"]) &&
                                    <Box sx={{
                                        borderTop: '1px solid #f2f0f0',
                                        height: '70px',
                                        marginTop: '40px',
                                        textAlign: 'left'
                                    }}>
                                        <Link to={"/vendors"} style={{textDecoration: 'none'}}>
                                            <Typography className="home-box-bottom-title">View All Vendors <NextIcon
                                                className="blue-next-icon"/></Typography>
                                        </Link>
                                    </Box>
                                }
                            </Box>
                        </Grid>

                        <Grid item xs>
                            <Box
                                sx={{border: 1, borderColor: 'divider', boxShadow: 1, borderRadius: 1}}>
                                <Box className="home-box-title-outer">
                                    <Typography className="home-box-title">Catalouge</Typography>
                                </Box>
                                <Box sx={{padding: '32px 45px'}}>
                                    <Box sx={{
                                        minHeight: '50px',
                                        marginBottom: '20px',
                                        textAlign: 'left',
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}>
                                        <Box sx={{flex: '30%'}}>
                                            <Typography className="home-box-elem-title">ITEM CATEGORY</Typography>
                                            <Typography
                                                className="home-box-elem-count">{catalogCount?.catalogData?.itemCategories || 0}</Typography>
                                        </Box>
                                        <Box sx={{flex: '30%'}}>
                                            <Typography className="home-box-elem-title">TOTAL ITEMS</Typography>
                                            <Typography
                                                className="home-box-elem-count">{catalogCount?.catalogData?.items || 0}</Typography>
                                        </Box>
                                        <Box sx={{flex: '50%'}}>
                                            <Typography className="home-box-elem-title">TOTAL SKUS</Typography>
                                            <Typography
                                                className="home-box-elem-count">{catalogCount?.catalogData?.skus || 0}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{display: 'flex'}}>
                                        <Box sx={{width: '200px', margin: '0 10% 0 5%'}}>
                                            <Box sx={{marginBottom: '37px'}}>
                                                View Data: <Typography
                                                className="sku_overview_selection">Overview</Typography>
                                            </Box>
                                            {catalogCount?.catalogData?.rcSkus &&
                                                <DoughnutChart cutout={60} Data={[
                                                    {
                                                        id: 1,
                                                        label: 'SKU with RC',
                                                        userGain: parseInt(catalogCount?.catalogData?.rcSkus || 0),
                                                    },
                                                    {
                                                        id: 2,
                                                        label: 'SKU without RC',
                                                        userGain: (parseInt(catalogCount?.catalogData?.skus || 0) - parseInt(catalogCount?.catalogData?.rcSkus || 0)),
                                                    }
                                                ]} title={"RC BREAKUP"}
                                                               showLegend={false} mleft={'-50px'} mtop={'-10px'}
                                                               firstColorSet={false}
                                                />
                                            }
                                        </Box>

                                        <hr className="deviderSkus"/>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '40%',
                                            marginLeft: '5%',
                                            marginTop: '12%',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <Box sx={{
                                                flex: '100%',
                                                maxHeight: '80px',
                                                marginBottom: '20px',
                                                minWidth: '160px'
                                            }}>
                                                <Typography className="home-box-elem-title">SKU'S WITH RC</Typography>
                                                <Typography
                                                    className="home-box-elem-count">{catalogCount?.catalogData?.rcSkus}</Typography>
                                            </Box>
                                            <Box sx={{flex: '100%', maxHeight: '80px', minWidth: '160px'}}>
                                                <Typography className="home-box-elem-title">SKU'S WITHOUT
                                                    RC</Typography>
                                                <Typography
                                                    className="home-box-elem-count">{(catalogCount?.catalogData?.skus - catalogCount?.catalogData?.rcSkus)}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {hasAccess(["view-skus", "edit-skus"]) &&
                                    <Box sx={{
                                        borderTop: '1px solid #f2f0f0',
                                        height: '70px',
                                        marginTop: '0px',
                                        textAlign: 'left'
                                    }}>
                                        <Link to={"/catalogue"} style={{textDecoration: 'none'}}>
                                            <Typography className="home-box-bottom-title">View Catalouge <NextIcon
                                                className="blue-next-icon"/></Typography>
                                        </Link>
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    )
}

export default Home;

