import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";

const SkuOptionsTable = (props: any) => {
    const {skuOptions} = props;

    return (
        <Box>
            {(skuOptions != undefined && skuOptions.length > 0) &&
                <Box>
                    <Typography variant={"h5"} sx={{py:2}}>Sku Specifications</Typography>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{
                                    'th': {
                                        border: 1,
                                        borderColor: 'secondary.light',
                                        backgroundColor: 'primary.light',
                                        fontWeight: '700'
                                    }
                                }}>
                                    <TableCell>
                                        SKU Option Name
                                    </TableCell>
                                    <TableCell>SKU Option Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {skuOptions.map((skuOption: any, index: number) => {
                                        return (
                                            <TableRow
                                                key={index}
                                                sx={{'td': {border: 1, borderColor: 'secondary.light'}}}
                                            >
                                                <TableCell>{skuOption.name}</TableCell>
                                                <TableCell>{skuOption.value}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            }
        </Box>
    )
}

export default SkuOptionsTable;