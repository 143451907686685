import ListPageMain from "./ListPageMain";

const ListPage = (props: any) => {
    const handleRowSelected = (data: any) => {

    }

    return (
        <ListPageMain
            columns={props.columns}
            totalElements={props.totalElements}
            rows={props.rows}
            sortColumn={props.sortColumn}
            sortDirection={props.sortDirection}
            size={props.size}
            page={props.page}
            hideColumns={props.hideColumns}
            handleSort={props.handleSort}
            handlePagination={props.handlePagination}
            checkboxSelection={false}
            handleCallBack={props.handleCallBack}
            handleRowSelected={handleRowSelected}
            hideFooter={props?.hideFooter}
            hideFooterPagination={props?.hideFooterPagination}
            rowHeight={props.rowHeight}
        />
    );
};
export default ListPage;
