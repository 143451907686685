/**
 * Validates if the input is a correctly formatted number.
 * 
 * This function checks if the input string consists of digits and at most one decimal point.
 * 
 * @param {any} value - The input to be validated.
 * @returns {boolean} - True if the input is a valid number format, false otherwise.
 */
export const isValidNumberInput = (value: any): boolean => {
  const regex = /^\d*\.?\d*$/;
  return regex.test(value);
};
