import { SyntheticEvent, useEffect, useState } from "react";
import CategoryTab from "../../../components/tabs/CategoryTab";
import SubCategoryTab from "../../../components/tabs/SubCategoryTab";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { a11yProps, CustomTabPanel } from "../../../utils/TabsUtils";
import { loadItem, loadItemCategories } from "../../project/features/actions";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ItemSkuListing from "../../../components/skus/listing/ItemSkuListing";
import SearchFilter from "../../../components/filter/SearchFilter";
import { api } from "../../../utils/Axios";
import PageHeading from "../../../components/page-heading/PageHeading";
import Loader from "../../../components/Loader";

const VendorCatalogue = (props: any) => {
    const [selectedCategory, setSelectedCategory] = useState<any>({});
    const [categoryTabValue, setCategoryTabValue] = useState("");
    const [subCategoryTabValue, setSubCategoryTabValue] = useState("");
    const [itemCategoryTabValue, setItemCategoryTabValue] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredItemCategories, setFilteredItemCategories] = useState<any[]>([]);
    const itemCategoriesData = useAppSelector((state) => state.categories.itemCategories) || [];
    const itemsData = useAppSelector((state: any) => state.categories.items) || [];
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState<any[]>([]);

    const handleSearchFilter = (query: any) => {
        const queryString = typeof query === 'string' ? query : '';
        setSearchQuery(queryString);
        if (!queryString) {
            setFilteredItemCategories(itemCategoriesData);
            setItemCategoryTabValue(itemCategoriesData[0]?.id || "");
            return;
        }
        const filtered = itemCategoriesData.filter((category: any) =>
            category.name.toLowerCase().includes(queryString?.toLowerCase())
        );
        setFilteredItemCategories(filtered);
        setItemCategoryTabValue(filtered.length > 0 ? filtered[0]?.id || "" : itemCategoriesData[0]?.id || "");
    };

    const loadCategories = async () => {
        setIsLoading(true);
        try {
            const response = await api.get("/catalogue/material-master/categories");
            const categoriesData = response.data;
            const updatedCategories = await Promise.all(categoriesData.map(async (category: any) => {
                const subCategoriesResponse = await api.get("/catalogue/material-master/sub-categories", {
                    params: { "category_id": category.id }
                });
                return {
                    ...category,
                    subCategories: subCategoriesResponse.data || [],
                };
            }));
            setCategories(updatedCategories);
        } catch (error) {
            console.error("Failed to load categories:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadCategories();
    }, []);

    useEffect(() => {
        if (categories.length > 0) {
            setCategoryTabValue(categories[0].id);
        }
    }, [categories]);

    useEffect(() => {
        if (categoryTabValue) {
            const selectedCat = categories.find((category) => category.id === categoryTabValue);
            if (selectedCat) {
                setSelectedCategory(selectedCat);
                setSubCategoryTabValue(selectedCat.subCategories[0]?.id || "");
            }
        }
    }, [categoryTabValue, categories]);

    useEffect(() => {
        if (subCategoryTabValue) {
            dispatch(loadItemCategories(subCategoryTabValue));
        }
    }, [subCategoryTabValue, dispatch]);

    useEffect(() => {
        if (itemCategoryTabValue) {
            dispatch(loadItem(itemCategoryTabValue));
        }
    }, [itemCategoryTabValue, dispatch]);

    useEffect(() => {
        if (itemCategoriesData.length > 0) {
            setItemCategoryTabValue(itemCategoriesData[0].id);
            setFilteredItemCategories(itemCategoriesData);
        }
    }, [itemCategoriesData]);

    const handleCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setCategoryTabValue(newValue);
    };

    const handleSubCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setSubCategoryTabValue(newValue);
    };

    const handleItemCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setItemCategoryTabValue(newValue);
    };

    if(isLoading){
        return <Loader />
    }

    return (
        <Box className={'common-space-container'}>
            <PageHeading title={'Vendor Catalogue'}/>
            {categoryTabValue && (
                <CategoryTab
                    includeSummaryTab={false}
                    categories={categories}
                    categoryTabValue={categoryTabValue}
                    handleCategoryTabChange={handleCategoryTabChange}
                />
            )}
            {selectedCategory.id && categoryTabValue !== "summary" && (
                <SubCategoryTab
                    subCategoryTabValue={subCategoryTabValue}
                    subCategories={selectedCategory?.subCategories || []}
                    categoryTabValue={categoryTabValue}
                    handleSubCategoryTabChange={handleSubCategoryTabChange}
                />
            )}
            <Box sx={{ display: "flex" }}>
                <CustomTabPanel width={"15%"} value={subCategoryTabValue} index={subCategoryTabValue}>
                    <Box sx={{ pr: 2, borderRight: 1, borderColor: "divider" }}>
                        <Box mt={2}>
                            <SearchFilter query={searchQuery} handleSearchFilter={handleSearchFilter} showSearch={true} />
                        </Box>
                        <Box mt={2}>
                            <Typography sx={{ py: 3, px: 2, fontWeight: 700 }}>
                                Item Category
                            </Typography>
                        </Box>
                        <Tabs
                            orientation={"vertical"}
                            variant={"scrollable"}
                            value={itemCategoryTabValue}
                            onChange={handleItemCategoryTabChange}
                            aria-label="Item Category Tabs"
                            className="tab-sidebar"
                        >
                            {filteredItemCategories.length > 0 ? filteredItemCategories.map((itemCategory: any, itemCategoryIndex: number) => (
                                <Tab
                                    sx={{
                                        alignItems: "flex-start",
                                        minHeight: "35px",
                                        borderRadius: 1,
                                        color: "info.main",
                                        '&.Mui-selected': {
                                            color: "info.main",
                                            fontWeight: 700,
                                        },
                                    }}
                                    key={"item-category-tab-" + itemCategoryIndex}
                                    value={itemCategory.id}
                                    {...a11yProps(itemCategory.id, false)}
                                    label={
                                        <Typography className="tab-sidebar-label">
                                            {itemCategory.name}
                                        </Typography>
                                    }
                                    wrapped
                                    className="tab-sidebar-item"
                                />
                            )) : (
                                <Typography>No categories found</Typography>
                            )}
                        </Tabs>
                    </Box>
                </CustomTabPanel>

                {itemCategoryTabValue && (
                    <CustomTabPanel width={"85%"} value={itemCategoryTabValue} index={itemCategoryTabValue}>
                        <Box sx={{ p: 2 }}>
                            {itemsData.length > 0 ? itemsData.map((item: any, itemIndex: number) => (
                                <Box key={"item-block-" + itemIndex}>
                                    {item?.id ? (
                                        <ItemSkuListing type={"all"} item={item} vendorDashboard/>
                                    ) : (
                                        <Box className={"center-aligned"}>
                                            <Typography>No Items and SKUs found</Typography>
                                        </Box>
                                    )}
                                </Box>
                            )) : (
                                <Typography>No items found</Typography>
                            )}
                        </Box>
                    </CustomTabPanel>
                )}
            </Box>
        </Box>
    );
}

export default VendorCatalogue;


// import { SyntheticEvent, useEffect, useState } from "react";
// import CategoryTab from "../../../components/tabs/CategoryTab";
// import SubCategoryTab from "../../../components/tabs/SubCategoryTab";
// import { Box, Tab, Tabs, Typography } from "@mui/material";
// import { a11yProps, CustomTabPanel } from "../../../utils/TabsUtils";
// import { loadItem, loadItemCategories } from "../../project/features/actions";
// import { useAppDispatch, useAppSelector } from "../../../app/hooks";
// import ItemSkuListing from "../../../components/skus/listing/ItemSkuListing";
// import SearchFilter from "../../../components/filter/SearchFilter";
// import { api } from "../../../utils/Axios";
// import PageHeading from "../../../components/page-heading/PageHeading";

// const VendorCatalogue = (props: any) => {
//     const [selectedCategory, setSelectedCategory] = useState<any>({});
//     const [categoryTabValue, setCategoryTabValue] = useState("");
//     const [subCategoryTabValue, setSubCategoryTabValue] = useState("");
//     const [itemCategoryTabValue, setItemCategoryTabValue] = useState("");
//     const [searchQuery, setSearchQuery] = useState("");
//     const [filteredItemCategories, setFilteredItemCategories] = useState<any[]>([]);
//     const itemCategoriesData = useAppSelector((state) => state.categories.itemCategories) || [];
//     const itemsData = useAppSelector((state: any) => state.categories.items) || [];
//     const dispatch = useAppDispatch();
//     const [isLoading, setIsLoading] = useState(false);
//     const [categories, setCategories] = useState<any[]>([]);

//     const handleSearchFilter = (query: any) => {
//         const queryString = typeof query === 'string' ? query : '';
//         setSearchQuery(queryString);
//         if (!queryString) {
//             setFilteredItemCategories(itemCategoriesData);
//             setItemCategoryTabValue(itemCategoriesData[0]?.id || "");
//             return;
//         }
//         const filtered = itemCategoriesData.filter((category: any) =>
//             category.name.toLowerCase().includes(queryString?.toLowerCase())
//         );
//         setFilteredItemCategories(filtered);
//         setItemCategoryTabValue(filtered.length > 0 ? filtered[0]?.id || "" : itemCategoriesData[0]?.id || "");
//     };

//     const loadCategories = async () => {
//         setIsLoading(true);
//         try {
//             const response = await api.get("/catalogue/material-master/categories");
//             const categoriesData = response.data;
//             const updatedCategories = await Promise.all(categoriesData.map(async (category: any) => {
//                 const subCategoriesResponse = await api.get("/catalogue/material-master/sub-categories", {
//                     params: { "category_id": category.id }
//                 });
//                 return {
//                     ...category,
//                     subCategories: subCategoriesResponse.data || [],
//                 };
//             }));
//             setCategories(updatedCategories);
//         } catch (error) {
//             console.error("Failed to load categories:", error);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     useEffect(() => {
//         loadCategories();
//     }, []);

//     const handleCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
//         setCategoryTabValue(newValue);
//         const selectedCat = categories.find((category) => category.id === newValue);
//         if (selectedCat) {
//             setSelectedCategory(selectedCat);
//             setSubCategoryTabValue(selectedCat.subCategories[0]?.id || "");
//         }
//     };

//     const handleSubCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
//         setSubCategoryTabValue(newValue);
//     };

//     const handleItemCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
//         setItemCategoryTabValue(newValue);
//     };

//     useEffect(() => {
//         if (subCategoryTabValue) {
//             dispatch(loadItemCategories(subCategoryTabValue));
//         }
//     }, [subCategoryTabValue, dispatch]);

//     useEffect(() => {
//         if (itemCategoryTabValue) {
//             dispatch(loadItem(itemCategoryTabValue));
//         }
//     }, [itemCategoryTabValue, dispatch]);

//     useEffect(() => {
//         if (itemCategoriesData && itemCategoriesData.length > 0) {
//             setItemCategoryTabValue(itemCategoriesData[0].id);
//             setFilteredItemCategories(itemCategoriesData);
//         }
//     }, [itemCategoriesData]);

//     useEffect(() => {
//         if (categories && categories.length > 0) {
//             setCategoryTabValue(categories[0].id);
//         }
//     }, [categories]);

//     return (
//         <Box className={'common-space-container'}>
//             <PageHeading title={'Vendor Catalogue'}/>
//             {categoryTabValue && (
//                 <CategoryTab
//                     includeSummaryTab={false}
//                     categories={categories}
//                     categoryTabValue={categoryTabValue}
//                     handleCategoryTabChange={handleCategoryTabChange}
//                 />
//             )}
//             {selectedCategory.id && categoryTabValue !== "summary" && (
//                 <SubCategoryTab
//                     subCategoryTabValue={subCategoryTabValue}
//                     subCategories={selectedCategory?.subCategories || []}
//                     categoryTabValue={categoryTabValue}
//                     handleSubCategoryTabChange={handleSubCategoryTabChange}
//                 />
//             )}
//             <Box sx={{ display: "flex" }}>
//                 <CustomTabPanel width={"15%"} value={subCategoryTabValue} index={subCategoryTabValue}>
//                     <Box sx={{ pr: 2, borderRight: 1, borderColor: "divider" }}>
//                         <Box mt={2}>
//                             <SearchFilter query={searchQuery} handleSearchFilter={handleSearchFilter} showSearch={true} />
//                         </Box>
//                         <Box mt={2}>
//                             <Typography sx={{ py: 3, px: 2, fontWeight: 700 }}>
//                                 Item Category
//                             </Typography>
//                         </Box>
//                         <Tabs
//                             orientation={"vertical"}
//                             variant={"scrollable"}
//                             value={itemCategoryTabValue}
//                             onChange={handleItemCategoryTabChange}
//                             aria-label="Item Category Tabs"
//                             className="tab-sidebar"
//                         >
//                             {filteredItemCategories.length > 0 ? filteredItemCategories.map((itemCategory: any, itemCategoryIndex: number) => (
//                                 <Tab
//                                     sx={{
//                                         alignItems: "flex-start",
//                                         minHeight: "35px",
//                                         borderRadius: 1,
//                                         color: "info.main",
//                                         '&.Mui-selected': {
//                                             color: "info.main",
//                                             fontWeight: 700,
//                                         },
//                                     }}
//                                     key={"item-category-tab-" + itemCategoryIndex}
//                                     value={itemCategory.id}
//                                     {...a11yProps(itemCategory.id, false)}
//                                     label={
//                                         <Typography className="tab-sidebar-label">
//                                             {itemCategory.name}
//                                         </Typography>
//                                     }
//                                     wrapped
//                                     className="tab-sidebar-item"
//                                 />
//                             )) : (
//                                 <Typography>No categories found</Typography>
//                             )}
//                         </Tabs>
//                     </Box>
//                 </CustomTabPanel>

//                 {itemCategoryTabValue && (
//                     <CustomTabPanel width={"85%"} value={itemCategoryTabValue} index={itemCategoryTabValue}>
//                         <Box sx={{ p: 2 }}>
//                             {itemsData.length > 0 ? itemsData.map((item: any, itemIndex: number) => (
//                                 <Box key={"item-block-" + itemIndex}>
//                                     {item?.id ? (
//                                         <ItemSkuListing type={"all"} item={item} vendorDashboard/>
//                                     ) : (
//                                         <Box className={"center-aligned"}>
//                                             <Typography>No Items and SKUs found</Typography>
//                                         </Box>
//                                     )}
//                                 </Box>
//                             )) : (
//                                 <Typography>No items found</Typography>
//                             )}
//                         </Box>
//                     </CustomTabPanel>
//                 )}
//             </Box>
//         </Box>
//     );
// }

// export default VendorCatalogue;

