import {DataGrid, GridRowParams} from '@mui/x-data-grid';
import {Box, Button, IconButton, Tooltip, Typography} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import {useEffect, useState} from 'react';
import {hasAccess} from "../../utils/Access";
import {getPriceInFormatted} from "../../utils/Common";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

const ListPageMain = (props: any) => {
    const [columns, setColumns] = useState([]);

    const handlePagination = (newPagination: any) => {
        props.handlePagination(newPagination);
    };

    const handleSortChange = (newSortModel: any) => {
        if (newSortModel && newSortModel.length > 0) {
            const sortModel = newSortModel[0];
            if (sortModel && sortModel.field) {
                props.handleSort(sortModel);
            }
        }
    };

    const onRowsSelectionHandler = (ids: any) => {
        const selectedRowsData = ids.map((id: any) => props.rows.find((row: any) => row.id === id));
        props.handleRowSelected(selectedRowsData);
    };
    const isRowSelectable = props.enableRowSelection ? (params: GridRowParams) => params.row.remainingQuantity !== 0 : undefined;

    useEffect(() => {
        const updatedColumns = () => {
            const updatedColumns = props.columns.map((column: any) => {
                if (column.field === "action") {
                    return {
                        ...column,
                        renderCell: (params: any) => {
                            const handleClick = (buttonData: any) => {
                                const isDisabled = typeof buttonData.disabled === 'function' 
                                  ? buttonData.disabled(params.row)
                                  : buttonData.disabled;
                                
                                if (isDisabled) {
                                  return;
                                }
                                props.handleCallBack(params.row, buttonData);
                              };
                            return (
                                <>
                                    {column.buttons?.map((columnButton: any, index: any) => {
                                        if (hasAccess(columnButton.permissions) && columnButton.condition(params.row)) {
                                            const isDisabled = typeof columnButton.disabled === 'function'
                                                ? columnButton.disabled(params.row)
                                                : !!columnButton.disabled;
                                            if (column.show && column.show === 'button') {
                                                return (
                                                    <Box key={"column-button-" + index} sx={{pr: 1}}
                                                         onClick={() => handleClick(columnButton)}>
                                                        {columnButton.type === 'anchor' ? (
                                                            <Button
                                                                sx={{textDecoration: 'none', '&:hover': {textDecoration: 'none', backgroundColor: 'unset'}}}
                                                                color={'info'}
                                                                variant={"text"}
                                                                disabled={isDisabled}
                                                            >
                                                                {columnButton.title}
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                sx={{fontSize: '0.8rem', py: 0.7, px: 2}}
                                                                color={'primary'}
                                                                disabled={isDisabled}
                                                                variant={columnButton.variant ? columnButton.variant : 'contained'}
                                                            >
                                                                {columnButton.title}
                                                            </Button>
                                                        )}
                                                    </Box>
                                                );
                                            } else {
                                                return (
                                                    <Tooltip title={columnButton.title} key={'data-table-' + index}>
                                                        <IconButton onClick={() => handleClick(columnButton)}>
                                                            {columnButton.type === 'edit' && (
                                                                <EditIcon style={{color: '#3854E4'}}/>
                                                            )}
                                                            {columnButton.type === 'view' && (
                                                                <VisibilityIcon sx={{color: 'info.main'}}/>
                                                            )}
                                                            {columnButton.type === 'details' && (
                                                                <PlagiarismOutlinedIcon sx={{color: 'info.3854E4'}}/>
                                                            )}
                                                        </IconButton>
                                                    </Tooltip>
                                                );
                                            }
                                        }
                                        return null;
                                    })}
                                </>
                            );
                        },
                    };
                } else if (column.type === 'price') {
                    return {
                        ...column,
                        valueGetter: (params: any) => {
                            const row = params.row;
                            return getPriceInFormatted(parseFloat(row[column.field]), "")
                        }
                    };
                } else if (column.type === 'image') {
                    return {
                        ...column,
                        renderCell: (params: any) => {
                            const row = params.row;
                            if (row[column.key]) {
                                return (
                                    <Box sx={{pt: 1}}>
                                        <img
                                            src={row[column.key]}
                                            style={{width: '80px'}}
                                            alt={'Image'}
                                        />
                                    </Box>
                                );
                            }
                            return '';
                        }
                    };
                } else if (column.type === 'status') {
                    return {
                        ...column,
                        renderCell: (params: any) => {
                            const row = params.row;
                            let color = "";
                            let value = '';
                            if (typeof row.status === 'boolean') {
                                value = row.status ? 'Yes' : 'No';
                                color = row.status ? '253, 182, 122' : '223, 149, 113';
                            } else {
                                value = row.status === 'ACTIVE' ? 'Yes' : 'No';
                                color = row.status === 'ACTIVE' ? '253, 182, 122' : '223, 149, 113';
                            }
                            return <Typography sx={{
                                border: 1,
                                borderColor: `rgba(${color}, 1)`,
                                borderRadius: 1,
                                p: 0.6,
                                backgroundColor: `rgba(${color}, 0.5)`,
                                minWidth: '70px',
                                textAlign: 'left'
                            }}>{value}</Typography>;
                        }
                    };
                } else if (column.type === 'imageWithName') {
                    return {
                        ...column,
                        renderCell: (params: any) => {
                            return (
                                <Box sx={{pt: 1}}>
                                    <Box sx={{display: 'flex', padding: '16px 8px'}}>
                                        {params.row.primaryImage ? <img
                                            src={params.row.primaryImage}
                                            style={{width: '67px', height: '67px'}}
                                            alt={'Image'}
                                        /> : <Box
                                            sx={{border: '1px solid lightgray', backgroundColor: '#D9D9D9', width: '67px', height: '67px'}}></Box>}
                                        <Box padding='0 8px'>
                                            <Typography
                                                sx={{fontSize: '12px', lineHeight: '14.4px', fontWeight: 500, padding: '8px 0', whiteSpace: 'pre-line', maxWidth: '150px'}}>{params.row.name}</Typography>
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                <Typography
                                                    sx={{fontSize: '12px', lineHeight: '14.4px', fontWeight: 500, padding: '8px 0', mr: 1.5}}>{params.row.code}</Typography>
                                                <ContentCopyRoundedIcon sx={{width: '12px', cursor: 'pointer'}}
                                                                        onClick={() => {
                                                                            navigator.clipboard.writeText(params?.row?.code)
                                                                        }}/>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        }
                    };
                } else {
                    return column;
                }
            });

            return updatedColumns;
        };

        setColumns(updatedColumns());
    }, [props.columns, hasAccess, props.handleCallBack]);

    return (
        <Box>
            <DataGrid
                sx={(theme) => ({
                    fontSize: '0.85rem',
                    fontFamily: 'Lato',
                    '&, [class^=MuiDataGrid]': {borderRadius: 0, borderRight: 0},
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeadersInner': {
                        backgroundColor: theme.palette.info.contrastText,
                        fontSize: '0.9rem',
                    },
                    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                        borderRight: 1,
                        borderColor: 'divider',
                    },
                    '& .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox': {
                        // px: 0,
                    },
                    '.MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '.MuiDataGrid-footerContainer': {
                        borderRight: 1,
                        borderColor: 'divider',
                    },
                    '.custom-column-header': {
                        fontFamily: 'Lato',
                        fontSize: '12px',
                        fontWeight: '700 !important',
                        lineHeight: '14.4px',
                        textAlign: 'left',
                        color: '#1E2B51',
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 700,
                    },
                    '.MuiDataGrid-cellContent': {
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.4px',
                        textAlign: 'left',
                        maxHeight: '70px',
                        whiteSpace: 'break-spaces'
                    },
                    '& .MuiDataGrid-colCell': {
                        minWidth: 820,
                    },

                })}
                sortingMode="server"
                sortingOrder={['desc', 'asc']}
                disableColumnMenu={true}
                autoHeight
                columns={columns}
                rowCount={props.totalElements}
                rows={props.rows}
                rowHeight={props?.rowHeight ? props?.rowHeight : 52}
                initialState={{
                    sorting: {
                        sortModel: [{field: props.sortColumn, sort: props.sortDirection}],
                    },
                    pagination: {
                        paginationModel: {pageSize: props.size, page: props.page},
                    },
                    columns: {
                        columnVisibilityModel: props.hideColumns,
                    },
                }}
                onSortModelChange={(newSortModel) => handleSortChange(newSortModel)}
                onPaginationModelChange={(newPaginationModel) =>
                    handlePagination(newPaginationModel)
                }
                pageSizeOptions={[10, 25, 50, 100]}
                paginationMode="server"
                checkboxSelection={props?.checkboxSelection}
                isRowSelectable={isRowSelectable}
                onRowSelectionModelChange={(items: any) => onRowsSelectionHandler(items)}
                hideFooter={props?.hideFooter}
                hideFooterPagination={props?.hideFooterPagination}
                rowSelectionModel={props?.selectionModel}
            />
        </Box>
    );
};
export default ListPageMain;
