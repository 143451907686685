import React, {useEffect, useMemo, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import Loader from "../Loader";
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {api} from "../../utils/Axios";
import {debounce} from "@mui/material/utils";
import CloseIcon from "@mui/icons-material/Close";
import ListPage from "../list-page/ListPage";
import {publishPackageConfig} from "../../modules/project/plan/config/PublishPackage";
import ResponsiveDatePickers from "../common/DateRangePicker";
import {useAppDispatch} from "../../app/hooks";
import {openSnackbar} from "../common/features/snackbarSlice";
import {ERROR_COLOR} from "../../constants";
import {getStyle} from "../../utils/ModalUtils";

interface Vendor {
    id: string;
    name: string;
}

const CreateEventPopup = (props: any) => {
    console.log(props);
    const style = getStyle(60, 70);
    const today = dayjs()
    const nextWeek = today.add(7, 'day')

    const dispatch = useAppDispatch();
    const [size, setSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(1);
    const [open, setOpen] = useState(false);
    const [page, pageChange] = useState(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [radioValue, setRadioValue] = useState("");
    const [selectedVendor, setSelectedVendor] = useState<any>({});
    const [vendors, setVendors] = useState([]);
    const [selectedVendors, setSelectedVendors] = useState(
        vendors ? [vendors] : []
    );
    const [isDateValid, setIsDateValid] = useState(true);
    const [openAllVendors, setOpenAllVendors] = useState(false);
    const [startDateValue, setStartDateValue] = useState<Dayjs | null>(today);
    const [endDateValue, setEndDateValue] = useState<Dayjs | null>(nextWeek);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<readonly Vendor[]>([]);
    const [value, setValue] = useState<Vendor | null>(null);

    useEffect(() => {
        setSelectedVendors(vendors ? vendors : []);
    }, [vendors]);

    const handleEndDateChange = (date: Dayjs | null) => {
        setIsDateValid(true);
        if (date && startDateValue && date.isBefore(startDateValue, "day")) {
            dispatch(
                openSnackbar({
                    message: "End date cannot be before start date",
                    backgroundColor: ERROR_COLOR,
                })
            );
            setIsDateValid(false);
            return;
        }
        setEndDateValue(date);
    };

    useEffect(() => {
        if (props.openPopup === true) {
            setOpen(true);
            loadVendors();
        }
    }, [props.openPopup]);

    const fetchVendors = useMemo(
        () =>
            debounce(
                (query: string, callback: (results?: readonly Vendor[]) => void) => {
                    api
                        .get(`/procurement/vendor/search?query=${query}`)
                        .then((response) => response.data)
                        .then((data) => callback(data))
                        .catch(() => callback([]));
                },
                400
            ),
        []
    );

    useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }
        if (inputValue.length >= 3) {
            fetchVendors(inputValue, (results?: readonly Vendor[]) => {
                if (active) {
                    let newOptions: readonly Vendor[] = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            });
        }

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchVendors]);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
    };

    const handleVendorChange = (event: SelectChangeEvent) => {
        const selectedVendorId = event.target.value;
        const selectedVendor = vendors.filter((vendor: any) => selectedVendorId == vendor.id)
            .reduce((acc, curr) => {
                return curr;
            }, {});
        setSelectedVendor(selectedVendor);
    };

    const handleCreatePr = () => {
        if (Object.keys(selectedVendor).length > 0) {
            setIsLoading(true);
            api
                .get(
                    `/procurement/package/create-pr/${selectedVendor.type}/${props.package.id}/${selectedVendor.id}`
                )
                .then((response) => response.data)
                .then((response: any) => {
                    console.log(response);
                    props.handlePopupCallBack({event: "close", prPackage: "created"});
                    setIsLoading(false);
                })
                .catch((ex) => {
                    dispatch(
                        openSnackbar({
                            message: ex.response?.data || "Oops. Something went wrong",
                            backgroundColor: ERROR_COLOR,
                        })
                    );
                    console.log(ex);
                    setIsLoading(false);
                });
        }
    };

    const handleRemoveVendor = (indexToRemove: number) => {
        const updatedVendors = selectedVendors.filter(
            (_, index) => index !== indexToRemove
        );
        setSelectedVendors(updatedVendors);
    };

    const handleCreateEvent = () => {
        const vendorIdsList =
            selectedVendors && selectedVendors.map((vendor: any) => vendor.id);
        const eventCreationData = {
            startDate: startDateValue,
            endDate: endDateValue,
            vendorIds: vendorIdsList ? vendorIdsList : [],
            packageId: props.package.id,
            projectId: props.projectId,
        };

        if (!isDateValid) {
            dispatch(
                openSnackbar({
                    message: "End date cannot be before start date",
                    backgroundColor: ERROR_COLOR,
                })
            );
        }

        if (vendorIdsList.length == 0) {
            dispatch(
                openSnackbar({
                    message: "Please select vendor to proceed",
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
        if (vendorIdsList.length > 0 && isDateValid) {
            setIsLoading(true);
            api
                .post(`procurement/event`, eventCreationData)
                .then((response) => response.data)
                .then((response: any) => {
                    props.handlePopupCallBack({event: "close", prPackage: "event"});
                    setIsLoading(false);
                    return response;
                })
                .catch((ex) => {
                    console.log(ex);
                    setIsLoading(false);
                });
        }
    };

    const handleSort = (sortModel: any) => {
        if (sortModel && sortModel.field) {
            setSortColumn(sortModel.field);
            setSortDirection(sortModel.sort);
            pageChange(0);
        }
    };

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    };

    const handleClose = () => {
        setOpen(false);
        props.handlePopupCallBack({event: "close"});
    };

    const loadVendors = () => {
        setIsLoading(true);
        api
            .get(`/procurement/package/entity-details/${props.package.id}`)
            .then((response) => response.data)
            .then((response: any) => {
                setVendors(response);
                if (!props.package.event && response && response.length > 0) {
                    setRadioValue("create-pr")
                } else {
                    setRadioValue("create-event")
                }
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    Publish Package
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            {isLoading &&
                                <Loader/>
                            }
                            <Box>
                                <ListPage
                                    rows={[{...props.package, total: props.packageTotal}]}
                                    columns={publishPackageConfig}
                                    page={page}
                                    size={size}
                                    totalElements={totalElements}
                                    sortColumn={sortColumn}
                                    sortDirection={sortDirection}
                                    handlePagination={handlePagination}
                                    handleSort={handleSort}
                                    hideFooter
                                />
                            </Box>
                            <Box padding={"16px 4px"}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={radioValue}
                                        onChange={handleRadioChange}
                                    >
                                        {!props.package.event && vendors && vendors.length > 0 &&
                                            <FormControlLabel
                                                sx={{mr: 6}}
                                                value="create-pr"
                                                control={<Radio/>}
                                                label="Create PR"
                                            />
                                        }
                                        <FormControlLabel
                                            value="create-event"
                                            control={<Radio/>}
                                            label="Create Event"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            <Box>
                                {radioValue === "create-pr" ? (
                                    <Box sx={{maxWidth: '250px', width: '100%'}}>
                                        <InputLabel required={true} sx={{pb: 1, fontSize: "0.8rem"}}>
                                            Select Vendor/Brand
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name={"entity"}
                                            displayEmpty
                                            onChange={handleVendorChange}
                                            style={{height: '40px'}}
                                        >
                                            {vendors.length > 0 && vendors.map((vendor: Vendor, index: number) => (
                                                <MenuItem key={index} value={vendor.id}>
                                                    {vendor.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Box>
                                                <Box
                                                    display={"flex"}
                                                    justifyContent={"center"}
                                                    alignItems={"center"}
                                                >
                                                    <ResponsiveDatePickers
                                                        label="Start Date"
                                                        value={startDateValue}
                                                        onChange={setStartDateValue}
                                                    />
                                                    <Box
                                                        component={"span"}
                                                        sx={{padding: "16px", textAlign: "center"}}
                                                    >
                                                        to
                                                    </Box>
                                                    <ResponsiveDatePickers
                                                        label="End Date"
                                                        value={endDateValue}
                                                        onChange={handleEndDateChange}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "700",
                                                            lineHeight: "14.4px",
                                                            textAlign: "left",
                                                            margin: "1rem 0",
                                                        }}
                                                    >
                                                        Select Vendors
                                                    </Typography>
                                                    <Table
                                                        sx={{
                                                            border: "1px solid",
                                                            borderColor: "#E8ECEF !important",
                                                        }}
                                                    >
                                                        <TableBody>
                                                            {selectedVendors &&
                                                                selectedVendors.length > 0 &&
                                                                selectedVendors.map(
                                                                    (vendor: any, index: number) => (
                                                                        <TableRow
                                                                            key={index}
                                                                            sx={{border: "1px solid #E8ECEF"}}
                                                                        >
                                                                            <TableCell
                                                                                sx={{
                                                                                    fontSize: "12px",
                                                                                    fontWeight: "400",
                                                                                    lineHeight: "14.4px",
                                                                                    textAlign: "left",
                                                                                    color: "#333333",
                                                                                    position: "relative",
                                                                                }}
                                                                            >
                                                                                {vendor.name}
                                                                                <IconButton
                                                                                    sx={{
                                                                                        position: "absolute",
                                                                                        top: "50%",
                                                                                        right: 0,
                                                                                        transform: "translateY(-50%)",
                                                                                    }}
                                                                                    onClick={() => handleRemoveVendor(index)}
                                                                                >
                                                                                    <CloseIcon sx={{fontSize: "14px"}}/>
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {openAllVendors && (
                                                <Autocomplete
                                                    id="vendor-autocomplete"
                                                    sx={{width: 300}}
                                                    getOptionLabel={(option: Vendor) => option.name}
                                                    filterOptions={(x) => x}
                                                    options={options}
                                                    autoComplete
                                                    includeInputInList
                                                    filterSelectedOptions
                                                    value={value}
                                                    noOptionsText="No vendors"
                                                    onChange={(event: any, newValue: any) => {
                                                        if (newValue) {
                                                            // Check if the selected vendor already exists in the vendors list
                                                            if (
                                                                !selectedVendors.some(
                                                                    (vendor: any) =>
                                                                        vendor.name === newValue.name
                                                                )
                                                            ) {
                                                                setSelectedVendors((prev: any) => [
                                                                    ...prev,
                                                                    newValue,
                                                                ]);
                                                            }
                                                        }
                                                        setValue(newValue);
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        setInputValue(newInputValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Add a vendor"
                                                            fullWidth
                                                        />
                                                    )}
                                                    renderOption={(props, option) => (
                                                        <li {...props}>
                                                            <Box sx={{fontWeight: "regular"}}>
                                                                {option.name}
                                                            </Box>
                                                        </li>
                                                    )}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box sx={{px: 2}}>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "400",
                                                        lineHeight: "14.4px",
                                                        textAlign: "left",
                                                        margin: "1rem 1rem",
                                                        color: "#3854E4",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => setOpenAllVendors((prev: any) => !prev)}
                                                >
                                                    {!openAllVendors ? "Add Vendors" : "Cancel"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={style.footer}>
                        <Grid
                            container
                            sx={{px: 2, height: "100%"}}
                            className={"center-aligned"}
                        >
                            <Grid item xs/>
                            <Grid item>
                                <Box>
                                    <Button variant={"text"} sx={{mr: 2, fontSize: "0.8rem"}} onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant={"contained"}
                                        sx={{fontSize: "0.8rem"}}
                                        onClick={
                                            radioValue === "create-pr"
                                                ? handleCreatePr
                                                : handleCreateEvent
                                        }
                                    >
                                        {radioValue === "create-pr" ? "Create PR" : "Create Event"}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default CreateEventPopup;
