import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {projectDeliveryAreaList} from "./estimate";
import {useAppDispatch} from "../../../../app/hooks";
import {openSnackbar} from "../../../common/features/snackbarSlice";
import {ERROR_COLOR} from "../../../../constants";

const SelectDeliveryArea = (props: any) => {
    const dispatch = useAppDispatch();
    const totalQuantity: number = props.sku.quantity;

    const [formValues, setFormValues] = useState<any>([]);
    let handleChange = (i: number, e: any) => {
        let newFormValues: any = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        setFormValues([...formValues, {quantity: "", area: ""}]);
    }

    let removeFormFields = (i: number) => {
        let newFormValues: any = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    let handleSubmit = (event: any) => {
        event.preventDefault();
        let totalCount: number = 0;
        const selectedAreas: any = [];
        let errorFlag = false;
        formValues.map((formValue: any) => {
            if (selectedAreas.includes(formValue.area)) {
                errorFlag = true;
            } else {
                selectedAreas.push(formValue.area);
            }
            totalCount += parseInt(formValue.quantity);
        });
        if (totalCount < totalQuantity) {
            dispatch(
                openSnackbar({
                    message:
                        "Total quantity selected should be equal to total quantity",
                    backgroundColor: ERROR_COLOR,
                })
            );
            return;
        }
        // if (totalCount > totalQuantity) {
        //     dispatch(
        //         openSnackbar({
        //             message:
        //                 "Total quantity selected should not be greater than total quantity",
        //             backgroundColor: ERROR_COLOR,
        //         })
        //     );
        //     return;
        // }
        if (errorFlag) {
            dispatch(
                openSnackbar({
                    message:
                        "Multiple times same delivery area selected",
                    backgroundColor: ERROR_COLOR,
                })
            );
            return;
        }

        props.handleDeliveryArea(props.sku, formValues);
    }

    useEffect(() => {
        if (props.sku.deliveryAreas.length > 0) {
            setFormValues(props.sku.deliveryAreas);
        } else {
            setFormValues([{quantity: props.sku.quantity, area: ""}]);
        }
    }, [props.sku, props.sku.quantity]);

    return (
        <Box
            sx={{py: 1, border: 1, borderTop: 0, borderColor: 'divider'}}>
            <Box className={'center-aligned'}>
                {totalQuantity > 0 ? (
                    <Box sx={{width: '50%'}}>
                        <Typography variant={"h5"} sx={{pb: 1}}>Select Delivery Area</Typography>
                        <Box component={"form"} onSubmit={handleSubmit}>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{backgroundColor: "info.light", fontWeight: 700}}>
                                            <TableCell sx={{py:1, px:2}}>Quantity</TableCell>
                                            <TableCell sx={{textAlign: 'center', py:1, px:2}}>Delivery Area</TableCell>
                                            <TableCell sx={{textAlign: 'right', py:1, px:2}}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{border: 1, borderColor: 'divider'}}>
                                        {formValues.map((element: any, index: number) => {
                                            return (
                                                <TableRow key={"form-field-" + index}>
                                                    <TableCell sx={{py:1, px:2}}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                fullWidth
                                                                size={"small"}
                                                                type={"number"}
                                                                variant={"outlined"}
                                                                className={"inputBox"}
                                                                name="quantity"
                                                                value={element.quantity || ""}
                                                                onChange={e => handleChange(index, e)}
                                                                InputProps={{
                                                                    style: {
                                                                        borderRadius: "5px",
                                                                        borderColor: "primary.light",
                                                                        fontSize: "0.8rem",
                                                                    },
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell sx={{textAlign: 'center', py:1, px:2}}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                size={"small"}
                                                                id={"demo-simple-select" + props.sku.id}
                                                                labelId="demo-simple-select-label"
                                                                name={"area"}
                                                                defaultValue={element.area || ""}
                                                                sx={{fontSize: "0.8rem"}}
                                                                onChange={e => handleChange(index, e)}
                                                            >
                                                                {projectDeliveryAreaList?.map(
                                                                    (item: any, idx: number) => {
                                                                        return (
                                                                            <MenuItem
                                                                                key={idx}
                                                                                value={item}
                                                                                sx={{fontSize: "0.8rem"}}
                                                                            >
                                                                                {item}
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell sx={{textAlign: 'right', py:1, px:2}}>
                                                        {
                                                            index ?
                                                                <Button
                                                                    onClick={() => removeFormFields(index)}>Remove</Button>
                                                                : null
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box sx={{display: 'flex', justifyContent: 'right', py: 1}}>
                                <Button variant={"outlined"} sx={{mr: 2}} onClick={() => addFormFields()}>Add
                                    More</Button>
                                <Button variant={"contained"} type="submit">Save</Button>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box>Please enter overall total quantity for Sku</Box>
                )}
            </Box>
        </Box>
    )
}

export default SelectDeliveryArea;