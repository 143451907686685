export const ItemTypes = {
    ITEM: 'item',
    SHOW_ROW_HEADER: 'row-header',
    TABS: "tab",
    DRAGGABLE_SUB_TAB: "sub-tabs",
    DRAGGABLE_BOQ_ITEM: "boq-items",
    CATEGORY: 'category',
    SUBCATEGORY: 'sub-category',
    SKU: "sku"
};
