export default function camelize(data: any) {
    if (typeof data !== 'object' || data === null) return data;

    const obj: any = {};
    for (let [key, val] of Object.entries(data)) {
        key = key.replace(/[\-_\s]+(.)?/g, (_, ch) => ch ? ch.toUpperCase() : '');
        key = key.substring(0, 1).toLowerCase() + key.substring(1);

        obj[key] = camelize(val);
    }
    return obj;
}

export const getContentUrl = () => {
    return process.env.REACT_APP_CONTENT_URL;
}

export const getPriceInFormatted = (price: any, country: string) => {
    if (price == undefined || isNaN(price)) {
        price = 0;
    }
    const currencySymbol = getCurrencySymbol(country);

    if (price < 0) {
        return "- " + currencySymbol + " " + (-price).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    return currencySymbol + " " + price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const getFormattedDate = (dateString: string) => {
    if (dateString == undefined) {
        return 'NA';
    }
    const date = new Date(dateString);
    const day = date.toLocaleString('default', {day: '2-digit'});
    const month = date.toLocaleString('default', {month: 'short'});
    const year = date.toLocaleString('default', {year: 'numeric'});
    return day + '-' + month + '-' + year;
}

export const getFormattedTime = (dateString: string) => {
    if (dateString == undefined) {
        return 'NA';
    }

    let dateIST = new Date(dateString);
    let utcEnabled = Number(process.env.REACT_APP_UTC_TIME_ENABLED);
    if(utcEnabled) {
        dateIST.setHours(dateIST.getHours() + 5);
        dateIST.setMinutes(dateIST.getMinutes() + 30);
    }

    let newTime = new Date(dateIST).toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', hour12: true});
    return newTime;
}

export const getCurrencySymbol = (country: string) => {
    switch (country) {
        case 'India':
            return "₹";
        case 'Singapore':
            return "S$";
        default:
            return "₹"
    }
}

export const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}