import {SyntheticEvent, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import PageHeading from "../../../components/page-heading/PageHeading";
import {api} from "../../../utils/Axios";
import Loader from "../../../components/Loader";
import {Box, Tab, Tabs} from "@mui/material";
import {a11yProps, CustomTabPanel} from "../../../utils/TabsUtils";
import ProjectDetailsTab from "./tabs/ProjectDetailsTab";
import SiteConditionTab from "./tabs/SiteConditionTab";
import EstimationTab from "./tabs/EstimationTab";
import ECatalogTab from "./tabs/ECatalogTab";
import BoQTab from "./tabs/BoQTab";
import PackageTab from "./tabs/PackageTab";
import PurchaseRequestTab from "./tabs/PurchaseRequestTab";
import HeadCountTab from "../../../components/head-count/HeadCountTab";
import {hasAccess} from "../../../utils/Access";

const ProjectPlan = () => {
    let {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [tabValue, setTabValue] = useState("");
    const [project, setProject] = useState<any>({});
    const [categories, setCategories] = useState([]);
    const [buttons, setButtons] = useState<any>([]);
    const [showUpdatedCharges, setShowUpdatedCharges] = useState(true);

    const [tabs, setTabs] = useState(
        [
            {id: "project-details", name: "Project Details", disabled: false},
            {id: "site-condition", name: "Parameters", disabled: true},
            {id: "head-count", name: "HC", disabled: true},
            {id: "e-catalog", name: "E-Catalog", disabled: true},
            {id: "estimation", name: "Estimation", disabled: true},
            {id: "boq", name: "BOQ", disabled: true},
            {id: "package", name: "Package", disabled: true},
            {id: "pr", name: "Purchase Request", disabled: true}
        ]
    );

    const getProjectDetails = () => {
        api
            .get(`/procurement/project/primary-details/${id}`)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                setProject(res);
                setName(res.name);
                if(res.status != 'COMPLETED') {
                    const headerButtons: any[] = [];
                    if (hasAccess("super-admin") && res.stage != "" && !["DETAILS", "PARAMETER", "HEADCOUNT", "CATALOG", "ESTIMATION", "BOQ", "PACKAGE"].includes(res.stage)) {
                        headerButtons.push({id: 'complete', label: 'Complete Project'})
                    }
                    setButtons(headerButtons);
                }
                let estimationType = res.estimationType;
                if(estimationType == 'clone') {
                    estimationType = res.sourceEstimationType;
                }
                switch (res.stage) {
                    case "DETAILS":
                        tabs.map((tab: any) => {
                            let activeTab = 'site-condition'
                            if (estimationType == 'scratch') {
                                activeTab = 'estimation';
                            }
                            if (tab.id == activeTab) {
                                tab.disabled = false;
                            }
                        });
                        break;
                    case "PARAMETER":
                        tabs.map((tab: any) => {
                            if (["project-details", "site-condition"].includes(tab.id)) {
                                tab.disabled = false;
                            }
                        });
                        break;
                    case "HEADCOUNT":
                        tabs.map((tab: any) => {
                            if (["project-details", "site-condition", "head-count"].includes(tab.id)) {
                                tab.disabled = false;
                            }
                        });
                        break;
                    case "ESTIMATION":
                        tabs.map((tab: any) => {
                            if (estimationType === "scratch") {
                                if (tab.id == "estimation") {
                                    tab.disabled = false;
                                }
                            }
                            if (estimationType === 'engine') {
                                if (["project-details", "site-condition", "head-count", "e-catalog", "estimation"].includes(tab.id)) {
                                    tab.disabled = false;
                                }
                            }
                        });
                        break;
                    case "BOQ":
                        tabs.map((tab: any) => {
                            if (["estimation", "boq", "package"].includes(tab.id)) {
                                tab.disabled = false;
                            }
                        });
                        break;
                    case "PACKAGE":
                        tabs.map((tab: any) => {
                            if (["estimation", "boq", "package"].includes(tab.id)) {
                                tab.disabled = false;
                            }
                        });
                        break;
                    case "PR":
                        tabs.map((tab: any) => {
                            if (["estimation", "boq", "package", "pr"].includes(tab.id)) {
                                tab.disabled = false;
                            }
                        });
                        break;
                    case "COMPLETED":
                        tabs.map((tab: any) => {
                            tab.disabled = false;
                        });
                        break;
                }
                setTabValue("project-details");
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    const handleTabChange = (event: SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };


    const handleEnableBoqTab = () => {
        tabs.map((tab: any) => {
            if (["estimation", "boq", "package"].includes(tab.id)) {
                tab.disabled = false;
            }
        });
    }


    const handleDisableNextTabs = (id: string, changeTab: any) => {
        console.log(id);
        console.log(changeTab);
        let hideTabList: any = [];
        if (id == 'project-details') {
            hideTabList = ["site-condition", "head-count", "e-catalog", "estimation"];
        }
        if (id == 'site-condition') {
            hideTabList = ["head-count", "e-catalog", "estimation"];
        }

        if (id == 'head-count') {
            hideTabList = ["e-catalog", "estimation"];
        }

        let changeRequired = false;
        tabs.map((tab: any) => {
            if (hideTabList.includes(tab.id) && !tab.disabled) {
                changeRequired = true;
                tab.disabled = true;
            }
        });
        if (changeRequired && changeTab) {
            setTabValue("");
            setTimeout(() => {
                setTabValue(id);
            }, 30);
        }
    }


    const proceedTabChange = (id: string, data: any) => {
        let hideEstimationTab = false;
        let hideEngineTabs = false;
        if (id == 'site-condition' && (data?.type == 'scratch' || data?.type == 'clone')) {
            if (project?.stage == 'DETAILS') {
                hideEngineTabs = true;
            }
            id = 'estimation';
            if(project?.sourceEstimationType == "engine") {
                id = 'site-condition';
            }
        } else {
            if (project?.stage == 'DETAILS') {
                hideEstimationTab = true;
            }
        }

        tabs.map((tab: any) => {
            if (hideEstimationTab && tab.id == 'estimation') {
                tab.disabled = true;
            }
            if (hideEngineTabs && (["site-condition", "head-count", "e-catalog"].includes(tab.id))) {
                tab.disabled = true;
            }

            if (tab.id == id) {
                tab.disabled = false;
            }
        });
        setTabValue(id);
    };

    const loadCategories = () => {
        setIsLoading(true);
        api.get("/catalogue/material-master/categories-with-subcategories").then((response) => {
            return response.data;
        }).then((response: any) => {
            setCategories(response);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const handleHeaderButtonClick = (data: any) => {
        if (data == 'complete') {
            setIsLoading(true);
            api.get(`/procurement/project/mark-complete/${id}`).then((response) => {
                return response.data;
            }).then((response: any) => {
                setIsLoading(false);
                window.location.reload();
            }).catch(ex => {
                console.log(ex);
                setIsLoading(false);
            });
        }
    }

    useEffect(() => {
        getProjectDetails();
        loadCategories();
        let updatedCharges = true;
        if (hasAccess(["procurement-head", "business-head", "super-admin"])) {
            updatedCharges = false;
        }
        setShowUpdatedCharges(updatedCharges);
    }, [id]);

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <Box className={"common-space-container"}>
            <PageHeading title={name} showButtons={true} buttons={buttons}
                         handleHeaderButtonClick={handleHeaderButtonClick}/>
            <Box>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Project Plan Tabs"
                    textColor="primary"
                    sx={{borderBottom: 1, borderColor: "divider", color: 'common.black'}}
                    TabIndicatorProps={{
                        sx: {
                            backgroundColor: "primary.main",
                        },
                    }}
                >
                    {tabs.map((tab: any, index: number) => (
                        <Tab
                            key={tab.id + "_" + index}
                            {...a11yProps(tab.id, true)}
                            value={tab.id}
                            label={tab.name}
                            disabled={!tab.disabled || tabValue === tab.id ? false : true}
                            wrapped
                        />
                    ))}
                </Tabs>
                {tabValue == 'project-details' &&
                    <CustomTabPanel value={tabValue} index={tabValue}>
                        <Box sx={{p: 2}}>
                            <ProjectDetailsTab
                                project={project}
                                proceedTabChange={proceedTabChange}
                                handleDisableNextTabs={handleDisableNextTabs}
                            />
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'site-condition' &&
                    <CustomTabPanel value={tabValue} index={tabValue}>
                        <Box sx={{p: 2}}>
                            <SiteConditionTab
                                project={project}
                                proceedTabChange={proceedTabChange}
                                handleDisableNextTabs={handleDisableNextTabs}
                            />
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'head-count' &&
                    <CustomTabPanel value={tabValue} index={tabValue}>
                        <Box sx={{p: 2}}>
                            <HeadCountTab
                                project={project}
                                proceedTabChange={proceedTabChange}
                                handleDisableNextTabs={handleDisableNextTabs}
                            />
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'e-catalog' &&
                    <CustomTabPanel value={tabValue} index={tabValue}>
                        <Box sx={{p: 2}}>
                            <ECatalogTab
                                categories={categories}
                                project={project}
                                proceedTabChange={proceedTabChange}
                            />
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'estimation' &&
                    <CustomTabPanel value={tabValue} index={"estimation"}>
                        <Box sx={{p: 2}}>
                            <EstimationTab
                                showUpdatedCharges={showUpdatedCharges}
                                categories={categories}
                                project={project}
                                proceedTabChange={proceedTabChange}
                                handleEnableBoqTab={handleEnableBoqTab}
                            />
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'boq' &&
                    <CustomTabPanel value={tabValue} index={"boq"}>
                        <Box sx={{p: 2}}>
                            <BoQTab
                                showUpdatedCharges={showUpdatedCharges}
                                categories={categories}
                                project={project}
                                proceedTabChange={proceedTabChange}
                            />
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'package' &&
                    <CustomTabPanel value={tabValue} index={"package"}>
                        <Box sx={{p: 2}}>
                            <PackageTab
                                showUpdatedCharges={showUpdatedCharges}
                                categories={categories}
                                project={project}
                                proceedTabChange={proceedTabChange}
                            />
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'pr' &&
                    <CustomTabPanel value={tabValue} index={"pr"}>
                        <Box sx={{p: 2}}>
                            <PurchaseRequestTab
                                showUpdatedCharges={showUpdatedCharges}
                                categories={categories}
                                project={project}
                                proceedTabChange={proceedTabChange}
                            />
                        </Box>
                    </CustomTabPanel>
                }
            </Box>
        </Box>
    );
};

export default ProjectPlan;
