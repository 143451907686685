import { useContext } from 'react';
import { CheckboxValueContext } from '../utils/CheckboxContext';

export const useCheckboxValues = () => {
  const context = useContext(CheckboxValueContext);
  if (!context) {
    throw new Error("useCheckboxValues must be used within a CheckboxValueProvider");
  }
  return context;
};
