import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../utils/Axios';

interface ApiResponse {
	// Define the properties of the API response here
}

// Action creator using createAsyncThunk
export const loadCategories = createAsyncThunk<ApiResponse, void>(
	'catalogue/loadCategories',
	async () => {
		try {
			const response = await api.get('/catalogue/material-master/categories');
			return response.data as ApiResponse;
		} catch (error: any) {
			throw Error(error.message || 'Failed to fetch categories');
		}
	}
);

export const loadSubCategories = createAsyncThunk<ApiResponse, string>(
    'catalogue/loadSubCategories',
    async (categoryId) => {
        try {
            const response = await api.get('/catalogue/material-master/sub-categories', {
                params: {
                    category_id: categoryId,
                },
            });
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || 'Failed to fetch subcategories');
        }
    }
);

export const loadItemCategories = createAsyncThunk<ApiResponse, string>(
    'catalogue/loadItemCategories',
    async (subCategoryId) => {
        try {
            const response = await api.get('/catalogue/item-category/list', {
                params: {
                    sub_category_id: subCategoryId,
                },
            });
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || 'Failed to fetch item categories');
        }
    }
);

export const loadItem = createAsyncThunk<ApiResponse, string>(
    'catalogue/loadItem',
    async (itemCategoryId) => {
        try {
            const response = await api.get('/catalogue/material-master/items', {
                params: {
                    item_category_id: itemCategoryId,
                },
            });
            return response.data as ApiResponse;
        } catch (error: any) {
            throw Error(error.message || 'Failed to fetch items');
        }
    }
);
