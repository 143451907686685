import {Avatar, Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const NotFound = () => {
    const navigate = useNavigate();
    const goHome = () => {
        navigate("/");
    }
    return (
        <>
            <Box className="center-aligned" sx={{
                minHeight: '40vh',
                flexDirection: 'column',
                mt:5
            }}>
                <Avatar sx={{bgcolor: 'primary.main', width:'200px', height:'200px', mb:5}}><WarningAmberIcon sx={{color:"common.white", fontSize:'7rem'}}/></Avatar>
                <Typography component={"h1"} sx={{fontWeight:700}}>404 - Page Not Found</Typography>
                <p>Sorry, the page you are looking for could not be found.</p>
                <Button variant={"contained"} onClick={goHome}>Go To Home</Button>
            </Box>
        </>
    );
}

export default NotFound;