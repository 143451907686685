import {Box, Button, Tab, Typography} from "@mui/material";
import {a11yProps} from "../../../../utils/TabsUtils";
import React from "react";
import {ItemTypes} from "../../../../modules/project/plan/tabs/boq-tabs/ItemTypes";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";

const SummaryEstimationItemsTabWidget = (props: any) => {
    const handleTabClick = () => {
        if (props.onChange) {
            props.onChange(null, props.estimationItem.id);
        }
    };

    const handleRemove = () => {
        props.handleRemoveItemTab({
            type: ItemTypes.ITEM,
            id: props.estimationItem?.id
        })
    }

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 8px",
            flexShrink: 0,
            backgroundColor: '#fff',
        }}>
            {props.section.status === 'DRAFT' &&
                <Button
                    onClick={handleRemove}
                    sx={{minWidth: "auto", padding: "4px"}}
                >
                    <DoDisturbOnOutlinedIcon
                        sx={{fontSize: "16px", color: "#CB5A42", cursor: "pointer"}}
                    />
                </Button>
            }
            <Tab
                {...a11yProps(props.estimationItem.id, true)}
                value={props.itemIndex}
                onClick={handleTabClick}
                sx={{
                    padding: "0 4px",
                    fontSize: "13px",
                    fontWeight: 600,
                    minWidth: 0,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1,
                    minHeight: 0
                }}
                label={
                    <Typography className="tab-label" sx={{
                        color: '#000', backgroundColor: '#fff'
                    }}>{props.estimationItem.item.name}</Typography>
                }
                className="tab-category"
            />
        </Box>
    )
}

export default SummaryEstimationItemsTabWidget;