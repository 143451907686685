import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import EstimationRevisionListItem from "./EstimationRevisionListItem";

const EstimationRevisionList = (props: any) => {
    const [formattedRevisions, setFormattedRevisions] = useState<any>([]);

    const toNested = (data: any, pid: any) => {
        return data.reduce((r: any, e: any) => {
            if (pid == e.parentId) {
                const object = {...e}
                const children = toNested(data, e.id);

                if (children.length) {
                    object.children = children
                }

                r.push(object)
            }

            return r;
        }, [])
    }

    useEffect(() => {
        if (props.revisions.length > 0) {
            const updatedRevisions = toNested(props.revisions, null);
            setFormattedRevisions(updatedRevisions);
        }
    }, [props.revisions]);

    return (
        <>
            {formattedRevisions.map((revision: any, revisionIndex: number) => {
                return (
                    <Box key={"revision-" + revisionIndex}>
                        <EstimationRevisionListItem
                            showUpdatedCharges={props.showUpdatedCharges}
                            revision={revision}
                            project={props.project}
                            categories={props.categories}
                            primarySpace={0}
                            refreshPage={props.refreshPage}
                            proceedTabChange={props.proceedTabChange}
                        />
                    </Box>
                )
            })}
        </>
    )
}

export default EstimationRevisionList;