import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ListPage from "../../../components/list-page/ListPage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PageHeading from "../../../components/page-heading/PageHeading";
import Loader from "../../../components/Loader";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { pageConfig } from "../../project/package/config/packageDetails";
import { vendorPackageDetails } from "../features/actions";
import { packageDetail, packageDetails } from "./config/config";

const VendorPackageDetails = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);
    const [page, pageChange] = useState(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const isLoading = useAppSelector((state) => state.vendor.loading);
    const [rowsData, rowsChange] = useState<any>({ items: [] });

    const handleCallBack = () => {
        return;
    };

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    };

    const handleSort = (sortModel: any) => {
        if (sortModel && sortModel.field) {
            setSortColumn(sortModel.field);
            setSortDirection(sortModel.sort);
            pageChange(0);
        }
    };

    const getVendorPackageDetails = async (id: string) => {
        try {
            const response = await dispatch(vendorPackageDetails(id)).unwrap();
            rowsChange(response);
        } catch (err) {
            console.error("Failed to fetch vendor package details:", err);
        }
    };

    useEffect(() => {
        if (id) {
            getVendorPackageDetails(id);
        }
    }, [id]);

    if (isLoading) {
        return <Loader />;
    }
    const packageDetailsList =
        Array.isArray(rowsData.items) && rowsData.items.length > 0
            ? rowsData.items.map((item: any, index: number) => ({
                  ...item,
                  id: item.id || index,
              }))
            : [];

    return (
        <Box className={"common-space-container"}>
            <Box display={"flex"} alignItems={"flex-start"} sx={{ pb: 4 }}>
                <ArrowBackIcon sx={{ marginTop: "2.08rem", cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Box className={"common-space-container"}>
                    <PageHeading
                        title={"Package Details: " + (rowsData.name || "")}
                    />
                    <Box>
                        {rowsData && (
                            <>
                                <ListPage
                                    rows={[{...rowsData, id: rowsData?.id || 0}]}
                                    columns={packageDetail}
                                    page={page}
                                    size={size}
                                    totalElements={totalElements}
                                    sortColumn={sortColumn}
                                    sortDirection={sortDirection}
                                    handlePagination={handlePagination}
                                    handleSort={handleSort}
                                    handleCallBack={handleCallBack}
                                    hideFooter
                                />
                            </>
                        )}
                    </Box>
                    <Box>
                        <PageHeading title={"Package Item Details"} />
                        <ListPage
                            rows={packageDetailsList}
                            columns={packageDetails}
                            page={page}
                            size={size}
                            totalElements={totalElements}
                            sortColumn={sortColumn}
                            sortDirection={sortDirection}
                            handlePagination={handlePagination}
                            handleSort={handleSort}
                            handleCallBack={handleCallBack}
                            getRowId={(row: any) => row.id}
                            hideFooter
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default VendorPackageDetails;
