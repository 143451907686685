import {Box, Tab, Tabs, Typography} from "@mui/material";
import {a11yProps} from "../../utils/TabsUtils";
import {SyntheticEvent, useState} from "react";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const CategoryTab = (props: any) => {
    const {categories, categoryTabValue: initialCategoryTabValue, includeSummaryTab, handleCategoryTabChange} = props;
    const [categoryTabValue, setCategoryTabValue] = useState(initialCategoryTabValue);

    const handleTabChange = (event: SyntheticEvent, newValue: string) => {
        setCategoryTabValue(newValue);
        handleCategoryTabChange(event, newValue);
    };

    return (
        <Box className={"category-tab"}>
            {categories.length > 0 && (
                <Tabs
                    className="tabs-container"
                    value={categoryTabValue}
                    onChange={handleTabChange}
                    aria-label="Category Tabs"
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                        overflow: 'visible',
                        display: "flex",
                        mb: 0,
                        pl: 4,
                        borderBottom: 1,
                        borderColor: 'divider',
                        "& .MuiTabs-indicator": {
                            display: 'none'
                        },
                        "& .MuiTabs-scroller": {
                            display: "flex !important",
                            overflow: 'visible!important'
                        },
                    }}
                >
                    {includeSummaryTab && (
                        <Tab
                            key="category-tab-summary"
                            value="summary"
                            label={
                                <Typography
                                    className={"vertically-center-aligned"}
                                    sx={{fontWeight: 700, fontFamily: 'Lato', fontSize: '0.85rem', color: '#1E2B51'}}
                                >
                                    <DescriptionOutlinedIcon sx={{mr: 0.5}}/>
                                    Summary
                                </Typography>
                            }
                            sx={{
                                '&.Mui-selected': {
                                    color: '#1E2B51 !important',
                                    fontWeight: 'bold !important',
                                    borderLeft: '1px solid #E3E7F1',
                                    borderRight: '1px solid #E3E7F1',
                                    borderTop: '2px solid',
                                    borderTopColor: 'primary.main',
                                    backgroundColor: '#fff',
                                    bottom: '-1px',
                                    zIndex: 99,
                                },
                            }}
                        />
                    )}
                    {categories.map((category: any) => (
                        category?.subCategories?.length > 0 && (
                            <Tab
                                key={`category-tab-${category.id}`}
                                {...a11yProps(category.id, true)}
                                value={category.id}
                                disabled={props?.disableCategories}
                                label={
                                    <Typography className="tab-label">
                                        {category.name}
                                        {props.showCount &&
                                            <Typography component={"sup"}
                                                        sx={{fontSize: '10px'}}>&nbsp;({category.totalSkus})</Typography>
                                        }
                                    </Typography>
                                }
                                className="tab-category"
                                sx={{
                                    '&.Mui-selected': {
                                        color: '#1E2B51 !important',
                                        fontWeight: 'bold !important',
                                        borderLeft: '1px solid #E3E7F1',
                                        borderRight: '1px solid #E3E7F1',
                                        borderTop: '2px solid',
                                        borderTopColor: 'primary.main',
                                        backgroundColor: '#fff',
                                        bottom: '-1px',
                                        zIndex: 99,
                                    },
                                }}
                            />
                        )
                    ))}
                </Tabs>
            )}
        </Box>
    );
}

export default CategoryTab;
