import {Box, Button, Grid, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {getPriceInFormatted} from "../../utils/Common";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SkuTable from "../summay/SkuTable";

const BoqSummarySubCategoryListing = (props: any) => {
    const {category, subCategory, project} = props;
    const [expandedCategory, setExpandedCategory] = useState("");
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [subCategorySku, setSubCategorySku] = useState([]);

    const handleExpandSubCategory = (subCategoryId: any) => {
        if (expandedCategory == subCategoryId) {
            setExpandedCategory("");
            props.handleSelectedSubCategory(false, "");
        } else {
            setExpandedCategory(subCategoryId);
            props.handleSelectedSubCategory(true, subCategoryId);
        }
    };

    const toggleSortOrder = () => {
        setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const sortedSubCategories = [...category.subCategories].sort((a: any, b: any) => {
        return sortOrder === 'asc' ? a.total - b.total : b.total - a.total;
    });

    useEffect(() => {
        if (subCategory.hasOwnProperty("id")) {
            const estimationItemsAllSkus: any = [];
            subCategory.estimationItems.map((estimationItem: any) => {
                if (estimationItem.estimationItemSkus && estimationItem.estimationItemSkus.length > 0) {
                    estimationItem.estimationItemSkus.map((sku: any) => {
                        estimationItemsAllSkus.push(sku);
                    });
                }
            });
            setSubCategorySku(estimationItemsAllSkus);
            setExpandedCategory(subCategory.id);
        }
    }, [subCategory]);

    return (
        <Box>
            <Box py={2} display="flex" alignItems="center" justifyContent="space-between">
                <Typography sx={{fontSize: "16px", fontWeight: 600}}>
                    {category.name}
                </Typography>
                <Button onClick={toggleSortOrder}
                        startIcon={sortOrder === 'asc' ? <ArrowUpwardIcon/> : <ArrowDownwardIcon/>}>
                    Sort by Total Price
                </Button>
            </Box>
            <Box>
                {expandedCategory == subCategory.id ? (
                    <Box sx={{py: 1}}>
                        <Box sx={{
                            border: 1,
                            borderColor: 'divider',
                            py: 0.5,
                            px: 2,
                            display: 'flex',
                            alignItems: 'stretch',
                            justifyContent: 'space-between'
                        }}>
                            <Box sx={{width: '100%'}}>
                                <Grid container>
                                    <Grid item xs={!props.showUpdatedCharges ? 4 : 6}>
                                        <Typography sx={{fontSize: '10px', py: 0.5}}>
                                            Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography sx={{fontSize: '10px', py: 0.5}}>
                                            %age
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography sx={{fontSize: '10px', py: 0.5}}>
                                            Total SKU
                                        </Typography>
                                    </Grid>
                                    {!props.showUpdatedCharges &&
                                        <Grid item xs={2}>
                                            <Typography sx={{fontSize: '10px', py: 0.5}}>
                                                Total COGS
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid item xs={2}>
                                        <Typography sx={{fontSize: '10px', py: 0.5}}>
                                            Total GSV
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={!props.showUpdatedCharges ? 4 : 6}>
                                        <Typography
                                            sx={{fontSize: "14px", fontWeight: 600, py: 0.5}}>
                                            {subCategory.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            sx={{fontSize: "14px", fontWeight: 600, py: 0.5}}>
                                            {((subCategory.total as number) / (category.total as number) * 100).toFixed(1)}%
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            sx={{fontSize: "14px", fontWeight: 600, py: 0.5}}>
                                            {subCategory.estimationTotalSkus} skus
                                        </Typography>
                                    </Grid>
                                    {!props.showUpdatedCharges &&
                                        <Grid item xs={2}>
                                            {props.showUpdatedCharges && category.hasOwnProperty("code") ? (
                                                <>
                                                    {category.code.toLowerCase() == "ff" ? (
                                                        <Typography
                                                            sx={{fontSize: "14px", fontWeight: 600, py: 0.5}}>
                                                            {getPriceInFormatted(subCategory.totalCogs + ((subCategory.totalCogs * 2) / 100), project.country)}
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            sx={{fontSize: "14px", fontWeight: 600, py: 0.5}}>
                                                            {getPriceInFormatted(subCategory.totalCogs + ((subCategory.totalCogs * 6) / 100), project.country)}
                                                        </Typography>
                                                    )}
                                                </>
                                            ) : (
                                                <Typography
                                                    sx={{fontSize: "14px", fontWeight: 600, py: 0.5}}>
                                                    {getPriceInFormatted(subCategory.totalCogs, project.country)}
                                                </Typography>
                                            )}
                                        </Grid>
                                    }
                                    <Grid item xs={2}>
                                        <Typography
                                            sx={{fontSize: "14px", fontWeight: 600, py: 0.5}}>
                                            {getPriceInFormatted(subCategory.total, project.country)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={"vertically-center-aligned"}>
                                <Button
                                    onClick={() =>
                                        handleExpandSubCategory(subCategory?.id)
                                    }
                                    sx={{
                                        minWidth: "auto",
                                        p: 0
                                    }}
                                >
                                    {expandedCategory == subCategory?.id ? (
                                        <KeyboardArrowUpIcon/>
                                    ) : (
                                        <ExpandMoreIcon/>
                                    )}
                                </Button>
                            </Box>
                        </Box>
                        {expandedCategory == subCategory?.id && (
                            <Box>
                                <SkuTable skuItems={subCategorySku}
                                          showUpdatedCharges={props.showUpdatedCharges}
                                          categoryCode={category.hasOwnProperty("code") ? category.code : "NA"}/>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <>
                        {category &&
                            sortedSubCategories.length > 0 &&
                            sortedSubCategories.map((subCategory: any, idx: number) => {
                                const estimationItemsAllSkus: any = [];
                                subCategory.estimationItems.map((estimationItem: any) => {
                                    if (estimationItem.estimationItemSkus && estimationItem.estimationItemSkus.length > 0) {
                                        estimationItem.estimationItemSkus.map((sku: any) => {
                                            estimationItemsAllSkus.push(sku);
                                        });
                                    }
                                });
                                if (estimationItemsAllSkus.length > 0) {
                                    let totalCogs = subCategory.totalCogs;
                                    let total = subCategory.total;
                                    if (props.showUpdatedCharges && category.hasOwnProperty("code")) {
                                        if (category.code.toLowerCase() == "ff") {
                                            totalCogs = totalCogs + ((totalCogs * 2) / 100);
                                        } else {
                                            totalCogs = totalCogs + ((totalCogs * 6) / 100);
                                        }
                                    }
                                    return (
                                        <Box key={"boq-breakup-sub-category-" + idx} sx={{py: 1}}>
                                            <Box sx={{
                                                border: 1,
                                                borderColor: 'divider',
                                                py: 0.5,
                                                px: 2,
                                                display: 'flex',
                                                alignItems: 'stretch',
                                                justifyContent: 'space-between'
                                            }}>
                                                <Box sx={{width: '100%'}}>
                                                    <Grid container>
                                                        <Grid item xs={!props.showUpdatedCharges ? 4 : 6}>
                                                            <Typography sx={{fontSize: '10px', py: 0.5}}>
                                                                Name
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography sx={{fontSize: '10px', py: 0.5}}>
                                                                %age
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography sx={{fontSize: '10px', py: 0.5}}>
                                                                Total SKU
                                                            </Typography>
                                                        </Grid>
                                                        {!props.showUpdatedCharges &&
                                                            <Grid item xs={2}>
                                                                <Typography sx={{fontSize: '10px', py: 0.5}}>
                                                                    Total COGS
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                        <Grid item xs={2}>
                                                            <Typography sx={{fontSize: '10px', py: 0.5}}>
                                                                Total GSV
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={!props.showUpdatedCharges ? 4 : 6}>
                                                            <Typography
                                                                sx={{fontSize: "14px", fontWeight: 600, py: 0.5}}>
                                                                {subCategory.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                sx={{fontSize: "14px", fontWeight: 600, py: 0.5}}>
                                                                {((subCategory.total as number) / (category.total as number) * 100).toFixed(1)}%
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                sx={{fontSize: "14px", fontWeight: 600, py: 0.5}}>
                                                                {subCategory.estimationTotalSkus} skus
                                                            </Typography>
                                                        </Grid>
                                                        {!props.showUpdatedCharges &&
                                                            <Grid item xs={2}>
                                                                <Typography
                                                                    sx={{fontSize: "14px", fontWeight: 600, py: 0.5}}>
                                                                    {getPriceInFormatted(totalCogs, project.country)}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                sx={{fontSize: "14px", fontWeight: 600, py: 0.5}}>
                                                                {getPriceInFormatted(total, project.country)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box className={"vertically-center-aligned"}>
                                                    <Button
                                                        onClick={() =>
                                                            handleExpandSubCategory(subCategory?.id)
                                                        }
                                                        sx={{
                                                            minWidth: "auto",
                                                            p: 0
                                                        }}
                                                    >
                                                        {expandedCategory == subCategory?.id ? (
                                                            <KeyboardArrowUpIcon/>
                                                        ) : (
                                                            <ExpandMoreIcon/>
                                                        )}
                                                    </Button>
                                                </Box>
                                            </Box>
                                            {expandedCategory == subCategory?.id && (
                                                <Box>
                                                    <SkuTable skuItems={estimationItemsAllSkus}
                                                              showUpdatedCharges={props.showUpdatedCharges}
                                                              categoryCode={category.hasOwnProperty("code") ? category.code : "NA"}/>
                                                </Box>
                                            )}
                                        </Box>
                                    );
                                }
                            })}
                    </>
                )}
            </Box>
        </Box>
    )
}
export default BoqSummarySubCategoryListing;
