import React, { createContext, ReactNode, useState } from 'react';

interface CheckboxValueContextType {
  selectedCheckboxValue: any[];
  setSelectedCheckboxValue: React.Dispatch<React.SetStateAction<any[]>>;
  resetSelectedValues: () => void;
}

const defaultContextValue: CheckboxValueContextType = {
  selectedCheckboxValue: [],
  setSelectedCheckboxValue: () => {},
  resetSelectedValues: () => {},
};

export const CheckboxValueContext = createContext<CheckboxValueContextType>(defaultContextValue);

export const CheckboxValueProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedCheckboxValue, setSelectedCheckboxValue] = useState<any[]>([]);

  const resetSelectedValues = () => {
    setSelectedCheckboxValue([]);
  };

  return (
    <CheckboxValueContext.Provider value={{ selectedCheckboxValue, setSelectedCheckboxValue, resetSelectedValues }}>
      {children}
    </CheckboxValueContext.Provider>
  );
};
