import {createPackage, loadPackageItems, updatePackage} from "./actions";
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

// Define the type for the results object
interface Results extends Array<any> {
}

// Initial state
interface packageState {
    loading: boolean;
    results: Results | null;
    packageItems: Results | null;
    error: string;
}

const initialState: packageState = {
    loading: false,
    results: null,
    packageItems: null,
    error: '',
};

// Package slice
const packageSlice = createSlice({
    name: 'package',
    initialState,
    reducers: {
        resetPackageItems: (state) => {
            state.packageItems = null;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(createPackage.pending, (state: any) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(createPackage.fulfilled, (state: any, action: PayloadAction<Results>) => {
                state.loading = false;
                state.results = action.payload;
                state.error = '';
            })
            .addCase(createPackage.rejected, (state: any, action: any) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to submit form';
            })
            .addCase(updatePackage.pending, (state: any) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(updatePackage.fulfilled, (state: any, action: PayloadAction<Results>) => {
                state.loading = false;
                state.results = action.payload;
                state.error = '';
            })
            .addCase(updatePackage.rejected, (state: any, action: any) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to submit form';
            })
            .addCase(loadPackageItems.pending, (state: any) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(loadPackageItems.fulfilled, (state: any, action: PayloadAction<Results>) => {
                state.loading = false;
                state.packageItems = action.payload;
                state.error = '';
            })
            .addCase(loadPackageItems.rejected, (state: any, action: any) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to submit form';
            })
    },
});

export const {resetPackageItems} = packageSlice.actions;

export default packageSlice.reducer;
