import {Box, Tabs} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDrag} from "react-dnd";
import {ItemTypes} from "../../../../modules/project/plan/tabs/boq-tabs/ItemTypes";
import {CustomTabPanel} from "../../../../utils/TabsUtils";
import EstimationItemsTabWidget from "./EstimationItemsTabWidget";
import SubCategoryBlock from "./SubCategoryBlock";
import SkuWidget from "./SkuWidget";

const SubCategoryWidget = (props: any) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [expandSubCategory, setExpandSubCategory] = useState(false);
    const [total, setTotal] = useState(0);
    const [totalSkus, setTotalSkus] = useState(0);
    const [estimationItems, setEstimationItems] = useState<any>([]);
    const [activeTab, setActiveTab] = useState<any>("");

    const [{isDragging}, subCategoryDrag] = useDrag({
        type: ItemTypes.SUBCATEGORY,
        item: {type: ItemTypes.SUBCATEGORY, id: props.subCategory.id},
        canDrag: !isDisabled,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    const handleExpand = () => {
        setExpandSubCategory(!expandSubCategory);
    }

    useEffect(() => {
        let isAllItemsDragged = true;
        setEstimationItems([]);
        props.estimations.map((estimation: any) => {
            if (estimation.materialSubCategoryId == props.subCategory.id) {
                if (activeTab == "") {
                    setActiveTab(estimation.estimationItems[0].id);
                }
                setEstimationItems(estimation.estimationItems);
                let subCategoryTotal = estimation.total + estimation?.domainTotal;
                setTotal(subCategoryTotal);
                setTotalSkus(estimation.totalSkus);
                estimation.estimationItems.map((estimationItem: any) => {
                    estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                        if (estimationItemSku?.disabled == false) {
                            isAllItemsDragged = false;
                        }
                    });
                });
            }
        })
        setIsDisabled(isAllItemsDragged);
    }, [props]);

    return (
        <Box>
            <Box
                ref={subCategoryDrag}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    backgroundColor: '#eee',
                    borderRadius: 1,
                    p: 1.2,
                    cursor: isDisabled ? "not-allowed" : "move",
                    opacity: isDisabled || isDragging ? 0.5 : 1
                }}
            >
                <SubCategoryBlock
                    subCategory={props.subCategory}
                    expandSubCategory={expandSubCategory}
                    total={total}
                    totalSkus={totalSkus}
                    project={props.project}
                    handleExpand={handleExpand}
                />
            </Box>
            {(expandSubCategory && activeTab != '') &&
                <>
                    <Box sx={{width: '100%', px: 2}}>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            aria-label="Subcategory Tabs"
                            variant="scrollable"
                            scrollButtons="auto"
                            textColor="primary"
                            indicatorColor="primary"
                            sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                                display: "flex",
                                mb: 0,
                                "& .MuiTabs-indicator": {
                                    backgroundColor: "primary.main",
                                },
                                "& .MuiTabs-scroller": {
                                    display: "flex !important",
                                },
                            }}
                        >
                            {estimationItems.map((estimationItem: any, itemIndex: any) => (
                                <EstimationItemsTabWidget
                                    estimationItem={estimationItem}
                                    project={props.project}
                                    key={estimationItem.id}
                                    itemIndex={itemIndex}
                                    onChange={handleTabChange}
                                    value={estimationItem?.id}
                                />
                            ))}
                        </Tabs>
                        {estimationItems.map((estimationItem: any, estimationItemIndex: number) => {
                            return (
                                <CustomTabPanel
                                    value={activeTab}
                                    index={estimationItem.id}
                                    key={`estimation-tab-` + estimationItem.id}
                                >
                                    {estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                                        return (
                                            <Box sx={{pt: 2, px: 1}}>
                                                <SkuWidget
                                                    showUpdatedCharges={props.showUpdatedCharges}
                                                    categoryCode={props.categoryCode}
                                                    estimationItemSku={estimationItemSku}
                                                    key={estimationItemSku.id}
                                                    project={props.project}
                                                />
                                            </Box>
                                        )
                                    })}
                                </CustomTabPanel>
                            )
                        })}
                    </Box>
                </>
            }
        </Box>
    )
}
export default SubCategoryWidget;