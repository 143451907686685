import {Box, Grid, Typography} from "@mui/material";
import SelectCompareRevisions from "./SelectCompareRevisions";
import TabularRevisionDetails from "./TabularRevisionDetails";
import {useEffect, useState} from "react";
import {hasAccess} from "../../../utils/Access";

const CompareRevisionTabular = (props: any) => {

    return (
        <Grid container>
            <Grid item xs={2.5}>
                <Box sx={{height: '70px'}}>
                    <SelectCompareRevisions
                        selectedRevisions={props.selectedRevisions}
                        revisions={props.revisions}
                        handleSave={props.handleSave}
                    />
                </Box>
                {(props.tab == 'table' && props.selectedRevisions.length > 0) &&
                    <Box>
                        <Box sx={{height: '70px', my: 2}}/>
                        <Box>
                            <Box
                                sx={{py: 1.5, px: 1, backgroundColor: '#E9EBF9', border: 1, borderBottom: 0, borderColor: '#E9EBF9'}}>
                                <Typography>Work Contracts</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{px: 1, py: 2, border: 1, borderColor: '#E9EBF9'}}>MEP</Typography>
                                <Typography sx={{px: 1, py: 2, border: 1, borderTop: 0, borderColor: '#E9EBF9'}}>Civil &
                                    Interior</Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{mt: 2, py: 1.5, px: 1, backgroundColor: '#E9EBF9', border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>Supply</Typography>
                        </Box>
                        <Box sx={{px: 1, py: 2, border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>FF&E</Typography>
                        </Box>
                        <Box
                            sx={{mt: 2, py: 1.5, px: 1, backgroundColor: '#E9EBF9', border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>Design & Consultancy Charges</Typography>
                        </Box>
                        <Box
                            sx={{mt: 2, py: 1.5, px: 1, backgroundColor: '#E9EBF9', border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>Prelim Charges</Typography>
                        </Box>

                        <Box
                            sx={{mt: 2, py: 1.5, px: 1, backgroundColor: '#E9EBF9', border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>Local Authority Charges</Typography>
                        </Box>

                        <Box
                            sx={{mt: 2, py: 1.5, px: 1, backgroundColor: '#E9EBF9', border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>BOCW Charges</Typography>
                        </Box>

                        <Box
                            sx={{mt: 2, py: 1.5, px: 1, backgroundColor: '#E9EBF9', border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>Taxes @ 18%</Typography>
                        </Box>

                        <Box sx={{mt: 2, py: 1.5, px: 1, border: 1, borderColor: '#E9EBF9'}}>
                            <Typography>Total</Typography>
                        </Box>
                    </Box>
                }
            </Grid>
            <Grid item xs={9.5} className={"revision-comparison"}>
                <Box sx={{overflowX: 'auto', pb: 5, whiteSpace: 'nowrap'}}>
                    {props.revisionEstimations.map((revision: any, index: number) => {
                        let bgColor = '#fff';
                        if((index+1) % 2 == 0) {
                            bgColor = '#E9EBF9';
                        }
                        return (
                            <Box sx={{width: '430px', marginRight: '20px', display: 'inline-block'}}
                                 key={"tabular-revision-compare-" + index}>
                                <TabularRevisionDetails
                                    bgColor={bgColor}
                                    tab={props.tab}
                                    revision={revision}
                                    project={props.project}
                                    categories={props.categories}
                                    showUpdatedCharges={props.showUpdatedCharges}
                                />
                            </Box>
                        )
                    })}
                </Box>
            </Grid>
        </Grid>
    )
}

export default CompareRevisionTabular;