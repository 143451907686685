import { createSlice } from "@reduxjs/toolkit";
import {
  getBasicVendor,
  getVendorPackageList,
  loadVendor,
  submitVendorForm,
  updateRateContractPrice,
  vendorPackageDetails,
} from "./actions";

const vendorSlice = createSlice({
  name: "vendor",
  initialState: {
    pending: false,
    vendorDetails: { owner: "", name: "", city: "", status: "", year: "" },
    vendorRcPrice: {},
    basicVendor: { id: "", name: "", status: "" },
    formResponse: {},
    error: "",
    loading: false,
    err: "",
    vendorPackageList: {},
    vendorPackageDetailsData: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadVendor.pending, (state: any) => {
        state.pending = true;
        state.error = "";
      })
      .addCase(loadVendor.fulfilled, (state: any, action) => {
        state.pending = false;
        state.vendorDetails = action.payload;
        state.error = "";
      })
      .addCase(loadVendor.rejected, (state: any, action) => {
        state.pending = false;
        state.error = action.payload;
      })
      .addCase(submitVendorForm.pending, (state: any) => {
        state.pending = true;
        state.error = "";
      })
      .addCase(submitVendorForm.fulfilled, (state: any, action) => {
        state.pending = false;
        state.formResponse = action.payload;
        state.error = "";
      })
      .addCase(submitVendorForm.rejected, (state: any, action) => {
        state.pending = false;
        state.error = action.payload;
      })
      .addCase(getBasicVendor.pending, (state: any) => {
        state.pending = true;
        state.error = "";
      })
      .addCase(getBasicVendor.fulfilled, (state: any, action) => {
        state.pending = false;
        state.basicVendor = action.payload;
        state.error = "";
      })
      .addCase(getBasicVendor.rejected, (state: any, action) => {
        state.pending = false;
        state.error = action.payload;
      })
      .addCase(getVendorPackageList.pending, (state: any) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getVendorPackageList.fulfilled, (state: any, action) => {
        state.loading = false;
        state.vendorPackageList = action.payload;
        state.err = "";
      })
      .addCase(getVendorPackageList.rejected, (state: any, action) => {
        state.loading = false;
        state.err = action.payload;
      })
      .addCase(vendorPackageDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(vendorPackageDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.vendorPackageDetailsData = action.payload;
      })
      .addCase(vendorPackageDetails.rejected, (state: any, action: any) => {
        state.loading = false;
        state.err = action.payload;
      })
      .addCase(updateRateContractPrice.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateRateContractPrice.fulfilled, (state, action) => {
        state.loading = false;
        state.vendorRcPrice = action.payload;
      })
      .addCase(updateRateContractPrice.rejected, (state: any, action: any) => {
        state.loading = false;
        state.err = action.payload;
      })

      
  },
});

export default vendorSlice.reducer;
