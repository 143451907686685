import {SyntheticEvent, useEffect, useState} from "react";
import Loader from "../../../../components/Loader";
import {Box, Button, Typography} from "@mui/material";
import CategoryTab from "../../../../components/tabs/CategoryTab";
import SubCategoryTab from "../../../../components/tabs/SubCategoryTab";
import {CustomTabPanel} from "../../../../utils/TabsUtils";
import ECatalogItemListing from "../../../../components/ecatalog/ECatalogItemListing";
import ECatalogDialog from "../../../../components/ecatalog/ECatalogDialog";
import SearchFilter from "../../../../components/filter/SearchFilter";
import SearchBar from "../../../../components/filter/SearchBar";
import RemoveFilters from "../../../../components/filter/RemoveFilters";
import {api} from "../../../../utils/Axios";
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';

const ECatalogTab = (props: any) => {
    const subCategoriesToShow = ["CH", "CA", "MF", "LF"];
    const [catalogCategories, setCatalogCategories] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<any>({});
    const [categoryTabValue, setCategoryTabValue] = useState<string>("");
    const [subCategoryTabValue, setSubCategoryTabValue] = useState("");
    const [subCategoryName, setSubCategoryName] = useState("");
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [appliedFilters, setAppliedFilters] = useState({});
    const [estimationItems, setEstimationItems] = useState<any>([]);
    const [priceRange, setPriceRangeValue] = useState<number[]>([]);
    const priceRangeMinDistance = 100;
    const [assignedQty, setAssignedQty] = useState<number>(0);
    const [availabelQty, setAvailabelQty] = useState<number>(0);
    const [selectOptions, setSelectOptions] = useState<any>({"make":[], "model": []});
    const [make, setMake] = useState("");
    const [defaultModelOptions, setDefaultModelOptions] = useState([]);
    const [priceSlide, setPriceSlide] = useState("");
    const [priceType, setPriceType] = useState("price");
    const [skuLabelWithQty, setSkuLabelWithQty] = useState<any>({});
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleSubCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setSubCategoryTabValue(newValue);
        setPriceType("price");
        setAppliedFilters({});
        loadEstimationDetails(newValue);
        loadMakeNModelData(newValue);
        loadPriceRangeData(newValue, false);

        selectedCategory.subCategories.map((subCategory: any, subCategoryIndex: number) => {
            if(subCategory.id == newValue) {
                setSubCategoryName(subCategory.name);
                console.log(subCategory.name);
            }
        });

    };

    const updateApiLoading = (loading: boolean) => {
        setIsApiLoading(loading);
    }
    const handleCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setCategoryTabValue(newValue);
    };

    const handleContinue = () => {
        setOpen(true);
    }

    const handleClose = (value:any) => {
        setOpen(false);
        if(value == "proceed") {
            props.proceedTabChange('estimation');
        }
    };

    const handelSearchFilter = (query: any) => {
        setSearchQuery(query);
    }

    const handleFilterChange = (appliedParams: any) => {
        setAppliedFilters(appliedParams);
        
        let update = true;
        if (Object.keys(appliedParams).length > 0) {
            Object.entries(appliedParams).map(([name, value]: any) => {
                if(name == "make") {
                    setMake(value);
                    update = false;
                }
                if(name === "price") {
                    setPriceSlide(value.toString());
                }
                if(name === "price_type") {
                    setPriceType(value.toString());
                }
            });
        }
        if(update) {
            setMake("");
        }
        
    }

    const modalStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      bgcolor: 'background.paper',
      boxShadow: 24,
      padding: "25px 16px",
    };

    const updateAssignedQty = (qty:number) => {
        setAssignedQty(qty);
    }
    const loadEstimationDetails = (subCategoryId:any) => {
        let url = `/procurement/estimation/get-project-selected-items/${props.project.id}/${subCategoryId}`;
        //setIsLoading(true);
        api.get(url)
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                //setIsLoading(false);
                let availQty: number = 0;
                let localSkuLabelWithQty:any = {};
                if (Array.isArray(response) && response.length > 0) {
                    response.forEach((estimation: any) => {
                        availQty += (estimation.estimationQuantity ? estimation.estimationQuantity : 0);
                        if(estimation?.estimationSkus) {
                            localSkuLabelWithQty = {...localSkuLabelWithQty, ...estimation.estimationSkus};
                        }
                    });
                    setEstimationItems(response);
                } else {
                    setEstimationItems([]);
                }
                setAvailabelQty(availQty);
                setSkuLabelWithQty(localSkuLabelWithQty);
                //console.log("skuLabelWithQty", localSkuLabelWithQty);
            })
            .catch((ex) => {
                console.log(ex);
                //setIsLoading(false);
            });
    };

    const loadPriceRangeData = (subCategoryId:any,  reset:any) => {
        let url = `/catalogue/item-category/item-price-range/${subCategoryId}`;
        const params = new URLSearchParams();
        params.append("price_type", priceType.toString());
        //setIsLoading(true);
        api.get(url, {
            params : params
        })
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                let minPrice = response?.minPrice ? parseInt(response.minPrice.toString()) :  0;
                let maxPrice = response?.maxPrice && (parseInt(response.maxPrice.toString()) > 10) ? parseInt(response.maxPrice.toString()) :  500000;

                let rem = maxPrice%100;
                let adNum = 100 - rem;
                maxPrice = maxPrice + adNum;

                let minMaxPrices = [0, maxPrice];
                console.log("minMaxPrices", minMaxPrices);
                setPriceRangeValue([0, maxPrice]);

                
                if (Object.keys(appliedFilters).length > 0 && reset) {
                    let setFilter = false;
                    let appliedValues:any = [];
                    Object.entries(appliedFilters).map(([name, value]: any) => {
                        appliedValues[name] = value;
                        if(name === "price") {
                            appliedValues["price"] = [0, maxPrice].toString();
                            appliedValues["price_type"] = priceType.toString();
                            setFilter = true;
                        }
                    });
                    if(setFilter) {
                        setAppliedFilters(appliedValues);
                    }
                }
                
            })
            .catch((ex) => {
                let minMaxPrices = [0, 200000];
                setPriceRangeValue(minMaxPrices);
                console.log("minMaxPrices", minMaxPrices);
                console.log(ex);
            });
            
    };

    const loadMakeNModelData = (subCategoryId:any) => {
        let url = `/catalogue/item-category/item-make-model/${subCategoryId}`;
        
        //setIsLoading(true);
        api.get(url)
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                let selectOptions = {"make":[], "model": []};
                setSelectOptions(selectOptions);

                if (Array.isArray(response.models) && response.models.length > 0) {
                    let modelOptions = response.models.map((model:any) => {
                        return {id: model, label: model};
                    })

                    selectOptions["model"] = modelOptions;
                    setDefaultModelOptions(modelOptions);
                } 
                if (Array.isArray(response.makes) && response.makes.length > 0) {
                    let makeOptions = response.makes.map((make:any) => {
                        return {id: make, label: make};
                    })
                    selectOptions["make"] = makeOptions;
                }
                setSelectOptions(selectOptions);
            })
            .catch((ex) => {
                console.log(ex);
            });
    };

    const loadModelByMakeValue = () => {
        let url = `/catalogue/item-category/models-by-make/${subCategoryTabValue}/${make}`;
        
         const params = new URLSearchParams();
         if (Object.keys(appliedFilters).length > 0) {
            Object.entries(appliedFilters).map(([name, value]: any) => {
                if(name == "price") {
                    params.append(name, value.toString());
                    params.append("price_type", priceType.toString());
                }
            });
        }


        //setIsLoading(true);
        api.get(url, {
                params: params,
            })
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                let options = selectOptions;

                setSelectOptions({});
                if (Array.isArray(response.models) && response.models.length > 0) {
                    let makeOptions = response.models.map((model:any) => {
                        return {id: model, label: model};
                    })

                    options["model"] = makeOptions;
                } else {
                    options["model"] = [];
                }
                
                setTimeout(() => {setSelectOptions(options);}, 10);
                
            })
            .catch((ex) => {
                setSelectOptions({});
                let options = selectOptions;
                options["model"] = defaultModelOptions;
                setTimeout(() => {setSelectOptions(options);}, 10);
                console.log(ex);
            });
    }
    
    const updatePriceFilterType = (priceType:any) => {
        setPriceType(priceType);
    }


    useEffect(() => {
        catalogCategories.forEach((category: any) => {
            if (category.id === categoryTabValue) {
                setSelectedCategory(category);
                setSubCategoryTabValue(category.subCategories[0]?.id || "");
                if(category.subCategories[0]?.id) {
                    setPriceType("price");
                    loadEstimationDetails(category.subCategories[0].id);
                    loadMakeNModelData(category.subCategories[0].id);
                    loadPriceRangeData(category.subCategories[0].id, false);
                    setSubCategoryName(category.subCategories[0].name);
                }
            }
        });
    }, [categoryTabValue]);

    useEffect(() => {
            loadModelByMakeValue();
    }, [make, priceSlide]);

    useEffect(() => {
        loadPriceRangeData(subCategoryTabValue,  true);
    }, [priceType])

    useEffect(() => {
        const updatedCategories = props.categories.map((category: any) => {
            const subCategories = category.subCategories.filter((subCategory: any) => {
                return subCategoriesToShow.includes(subCategory.code);
            });
            if(subCategories.length > 0 && categoryTabValue == "") {
                setCategoryTabValue(category.id);
            }
            return {...category, subCategories};
        });
        setCatalogCategories(updatedCategories);
    }, [props.categories]);


    if (isLoading) {
        return <Loader/>;
    }

    return (
        <Box sx={{position: "relative"}}>
            {isApiLoading && <Box sx={{position: "absolute", width: "100%", left: "0", top: "0%", height:"80vh", background: "transparent", zIndex: "3"}}></Box>}
            {categoryTabValue !== "" && (
                <CategoryTab
                    includeSummaryTab={false}
                    categories={catalogCategories}
                    categoryTabValue={categoryTabValue}
                    handleCategoryTabChange={handleCategoryTabChange}
                />
            )}
            {selectedCategory.hasOwnProperty("id") && categoryTabValue !== "summary" && (
                <SubCategoryTab
                    subCategoryTabValue={subCategoryTabValue}
                    subCategories={selectedCategory?.subCategories}
                    categoryTabValue={categoryTabValue}
                    handleSubCategoryTabChange={handleSubCategoryTabChange}
                    noBotttomBorder={true}
                />
            )}
            <Box
                sx={{
                    height: "calc(100vh - 135px)",
                    overflowY: "auto",
                    paddingTop: "5px",
                    pb: 5,
                }}
            >
                <Box sx={{display: "flex"}}>
                    <Box sx={{flexBasis: "80%", border: "1px solid #d5d5d5", padding: "10px 20px",borderRadius: "3px"}}>
                        <Typography sx={{display: 'block', marginBottom: '10px', fontSize:  "0.8rem"}}> <b>Available Quantity:</b> {(availabelQty - assignedQty) >= 0 ? (availabelQty - assignedQty) : 0}</Typography>
                        <Typography sx={{display: 'block', fontSize:  "0.8rem"}}> 
                            <b>Assigned Quantity:</b> {assignedQty}
                            {Object.keys(skuLabelWithQty).length > 0  && 
                                <Typography sx={{display: 'inline-block', fontSize:  "0.8rem", marginLeft: "30px", color: "blue", cursor: "pointer"}} onClick={handleModalOpen}> View Engine Suggestions <span style={{verticalAlign: "middle"}}>{">"}</span></Typography>
                            }
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'right', flexBasis: "20%", alignItems: "center"}}>
                        <Button variant={"outlined"} sx={{mr: 2, height: "38px"}} onClick={() => handleContinue()}>Proceed to Estimation</Button>
                    </Box>
                </Box>
                <Modal
                  open={modalOpen}
                  onClose={handleModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontSize: "1.2rem", fontWeight: "600", marginBottom: "20px"}}>
                      Engine Suggestions - {subCategoryName}'s
                    </Typography>
                    <CancelIcon onClick={handleModalClose} sx={{right: "15px", top: "28px", position: "absolute", cursor: "pointer"}}/>
                    {Object.keys(skuLabelWithQty).length > 0 && <Box sx={{marginTop: "10px"}}>
                        <Box sx={{display: "flex", flexBasis: "100%", borderBottom: "1px solid #d5d5d5", paddingBottom: "10px", color: "#666"}}>
                            <Box sx={{flexBasis: "70%"}}>NAME</Box>
                            <Box sx={{flexBasis: "30%"}}>COUNT</Box>
                        </Box>
                        {Object.entries(skuLabelWithQty).map(([skuLabel, qty]: any, index: number) => {
                                return (
                                    <Box sx={{display: "flex", flexBasis: "100%", border: "1px solid #d5d5d5", borderTop: "none", fontSize: "0.8rem"}}>
                                        <Box sx={{flexBasis: "70%", borderRight: "1px solid #d5d5d5", padding: "15px"}}>{skuLabel}</Box>
                                        <Box sx={{flexBasis: "30%", padding: "10px",fontWeight: "700"}}>{qty}</Box>
                                    </Box>
                                    );
                            })
                        }
                        
                    </Box>}
                    {Object.keys(skuLabelWithQty).length === 0 && 
                            <Box sx={{marginTop: "10px"}}>
                                <Box sx={{display: "flex", flexBasis: "100%", paddingBottom: "10px", color: "#666"}}>
                                    No suggestions found!
                                </Box>
                            </Box>
                        }
                  </Box>
                </Modal>
                
                {subCategoryTabValue !== "" && categoryTabValue !== "summary" && (
                    <CustomTabPanel value={subCategoryTabValue} index={subCategoryTabValue}>
                        <Box sx={{display: 'flex', justifyContent: 'right', py: 2}}>
                            <SearchBar
                                handleFilterChange={handleFilterChange}
                                filters={filters}
                                appliedFilters={appliedFilters}
                                showFilters={true}
                                showSearch={true}
                                showRangeSlider={true}
                                query={searchQuery}
                                handleSearchFilter={handelSearchFilter}
                                range={priceRange}
                                minDistance={priceRangeMinDistance}
                                selectOptions={selectOptions}
                                category={selectedCategory}
                                subCategoryId={subCategoryTabValue}
                                updatePriceFilterType={updatePriceFilterType}
                            />
                        </Box>
                        <Box>
                            <RemoveFilters handleFilterChange={handleFilterChange} filters={filters}
                                       appliedFilters={appliedFilters}/>
                        </Box>
                        <ECatalogDialog
                            open={open}
                            onClose={handleClose}
                            confirmText={"Are you sure you want to proceed to Estimation?"}
                            nextTabValue={""}
                          />
                        <ECatalogItemListing
                            type={"brand"}
                            project={props.project}
                            subCategoryId={subCategoryTabValue}
                            category={selectedCategory}
                            appliedFilters={appliedFilters}
                            searchQuery={searchQuery}
                            updateAssignedQty={updateAssignedQty}
                            updateApiLoading={updateApiLoading}
                        />
                    </CustomTabPanel>
                )}
            </Box>
        </Box>
    );
};

const filters = {
    'make': {
        element: 'select',
        type: 'text',
        placeholder: 'Search By Make',
        label: 'Make',
        name: 'make',
        options: [
            {id: '', label: 'Select'},
            {id: 'HNI', label: 'HNI'},
            {id: 'Steelcase', label: 'Steelcase'},
        ],
    },
    'model': {
        element: 'select',
        type: 'text',
        placeholder: 'Search By Model',
        label: 'Model',
        name: 'model',
        options: [
            {id: '', label: 'Select'},
            {id: 'HNI', label: 'HNI'},
            {id: 'Steelcase', label: 'Steelcase'},
        ],
    },
    'price': {
        element: 'slider',
        type: 'text',
        placeholder: 'Search By Item Code',
        label: 'Price',
        name: 'price',
        range: [0,100],
        distance: 10
    }
};

export default ECatalogTab;
