import {createTheme} from "@mui/material/styles";

let themeData = createTheme({
    palette: {
        primary: {
            main: "#3854E4",
            light: "#e5f4ff",
            dark: '#1E2B51',
            contrastText: '#fff',
        },
        secondary: {
            main: "#1565c0",
            light: "#D9D9D9",
        },
        success: {
            main: "#6495ED",
            light: "#4E4E4E"
        },
        info: {
            main: "#333333",
            light: "#B5C0DA",
            dark: "#1E2B51",
            contrastText: '#E9EBF9'
        }
    },
    shape: {
        borderRadius: 5
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

let theme = createTheme(themeData, {
    typography: {
        fontFamily: "'Lato', 'Work Sans'",
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontSize: '1.5rem',
            fontWeight: 700,
            fontFamily: 'inherit'
        },
        h2: {
            fontSize: '1.4rem',
            fontWeight: 700,
            fontFamily: 'inherit'
        },
        h3: {
            fontSize: '1.3rem',
            fontFamily: 'inherit'
        },
        h4: {
            fontSize: '1.2rem',
            fontFamily: 'inherit',
            fontWeight: 700,
        },
        h5: {
            fontSize: '1rem',
            fontWeight: 700,
            fontFamily: 'inherit'
        },
        h6: {
            fontSize: '0.8rem',
            fontFamily: 'inherit'
        },
        body1: {
            fontSize: '0.85rem',
            fontFamily: 'inherit',
            lineHeight: 1.2
        },
        body2: {
            fontSize: '0.8rem',
            fontFamily: 'inherit',
            lineHeight: 1.2
        },
        button: {
            textTransform: 'capitalize'
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                outlined: {
                    borderColor: themeData.palette.info.light,
                    color: themeData.palette.primary.dark,
                    "&:hover": {
                        borderColor: themeData.palette.info.light,
                        backgroundColor: themeData.palette.common.white,
                    }
                },
                outlinedPrimary: {
                    borderColor: themeData.palette.info.light,
                },
                contained: {
                    "&:hover": {
                        backgroundColor: themeData.palette.secondary.main,
                    }
                },
                containedPrimary: {
                    backgroundColor: themeData.palette.primary.main,
                },
                text: {
                    color: themeData.palette.primary.main,
                },
                textPrimary: {
                    color: themeData.palette.primary.main,
                }
            }
        },
        MuiButtonContained: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: themeData.palette.primary.main
                    },
                    "&:focus": {
                        outline: 'none'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontSize: '0.85rem',
                    color: themeData.palette.info.main,
                    "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: `1px solid ${themeData.palette.secondary.light}`,
                        },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${themeData.palette.secondary.light}`,
                    },
                    "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: `1px solid ${themeData.palette.info.light}`,
                        },
                    }
                },
            }
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "#000"
                }
            }
        }
    }
});

export default theme;