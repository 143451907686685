import React, { FormEvent, useEffect, useState, useRef, Component } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  styled,
  Input
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slider from "@mui/material/Slider";

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    margin: theme.spacing(1, 0),
    "& .MuiRadio-root": {
        padding: theme.spacing(0.5),
    },
    "& .MuiTypography-root": {
        fontSize: "0.8rem",
    },
}));

const SearchBarComponent = (props: any) => {
  const [formValues, setFormValues] = useState<any>({});
  const [rangeValue, setRangeValue] = useState<number[]>(props.range);
  const [minDistance, setMinDistance] = useState<number>(props.minDistance);

  const [filters, setFilters] = useState<any>({});
  const [showBasicPriceFilter, setShowBasicPriceFilter] = useState<any>(false); 
  const [priceSearch, setPriceSearch] = useState<any>("price"); 


  const handleRangeChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setRangeValue([
        Math.min(newValue[0], rangeValue[1] - minDistance),
        rangeValue[1],
      ]);
    } else {
      setRangeValue([
        rangeValue[0],
        Math.max(newValue[1], rangeValue[0] + minDistance),
      ]);
    }
    //handleOnChange(event);
  };

  const handleChangeComplete = (event: any) => {
    formValues["price"] = rangeValue;
    setFormValues(formValues);
    setTimeout(() => {applyFilters(event);}, 100);
  }

  const applyFilters = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.handleFilterApply({ event: "submit", form: formValues });
  };

  const handleOnChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    formValues[name] = (type === "checkbox") ? checked : value;
    setFormValues(formValues);
    setTimeout(() => {applyFilters(event);}, 100);
  };


  const setRightRange = (event: any) => {
      let val = event.target.value === '' ? 0 : Number(event.target.value);
      setRangeValue([
          rangeValue[0],
          val,
        ]);
      if (event.keyCode == 13) {
        handleChangeComplete(event);
      }
  };

  const setRightRangeEnter = (event: any) => {
    if (event.keyCode == 13) {
      handleChangeComplete(event);
    }
  }
  
  const setLeftRange = (event: any) => {
    let val = event.target.value === '' ? 0 : Number(event.target.value);
    setRangeValue([
        Math.min(val, rangeValue[1] - minDistance),rangeValue[1],
      ]);
    if (event.keyCode == 13) {
      handleChangeComplete(event);
    }
  };

  const setLeftRangeEnter = (event: any) => {
    if (event.keyCode == 13) {
      handleChangeComplete(event);
    }
  }

  const getDefaultValue = (filter: any) => {
    if (
        filter.type === "autocomplete" &&
      props.appliedFilters.hasOwnProperty(filter.name)
    ) {
      const selectedValue = props.appliedFilters[filter.name];
      let selectedOption = "";
      filter.options.map((option: any) => {
        if (option.id === selectedValue) {
          selectedOption = option;
          return selectedOption;
        }
      });
      return selectedOption;
    }
    if (props.appliedFilters.hasOwnProperty(filter.name)) {
      return props.appliedFilters[filter.name];
    }
    if (filter.type === "slider") {
      return filter.range;
    }
    return "";
  };

  const setPriceType = (() => {
    formValues["price_type"] = "price";
    setFormValues(formValues);
    setPriceSearch("price");
    props.updatePriceFilterType("price");
  }); 

  const setBasicPriceType = (() => {
    formValues["price_type"] = "basic";
    setFormValues(formValues);
    setPriceSearch("basic");
    props.updatePriceFilterType("basic");
  }); 

  useEffect(() => {
    
    Object.entries(props.filters).map(([name, filter]: any, index: number) => {
      setRangeValue(props.range);

      if (props.appliedFilters.hasOwnProperty(name)) {
        setFormValues((prev: any) => ({
          ...prev,
          [name]: props.appliedFilters[name],
        }));

        if (name === "price") {
          setRangeValue(props.appliedFilters[name]);
        }
      } else {
        setFormValues((prev: any) => ({
          ...prev,
          [name]: "",
        }));
      }
    });

    setFilters({});
    setTimeout(() => {setFilters(props.filters);}, 10);
  }, [props.appliedFilters]);

  useEffect(() => {
    let newFilters = props.filters;
    newFilters["model"].mykey = Math.random();
    setFilters({});
    setFilters(newFilters);
  }, [props.selectOptions]);


  useEffect(() => {
    setRangeValue(props.range);
    let divVal = props.range[1]/100;
    let stepVal = (divVal >= 100) ? 100 : 10;
    setMinDistance(stepVal);

  }, [props.range]);

  useEffect(() => {
    let basicPriceCategory = false;
    let formVal:any = [];
    props.category.subCategories.map((subcategory:any) => {
        if(subcategory.id == props.subCategoryId && (subcategory.name.toLowerCase() == 'chairs' || subcategory.name.toLowerCase() == 'carpet')) {
            basicPriceCategory = true;
        }
    });
    setShowBasicPriceFilter(basicPriceCategory);
    setPriceSearch("price");
    formVal["price_type"] = "price";
    setFormValues(formVal);
  }, [props.subCategoryId]);

  return (
    <Box sx={{ width: { xs: "100%" }, flexDirection:'column'}} role="presentation">
      <Box sx={{ px: 4, pt: 2 }}>
        <Box component={"form"} sx={{width: "100%", display: "flex"}} onSubmit={applyFilters}>
          {Object.keys(filters).length > 0 &&
            Object.entries(props.filters).map(
              ([name, filter]: any, index: number) => {
                return (
                  <Box sx={{ pt: 1, width: "100%", minWidth: "200px", margin: "0 20px"}} key={"filter-" + index} >
                    {filter.element == "input" &&
                      (filter.type == "text" || filter.type == "number") && (
                        <>
                          <InputLabel sx={{ pb: 1, fontSize: "0.8rem" }}>
                            {filter.label}
                          </InputLabel>
                          <TextField
                            fullWidth
                            autoComplete={"off"}
                            id={filter.name}
                            type={filter.type}
                            onWheel={(e: any) =>
                                filter.type === "number"
                                ? e.target.blur()
                                : ""
                            }
                            size={"small"}
                            variant="outlined"
                            placeholder={filter.placeholder}
                            className={"inputBox"}
                            name={filter.name}
                            onChange={handleOnChange}
                            defaultValue={getDefaultValue(filter)}
                            InputProps={{
                              style: {
                                borderRadius: "5px",
                                borderColor: "primary.light",
                                fontSize: "0.8rem",
                              },
                            }}
                          />
                        </>
                      )}
                    {filter.element === "select" &&  (
                      <>
                        <InputLabel sx={{ pb: 1, fontSize: "0.8rem" }}>
                          {filter.label}
                        </InputLabel>
                        <Select
                          id={filter.name}
                          fullWidth
                          displayEmpty
                          disabled={(props.selectOptions[filter.name]?.length > 0) ? false : true}
                          labelId={`select-label-${filter.name}`}
                          name={filter.name}
                          onChange={handleOnChange}
                          defaultValue={getDefaultValue(filter)}
                          style={{ height: "40px" }}
                          MenuProps={{
                            style: { zIndex: 99999 },
                          }}
                        >
                          <MenuItem disabled value="">
                            <em style={{ color: "#a9a9a9" }}>
                              {filter.placeholder}
                            </em>
                          </MenuItem>
                          {Array.isArray(props.selectOptions[filter.name]) &&
                            props.selectOptions[filter.name].map(
                              (option: any, index: any) => (
                                <MenuItem
                                  key={`select-option-${index}`}
                                  value={option.id}
                                >
                                  {option.label}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </>
                    )}
                    {filter.element === "radio" && (
                      <FormControl component="fieldset">
                        <FormLabel component="legend" sx={{ fontSize: "0.8rem", marginBottom: 1 }}>
                          {filter.label}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label={filter.label}
                          name={filter.name}
                          value={formValues[filter.name] || ""}
                          onChange={handleOnChange}
                        >
                          {Array.isArray(filter.options) &&
                            filter.options.map(
                              (option: any, index: any) => (
                                <CustomFormControlLabel
                                  key={`radio-option-${index}`}
                                  value={option.id}
                                  control={<Radio />}
                                  label={option.label}
                                />
                              )
                            )}
                        </RadioGroup>
                      </FormControl>
                    )}
                    {filter.element === "checkbox" && (
                      <Box sx={{ pt: 1, display: "flex" }} key={"filter-" + index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formValues[name] || false}
                              onChange={handleOnChange}
                              name={name}
                            />
                          }
                          label={filter.label}
                        />
                      </Box>
                    )}
                    {filter.element === "slider" && (
                      <Box
                        sx={{ pt: 1, display: "flex", flexDirection: "column", paddingTop: '0px' }}
                        key={"filter-" + index}
                      >
                        {!showBasicPriceFilter && <InputLabel sx={{ pb: 1, fontSize: "0.8rem", paddingTop: '0px' }}>
                          {filter.label}
                        </InputLabel>}
                        {showBasicPriceFilter && <InputLabel sx={{ pb: 1, fontSize: "0.8rem", paddingTop: '0px' }}>
                          <><Box className={(priceSearch === "price") ? "price-search-selected" : ""} sx={{display: "inline-block",  cursor: 'pointer'}} onClick={setPriceType}>{filter.label}</Box> / <Box className={(priceSearch === "basic") ? "price-search-selected" : ""} sx={{display: "inline-block", cursor: 'pointer'}} onClick={setBasicPriceType}>{"Basic Price"}</Box></>
                        </InputLabel>}
                        <Slider
                          id={filter.name}
                          sx={{ mx: 1, width: "96%", marginLeft: '3px', padding: '9px 0' }}
                          getAriaLabel={() => "Minimum distance shift"}
                          value={rangeValue}
                          onChange={handleRangeChange}
                          onChangeCommitted={handleChangeComplete}
                          valueLabelDisplay="auto"
                          disableSwap
                          size="small"
                          name={filter.name}
                          min={0}
                          max={props.range[1]}
                          step={minDistance}
                        />
                        {true && <Box sx={{display: "flex"}}>
                          <Input
                            value={rangeValue[0]}
                            size="small"
                            onBlur={setLeftRange}
                            onChange={setLeftRange}
                            onKeyDown={setLeftRangeEnter}
                            sx={{width:"50px", flexBasis: "25%", marginRight: "62%", 
                              textAlign: "center", fontSize: '9px',
                              '.MuiInput-input': {
                                textAlign: 'center'
                              },
                            }}
                            inputProps={{
                              step: 100,
                              min: 0,
                              max: 500000,
                              type: 'number',
                              'aria-labelledby': 'input-slider',
                            }}
                          />
                          <Input
                            value={rangeValue[1]}
                            size="small"
                            onBlur={setRightRange}
                            onChange={setRightRange}
                            onKeyDown={setRightRangeEnter}
                            sx={{width:"50px", flexBasis: "25%", 
                              textAlign: "center", fontSize: '9px',
                              '.MuiInput-input': {
                                textAlign: 'center'
                              },
                            }}
                            inputProps={{
                              step: 100,
                              min: 0,
                              max: 500000,
                              type: 'number',
                              'aria-labelledby': 'input-slider',
                            }}
                          />
                          {false && <Button variant={"contained"}  sx={{color:'common.white', textTransform:'none', width: '30%', height: "19px", borderRadius:"5px", fontSize: "10px", marginLeft: '10%', padding: "0"}}>Apply</Button>}
                        </Box>}
                      </Box>
                    )}
                  </Box>
                );
              }
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default SearchBarComponent;
