import {Box, Divider, IconButton, Tooltip, Typography} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const skuPageConfig = {
    "vendorColumns": [
        {
            field: 'imageWithName',
            headerName: 'Sku Name',
            headerClassName: 'data-grid-header',
            flex: 1.5,
            sortable: false,
            key: 'url',
            type: 'imageWithName'
        },
        {field: 'description', headerName: 'Description', headerClassName: 'data-grid-header', flex: 1,},
        {
            field: "specs",
            headerName: "Specifications",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            maxHeight: '100px',
                            overflowY: 'auto',
                            whiteSpace: 'normal',
                            display: 'block',
                        }}
                    >
                        {params.row.options && params.row.options.length > 0 ? (
                            params.row.options.map((option: any, idx: number) => {
                                return (
                                    <Typography
                                        key={idx}
                                        variant={"body2"}
                                        sx={{
                                            fontSize: "0.8rem",
                                            padding: '0.4rem',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        <strong>{option.name}:</strong> {option.value}
                                    </Typography>
                                );
                            })
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: "0.8rem",
                                    padding: '0.4rem',
                                    wordWrap: 'break-word',
                                }}
                            >
                                NA
                            </Typography>
                        )}
                    </Box>
                );
            }

        },
        {field: 'code', headerName: 'SKU Code', headerClassName: 'data-grid-header', flex: 1,},
        {field: 'uom', headerName: 'UOM', headerClassName: 'data-grid-header', flex: 0.5,},
        {field: 'status', type: 'status', headerName: 'Active?', headerClassName: 'data-grid-header', flex: 0.6,},
        {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'data-grid-header',
            flex: 0.8,
            show: 'button',
            sortable: false,
            buttons: [
                {
                    type: 'anchor',
                    title: 'View Details',
                    permissions: ['view-skus', 'edit-skus', "vendor-admin"],
                    condition: (row: any) => true
                },
            ]
        }
    ],
    "brandColumns": [
        {
            field: 'imageWithName',
            headerName: 'Sku Name',
            headerClassName: 'data-grid-header',
            flex: 1.5,
            sortable: false,
            key: 'url',
            type: 'imageWithName'
        },
        {field: 'description', headerName: 'Description', headerClassName: 'data-grid-header', flex: 1,},
        {
            field: "specs",
            headerName: "Specifications",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            maxHeight: '100px',
                            overflowY: 'auto',
                            whiteSpace: 'normal',
                            display: 'block',
                        }}
                    >
                        {params.row.options && params.row.options.length > 0 ? (
                            params.row.options.map((option: any, idx: number) => {
                                return (
                                    <Typography
                                        key={idx}
                                        variant={"body2"}
                                        sx={{
                                            fontSize: "0.8rem",
                                            padding: '0.4rem',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        <strong>{option.name}:</strong> {option.value}
                                    </Typography>
                                );
                            })
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: "0.8rem",
                                    padding: '0.4rem',
                                    wordWrap: 'break-word',
                                }}
                            >
                                NA
                            </Typography>
                        )}
                    </Box>
                );
            }
        },
        {field: 'code', headerName: 'SKU Code', headerClassName: 'data-grid-header', flex: 1,},
        {field: 'uom', headerName: 'UOM', headerClassName: 'data-grid-header', flex: 0.5,},
        {field: 'status', type: 'status', headerName: 'Active?', headerClassName: 'data-grid-header', flex: 0.6,},
        {
            field: 'totalBrandRc',
            headerName: 'RC Brand',
            headerClassName: 'data-grid-header',
            flex: 0.6,
            sortable: true,
            renderCell: (params: any) => {
                const entityDetails = params.row.entityDetails;
                return (
                    <>
                        <Typography sx={{pr: 1}}>{params.row?.totalBrandRc}</Typography>
                        {entityDetails != null &&
                            <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'common.white',
                                            boxShadow: 1,
                                            '& .MuiTooltip-arrow': {
                                                color: 'common.white',
                                                "&::before": {
                                                    backgroundColor: "common.white",
                                                    boxShadow: 1,
                                                }
                                            },
                                        },
                                    },
                                }}
                                title={
                                    <Box sx={{px: 1, py: 1}}>
                                        {entityDetails?.brands.length > 0 &&
                                            <Box sx={{py: 1}}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black',
                                                        pb: 0.5
                                                    }}>Brands</Typography>
                                                {entityDetails?.brands.map((brand: any) => {
                                                    return (
                                                        <Typography sx={{py: 0.4, color: 'common.black', pr: 5}}
                                                                    key={"brand-info-tool-" + brand.id}>
                                                            {brand.name}
                                                        </Typography>
                                                    )
                                                })}
                                            </Box>
                                        }
                                    </Box>
                                } arrow>
                                <IconButton
                                    sx={{
                                        p: 0,
                                    }}>
                                    <InfoOutlinedIcon sx={{fontSize: 15}}/>
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                )
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'data-grid-header',
            flex: 0.8,
            show: 'button',
            sortable: false,
            buttons: [
                {
                    type: 'anchor',
                    title: 'View Details',
                    permissions: ['view-skus', 'edit-skus'],
                    condition: (row: any) => true
                },
            ]
        }
    ],
    "adminColumns": [
        {
            field: 'imageWithName',
            headerName: 'Sku Name',
            headerClassName: 'data-grid-header',
            flex: 1.5,
            sortable: false,
            key: 'url',
            type: 'imageWithName'
        },
        {field: 'description', headerName: 'Description', headerClassName: 'data-grid-header', flex: 1,},
        {
            field: "specs",
            headerName: "Specifications",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            maxHeight: '100px',
                            overflowY: 'auto',
                            whiteSpace: 'normal',
                            display: 'block',
                        }}
                    >
                        {params.row.options && params.row.options.length > 0 ? (
                            params.row.options.map((option: any, idx: number) => {
                                return (
                                    <Typography
                                        key={idx}
                                        variant={"body2"}
                                        sx={{
                                            fontSize: "0.8rem",
                                            padding: '0.4rem',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        <strong>{option.name}:</strong> {option.value}
                                    </Typography>
                                );
                            })
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: "0.8rem",
                                    padding: '0.4rem',
                                    wordWrap: 'break-word',
                                }}
                            >
                                NA
                            </Typography>
                        )}
                    </Box>
                );
            }
        },
        {field: 'code', headerName: 'SKU Code', headerClassName: 'data-grid-header', flex: 1,},
        {field: 'uom', headerName: 'UOM', headerClassName: 'data-grid-header', flex: 0.5,},
        {field: 'cogsPrice', headerName: 'COGS', headerClassName: 'data-grid-header', flex: 0.5,},
        {field: 'status', type: 'status', headerName: 'Active?', headerClassName: 'data-grid-header', flex: 0.6,},
        {
            field: 'totalRc', headerName: 'RC', headerClassName: 'data-grid-header', flex: 0.6, sortable: true,
            renderCell: (params: any) => {
                const entityDetails = params.row.entityDetails;
                return (
                    <>
                        <Typography sx={{pr: 1}}>{params.row?.totalRc}</Typography>
                        {entityDetails != null &&
                            <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'common.white',
                                            boxShadow: 1,
                                            '& .MuiTooltip-arrow': {
                                                color: 'common.white',
                                                "&::before": {
                                                    backgroundColor: "common.white",
                                                    boxShadow: 1,
                                                }
                                            },
                                        },
                                    },
                                }}
                                title={
                                    <Box sx={{px: 1, py: 1}}>
                                        {entityDetails?.brands.length > 0 &&
                                            <Box sx={{py: 1}}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black',
                                                        pb: 0.5
                                                    }}>Brands</Typography>
                                                {entityDetails?.brands.map((brand: any) => {
                                                    return (
                                                        <Typography sx={{py: 0.4, color: 'common.black', pr: 5}}
                                                                    key={"brand-info-tool-" + brand.id}>
                                                            {brand.name}
                                                        </Typography>
                                                    )
                                                })}
                                            </Box>
                                        }
                                        {(entityDetails?.brands.length > 0 && entityDetails?.vendors.length > 0) &&
                                            <Divider/>
                                        }
                                        {entityDetails?.vendors.length > 0 &&
                                            <Box sx={{py: 1}}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black',
                                                        pb: 0.5
                                                    }}>Vendors</Typography>
                                                {entityDetails.vendors.map((vendor: any) => {
                                                    return (
                                                        <Typography sx={{py: 0.4, color: 'common.black', pr: 5}}
                                                                    key={"vendor-info-tool-" + vendor.id}>
                                                            {vendor.name}
                                                        </Typography>
                                                    )
                                                })}
                                            </Box>
                                        }
                                    </Box>
                                } arrow>
                                <IconButton
                                    sx={{
                                        p: 0,
                                    }}>
                                    <InfoOutlinedIcon sx={{fontSize: 15}}/>
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                )
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'data-grid-header',
            flex: 0.8,
            show: 'button',
            sortable: false,
            buttons: [
                {
                    type: 'anchor',
                    title: 'View Details',
                    permissions: ['view-skus', 'edit-skus'],
                    condition: (row: any) => true
                },
            ]
        }
    ],
    "columns": [
        {
            field: 'imageWithName',
            headerName: 'Sku Name',
            headerClassName: 'data-grid-header',
            flex: 1.5,
            sortable: false,
            key: 'url',
            type: 'imageWithName'
        },
        {field: 'description', headerName: 'Description', headerClassName: 'data-grid-header', flex: 1,},
        {
            field: "specs",
            headerName: "Specifications",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            maxHeight: '100px',
                            overflowY: 'auto',
                            whiteSpace: 'normal',
                            display: 'block',
                        }}
                    >
                        {params.row.options && params.row.options.length > 0 ? (
                            params.row.options.map((option: any, idx: number) => {
                                return (
                                    <Typography
                                        key={idx}
                                        variant={"body2"}
                                        sx={{
                                            fontSize: "0.8rem",
                                            padding: '0.4rem',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        <strong>{option.name}:</strong> {option.value}
                                    </Typography>
                                );
                            })
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: "0.8rem",
                                    padding: '0.4rem',
                                    wordWrap: 'break-word',
                                }}
                            >
                                NA
                            </Typography>
                        )}
                    </Box>
                );
            }

        },
        {field: 'code', headerName: 'SKU Code', headerClassName: 'data-grid-header', flex: 1,},
        {field: 'uom', headerName: 'UOM', headerClassName: 'data-grid-header', flex: 0.5,},
        {field: 'status', type: 'status', headerName: 'Active?', headerClassName: 'data-grid-header', flex: 0.6,},
        {
            field: 'totalRc', headerName: 'RC', headerClassName: 'data-grid-header', flex: 0.6, sortable: true,
            renderCell: (params: any) => {
                const entityDetails = params.row.entityDetails;
                return (
                    <>
                        <Typography sx={{pr: 1}}>{params.row?.totalRc}</Typography>
                        {entityDetails != null &&
                            <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'common.white',
                                            boxShadow: 1,
                                            '& .MuiTooltip-arrow': {
                                                color: 'common.white',
                                                "&::before": {
                                                    backgroundColor: "common.white",
                                                    boxShadow: 1,
                                                }
                                            },
                                        },
                                    },
                                }}
                                title={
                                    <Box sx={{px: 1, py: 1}}>
                                        {entityDetails?.brands.length > 0 &&
                                            <Box sx={{py: 1}}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black',
                                                        pb: 0.5
                                                    }}>Brands</Typography>
                                                {entityDetails?.brands.map((brand: any) => {
                                                    return (
                                                        <Typography sx={{py: 0.4, color: 'common.black', pr: 5}}
                                                                    key={"brand-info-tool-" + brand.id}>
                                                            {brand.name}
                                                        </Typography>
                                                    )
                                                })}
                                            </Box>
                                        }
                                        {(entityDetails?.brands.length > 0 && entityDetails?.vendors.length > 0) &&
                                            <Divider/>
                                        }
                                        {entityDetails?.vendors.length > 0 &&
                                            <Box sx={{py: 1}}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black',
                                                        pb: 0.5
                                                    }}>Vendors</Typography>
                                                {entityDetails.vendors.map((vendor: any) => {
                                                    return (
                                                        <Typography sx={{py: 0.4, color: 'common.black', pr: 5}}
                                                                    key={"vendor-info-tool-" + vendor.id}>
                                                            {vendor.name}
                                                        </Typography>
                                                    )
                                                })}
                                            </Box>
                                        }
                                    </Box>
                                } arrow>
                                <IconButton
                                    sx={{
                                        p: 0,
                                    }}>
                                    <InfoOutlinedIcon sx={{fontSize: 15}}/>
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                )
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'data-grid-header',
            flex: 0.8,
            show: 'button',
            sortable: false,
            buttons: [
                {
                    type: 'anchor',
                    title: 'View Details',
                    permissions: ['view-skus', 'edit-skus'],
                    condition: (row: any) => true
                },
            ]
        }
    ]
};

export const vendorSkuPageConfig = {
    columns: [
        {
            field: 'imageWithName',
            headerName: 'Sku Name',
            headerClassName: 'data-grid-header',
            flex: 1.5,
            sortable: false,
            key: 'url',
            type: 'imageWithName'
        },
        {field: 'description', headerName: 'Description', headerClassName: 'data-grid-header', flex: 1,},
        {
            field: "specs",
            headerName: "Specifications",
            sortable: false,
            headerClassName: "data-grid-header",
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            maxHeight: '100px',
                            overflowY: 'auto',
                            whiteSpace: 'normal',
                            display: 'block',
                        }}
                    >
                        {params.row.options && params.row.options.length > 0 ? (
                            params.row.options.map((option: any, idx: number) => {
                                return (
                                    <Typography
                                        key={idx}
                                        variant={"body2"}
                                        sx={{
                                            fontSize: "0.8rem",
                                            padding: '0.4rem',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        <strong>{option.name}:</strong> {option.value}
                                    </Typography>
                                );
                            })
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: "0.8rem",
                                    padding: '0.4rem',
                                    wordWrap: 'break-word',
                                }}
                            >
                                NA
                            </Typography>
                        )}
                    </Box>
                );
            }

        },
        {field: 'code', headerName: 'SKU Code', headerClassName: 'data-grid-header', flex: 1,},
        {field: 'uom', headerName: 'UOM', headerClassName: 'data-grid-header', flex: 0.5,},
        {field: 'status', type: 'status', headerName: 'Active?', headerClassName: 'data-grid-header', flex: 0.6,},
        {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'data-grid-header',
            flex: 0.8,
            show: 'button',
            sortable: false,
            buttons: [
                {
                    type: 'anchor',
                    title: 'View Details',
                    permissions: ['view-skus', 'edit-skus'],
                    condition: (row: any) => true
                },
            ]
        }
    ]
};