import {createAsyncThunk} from "@reduxjs/toolkit";
import {api} from "../../../../../../utils/Axios";

interface ApiResponse {
    // Define the properties of the API response here
}

export const handleRemoveItem = createAsyncThunk(
    "estimation/remove/item",
    async ({
               revisionId,
               materialSubCategoryId,
               itemId,
           }: {
        revisionId: string;
        materialSubCategoryId: string;
        itemId: string;
    }) => {
        try {
            const response = await api.get(
                `/procurement/estimation/remove/${revisionId}/${materialSubCategoryId}/${itemId}`
            );
            return response.data as ApiResponse;
        } catch (error: any) {
            throw new Error(error.message || "Failed to remove item from estimation");
        }
    }
);

export const handleRemoveItemFromECatalog = createAsyncThunk(
    "e-catalog/remove/item",
    async ({
             projectId,
             materialSubCategoryId,
             itemId,
           }: {
      projectId: string;
      materialSubCategoryId: string;
      itemId: string;
    }) => {
      try {
        const response = await api.get(
            `/procurement/e-catalog/remove/${projectId}/${materialSubCategoryId}/${itemId}`
        );
        return response.data as ApiResponse;
      } catch (error: any) {
        throw new Error(error.message || "Failed to remove item from e-catalog");
      }
    }
);

