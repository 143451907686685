import {SyntheticEvent, useEffect, useState} from "react";
import CategoryTab from "../../../components/tabs/CategoryTab";
import SubCategoryTab from "../../../components/tabs/SubCategoryTab";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import {a11yProps, CustomTabPanel} from "../../../utils/TabsUtils";
import {loadItem, loadItemCategories} from "../../project/features/actions";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import ItemSkuListing from "../../../components/skus/listing/ItemSkuListing";
import SearchFilter from "../../../components/filter/SearchFilter";

const RcCatalogTab = (props: any) => {
    const [selectedCategory, setSelectedCategory] = useState<any>({});
    const [categoryTabValue, setCategoryTabValue] = useState("");
    const [subCategoryTabValue, setSubCategoryTabValue] = useState("");
    const [itemCategoryTabValue, setItemCategoryTabValue] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredItemCategories, setFilteredItemCategories] = useState([]);
    const itemCategoriesData: any = useAppSelector((state) => state.categories.itemCategories);
    const itemsData = useAppSelector((state: any) => state.categories.items);
    const dispatch = useAppDispatch();

    const handleSearchFilter = (query: any) => {
        const queryString = typeof query === 'string' ? query : '';
        setSearchQuery(queryString);
        if (!queryString) {
            setFilteredItemCategories(itemCategoriesData);
            setItemCategoryTabValue(itemCategoriesData[0]?.id || "");
            return;
        }
        const filtered = itemCategoriesData.filter((category: any) =>
            category.name.toLowerCase().includes(queryString?.toLowerCase())
        );
        setFilteredItemCategories(filtered);
        setItemCategoryTabValue(filtered.length > 0 ? filtered[0]?.id || "" : itemCategoriesData[0]?.id || "");
    };

    const handleCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setCategoryTabValue(newValue);
    };

    const handleSubCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setSubCategoryTabValue(newValue);
    };

    const handleItemCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setItemCategoryTabValue(newValue);
    };

    useEffect(() => {
        if (categoryTabValue !== "") {
            props.categories.forEach((category: any) => {
                if (category.id === categoryTabValue) {
                    setSelectedCategory(category);
                    setSubCategoryTabValue(category.subCategories[0]?.id || "");
                }
            });
        }
    }, [categoryTabValue, props.categories]);

    useEffect(() => {
        const fetchItemCategories = async () => {
            if (subCategoryTabValue !== "") {
                dispatch(loadItemCategories(subCategoryTabValue));
            }
        };

        fetchItemCategories();
    }, [subCategoryTabValue]);

    useEffect(() => {
        const fetchItems = async () => {
            if (itemCategoryTabValue !== "") {
                dispatch(loadItem(itemCategoryTabValue));
            }
        };
        fetchItems();
    }, [itemCategoryTabValue]);

    useEffect(() => {
        if (itemCategoriesData && itemCategoriesData.length > 0) {
            setItemCategoryTabValue(itemCategoriesData[0].id);
            setFilteredItemCategories(itemCategoriesData);
        }
    }, [itemCategoriesData]);

    useEffect(() => {
        setCategoryTabValue(props.categories[0].id);
    }, []);

    return (
        <>
            {categoryTabValue !== "" && (
                <CategoryTab
                    showCount={true}
                    includeSummaryTab={false}
                    categories={props.categories}
                    categoryTabValue={categoryTabValue}
                    handleCategoryTabChange={handleCategoryTabChange}
                />
            )}
            {selectedCategory.hasOwnProperty("id") && categoryTabValue !== "summary" && (
                <SubCategoryTab
                    showCount={true}
                    subCategoryTabValue={subCategoryTabValue}
                    subCategories={selectedCategory?.subCategories}
                    categoryTabValue={categoryTabValue}
                    handleSubCategoryTabChange={handleSubCategoryTabChange}
                />
            )}
            <Box sx={{display: "flex"}}>
                <CustomTabPanel width={"15%"} value={subCategoryTabValue} index={subCategoryTabValue}>
                    <Box sx={{pr: 2, borderRight: 1, borderColor: "divider"}}>
                        <Box mt={2}>
                            <SearchFilter query={searchQuery} handleSearchFilter={handleSearchFilter}
                                          showSearch={true}/>
                        </Box>
                        <Box mt={2}>
                            <Typography sx={{py: 3, px: 2, fontWeight: 700}}>
                                Item Category
                            </Typography>
                        </Box>
                        <Tabs
                            orientation={"vertical"}
                            variant={"scrollable"}
                            value={itemCategoryTabValue}
                            onChange={handleItemCategoryTabChange}
                            aria-label="Item Category Tabs"
                            className="tab-sidebar"
                        >
                            {filteredItemCategories && filteredItemCategories.length > 0 ? (
                                filteredItemCategories.map((itemCategory: any, itemCategoryIndex: number) => (
                                    <Tab
                                        sx={{
                                            alignItems: "flex-start",
                                            minHeight: "35px",
                                            borderRadius: 1,
                                            color: "info.main",
                                            '&.Mui-selected': {
                                                color: "info.main",
                                                fontWeight: 700,
                                            },
                                        }}
                                        key={"item-category-tab-" + itemCategoryIndex}
                                        value={itemCategory.id}
                                        {...a11yProps(itemCategory.id, false)}
                                        label={
                                            <Typography className="tab-sidebar-label">
                                                {itemCategory.name}&nbsp;
                                                <Typography component={"sup"}
                                                            sx={{fontSize: '10px'}}>({itemCategory.totalSkus})</Typography>
                                            </Typography>
                                        }
                                        wrapped
                                        className="tab-sidebar-item"
                                    />
                                ))
                            ) : (
                                itemCategoriesData && itemCategoriesData.length > 0 && itemCategoriesData.map((itemCategory: any, itemCategoryIndex: number) => (
                                    <Tab
                                        sx={{
                                            alignItems: "flex-start",
                                            minHeight: "35px",
                                            borderRadius: 1,
                                            color: "info.main",
                                            '&.Mui-selected': {
                                                color: "info.main",
                                                fontWeight: 700,
                                            },
                                        }}
                                        key={"item-category-tab-" + itemCategoryIndex}
                                        value={itemCategory.id}
                                        {...a11yProps(itemCategory.id, false)}
                                        label={
                                            <Typography className="tab-sidebar-label">
                                                {itemCategory.name}
                                            </Typography>
                                        }
                                        wrapped
                                        className="tab-sidebar-item"
                                    />
                                ))
                            )}
                        </Tabs>
                    </Box>
                </CustomTabPanel>

                {itemCategoryTabValue !== "" && (
                    <CustomTabPanel width={"85%"} value={itemCategoryTabValue} index={itemCategoryTabValue}>
                        <Box sx={{p: 2}}>
                            {itemsData &&
                                itemsData.map((item: any, itemIndex: number) => {
                                    return (
                                        <Box key={"item-block-" + itemIndex}>
                                            {item?.id ? (
                                                <>
                                                    <ItemSkuListing type={"all"} item={item}/>
                                                </>
                                            ) : (
                                                <Box className={"center-aligned"}>
                                                    <Typography>No Items and SKUs found</Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    );
                                })}
                        </Box>
                    </CustomTabPanel>
                )}
            </Box>
        </>
    );
}

export default RcCatalogTab;
