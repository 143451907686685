import React, { FormEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slider from "@mui/material/Slider";

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    margin: theme.spacing(1, 0),
    "& .MuiRadio-root": {
        padding: theme.spacing(0.5),
    },
    "& .MuiTypography-root": {
        fontSize: "0.8rem",
    },
}));

const FilterDrawer = (props: any) => {
  const [formValues, setFormValues] = useState<any>({});
  const [rangeValue, setRangeValue] = useState<number[]>(props.range);
  const minDistance = props.minDistance;
  const handleRangeChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setRangeValue([
        Math.min(newValue[0], rangeValue[1] - minDistance),
        rangeValue[1],
      ]);
    } else {
      setRangeValue([
        rangeValue[0],
        Math.max(newValue[1], rangeValue[0] + minDistance),
      ]);
    }
    handleOnChange(event);
  };

  const applyFilters = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.handleFilterApply({ event: "submit", form: formValues });
  };
  const handleOnChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prev: any) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const getDefaultValue = (filter: any) => {
    if (
        filter.type === "autocomplete" &&
      props.appliedFilters.hasOwnProperty(filter.name)
    ) {
      const selectedValue = props.appliedFilters[filter.name];
      let selectedOption = "";
      filter.options.map((option: any) => {
        if (option.id === selectedValue) {
          selectedOption = option;
          return selectedOption;
        }
      });
      return selectedOption;
    }
    if (props.appliedFilters.hasOwnProperty(filter.name)) {
      return props.appliedFilters[filter.name];
    }
    if (filter.type === "slider") {
      return filter.range;
    }
    return "";
  };

  useEffect(() => {
    Object.entries(props.filters).map(([name, filter]: any, index: number) => {
      if (props.appliedFilters.hasOwnProperty(name)) {
        setFormValues((prev: any) => ({
          ...prev,
          [name]: props.appliedFilters[name],
        }));
        if (name === "price") {
          setRangeValue(props.appliedFilters[name]);
        }
      }
    });
  }, [props.appliedFilters]);
  return (
    <Box sx={{ width: { xs: "100%", md: 300 } }} role="presentation">
      <Box sx={{ px: 4 }}>
        <Grid container>
          <Grid item xs={10}>
            <Box sx={{ pt: 5, pb: 1 }}>
              <Typography variant={"h4"}>Filters</Typography>{" "}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={{ pt: 5, pb: 2, cursor: "pointer", textAlign: "right" }}>
              <CloseIcon
                onClick={() => props.handleFilterApply({ event: "close" })}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ px: 4, pt: 2 }}>
        <Box component={"form"} onSubmit={applyFilters}>
          {Object.keys(props.filters).length > 0 &&
            Object.entries(props.filters).map(
              ([name, filter]: any, index: number) => {
                return (
                  <Box sx={{ pt: 1 }} key={"filter-" + index}>
                    {filter.element == "input" &&
                      (filter.type == "text" || filter.type == "number") && (
                        <>
                          <InputLabel sx={{ pb: 1, fontSize: "0.8rem" }}>
                            {filter.label}
                          </InputLabel>
                          <TextField
                            fullWidth
                            autoComplete={"off"}
                            id={filter.name}
                            type={filter.type}
                            onWheel={(e: any) =>
                                filter.type === "number"
                                ? e.target.blur()
                                : ""
                            }
                            size={"small"}
                            variant="outlined"
                            placeholder={filter.placeholder}
                            className={"inputBox"}
                            name={filter.name}
                            onChange={handleOnChange}
                            defaultValue={getDefaultValue(filter)}
                            InputProps={{
                              style: {
                                borderRadius: "5px",
                                borderColor: "primary.light",
                                fontSize: "0.8rem",
                              },
                            }}
                          />
                        </>
                      )}
                    {filter.element === "select" && (
                      <>
                        <InputLabel sx={{ pb: 1, fontSize: "0.8rem" }}>
                          {filter.label}
                        </InputLabel>
                        <Select
                          fullWidth
                          displayEmpty
                          labelId={`select-label-${filter.name}`}
                          name={filter.name}
                          onChange={handleOnChange}
                          defaultValue={getDefaultValue(filter)}
                          style={{ height: "40px" }}
                          MenuProps={{
                            style: { zIndex: 99999 },
                          }}
                        >
                          <MenuItem disabled value="">
                            <em style={{ color: "#a9a9a9" }}>
                              {filter.placeholder}
                            </em>
                          </MenuItem>
                          {Array.isArray(filter.options) &&
                            filter.options.map(
                              (option: any, index: any) => (
                                <MenuItem
                                  key={`select-option-${index}`}
                                  value={option.id}
                                >
                                  {option.label}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </>
                    )}
                    {filter.element === "radio" && (
                      <FormControl component="fieldset">
                        <FormLabel component="legend" sx={{ fontSize: "0.8rem", marginBottom: 1 }}>
                          {filter.label}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label={filter.label}
                          name={filter.name}
                          value={formValues[filter.name] || ""}
                          onChange={handleOnChange}
                        >
                          {Array.isArray(filter.options) &&
                            filter.options.map(
                              (option: any, index: any) => (
                                <CustomFormControlLabel
                                  key={`radio-option-${index}`}
                                  value={option.id}
                                  control={<Radio />}
                                  label={option.label}
                                />
                              )
                            )}
                        </RadioGroup>
                      </FormControl>
                    )}
                    {filter.element === "checkbox" && (
                      <Box sx={{ pt: 1, display: "flex" }} key={"filter-" + index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formValues[name] || false}
                              onChange={handleOnChange}
                              name={name}
                            />
                          }
                          label={filter.label}
                        />
                      </Box>
                    )}
                    {filter.element === "slider" && (
                      <Box
                        sx={{ pt: 1, display: "flex", flexDirection: "column" }}
                        key={"filter-" + index}
                      >
                        <InputLabel sx={{ pb: 1, fontSize: "0.8rem" }}>
                          {filter.label}
                        </InputLabel>{" "}
                        <Slider
                          sx={{ mx: 1, width: "96%" }}
                          getAriaLabel={() => "Minimum distance shift"}
                          value={rangeValue}
                          onChange={handleRangeChange}
                          valueLabelDisplay="auto"
                          disableSwap
                          name={filter.name}
                          min={0}
                          max={500000}
                          step={1000}
                        />
                      </Box>
                    )}
                  </Box>
                );
              }
            )}

          <Box sx={{ pt: 4 }}>
            <Button type={"submit"} variant={"contained"} fullWidth>
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterDrawer;
