import {useNavigate, useParams} from "react-router-dom";
import PageHeading from "../../../components/page-heading/PageHeading";
import {Box} from "@mui/material";
import UserList from "../../../components/users/UserList";
import {useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import {api} from "../../../utils/Axios";

const VendorUser = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [vendor, setVendor] = useState<any>({});

    const handleHeaderButtonClick = (data: any) => {
        if (data == 'create') {
            navigate(`/vendors/user/create/${id}`);
        }
        if (data == 'role') {
            navigate("/users/roles");
        }
    }

    const handleCallback = (rowData: any, buttonData: any) => {
        if (buttonData.type == 'edit') {
            navigate(`/vendors/user/edit/${id}/${rowData.id}`);
        }
    }

    const loadBasicVendor = () => {
        setIsLoading(true);
        api.get(`/procurement/vendor/basic/${id}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            setVendor(response);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        loadBasicVendor();
    }, [id]);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Box className={"common-space-container"}>
            <PageHeading
                title={vendor?.name + ": Vendor Users"}
                showButtons={true}
                buttons={[
                    {id: 'create', label: 'Create User', permissions: ["edit-vendors"]},
                ]}
                handleHeaderButtonClick={handleHeaderButtonClick}/>
            <Box>
                <UserList
                    listEndpoint={`/authentication/user/entity-users/vendor/${id}`}
                    handleCallback={handleCallback}
                />
            </Box>
        </Box>
    )
}

export default VendorUser;