import React from 'react';
import './App.css';
import {CssBaseline, Snackbar, ThemeProvider} from '@mui/material';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ProtectedRoutes from './utils/ProtectedRoutes';
import Login from './modules/login/Login';
import ForgotPassword from './modules/login/ForgotPassword';
import ResetPassword from './modules/login/ResetPassword';
import Oauth2Redirect from './modules/oauth2/Oauth2Redirect';
import theme from './config/Theme';
import NotFound from './modules/not-found/NotFound';
import {useAppDispatch, useAppSelector} from './app/hooks';
import {
    closeSnackbar,
    selectSnackbarColor,
    selectSnackbarMessage,
    selectSnackbarOpen,
} from './components/common/features/snackbarSlice';
import {hasAccess} from "./utils/Access";
import {AppPages} from "./utils/Pages";
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

function App() {
    const dispatch = useAppDispatch();
    const message = useAppSelector(selectSnackbarMessage);
    const open = useAppSelector(selectSnackbarOpen);
    const backgroundColor = useAppSelector(selectSnackbarColor);

    const handleCloseSnackbar = () => {
        dispatch(closeSnackbar());
    };

    const auth = useAppSelector((state) => state.auth.token);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message={message}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    ContentProps={{
                        style: {
                            display: "flex",
                            justifyContent: "center",
                            color: 'white',
                            backgroundColor: backgroundColor
                        },
                    }}
                />
            </div>
            <BrowserRouter>
                <DndProvider backend={HTML5Backend}>
                    <Routes>
                        <Route element={<ProtectedRoutes/>}>
                            {auth !== null && AppPages.map((apiPage: any, index: number) => (
                                apiPage.hasOwnProperty('permissions') ? (
                                    hasAccess(apiPage.permissions) &&
                                    <Route key={'app-page-' + index} path={apiPage.path} element={apiPage.element}/>
                                ) : (
                                    <Route key={'app-page-' + index} path={apiPage.path} element={apiPage.element}/>
                                )
                            ))}
                            <Route path="*" element={<NotFound/>}/>
                        </Route>
                        <Route path={'/login'} element={<Login/>}/>
                        <Route path={'/forgot-password'} element={<ForgotPassword/>}/>
                        <Route path={'/reset-password'} element={<ResetPassword/>}/>
                        <Route path={'/oauth2/redirect'} element={<Oauth2Redirect/>}/>
                    </Routes>
                </DndProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
