import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import { api } from "../../../utils/Axios";
import PageHeading from "../../../components/page-heading/PageHeading";
import { Box, Button } from "@mui/material";
import { eventConfig, packageConfig } from "./config/config";
import PackageAwarded from "./VendorPackageAwarded";
import VendorParticipationEvents from "./VendorParticipationEvents";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getVendorPackageList } from "../features/actions";

const VendorEvent = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, rowChange] = useState([]);
  const [page, pageChange] = useState(0);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState<any>("desc");
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const hideColumns = { id: false };
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const vendorPackageList: any = useAppSelector(
    (state) => state?.vendor?.vendorPackageList
  );

  const handleSort = (sortModel: any) => {
    setSortColumn(sortModel.field);
    setSortDirection(sortModel?.sort);
    pageChange(0);
  };

  const handlePagination = (newPagination: any) => {
    setSize(newPagination.pageSize);
    pageChange(newPagination.page);
  };

  const loadPackages = async () => {
    await dispatch(
      getVendorPackageList({
        pageNo: page,
        pageSize: size,
        sort: sortDirection.toUpperCase(),
        sortByColumn: sortColumn,
        searchQuery: searchQuery
      })
    ).unwrap();
  };

  const loadEvents = () => {
    setIsLoading(true);
    api
      .get("/procurement/event/vendor/list", {
        params: {
          pageNo: page,
          pageSize: size,
          sort: sortDirection?.toUpperCase(),
          sortByColumn: sortColumn,
          searchQuery: searchQuery
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((response: any) => {
        rowChange(response.content);
        setTotalElements(response.totalElements);
        setIsLoading(false);
      })
      .catch((ex) => {
        console.log(ex);
        setIsLoading(false);
      });
  };

  const handleCallBack = (rowData: any, buttonData: any) => {
    if (buttonData.type === "detail") {
      navigate(`/vendor/events/details/${rowData.id}`);
    } else if(buttonData.type === "packageDetail"){
      navigate(`/vendor/package/details/${rowData.id}`);
    }
  };

  useEffect(() => {
    loadPackages();
    loadEvents();
  }, [sortColumn, sortDirection, page, size, searchQuery]);

  const tabsValues: any[] = [
    {
      id: 0,
      label: "Participating Events",
      component: (
        <VendorParticipationEvents
          key="events-tab"
          rows={rows}
          columns={eventConfig}
          page={page}
          size={size}
          query={searchQuery}
          setSearchQuery={setSearchQuery}
          totalElements={totalElements}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          handlePagination={handlePagination}
          handleSort={handleSort}
          handleCallBack={handleCallBack}
          hideColumns={hideColumns}
        />
      ),
    },
    {
      id: 1,
      label: "Package Awarded",
      component: (
        <PackageAwarded
          key="package-tab"
          rows={vendorPackageList && vendorPackageList.content}
          columns={packageConfig}
          page={page}
          size={size}
          query={searchQuery}
          setSearchQuery={setSearchQuery}
          totalElements={totalElements}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          handlePagination={handlePagination}
          handleSort={handleSort}
          handleCallBack={handleCallBack}
          hideColumns={hideColumns}
        />
      ),
    },
  ];

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Box className={"common-space-container"}>
      <PageHeading title={"Events"} showButtons={false} />

      <Box sx={{ display: "flex", margin: "1rem 0rem" }}>
        {tabsValues.map((tab, index) => {
          let backgroundColor = "background.default";
          let onHoverBackgroundColor = "background.paper";
          let color = "text.primary";
          if (activeIndex === index) {
            backgroundColor = "primary.main";
            color = "primary.contrastText";
            onHoverBackgroundColor = "primary.dark";
          }
          return (
            <Button
              key={index}
              onClick={() => setActiveIndex(index)}
              sx={{
                borderRadius: "0",
                borderTopLeftRadius: index === 0 ? "0.2rem" : "0",
                borderTopRightRadius:
                  index === tabsValues.length - 1 ? "0.2rem" : "0",
                borderBottomLeftRadius: index === 0 ? "0.2rem" : "0",
                borderBottomRightRadius:
                  index === tabsValues.length - 1 ? "0.2rem" : "0",
                bgcolor: backgroundColor,
                color: color,
                "&:hover": {
                  bgcolor: onHoverBackgroundColor,
                },
                "&.active": {
                  fontWeight: "bold",
                },
                "&:not(:last-child)": {
                  marginRight: "1px",
                },
              }}
              variant={activeIndex === index ? "contained" : "outlined"}
              className={activeIndex === index ? "active" : ""}
            >
              {tab.label}
            </Button>
          );
        })}
      </Box>

      <Box>
        <Box>{tabsValues[activeIndex].component}</Box>
      </Box>
    </Box>
  );
};

export default VendorEvent;
