import {Box, Button, Grid, Typography} from "@mui/material";
import {hasAccess} from "../../utils/Access";

const PageHeading = (props: any) => {
    const handleClick = (data: any) => {
        props.handleHeaderButtonClick(data);
    }
    return (
        <Box className={props.customClass ? props.customClass : "page-heading"}>
            <Grid container sx={{py: 4}} className={"center-aligned"}>
                <Grid item xs>
                    <Typography variant={"h4"} sx={{textTransform: 'capitalize'}}>
                        {props.title}
                    </Typography>
                </Grid>
                {props.showButtons &&
                    <Grid item>
                        {props.buttons.map((buttonData: any, index: number) => {
                            let flag = true;
                            if (buttonData.hasOwnProperty("permissions") && !hasAccess(buttonData.permissions)) {
                                flag = false;
                            }
                            if (flag) {
                                return (
                                    <Box key={"page-header-buttons-" + index} sx={{
                                        display: 'inline',
                                        mr: 2,
                                        '&:last-child': {
                                            mr: 0
                                        }
                                    }}>
                                        <Button
                                            color={buttonData?.color ? buttonData.color : 'primary'}
                                            variant={buttonData?.variant ? buttonData.variant : "outlined"}
                                            onClick={() => handleClick(buttonData.id)}
                                        >
                                            {buttonData.label}
                                        </Button>
                                    </Box>
                                )
                            }
                        })}
                    </Grid>
                }
            </Grid>
        </Box>
    )
}
export default PageHeading;