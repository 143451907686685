const release_version = process.env.REACT_APP_RELEASE_VERSION
export function setItem(keyName: string, defaultValue: any) {
    keyName = getKeyNameWithVersion(keyName);
    localStorage.setItem(keyName, decode(defaultValue));
}

export function getItem(keyName: string) {
    keyName = getKeyNameWithVersion(keyName);
    const defaultValue = localStorage.getItem(keyName);
    if (defaultValue === null || defaultValue === undefined) {
        return null;
    }
    return encode(defaultValue);
}

export function deleteItem(keyName: string) {
    keyName = getKeyNameWithVersion(keyName);
    localStorage.removeItem(keyName);
}

export function clearAll() {
    localStorage.clear();
}

function decode(value: string) {
    return JSON.stringify(value);
}

function encode(value: any) {
    return JSON.parse(value);
}

function getKeyNameWithVersion(keyName: string) {
    let version = release_version;
    if (version == null)
        version = "v0.1";
    return version + "_" + keyName;
}