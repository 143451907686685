export const interpolateColor = (minPrice: number, maxPrice: number, price: number): string => {
        const green = [0, 128, 0];
        const red = [255, 0, 0];
    
        if (typeof price !== 'number' || isNaN(price)) {
            return 'darkgray';
        }
    
        if (price === minPrice) {
            return `rgb(${green[0]}, ${green[1]}, ${green[2]})`;
        }
    
        if (price === maxPrice) {
            return `rgb(${red[0]}, ${red[1]}, ${red[2]})`;
        }
    
        const ratio = (price - minPrice) / (maxPrice - minPrice);
        const color = green.map((greenValue, index) =>
            Math.round(greenValue + ratio * (red[index] - greenValue))
        ) as [number, number, number];
    
        return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    };

export const getMinMaxPrices = (eventPackageDetails: any[], eventVendorsList: any[]) => {
        return eventPackageDetails.map((eventPackage: any) => {
            return eventPackage.items.map((item: any, itemIndex: number) => {
                let minPrice: number = Infinity;
                let maxPrice: number = -Infinity;
    
                eventVendorsList.forEach((vendor: any) => {
                    const price = vendor.eventVendorDetails[itemIndex]?.price + parseFloat(vendor.eventVendorDetails[itemIndex]?.domainCharges?.total);
    
                    if (typeof price === 'number' && !isNaN(price)) {
                        if (price < minPrice) minPrice = price;
                        if (price > maxPrice) maxPrice = price;
                    }
                });
    
                return { minPrice, maxPrice };
            });
        });
    };