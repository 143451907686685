import {SyntheticEvent, useEffect, useState} from "react";
import Loader from "../Loader";
import {Box} from "@mui/material";
import {CustomTabPanel} from "../../utils/TabsUtils";
import SelectItemCategory from "./SelectItemCategory";
import CategoryTab from "../tabs/CategoryTab";
import SubCategoryTab from "../tabs/SubCategoryTab";
import CreatePackageDrawer from "./CreatePackageDrawer";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {loadPackageItems} from "./features/actions";
import {resetPackageItems} from "./features/packageSlice";

const SelectPackage = (props: any) => {
    const {setPackageTabValue, boq, selectedPackage} = props;
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state: any) => state.package.loading);
    const [selectedCategory, setSelectedCategory] = useState<any>({});

    const [categoryTabValue, setCategoryTabValue] = useState('');
    const [subCategoryTabValue, setSubCategoryTabValue] = useState('');

    const [selectedRows, setSelectedRows] = useState<any>({});
    const [selectedRowsDrawer, setSelectedRowsDrawer] = useState<any>({});
    const [packageCategories, setPackageCategories] = useState<any>([]);

    const packageItems = useAppSelector((state: any) => state.package.packageItems);
    const [categoryCode, setCategoryCode] = useState<string>("");

    const handleDrawerCallback = () => {
        props.handlePendingCallback();
    }

    const handleRowSelection = (rows: any, subCategoryId: string) => {
        const selectedIds = rows.map((row: any) => row.id);
        const selectedIdsForDrawer = rows.map((row: any) => {
            return {id: row.id, quantity: ""}
        });
        setSelectedRowsDrawer((prev: any) => {
            const updated = {...prev};
            if (rows.length === 0) {
                delete updated[subCategoryId];
            } else {
                if (Object.keys(updated).length > 0 && updated[subCategoryId] != undefined && updated[subCategoryId].length > 0) {
                    updated[subCategoryId].map((selectedItem: any) => {
                        selectedIdsForDrawer.map((selectedRowId: any) => {
                            if (selectedRowId.id == selectedItem.id) {
                                selectedRowId.quantity = selectedItem.quantity;
                            }
                        });
                    })
                }
                updated[subCategoryId] = selectedIdsForDrawer;
            }
            return updated;
        });
        setSelectedRows((prev: any) => {
            const updated = {...prev};
            if (rows.length === 0) {
                delete updated[subCategoryId];
            } else {
                updated[subCategoryId] = selectedIds;
            }
            return updated;
        });
    }

    const handleCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setCategoryTabValue(newValue);
    };

    const handleSubCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setSubCategoryTabValue(newValue);
    };

    const handleCallback = () => {
        props.handlePendingCallback();
    }

    const handlePackageCreated = () => {
        setPackageTabValue('created');
        props.handlePackageCreated(); // Call the callback function
    };

    useEffect(() => {
        if (boq.hasOwnProperty("id") && boq?.id != null) {
            const updatedCategories = props.categories.map((category: any) => {
                const subCategories = category.subCategories.filter((subCategory: any) => {
                    return boq?.boqItems.some((boqItem: any) => boqItem.materialSubCategoryId === subCategory.id);
                });
                if (subCategories.length > 0 && categoryTabValue == "") {
                    setCategoryTabValue(category.id);
                }
                return {...category, subCategories};
            });

            setPackageCategories(updatedCategories);
        }
    }, [boq]);

    useEffect(() => {
        dispatch(resetPackageItems());
        if (Object.keys(selectedPackage).length > 0) {
            dispatch(loadPackageItems(selectedPackage.id));
        }
    }, [selectedPackage]);

    useEffect(() => {
        setSelectedRowsDrawer({});
        setSelectedRows({});
        if (packageItems != null) {
            const updatedRowsDrawer: any[] = [];
            const updatedRows: any[] = [];

            props.boq.boqItems.map((boqItem: any) => {
                boqItem.boqSkus.map((boqSku: any) => {
                    packageItems.map((selectedPackageItem: any) => {
                        if (boqSku.id == selectedPackageItem.itemDetail.id) {
                            let selectedIds: any[] = [];
                            let selectedIdsDrawer: any[] = [];
                            if (updatedRowsDrawer[boqItem.materialSubCategoryId] != undefined && updatedRowsDrawer[boqItem.materialSubCategoryId].length > 0) {
                                selectedIdsDrawer = updatedRowsDrawer[boqItem.materialSubCategoryId];
                                selectedIds = updatedRows[boqItem.materialSubCategoryId];
                            }

                            if (!selectedIds.includes(boqSku.id)) {
                                selectedIds.push(boqSku.id);
                                selectedIdsDrawer.push(
                                    {id: boqSku.id, quantity: selectedPackageItem.quantity}
                                );
                            }

                            updatedRowsDrawer[boqItem.materialSubCategoryId] = selectedIdsDrawer;
                            updatedRows[boqItem.materialSubCategoryId] = selectedIds;
                        }
                    });
                });
            });

            setSelectedRows(updatedRows);
            setSelectedRowsDrawer(updatedRowsDrawer);
        }
    }, [packageItems]);

    useEffect(() => {
        if (categoryTabValue != "") {
            setSubCategoryTabValue("");
            packageCategories.map((category: any) => {
                if (category.id == categoryTabValue) {
                    setSelectedCategory(category);
                    setCategoryCode(category.code);
                    if (category.subCategories.length > 0) {
                        setSubCategoryTabValue(category.subCategories[0].id);
                    }
                }
            })
        }
    }, [categoryTabValue]);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <>
            {categoryTabValue != "" &&
                <CategoryTab
                    categories={packageCategories}
                    categoryTabValue={categoryTabValue}
                    handleCategoryTabChange={handleCategoryTabChange}
                />
            }

            {(selectedCategory.hasOwnProperty("id") && selectedCategory.subCategories.length > 0 && subCategoryTabValue != '') &&
                <SubCategoryTab
                    subCategoryTabValue={subCategoryTabValue}
                    subCategories={selectedCategory?.subCategories}
                    categoryTabValue={categoryTabValue}
                    handleSubCategoryTabChange={handleSubCategoryTabChange}
                />
            }

            <CustomTabPanel value={subCategoryTabValue} index={subCategoryTabValue}>
                <Box sx={{pt: 2, pb: 5}}>
                    <SelectItemCategory
                        showUpdatedCharges={props.showUpdatedCharges}
                        categoryCode={categoryCode}
                        selectedRows={selectedRows[subCategoryTabValue] || []}
                        subCategoryId={subCategoryTabValue}
                        boq={boq}
                        project={props.project}
                        handleRowSelection={(rows: any) => handleRowSelection(rows, subCategoryTabValue)}
                        handleCallback={handleCallback}
                        handlePackageCreated={handlePackageCreated}
                    />
                </Box>
            </CustomTabPanel>

            {Object.keys(selectedRowsDrawer).length > 0 &&
                <CreatePackageDrawer
                    handleDrawerCallback={handleDrawerCallback}
                    boq={props.boq}
                    selectedPackage={selectedPackage}
                    packageItems={selectedRowsDrawer}
                    onPackageCreated={props.handlePackageCreated} // Add this prop
                />
            }
        </>
    )
}

export default SelectPackage;
