export const pageConfig = {
    columns: [
        {field: 'id', headerName: 'ID', headerClassName: 'data-grid-header', flex: 1, maxWidth: 90},
        {field: 'name', headerName: 'Name', headerClassName: 'data-grid-header', flex: 1,},
        {field: 'email', headerName: 'Email', headerClassName: 'data-grid-header', flex: 1,},
        {field: 'username', headerName: 'Username', headerClassName: 'data-grid-header', flex: 1,},
        {field: 'status', headerName: 'Active', type: "status", headerClassName: 'data-grid-header', flex: 1},
        {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'data-grid-header data-grid-right-aligned-header',
            headerAlign: 'right',
            align: 'right',
            sortable: false,
            flex: 1,
            show: 'button',
            buttons: [
                {
                    type: 'edit',
                    title: 'Edit User',
                    permissions: ['edit-users', 'edit-vendors'],
                    condition: (row: any) => true
                },
            ]
        }
    ]
};