import {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {getPriceInFormatted} from "../../../../../utils/Common";
import ListPage from "../../../../../components/list-page/ListPage";
import {boqColumns} from "../../config/BoQList";

const ListBoQDetailsForSubCategory = (props: any) => {
    const [boqDetails, setBoQDetails] = useState([]);
    const [subTotal, setSubTotal] = useState<number>(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortDirection, setSortDirection] = useState<any>("desc");

    const handleCallBack = (rowData: any, buttonData: any) => {
        console.log(rowData);
    }

    useEffect(() => {
        if (props.boqItems != undefined) {
            let total = 0;
            const selectedDetails: any = [];
            props?.boqItems.forEach((boqItem: any) => {
                if (boqItem.materialSubCategoryId == props.subCategoryId) {
                    boqItem.boqSkus.map((boqSku: any) => {
                        boqSku.itemCategory = boqItem.itemCategory;
                        selectedDetails.push({
                            ...boqSku,
                            country: props.project.country,
                            showUpdatedCharges: props.showUpdatedCharges,
                            categoryCode: props.categoryCode
                        });
                        total += ((parseFloat(boqSku.sellingPrice) * parseInt(boqSku.quantity)) + (parseInt(boqSku.quantity) * parseFloat(boqSku?.domainGsvCharges?.total)));
                    });
                }
            });

            setSubTotal(total);
            setBoQDetails(selectedDetails);
        }
    }, [props.subCategoryId]);

    return (
        <>
            <ListPage
                rows={boqDetails}
                columns={boqColumns}
                page={0}
                size={100}
                totalElements={boqDetails.length}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                handleCallBack={handleCallBack}
                checkboxSelection={true}
                hideColumns={{id: false}}
                hideFooter={true}
            />
            <Grid container sx={{backgroundColor: 'info.light', p: 2}}>
                <Grid item xs={6.9}/>
                <Grid item xs={2.6}>
                    <Typography component={"span"}>Sub Total</Typography>
                </Grid>
                <Grid item xs={2.5}>
                    <Typography component={"span"}>
                        {getPriceInFormatted(subTotal, props.project.country)}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default ListBoQDetailsForSubCategory;