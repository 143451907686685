import React, {useState} from "react";
import {Box, Button, Container, Grid, TextField, Typography,} from "@mui/material";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import Backdrop from "../../assets/Backdrop.png";
import LOGO from "../../assets/sm-main-logo.png";
import LoginCarousel from "./LoginCarousel";
import TextSwiper from "./TextSwiper";
import "./Login.css";
import Loader from "../../components/Loader";
import {api} from "../../utils/Axios";
import {validateEmail} from "../../utils/Common";

interface ErrorMsg {
    emailValidation: string;
    others: string;
}

function ForgotPassword() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState<ErrorMsg>({
        emailValidation: "",
        others: "",
    });
    const [successMsg, setSuccessMsg] = useState(false);
    const [email, setEmail] = useState<string>("");

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        const data = new FormData(event.currentTarget);
        const emailValue = data.get("email")?.toString() || "";
        setEmail(emailValue);

        if (validateEmail(emailValue)) {
            try {
                await api.post("authentication/forget-password", {email: emailValue});
                setIsLoading(false);
                setErrorMsg({
                    emailValidation: "",
                    others: "",
                });
                setSuccessMsg(true);
            } catch (err) {
                setErrorMsg({
                    emailValidation: "",
                    others: "An error occurred. Please try again.",
                });
                setIsLoading(false);
            }
        } else {
            setErrorMsg({
                emailValidation: "Invalid email address",
                others: "",
            });
            setIsLoading(false);
        }
    };

    return (
        <Container component="section" disableGutters maxWidth={false}>
            <Grid container alignItems="stretch" sx={{height: "100vh"}}>
                <Grid
                    item
                    xs={12}
                    md={7}
                    sx={{
                        display: {xs: "none", md: "block"},
                        bgcolor: "transparent",
                    }}
                >
                    <Box
                        sx={{
                            height: "100vh",
                            minWidth: "550px",
                            margin: "0 auto",
                            backgroundImage: `url(${Backdrop})`,
                            backgroundSize: "contain",
                            backgroundPosition: "center bottom",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <Box sx={{margin: "20px auto 0"}}>
                            <Box
                                component="img"
                                src={LOGO}
                                sx={{
                                    width: "85px",
                                    margin: "0 0 20px 50px",
                                }}
                            />
                            <LoginCarousel/>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={5} className={"center-aligned"} sx={{ backgroundColor: '#0388dd', position: 'relative', minHeight: "100vh" }}>
                    {isLoading && <Loader/>}
                    <Box className={"login-box-left"} sx={{ width: { xs: "90%", sm: "70%", lg: "80%" }, m:'auto', px: {xs: 1, md: 5, height:'90%'}}}>
                        <Box
                            sx={{
                                marginTop: 2,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: { xs: "center", md: "start" },
                                fontFamily: "Work Sans",
                                height:'90%'
                            }}
                        >
                            <Box sx={{margin: "0 auto 20px"}}>
                                <Box
                                    component="img"
                                    src={`${process.env.REACT_APP_CONTENT_URL}/innov8/bigfish_logo/3xBflogo.png`}
                                    sx={{width: "180px", margin: "0 auto"}}
                                />
                            </Box>
                            <Box sx={{margin: "0 auto 40px"}}>
                                <TextSwiper/>
                            </Box>

                            <Typography
                                sx={{
                                    fontFamily: "inherit",
                                    color: "#fff",
                                    mb: 2,
                                    textAlign: "center",
                                    margin: "0 auto 16px",
                                }}
                            >
                                Forgot your password?
                            </Typography>

                            <Typography
                                sx={{
                                    fontFamily: "inherit",
                                    color: "#fff",
                                    mb: 3,
                                    textAlign: "center",
                                    margin: "0 auto 16px",
                                }}
                            >
                                Enter your email address below and we'll send you a link to
                                reset your password.
                            </Typography>
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                style={{marginTop: 1, fontFamily: "inherit", width: "100%"}}
                            >
                                <Box sx={{pt: 1}}>
                                    <Typography
                                        variant={"body1"}
                                        sx={{
                                            mb: 0.5,
                                            fontWeight: 500,
                                            color: "#fff",
                                            fontFamily: "inherit",
                                        }}
                                    >
                                        Email
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        required
                                        margin="dense"
                                        variant="outlined"
                                        className={"borderedInput"}
                                        placeholder="Enter Your Email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        autoFocus
                                        InputProps={{
                                            style: {
                                                borderRadius: "5px",
                                                fontFamily: "inherit",
                                                backgroundColor: "#fff",
                                            },
                                        }}
                                    />

                                    {errorMsg.emailValidation && (
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: 500,
                                                lineHeight: "21px",
                                                color: "#ff0000",
                                            }}
                                        >
                                            {errorMsg.emailValidation}
                                        </Typography>
                                    )}
                                </Box>
                                {successMsg && (
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            color: "#ffffff",
                                            mt: 2,
                                        }}
                                    >
                                        We sent a recovery link to you at {email}. If you haven't
                                        received the email, check your spam folder.
                                    </Typography>
                                )}
                                <Button
                                    className={"secondary-button"}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        padding: "15px 14px",
                                        fontFamily: "inherit",
                                        backgroundColor: "#41539b",
                                    }}
                                >
                                    <Typography variant={"body1"} sx={{fontFamily: "inherit"}}>
                                        Send
                                    </Typography>
                                </Button>
                                <Box sx={{display: "flex", justifyContent: "center"}}>
                                    {errorMsg.others && (
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: 500,
                                                lineHeight: "21px",
                                                color: "#ff0000",
                                            }}
                                        >
                                            {errorMsg.others}
                                        </Typography>
                                    )}
                                </Box>
                            </form>
                            <Box sx={{textAlign: "center"}}>
                                <RouterLink to="/login" style={{textDecoration: "none"}}>
                                    <Typography sx={{color: "#fff", mt: 1}}>
                                        Back to Login
                                    </Typography>
                                </RouterLink>
                            </Box>
                        </Box>
                        <Box sx={{py: {xs: 2, md: 4}}}>
                            <Typography className={"copyright-text"} sx={{ width: '100%', textAlign: 'center', color: '#f8f8f8' }}>&copy; Proprietary Product of SpaceMatrix Pvt Ltd. All Rights Reserved.</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default ForgotPassword;
