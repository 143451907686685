import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../app/hooks";
import {api} from "../../../utils/Axios";
import {openSnackbar} from "../../../components/common/features/snackbarSlice";
import {ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";

import {Box, Button, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import PageHeading from "../../../components/page-heading/PageHeading";

const PlatformDetails = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [unionChargesFormValues, setUnionChargesFormValues] = useState<any>([]);
    const [floorChargesFormValues, setFloorChargesFormValues] = useState<any>([]);
    const [selectedUnionRegions, setSelectedUnionRegions] = useState<any>([]);
    const [selectedFloorRegions, setSelectedFloorRegions] = useState<any>([]);
    const [selectedFloor, setSelectedFloor] = useState<any>([]);
    const [domainDetails, setDomainDetails] = useState<any>({});
    const [regions, setRegions] = useState([
        {id: 'north', label: 'North'},
        {id: 'south', label: 'South'},
        {id: 'east', label: 'East'},
        {id: 'west', label: 'West'}
    ]);
    const dispatch = useAppDispatch();

    const waterAndPowerTypes = [
        {id: "waterChargesForFromClient", label: 'Water & Power FOC from client'},
        {id: "waterChargesForChargeableFromClient", label: 'Water & Power - FROM CLIENT ON CHARGABLE BASIS'},
        {id: "waterChargesForFromUs", label: 'Water & Power need to be arranged by SM at site (DG + Water tanker)'},
    ];
    const workingTypes = [
        {id: "allWorkingDays", label: '24 x 7'},
        {id: "nightShift", label: 'Only Night shifts'},
        {id: "nightShiftAndWeekend", label: 'Night shift + Weekends'},
    ];

    const loadDomainSettings = () => {
        setIsLoading(true);
        api.get(`/procurement/project/domain-details/${props.type}`)
            .then((response) => response.data)
            .then((res) => {
                if (res.hasOwnProperty("id") && res.id != null) {
                    setDomainDetails(res);

                    const unionData: any[] = [];
                    const floorData: any[] = [];
                    res.floor.map((floor: any, index: number) => {
                        let currentRegion = res.floorRegion != null ? res.floorRegion[index] : '';
                        floorData.push({region: currentRegion, floor: floor, charges: res.floorCharge[index]});
                        setSelectedFloor((prevFloor: any) => [...prevFloor, floor]);
                    });

                    setFloorChargesFormValues(floorData);

                    res.unionRegion.map((region: any, index: number) => {
                        unionData.push({region: region, charges: res.unionCharge[index]});
                        setSelectedUnionRegions((prevRegion: any) => [...prevRegion, region]);
                    });
                    setUnionChargesFormValues(unionData);
                } else {
                    setUnionChargesFormValues([{region: "", charges: ""}]);
                    setFloorChargesFormValues([{region: "", floor: "", charges: ""}]);
                }
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    }

    const handleSaveDomainSettings = (event: any) => {
        event.preventDefault();
        const data = new FormData(event.target);
        const form: any = {};
        data.forEach((value: any, key: string) => {
            if (!["unionRegion", "unionCharge", "floor", "floorCharge"].includes(key)) {
                form[key] = value;
            }
        });

        form.unionRegion = data.getAll("unionRegion");
        form.unionCharge = data.getAll("unionCharge");
        form.floor = data.getAll("floor");
        form.floorCharge = data.getAll("floorCharge");
        form.floorRegion = data.getAll("floorRegion");

        setIsLoading(true);
        api.post(`/procurement/project/update-domain-details/${props.type}`, form, {
            headers: {"content-type": "application/json"}
        })
            .then((response) => response.data)
            .then((res) => {
                dispatch(
                    openSnackbar({
                        message: "Project domain setting updated successfully",
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
                setIsLoading(false);
            })
            .catch((ex) => {
                dispatch(
                    openSnackbar({
                        message: "Oops. Something went wrong",
                        backgroundColor: ERROR_COLOR,
                    })
                );
                console.log(ex);
                setIsLoading(false);
            });

    }

    const addUnionChargesFormFields = () => {
        setUnionChargesFormValues([...unionChargesFormValues, {region: "", charges: ""}]);
    }

    const addFloorChargesFormFields = () => {
        setFloorChargesFormValues([...floorChargesFormValues, {region: "", floor: "", charges: ""}]);
    }

    const removeUnionFormFields = (index: number) => {
        let newFormValues: any = [...unionChargesFormValues];
        newFormValues.splice(index, 1);
        setUnionChargesFormValues(newFormValues);
        const selectedRegions = newFormValues.map((item: any) => item.region);
        setSelectedUnionRegions(selectedRegions);
    }

    const removeFloorFormFields = (index: number) => {
        let newFormValues: any = [...floorChargesFormValues];
        newFormValues.splice(index, 1);
        setFloorChargesFormValues(newFormValues)
    }

    const onUnionRegionChangeHandler = (event: any) => {
        setSelectedUnionRegions((prevRegion: any) => [...prevRegion, event.target.value]);
    }

    const onFloorChangeHandler = (event: any) => {
        setSelectedFloor((prevFloor: any) => [...prevFloor, event.target.value]);
    }

    const onFloorRegionChangeHandler = (event: any) => {
        setSelectedFloorRegions((prevRegion: any) => [...prevRegion, event.target.value]);
    }

    useEffect(() => {
        setUnionChargesFormValues([]);
        setFloorChargesFormValues([]);
        setSelectedUnionRegions([]);
        setSelectedFloor([]);
        setSelectedFloorRegions([]);
        setDomainDetails({});
        loadDomainSettings();
    }, [props.type]);
    return (
        <Box>
            <PageHeading
                title={props.type}
                showButtons={false}
            />
            {unionChargesFormValues.length > 0 &&
                <Box component={"form"} onSubmit={handleSaveDomainSettings}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{p: 2}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <InputLabel required={true} sx={{pb: 1, fontSize: "0.8rem"}}>
                                            Premium Charges
                                        </InputLabel>
                                        <Box sx={{width: '60%'}}>
                                            <TextField
                                                fullWidth
                                                size={"small"}
                                                type={"number"}
                                                variant={"outlined"}
                                                className={"inputBox"}
                                                name="premiumCharges"
                                                onWheel={(e: any) => e.target.blur()}
                                                required={true}
                                                defaultValue={domainDetails?.premiumCharges}
                                                inputProps={{
                                                    step: ".10",
                                                }}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "5px",
                                                        borderColor: 'primary.light',
                                                        fontSize: "0.8rem",
                                                    },
                                                    endAdornment: <InputAdornment
                                                        position="start">%</InputAdornment>
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel required={true} sx={{pb: 1, fontSize: "0.8rem"}}>
                                            Area Slab Discount
                                        </InputLabel>
                                        <Box sx={{width: '60%'}}>
                                            <TextField
                                                fullWidth
                                                size={"small"}
                                                type={"number"}
                                                variant={"outlined"}
                                                className={"inputBox"}
                                                name="areaSlabDiscount"
                                                defaultValue={domainDetails?.areaSlabDiscount}
                                                onWheel={(e: any) => e.target.blur()}
                                                required={true}
                                                inputProps={{
                                                    step: ".10",
                                                }}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "5px",
                                                        borderColor: 'primary.light',
                                                        fontSize: "0.8rem",
                                                    },
                                                    endAdornment: <InputAdornment
                                                        position="start">%</InputAdornment>
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{border: 1, borderColor: 'divider', mb: 4}}>
                                <Box>
                                    <Typography sx={{backgroundColor: "#F9FAFF", fontWeight: 700, p: 2}}>
                                        Water and Power Charges
                                    </Typography>
                                </Box>
                                <Box sx={{p: 2}}>
                                    <Grid container spacing={2}>
                                        {waterAndPowerTypes.map((option: any, index: number) => (
                                            <Grid item xs={6} key={"water-and-power-" + index}>
                                                <InputLabel required={true}
                                                            sx={{pb: 1, fontSize: "0.8rem"}}>
                                                    {option.label}
                                                </InputLabel>
                                                <Box sx={{width: '60%'}}>
                                                    <TextField
                                                        fullWidth
                                                        size={"small"}
                                                        type={"number"}
                                                        variant={"outlined"}
                                                        className={"inputBox"}
                                                        name={option.id}
                                                        defaultValue={domainDetails[option.id]}
                                                        onWheel={(e: any) => e.target.blur()}
                                                        required={true}
                                                        inputProps={{
                                                            step: ".10",
                                                        }}
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "5px",
                                                                borderColor: 'primary.light',
                                                                fontSize: "0.8rem",
                                                            },
                                                            endAdornment: <InputAdornment
                                                                position="start">%</InputAdornment>
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{border: 1, borderColor: 'divider', mb: 4}}>
                                <Box>
                                    <Typography sx={{backgroundColor: "#F9FAFF", fontWeight: 700, p: 2}}>
                                        Working Type Charges
                                    </Typography>
                                </Box>
                                <Box sx={{p: 2}}>
                                    <Grid container spacing={2}>
                                        {workingTypes.map((option: any, index: number) => (
                                            <Grid item xs={6} key={"working-types-" + index}>
                                                <InputLabel required={true}
                                                            sx={{pb: 1, fontSize: "0.8rem"}}>
                                                    Working Type - {option.label}
                                                </InputLabel>
                                                <Box sx={{width: '60%'}}>
                                                    <TextField
                                                        fullWidth
                                                        size={"small"}
                                                        type={"number"}
                                                        variant={"outlined"}
                                                        className={"inputBox"}
                                                        name={option.id}
                                                        defaultValue={domainDetails[option.id]}
                                                        onWheel={(e: any) => e.target.blur()}
                                                        required={true}
                                                        inputProps={{
                                                            step: ".10",
                                                        }}
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "5px",
                                                                borderColor: 'primary.light',
                                                                fontSize: "0.8rem",
                                                            },
                                                            endAdornment: <InputAdornment
                                                                position="start">%</InputAdornment>
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{border: 1, borderColor: 'divider', mb: 4}}>
                                <Box>
                                    <Typography sx={{backgroundColor: "#F9FAFF", fontWeight: 700, p: 2}}>
                                        Union Charges
                                    </Typography>
                                </Box>
                                <Box sx={{p: 2}}>
                                    {unionChargesFormValues.map((element: any, index: number) => {
                                        return (
                                            <Grid container key={"union-charges-" + index} sx={{pb: 1}}>
                                                <Grid item xs>
                                                    <InputLabel required={true}
                                                                sx={{pb: 1, fontSize: "0.8rem"}}>
                                                        Region
                                                    </InputLabel>
                                                    <Box sx={{width: '60%'}}>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            name={"unionRegion"}
                                                            required={true}
                                                            onChange={(event: any) => onUnionRegionChangeHandler(event)}
                                                            defaultValue={element?.region || ""}
                                                            style={{height: '40px', width: '100%'}}
                                                        >
                                                            {regions.map((region: any, regionIndex: number) => (
                                                                <MenuItem
                                                                    key={"union-charges-for-region-" + regionIndex}
                                                                    value={region.id}
                                                                    disabled={selectedUnionRegions.includes(region.id)}
                                                                >
                                                                    {region.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <InputLabel required={true}
                                                                sx={{pb: 1, fontSize: "0.8rem"}}>
                                                        Charges
                                                    </InputLabel>
                                                    <Box sx={{width: '60%'}}>
                                                        <TextField
                                                            fullWidth
                                                            size={"small"}
                                                            type={"number"}
                                                            variant={"outlined"}
                                                            className={"inputBox"}
                                                            name="unionCharge"
                                                            onWheel={(e: any) => e.target.blur()}
                                                            defaultValue={element?.charges || ""}
                                                            InputProps={{
                                                                style: {
                                                                    borderRadius: "5px",
                                                                    borderColor: "primary.light",
                                                                    fontSize: "0.8rem",
                                                                },
                                                                endAdornment: <InputAdornment
                                                                    position="start">%</InputAdornment>
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs className={"vertically-center-aligned"}>
                                                    <Button
                                                        onClick={() => removeUnionFormFields(index)}>Remove</Button>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Box>
                                <Box className={"right-align-content"} sx={{pb: 1, px: 2}}>
                                    <Button sx={{px: 2}} onClick={addUnionChargesFormFields}>Add
                                        More</Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{border: 1, borderColor: 'divider', mb: 4}}>
                                <Box>
                                    <Typography sx={{backgroundColor: "#F9FAFF", fontWeight: 700, p: 2}}>
                                        Floor-wise Charges
                                    </Typography>
                                </Box>
                                <Box sx={{p: 2}}>
                                    {floorChargesFormValues.map((element: any, index: number) => {
                                        return (
                                            <Grid container key={"union-charges-" + index} sx={{pb: 1}}>
                                                <Grid item xs>
                                                    <InputLabel required={true}
                                                                sx={{pb: 1, fontSize: "0.8rem"}}>
                                                        Region
                                                    </InputLabel>
                                                    <Box sx={{width: '60%'}}>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select-floor-region"
                                                            name={"floorRegion"}
                                                            required={true}
                                                            onChange={(event: any) => onFloorRegionChangeHandler(event)}
                                                            defaultValue={element?.region || ""}
                                                            style={{height: '40px', width: '100%'}}
                                                        >
                                                            {regions.map((region: any, regionIndex: number) => (
                                                                <MenuItem
                                                                    key={"floor-charges-for-region-" + regionIndex}
                                                                    value={region.id}
                                                                    // disabled={selectedUnionRegions.includes(region.id)}
                                                                >
                                                                    {region.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <InputLabel required={true}
                                                                sx={{pb: 1, fontSize: "0.8rem"}}>
                                                        Floor
                                                    </InputLabel>
                                                    <Box sx={{width: '60%'}}>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            name={"floor"}
                                                            required={true}
                                                            onChange={(event: any) => onFloorChangeHandler(event)}
                                                            defaultValue={element?.floor || ""}
                                                            style={{height: '40px', width: '100%'}}
                                                        >
                                                            {Array.from(Array(30), (e: any, i: number) => (
                                                                <MenuItem value={i + 1}
                                                                          // disabled={selectedFloor.includes(i + 1)}
                                                                          key={"floor-charges-" + i}>{i + 1}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <InputLabel required={true}
                                                                sx={{pb: 1, fontSize: "0.8rem"}}>
                                                        Charges
                                                    </InputLabel>
                                                    <Box sx={{width: '60%'}}>
                                                        <TextField
                                                            fullWidth
                                                            size={"small"}
                                                            type={"number"}
                                                            variant={"outlined"}
                                                            className={"inputBox"}
                                                            name="floorCharge"
                                                            onWheel={(e: any) => e.target.blur()}
                                                            defaultValue={element?.charges || ""}
                                                            InputProps={{
                                                                style: {
                                                                    borderRadius: "5px",
                                                                    borderColor: "primary.light",
                                                                    fontSize: "0.8rem",
                                                                },
                                                                endAdornment: <InputAdornment
                                                                    position="start">%</InputAdornment>
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs className={"vertically-center-aligned"}>
                                                    <Button
                                                        onClick={() => removeFloorFormFields(index)}>Remove</Button>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Box>
                                <Box className={"right-align-content"} sx={{pb: 1, px: 2}}>
                                    <Button sx={{px: 2}} onClick={addFloorChargesFormFields}>Add More</Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={"right-align-content"} sx={{py: 2}}>
                                <Button variant="contained" type={"submit"} sx={{marginLeft: '4px'}}>
                                    Save Project Domain Setting
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Box>
    )
}

export default PlatformDetails;