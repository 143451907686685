import {api} from "../../../utils/Axios";
import Loader from "../../Loader";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ListPage from "../../list-page/ListPage";
import {skuPageConfig} from "./PageConfig";
import {Box, Typography} from "@mui/material";
import SearchFilter from "../../filter/SearchFilter";
import RemoveFilters from "../../filter/RemoveFilters";

const filters = {
    'name': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Sku Name',
        label: 'Sku Name',
        name: 'name'
    },
    'code': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Sku code',
        label: 'Sku Code',
        name: 'code'
    },
    'uom': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By UOM',
        label: 'Sku UOM',
        name: 'uom'
    },
    'location': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Location',
        label: 'Location',
        name: 'location'
    },
    'RC_price': {
        element: 'radio',
        label: 'RC Price',
        name: 'RC_price',
        options: [
            {id: 'yes', label: 'Yes'},
            {id: 'no', label: 'No'},
        ],
    },
    'price': {
        element: 'slider',
        placeholder: 'Search Here',
        label: 'Price Range',
        name: 'price',
        info: '1 = 1000',
        range: [0, 1000000]
    },
};

const brandFilters = {
    'name': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Sku Name',
        label: 'Sku Name',
        name: 'name'
    },
    'code': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Sku code',
        label: 'Sku Code',
        name: 'code'
    },
    'uom': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By UOM',
        label: 'Sku UOM',
        name: 'uom'
    },
    'location': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Location',
        label: 'Location',
        name: 'location'
    },
    'price': {
        element: 'slider',
        placeholder: 'Search Here',
        label: 'Price Range',
        name: 'price',
        info: '1 = 1000',
        range: [0, 1000000]
    },
};


const ItemSkuListing = (props: any) => {
    let pageConfigs = skuPageConfig.columns;
    if (props.vendorDashboard) {
        pageConfigs = skuPageConfig.vendorColumns;
    } else if (props.type == 'brand') {
        pageConfigs = skuPageConfig.brandColumns;
    }

    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(0);
    const [sortColumn, setSortColumn] = useState("totalRc");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);
    const [appliedFilters, setAppliedFilters] = useState({});
    const navigate = useNavigate();

    const handleFilterChange = (appliedParams: any) => {
        setAppliedFilters(appliedParams);
        setPage(0); // Reset to first page when filters change
    }

    const handleSearchFilter = (query: any) => {
        setSearchQuery(query);
    }

    const handleSort = (sortModel: any) => {
        setSortColumn(sortModel.field);
        setSortDirection(sortModel?.sort);
        setPage(0);
    }

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        setPage(newPagination.page);
    }

    const handleCallBack = (rowData: any, buttonData: any) => {
        if (buttonData.type === 'anchor' && !props.vendorDashboard) {
            navigate(`/catalogue/skus/details/${rowData.id}`);
        } else {
            navigate(`/vendor/catalogue/skus/details/${rowData.id}`);
        }
    }

    const loadSku = () => {
        setIsLoading(true);
        let url = `/catalogue/sku/list/${props.item.id}`;
        if (props.type === 'brand') {
            url = `/catalogue/sku/brand-list/${props.item.id}`;
        }

        const params = new URLSearchParams({
            pageNo: page.toString(),
            pageSize: size.toString(),
            sort: sortDirection?.toUpperCase(),
            sortByColumn: sortColumn,
            searchQuery: searchQuery
        });

        if (Object.keys(appliedFilters).length > 0) {
            Object.entries(appliedFilters).map(([name, value]: any) => {
                params.append(name, value);
            });
        }

        api.get(url, {params})
            .then((response) => response.data)
            .then((response: any) => {
                setRows(response.content);
                setTotalElements(response.totalElements);
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        loadSku();
    }, [sortColumn, sortDirection, page, size, searchQuery, appliedFilters, props.item]);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Box sx={{pb: 5}} className={"sku-listing"}>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Box>
                    <Box sx={{pb: 2}}>
                        <Typography component={'span'}
                                    sx={{
                                        py: 1,
                                        fontWeight: 500,
                                        lineHeight: '12px',
                                        textAlign: 'left',
                                        fontSize: '10px'
                                    }}>
                            Item Name&nbsp;
                        </Typography>
                        <br/>
                        <Typography component={'span'}
                                    sx={{fontSize: '14px', fontWeight: 600, lineHeight: '16.8px', textAlign: 'left'}}>
                            {props.item?.name.split('Item :')[1]}
                        </Typography>
                    </Box>
                    {/* <Box sx={{pb: 2}}>
                        <Typography component={'span'} sx={{py: 1, fontWeight: 700}}>
                            Item Code:&nbsp;
                        </Typography>
                        <Typography component={'span'}>
                            {props.item?.code}
                        </Typography>
                    </Box> */}
                </Box>
                <Box sx={{pb: 2}}>
                    <SearchFilter
                        handleFilterChange={handleFilterChange}
                        handleSearchFilter={handleSearchFilter}
                        filters={props.type == 'brand' ? brandFilters : filters}
                        appliedFilters={appliedFilters}
                        showFilters={true}
                        showSearch={true}
                        showSearchInput={true}
                    />
                </Box>

            </Box>
            <Box>
                <RemoveFilters handleFilterChange={handleFilterChange}
                               filters={props.type == 'brand' ? brandFilters : filters}
                               appliedFilters={appliedFilters}/>
            </Box>

            <ListPage
                rows={rows}
                columns={pageConfigs}
                page={page}
                size={size}
                totalElements={totalElements}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                handlePagination={handlePagination}
                handleSort={handleSort}
                handleCallBack={handleCallBack}
                rowHeight={92}
            />
        </Box>
    )
}

export default ItemSkuListing;
