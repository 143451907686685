import React, {SyntheticEvent, useEffect, useState} from "react";
import {api} from "../../../utils/Axios";
import {hasAccess} from "../../../utils/Access";
import Loader from "../../../components/Loader";
import {Box, Modal} from "@mui/material";
import {formatData, getCSVFormatEstimation} from "../../../utils/downloadHelper";
import SubCategoryTab from "../../../components/tabs/SubCategoryTab";
import CategoryTab from "../../../components/tabs/CategoryTab";
import {CustomTabPanel} from "../../../utils/TabsUtils";
import EstimationItemListing from "../../../components/estimation/EstimationItemListing";
import {getStyle} from "../../../utils/ModalUtils";
import PageHeading from "../../../components/page-heading/PageHeading";
import CloseIcon from "@mui/icons-material/Close";
import EstimationSummary from "../../../components/estimation/summary/EstimationSummary";

const EstimationDetailsPopup = (props: any) => {
    const {revision, project, categories} = props;
    const style = getStyle(100, 85);
    const [openPopup, setOpenPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [headerButtons, setHeaderButtons] = useState<any>([]);

    const [currentRevision, setCurrentRevision] = useState<any>({});
    const [selectedCategory, setSelectedCategory] = useState<any>({});
    const [categoryTabValue, setCategoryTabValue] = useState("summary");
    const [subCategoryTabValue, setSubCategoryTabValue] = useState("");

    const handleCategoryTabChange = (_event: SyntheticEvent, newValue: string) => {
        setCategoryTabValue(newValue);
    };

    const handleSubCategoryTabChange = (_event: SyntheticEvent, newValue: string) => {
        setSubCategoryTabValue(newValue);
    };

    const refreshRevision = () => {
        setOpenPopup(false);
        props.proceedTabChange("boq");
    }

    const loadEstimationDetails = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(`/procurement/estimation/details/${currentRevision.id}`);
            const results = await response.data;
            if (!currentRevision?.downloaded) {
                markDownloaded();
            }
            downloadCSV(results);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    };

    const markDownloaded = () => {
        api.get(`/procurement/estimation/revision/mark-downloaded/${currentRevision.id}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            setCurrentRevision(response);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const downloadCSV = (estimations: any) => {
        const formattedData = formatData(estimations);
        const csvString = getCSVFormatEstimation(formattedData);
        const blob = new Blob([csvString], {type: "text/csv"});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "report.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleClose = () => {
        setOpenPopup(false);
        props.handleCallBack({event: 'close'});
    }

    const handleHeaderButtonClick = async (data: any) => {
        if (data === "download-estimation") {
            loadEstimationDetails();
        }
    }

    useEffect(() => {
        if (categoryTabValue !== "summary" && categoryTabValue !== "" && categories.length > 0) {
            categories.forEach((category: any) => {
                if (category.id === categoryTabValue) {
                    setSelectedCategory(category);
                    setSubCategoryTabValue(category.subCategories[0]?.id || "");
                }
            });
        } else {
            setSubCategoryTabValue("");
        }
    }, [categoryTabValue, categories]);

    useEffect(() => {
        setHeaderButtons([{
            id: "download-estimation",
            label: "Download Estimation",
            variant: "contained",
            color: "primary",
            permissions: ["super-admin", "edit-projects"],
        }]);
        setCategoryTabValue("summary");
        setCurrentRevision(revision);
    }, [revision]);

    useEffect(() => {
        if (props.openPopup) {
            setOpenPopup(true);
        }
    }, [props.openPopup]);


    if (isLoading) {
        return <Loader/>;
    }

    return (
        <Modal
            disableScrollLock={false}
            open={openPopup}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            sx={{
                "& > .MuiBackdrop-root": {
                    backdropFilter: "blur(0px)",
                    backgroundColor: "transparent",
                },
            }}
        >
            <Box sx={{
                position: "absolute" as "absolute",
                bottom: 0,
                width: "100%",
                height: '90%',
                border: 0,
                backgroundColor: "background.paper",
                boxShadow: 1,
                '&:focus-visible': {
                    outline: 'none'
                }
            }}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box sx={{width: '95%'}}>
                                <PageHeading
                                    title={"Project: " + project.name + " (Revision: " + currentRevision.name+")"}
                                    showButtons={true}
                                    buttons={headerButtons}
                                    handleHeaderButtonClick={handleHeaderButtonClick}
                                />
                            </Box>
                            <Box onClick={handleClose}
                                 sx={{display: 'flex', justifyContent: 'center', width: '5%', cursor: 'pointer'}}>
                                <CloseIcon/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.modalFullBody}>
                        <Box sx={style.bodyContainer}>
                            <Box className={"common-space-container"}>
                                <Box display={"flex"} alignItems={"flex-start"} sx={{pb: 1}}>
                                    <Box sx={{width:'100%'}}>
                                        <Box>
                                            {categoryTabValue !== "" && (
                                                <CategoryTab
                                                    includeSummaryTab={true}
                                                    categories={categories}
                                                    disableCategories={project.status == 'COMPLETED' ? true : false}
                                                    categoryTabValue={categoryTabValue}
                                                    handleCategoryTabChange={handleCategoryTabChange}
                                                />
                                            )}
                                            {selectedCategory.hasOwnProperty("id") && categoryTabValue !== "summary" && (
                                                <SubCategoryTab
                                                    subCategoryTabValue={subCategoryTabValue}
                                                    subCategories={selectedCategory?.subCategories}
                                                    categoryTabValue={categoryTabValue}
                                                    handleSubCategoryTabChange={handleSubCategoryTabChange}
                                                />
                                            )}
                                            <Box sx={{pt: 2, pb: 1}}>
                                                {subCategoryTabValue !== "" && categoryTabValue !== "summary" && (
                                                    <CustomTabPanel value={subCategoryTabValue}
                                                                    index={subCategoryTabValue}>
                                                        <EstimationItemListing
                                                            revision={currentRevision}
                                                            project={project}
                                                            subCategoryId={subCategoryTabValue}
                                                        />
                                                    </CustomTabPanel>
                                                )}

                                                {categoryTabValue === "summary" && (
                                                    <EstimationSummary
                                                        categories={categories}
                                                        revision={currentRevision}
                                                        project={project}
                                                        showUpdatedCharges={props.showUpdatedCharges}
                                                        refreshRevision={refreshRevision}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default EstimationDetailsPopup;