import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Typography,
    TextField,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import {api} from "../../utils/Axios";
import Loader from "../../components/Loader";
import jwtDecode from "jwt-decode";
import GLogin from "../../assets/g-login.svg";
import {getItem} from "../../utils/LocalStorage";
import {setAuth} from "../../features/authSlice";
import {openSnackbar} from "../../components/common/features/snackbarSlice";
import {ERROR_COLOR} from "../../constants";
import {useAppDispatch} from "../../app/hooks";
import LoginCarousel from './LoginCarousel';
import Backdrop from '../../assets/Backdrop.png'
import BlueLogo from '../../assets/BigfishLogoWhite.svg'
import TextSwiper from './TextSwiper'
import LOGO from '../../assets/sm-main-logo.png';
import './Login.css'

function Login() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const GOOGLE_AUTH_URL =
        process.env.REACT_APP_REST_API +
        "/authentication/oauth2/authorize/google?redirect_uri=" +
        process.env.REACT_APP_REDIRECT_URL;

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setIsLoading(true);
        const data = new FormData(event.currentTarget);
        const form = {
            username: data.get("username"),
            password: data.get("password"),
        };
        api
            .post("/authentication/login", form)
            .then((response) => response.data)
            .then((res) => {
                dispatch(setAuth({token: res.token}));
                setIsLoading(false);
                navigate("/");
            })
            .catch((ex) => {
                console.log(ex);
                dispatch(openSnackbar({message: 'Invalid Request. Please check', backgroundColor: ERROR_COLOR}))
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const auth = getItem("auth");
        if (auth !== null) {
            navigate("/");
        }
    }, [navigate]);

    return (
        <Container component="section" disableGutters maxWidth={false}>
            <Grid container alignItems={"stretch"} sx={{ height: "100vh" }}>
                <Grid item xs={12} md={7} sx={{ bgcolor: "transparent", display: { xs: 'none', md: 'block' } }}>
                    <Box sx={{ height: '100vh', minWidth: '550px', margin: '0 auto', backgroundImage: "url(" + Backdrop + ")", backgroundSize: 'contain', backgroundPosition: 'center bottom', backgroundRepeat: 'no-repeat' }}>
                        <Box sx={{ margin: '20px auto 0' }}>
                            <Box component="img" src={LOGO} sx={{ width: "85px", margin: "0 0 20px 50px" }}></Box>
                            <LoginCarousel />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={5} className={"center-aligned"} sx={{ backgroundColor: '#0388dd', position: 'relative', minHeight: "100vh" }}>
                    {isLoading && <Loader />}
                    <Box className={"login-box-left"} sx={{ width: { xs: "90%", sm: "70%", lg: "80%" }, m:'auto', px: {xs: 1, md: 5}}}>
                        <Box
                            sx={{
                                marginTop: 2,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: { xs: "center", md: "start" },
                                fontFamily: "Work Sans",
                            }}
                        >
                            <Box sx={{ margin: { xs: '0 auto 20px', md: '0 auto 40px' } }}>
                                <Box component="img" src={`${process.env.REACT_APP_CONTENT_URL}/innov8/bigfish_logo/3xBflogo.png`} sx={{ width: { xs: '140px', md: '180px' }, margin: '0 auto' }}></Box>
                            </Box>
                            <Box sx={{ margin: { xs: '0 auto 20px', md: '0 auto 40px' } }}>
                                <TextSwiper />
                            </Box>

                            <Typography sx={{ fontFamily: "inherit", color: "#fff", mb: {xs: 0, md: 2}, textAlign: 'center', margin: '0 auto 16px' }}>
                                Sign in to your account
                            </Typography>
                            <Box className={"google-sign-in"} sx={{my: {xs: 1, md: 2}, width: "100%", textAlign: 'center'}}>
                                <Link to={GOOGLE_AUTH_URL}>
                                    <Button
                                        className="primary-button"
                                        sx={{
                                            border: 0,
                                            borderColor: "#fff",
                                            px: 1,
                                            py: 1.4,
                                            width: "70%",
                                            fontFamily: "inherit",
                                            borderRadius: '18px',
                                            backgroundColor: '#fff',
                                            '&:hover': {
                                                border: 'unset'
                                            }
                                        }}

                                        variant="outlined"
                                        startIcon={<Box component={"img"} src={GLogin} alt={"G"} />}
                                    >
                                        <Typography
                                            variant={"body1"}
                                            sx={{ color: "info.main", fontFamily: "inherit" }}
                                        >
                                            Continue With Google
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>

                            <Divider sx={{ width: "100%", mt: {xs:1, md: 2}, fontFamily: "inherit", color: '#fff', "&::before, &::after": { borderColor: "secondary.light" } }}>
                                or
                            </Divider>
                            <Box
                                component="form"
                                onSubmit={handleSubmit}
                                noValidate
                                sx={{ mt: 1, fontFamily: "inherit", width: "100%" }}
                            >
                                <Box sx={{ pt: 1 }}>
                                    <Typography
                                        variant={"body1"}
                                        sx={{
                                            mb: 0.5,
                                            fontWeight: 500,
                                            color: "#fff",
                                            fontFamily: "inherit"
                                        }}
                                    >
                                        Email
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        required
                                        margin="dense"
                                        variant="outlined"
                                        className={"borderedInput"}
                                        placeholder="Email"
                                        name="username"
                                        autoComplete="email"
                                        autoFocus
                                        InputProps={{
                                            style: {
                                                borderRadius: "5px",
                                                fontFamily: "inherit",
                                                backgroundColor: "#fff"
                                            },
                                        }}
                                    />
                                </Box>
                                <Box sx={{ pt: 1 }}>
                                    <Typography
                                        variant={"body1"}
                                        sx={{
                                            mb: 0.5,
                                            fontWeight: 500,
                                            color: "#fff",
                                            fontFamily: "inherit"
                                        }}
                                    >
                                        Password
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        required
                                        margin="dense"
                                        variant="outlined"
                                        className={"borderedInput"}
                                        placeholder="Password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        InputProps={{
                                            style: {
                                                borderRadius: "5px",
                                                fontFamily: "inherit",
                                                backgroundColor: "#fff"
                                            },
                                        }}
                                    />
                                </Box>
                                <Button
                                    className={"secondary-button"}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        mt: 3,
                                        mb: 1,
                                        padding: '15px 14px',
                                        fontFamily: "inherit",
                                        backgroundColor: '#41539b'
                                    }}
                                >
                                    <Typography variant={"body1"} sx={{ fontFamily: "inherit" }}>
                                        Continue
                                    </Typography>
                                </Button>
                                <Box sx={{textAlign: 'left'}}>
                                    <Link to="/forgot-password" style={{textDecoration: 'none'}}>
                                        <Typography sx={{color: '#fff'}}>
                                            Forgot Password?
                                        </Typography>
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{py: {xs: 2, md: 4}}}>
                            <Typography className={"copyright-text"} sx={{ width: '100%', textAlign: 'center', color: '#f8f8f8' }}>&copy; Proprietary Product of SpaceMatrix Pvt Ltd. All Rights Reserved.</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Login;
