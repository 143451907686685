import {SyntheticEvent, useEffect, useState} from "react";
import Loader from "../../../../components/Loader";
import {api} from "../../../../utils/Axios";
import {Box, Button} from "@mui/material";
import {CustomTabPanel} from "../../../../utils/TabsUtils";
import ListBoQDetailsForSubCategory from "./boq-tabs/ListBoQDetailsForSubCategory";
import CategoryTab from "../../../../components/tabs/CategoryTab";
import SubCategoryTab from "../../../../components/tabs/SubCategoryTab";
import BoqOverallSummary from "../../../../components/boq/BoqOverallSummary";

const BoQTab = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isBoqLoading, setIsBoqLoading] = useState(false);
    const [boq, setBoq] = useState<any>({});
    const [selectedCategory, setSelectedCategory] = useState<any>({});
    const [boqCategories, setBoqCategories] = useState<any>([]);
    const [categoryTabValue, setCategoryTabValue] = useState<string>("");
    const [subCategoryTabValue, setSubCategoryTabValue] = useState<string>("");
    const [categoryCode, setCategoryCode] = useState<string>("");

    const handleCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setCategoryTabValue(newValue);
    };

    const handleSubCategoryTabChange = (
        event: SyntheticEvent,
        subCategoryId: string
    ) => {
        setSubCategoryTabValue(subCategoryId);
    };

    const continueToPackage = () => {
        props.proceedTabChange("package");
    }

    const loadBoQ = () => {
        setIsBoqLoading(true);
        api
            .get(`/procurement/boq/get-by-project/${props.project.id}`)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                setBoq(res);
            })
            .catch((ex) => {
                console.log(ex);
            })
            .finally(() => {
                setIsBoqLoading(false);
            });
    };

    const loadProjectDetails = () => {
        setIsLoading(true);
        api
            .get(`/procurement/project/project-details/${props.project.id}`)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                props.project.projectDetails = res;
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadBoQ();
        if (props.project.projectDetails == null) {
            loadProjectDetails();
        }
    }, []);

    useEffect(() => {
        if (categoryTabValue != "") {
            setSubCategoryTabValue("");
            boqCategories.map((category: any) => {
                if (category.id === categoryTabValue) {
                    setSelectedCategory(category);
                    setCategoryCode(category.code);
                    if (category.subCategories.length > 0) {
                        setSubCategoryTabValue(category.subCategories[0].id);
                    }
                }
            })
        }
    }, [categoryTabValue]);

    useEffect(() => {
        if (boq.hasOwnProperty("id") && boq?.id != null) {
            const updatedCategories = props.categories.map((category: any) => {
                const subCategories = category.subCategories.filter((subCategory: any) => {
                    return boq?.boqItems.some((boqItem: any) => boqItem.materialSubCategoryId === subCategory.id);
                });
                return {...category, subCategories};
            });

            setBoqCategories(updatedCategories);
        }
        setCategoryTabValue("summary");
        setCategoryCode("");
    }, [props.categories, boq]);

    if (isLoading || isBoqLoading) {
        return <Loader/>;
    }

    return (
        <>

            <Box>
                {categoryTabValue != "" &&
                    <CategoryTab
                        includeSummaryTab={true}
                        categories={boqCategories}
                        categoryTabValue={categoryTabValue}
                        handleCategoryTabChange={handleCategoryTabChange}
                    />
                }

                {(selectedCategory.hasOwnProperty("id") && selectedCategory.subCategories.length > 0 && subCategoryTabValue != '') &&
                    <SubCategoryTab
                        subCategoryTabValue={subCategoryTabValue}
                        subCategories={selectedCategory?.subCategories}
                        categoryTabValue={categoryTabValue}
                        handleSubCategoryTabChange={handleSubCategoryTabChange}
                    />
                }
                {subCategoryTabValue !== "" && categoryTabValue !== "summary" && (
                    <CustomTabPanel value={subCategoryTabValue} index={subCategoryTabValue}>
                        <Box sx={{pt: 2, pb: 5}}>
                            <ListBoQDetailsForSubCategory
                                categoryCode={categoryCode}
                                showUpdatedCharges={props.showUpdatedCharges}
                                project={props.project}
                                subCategoryId={subCategoryTabValue}
                                boqItems={boq.boqItems}
                            />
                        </Box>
                    </CustomTabPanel>
                )}

                {categoryTabValue === "summary" && (
                    <BoqOverallSummary showUpdatedCharges={props.showUpdatedCharges} project={props.project} boq={boq}
                                       categories={boqCategories}/>
                )}

                {boq && (
                    <Box className={"right-align-content"}>
                        <Button onClick={continueToPackage} variant={"outlined"}>
                            Continue
                        </Button>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default BoQTab;
