import FormTabMain from "./FormTabMain";
import ParameterFormTab from "./ParameterFormTab";

const PheTab = (props: any) => {
    const {formState, setFormState } = props;
    const handleNextButton = () => {
        props.handleNext(props.categoryName)
    }
    let buttons: any = [
        {
            type: 'submit',
            color: 'primary.main',
            variant: 'contained',
            title: 'Save',
            permissions: ['edit-projects'],
        },
    ];

    return (
        <ParameterFormTab project={props.project} formState={formState} setFormState={setFormState} formFields={formFields} categoryName={props.categoryName} allParameters={props.allParameters} buttons={buttons} handleNextButton={handleNextButton} updateFieldChange={props.updateFieldChange}  buttonRef={props.buttonRef}/>
    )
}


const formFields = [
    {
        groupName: 'Fire supression system',
        fields: [
            {
                element: 'select',
                label: "Scope of Sprinkler ",
                name: "scopeOfSprinkler",
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: 'New Sprinkler',
                options: [
                    {id: 'New Sprinkler', label: 'New Sprinkler'},
                    {id: 'Modification', label: 'Modification'},
                    {id: 'NA', label: 'NA'},
                ]
            },
            {
                element: 'select',
                label: "Sprinkler (false ceiling void)",
                name: "sprinklerFalseCeilingVoidMore800",
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: '>800',
                options: [
                    {id: '<800', label: '< 800'},
                    {id: '>800', label: '> 800'},
                ]
            },
            {
                element: 'input',
                label: "Select Nos of tap off",
                name: "tap_off_count",
                required: true,
                type: "number",
                mainContainerWidth: 12,
                width: 4
            },
            {
                element: 'select',
                label: "Scope of Gas supression system (Novec)",
                name: "scopeOfGasSupressionSystem",
                required: true,
                width: 4,
                mainContainerWidth: 12,
                disableField: "gss_room_coverage",
                disableOnValue: "No",
                value: "Yes",
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'No', label: 'No'},
                ]
            },
            {
                element: 'select',
                label: "Gas Supression System (Room Coverage)",
                name: "gss_room_coverage",
                required: true,
                disableOn: true,
                width: 4,
                mainContainerWidth: 12,
                value: 'All Critical room',
                options: [
                    {id: 'All Critical room', label: 'All Critical room'},
                    {id: 'Server&HUB', label: 'Server&HUB'},
                    {id: 'Server', label: 'Server'},
                ]
            },
            {
                element: 'select',
                label: "FE 36 Supression system",
                name: "fe36SupressionSystem",
                required: true,
                width: 4,
                mainContainerWidth: 12,
                disableField: "fe36_supression_system_room_coverage",
                disableOnValue: "No",
                value: "Yes",
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'No', label: 'No'},
                ]
            },
            {
                element: 'select',
                label: "FE 36 Supression system (Room Coverage)",
                name: "fe36_supression_system_room_coverage",
                required: true,
                disableOn: true,
                width: 4,
                mainContainerWidth: 12,
                options: [
                    {id: 'Server+Hub+UPS+Battery', label: 'Server+Hub+UPS+Battery'},
                    {id: 'Hub+UPS+Battery', label: 'Hub+UPS+Battery'},
                    {id: 'UPS+Battery', label: 'UPS+Battery'},
                    {id: 'Server/HUB', label: 'Server/HUB'},
                ]
            },
            {
                element: 'select',
                label: "Fire extinguisher",
                name: "fireExtinguisher",
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: 'HIGH',
                options: [
                    {id: 'ECO/MID', label: 'ECO/MID'},
                    {id: 'HIGH', label: 'HIGH'},
                ]
            },
            {
                element: 'select',
                label: "Fire extinguisher (Room Coverage)",
                name: "fire_extinguisher_room_coverage",
                required: true,
                width: 4,
                mainContainerWidth: 12,
                options: [
                    {id: 'Office+Server+Hub+UPS+Battery', label: 'Office+Server+Hub+UPS+Battery'},
                    {id: 'Office+Hub+UPS+Battery', label: 'Office+Hub+UPS+Battery'},
                    {id: 'Office+UPS+Battery', label: 'Office+UPS+Battery'},
                    {id: 'Office', label: 'Office'},
                ]
            },
            {
                element: 'select',
                label: "Type of Fire Curtain",
                name: "fire_curtain_type",
                required: true,
                width: 4.1,
                disableField: "fireCurtainRequirementInSqm",
                disableOnValue: "NA",
                value:'BLE Approved',
                options: [
                    {id: 'NA', label: 'NA'},
                    {id: 'BSEN Approved', label: 'BSEN Approved'},
                    {id: 'BLE Approved', label: 'BLE Approved'},
                ]
            },
            {
                element: 'input',
                label: "Fire Curtain requirement in sqm.",
                name: "fireCurtainRequirementInSqm",
                required: true,
                disableOn: true,
                type: "number",
                mainContainerWidth: 12,
                width: 4
            },
        ]
    },
    {
        groupName: 'PHE',
        fields: [
            {
                element: 'input',
                label: "EWC Count",
                name: "ewc",
                required: true,
                type: "number",
                mainContainerWidth: 12,
                width: 4
            },
            {
                element: 'input',
                label: "Wash Basin Count",
                name: "wash-basin",
                required: true,
                type: "number",
                mainContainerWidth: 12,
                width: 4
            },
            {
                element: 'input',
                label: "Urinal Count",
                name: "urinal",
                required: true,
                type: "number",
                width: 4,
                mainContainerWidth: 12,
            },
            {
                element: 'select',
                label: "Lifting pump for wet pantry /mothers/wellness room for away from the plumbing shaft ",
                name: "lifting-pump-for-wet-pantry",
                required: true,
                width: 4,
                mainContainerWidth: 12,
                disableField: "wet_point_qty",
                disableOnValue: "NA",
                value: "Yes",
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'NA', label: 'NA'}
                ]
            },
            {
                element: 'input',
                label: "Select Qty if Wet point from the PHE shaft?",
                name: "wet_point_qty",
                required: true,
                disableOn: true,
                type: "number",
                width: 4,
                mainContainerWidth: 12,
            },
        ]
    },
];


export default PheTab