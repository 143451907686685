import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/Axios";

interface ApiResponse {}

interface removeDetails {}

export const removeSkuPrices = createAsyncThunk<ApiResponse, removeDetails>(
  "procurement/catalog/brand/remove-sku",
  async (skuPriceId) => {
    try {
      const response = await api.get(`/catalogue/sku/remove-price/${skuPriceId}`);
      return response.data as ApiResponse;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);
