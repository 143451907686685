import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {api} from "../../../utils/Axios";

const EstimationSkuDetails = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [sku, setSku] = useState<any>({});
    const loadSkuDetails = () => {
        setIsLoading(true);
        let url = `/catalogue/sku/details/${props.skuId}`;

        api.get(url).then((response) => {
            return response.data;
        }).then((response: any) => {
            setSku(response);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        loadSkuDetails();
    }, [props.skuId]);


    return (
        <>
            {sku.hasOwnProperty("id") &&
                <Box display="flex" className={"show-view-details-sku"}
                     justifyContent="center"
                     sx={{padding: "2rem", border: 1, borderTop: 0, borderColor: 'divider'}}>
                    <Box padding={"0 4rem"} sx={{width: "380px"}}>
                        <Box display="flex" alignItems="flex-start"
                             justifyContent={"space-between"} padding={"4px"}>
                            <Typography textAlign={"start"}>Category Name</Typography>
                            <Typography textAlign={"start"}
                                        fontWeight={600}>{sku?.category.name}</Typography>
                        </Box>
                        <Box display="flex" alignItems="flex-start"
                             justifyContent={"space-between"} padding={"4px"}>
                            <Typography>Sub Category name</Typography>
                            <Typography textAlign={"start"}
                                        fontWeight={600}>{sku?.subCategory.name}</Typography>
                        </Box>
                        <Box display="flex" alignItems="flex-start"
                             justifyContent={"space-between"} padding={"4px"}>
                            <Typography>Sku Code</Typography>
                            <Typography textAlign={"start"}
                                        fontWeight={600}>{sku?.code}</Typography>
                        </Box>
                    </Box>
                    {(sku?.skuOptions && sku.skuOptions.length > 0) &&
                        <TableContainer sx={{width: "400px"}}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            th: {
                                                border: 1,
                                                borderColor: "divider",
                                                backgroundColor: "info.light",
                                                fontWeight: "700",
                                            },
                                        }}
                                    >
                                        <TableCell>Sku Option Name</TableCell>
                                        <TableCell>Sku Option Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sku?.skuOptions.map((skuOption: any) => (
                                        <TableRow sx={{border: 1, borderColor: 'divider'}}>
                                            <TableCell
                                                sx={{border: 1, borderColor: 'divider'}}>{skuOption?.name}</TableCell>
                                            <TableCell
                                                sx={{border: 1, borderColor: 'divider'}}>{skuOption?.value}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Box>
            }
        </>
    )
}

export default EstimationSkuDetails;