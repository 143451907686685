import {Box, Grid, Tooltip, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {Doughnut} from "react-chartjs-2";
import {Link} from "react-router-dom";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

const ProjectDonutChart = (props: any) => {
    const [chartData, setChartData] = useState<any>({});
    const [total, setTotal] = useState<any>(0);
    const [totalEstimation, setTotalEstimation] = useState<any>(0);
    const [isHoveringEstimationChart, setIsHoveringEstimationChart] = useState(false);
    const [isHoveringEstimationItem, setIsHoveringEstimationItem] = useState(false);
    const backgroundColors: string[] = ["#4B46D6", "#02478F", "#28B463", "#6495ED"];

    useEffect(() => {
        let total: number = 0;
        let totalEstimation = 0;
        Object.keys(props.project?.stages).map((key: any) => {
            total += parseInt(props.project?.stages[key]);
            if (["details", "parameter", "headCount", "catalog", "estimation"].includes(key)) {
                totalEstimation += parseInt(props.project?.stages[key]);
            }
        });

        setTotal(total);
        setTotalEstimation(totalEstimation);

        setChartData(
            {
                labels: ["ESTIMATION", "BOQ", "Package", "Purchase Request"],
                datasets: [{
                    label: '',
                    data: [totalEstimation, parseInt(props.project?.stages?.boq), parseInt(props.project?.stages?.packageCount), parseInt(props.project?.stages?.pr)],
                    backgroundColor: backgroundColors,
                    borderColor: 'transparent',
                    borderWidth: 0
                }]
            }
        );

    }, [props]);

    const handleHover = (event: any, elements: any) => {
        if (elements.length > 0) {
            const index = elements[0].index;
            const label = chartData.labels[index];
            if (label === "ESTIMATION") {
                setIsHoveringEstimationChart(true);
            } else {
                setIsHoveringEstimationChart(false);
            }
        } else {
            setIsHoveringEstimationChart(false);
        }
    };

    const handleItemMouseEnter = () => {
        setIsHoveringEstimationItem(true);
    };

    const handleItemMouseLeave = () => {
        setIsHoveringEstimationItem(false);
    };

    const isHoveringEstimation = isHoveringEstimationChart || isHoveringEstimationItem;

    return (
        <Grid container sx={{height: '100%'}}>
            <Grid item xs={6}>
                {Object.keys(chartData).length > 0 &&
                    <Box className="chart-container" sx={{position: 'relative'}}>
                        <Typography style={{
                            textAlign: "center",
                            position: 'absolute',
                            top: '45%',
                            left: 0,
                            right: 0,
                            marginLeft: props.mleft,
                            marginTop: props.mtop,
                            fontSize: '1.5rem',
                            fontWeight: 600
                        }}>Stages</Typography>
                        <Doughnut
                            id={"sm-doughnut-chart-project"}
                            data={chartData} options={{
                                cutout: "55%",
                                onHover: handleHover,
                                plugins: {
                                    datalabels: {
                                        display: true,
                                        color: '#ffffff',
                                        formatter: (value: any, context: any) => {
                                            return value >= 1 ? value : ''; 
                                        }
                                    },
                                    legend: {
                                        display: false,
                                        position: 'right',
                                        align: 'center',
                                        labels: {
                                            usePointStyle: true
                                        }
                                    }
                                }
                            }} />
                    </Box>
                }
            </Grid>
            <Grid item xs={6} sx={{p: 4}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{py: 2, cursor: 'pointer'}}
                             onMouseEnter={handleItemMouseEnter}
                             onMouseLeave={handleItemMouseLeave}>
                            <Tooltip
                                className={"tooltip"}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            p: 0,
                                            width: '150%',
                                            bgcolor: 'common.white',
                                            boxShadow: 1,
                                            '& .MuiTooltip-arrow': {
                                                color: 'common.white',
                                                "&::before": {
                                                    backgroundColor: "common.white",
                                                    boxShadow: 1,
                                                }
                                            },
                                        },
                                    },
                                }}
                                arrow
                                title={<ShowTooltip totalEstimation={totalEstimation} stages={props.project.stages}/>}
                                placement="right"
                                open={isHoveringEstimation}>
                                <Box sx={{ display: 'inline-block' }}>
                                    <Link to={"/projects?stage=estimation"} style={{ textDecoration: 'none' }}>
                                        <Typography component={"span"} className={"badge-circle"}
                                                    sx={{mr: 1, backgroundColor: backgroundColors[0]}}/>
                                        <Typography component={"span"} className={"project-chart-title"}>
                                            Estimation
                                        </Typography>

                                    </Link>
                                    <Typography
                                        className={"project-chart-percentage"}>{Math.floor((totalEstimation / total) * 100)}%</Typography>
                                </Box>
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{py: 2}}>
                            <Typography>
                                <Typography component={"span"} className={"badge-circle"}
                                            sx={{mr: 1, backgroundColor: backgroundColors[1]}}/>
                                <Typography component={"span"} className={"project-chart-title"}>
                                    BOQ
                                </Typography>
                            </Typography>
                            <Typography
                                className={"project-chart-percentage"}>{Math.floor((props.project?.stages.boq / total) * 100)}%</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{py: 2}}>
                            <Typography><Typography component={"span"} className={"badge-circle"}
                                                    sx={{mr: 1, backgroundColor: backgroundColors[2]}}/>
                                <Typography component={"span"} className={"project-chart-title"}>
                                    Package
                                </Typography>
                            </Typography>
                            <Typography
                                className={"project-chart-percentage"}>{Math.floor((props.project?.stages.packageCount / total) * 100)}%</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{py: 2}}>
                            <Typography>
                                <Typography component={"span"} className={"badge-circle"}
                                            sx={{mr: 1, backgroundColor: backgroundColors[3]}}/>
                                <Typography component={"span"} className={"project-chart-title"}>
                                    Purchase Request
                                </Typography></Typography>
                            <Typography
                                className={"project-chart-percentage"}>{Math.floor((props.project?.stages.pr / total) * 100)}%</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const ShowTooltip = (props: any) => {
    return (
        <Box sx={{color: 'common.black', borderColor: '#525983', borderRight: 4, borderRadius: 1}}>
            <Box sx={{py: 2, px: 2}}>
                <Box>
                    <Typography variant={"h6"} sx={{fontWeight: 700, color: '#525983', fontSize: '12px'}}>Estimation
                        Breakup</Typography>
                </Box>
                <Box>
                    <Typography sx={{py: 0.5, display: 'flex', justifyContent: 'space-between'}}>
                        <Typography component={"span"} sx={{fontSize: '0.75rem', color: '#768698'}}>
                            Project Details
                        </Typography>
                        <Typography
                            component={"span"}
                            sx={{
                                fontSize: '0.75rem',
                                color: '#525983',
                                fontWeight: 700
                            }}>{Math.floor((props.stages?.details / props.totalEstimation) * 100)}%</Typography>
                    </Typography>
                    <Typography sx={{py: 0.5, display: 'flex', justifyContent: 'space-between'}}>
                        <Typography component={"span"}
                                    sx={{fontSize: '0.75rem', color: '#768698'}}>Parameter</Typography>
                        <Typography
                            component={"span"}
                            sx={{
                                fontSize: '0.75rem',
                                color: '#525983',
                                fontWeight: 700
                            }}>{Math.floor((props.stages?.parameter / props.totalEstimation) * 100)}%</Typography>
                    </Typography>
                    <Typography sx={{py: 0.5, display: 'flex', justifyContent: 'space-between'}}>
                        <Typography component={"span"} sx={{fontSize: '0.75rem', color: '#768698'}}>Head
                            Count</Typography>
                        <Typography
                            component={"span"}
                            sx={{
                                fontSize: '0.75rem',
                                color: '#525983',
                                fontWeight: 700
                            }}>{Math.floor((props.stages?.headCount / props.totalEstimation) * 100)}%</Typography>
                    </Typography>
                    <Typography sx={{py: 0.5, display: 'flex', justifyContent: 'space-between'}}>
                        <Typography component={"span"} sx={{fontSize: '0.75rem', color: '#768698'}}>E -
                            Catalog</Typography>
                        <Typography
                            component={"span"}
                            sx={{
                                fontSize: '0.75rem',
                                color: '#525983',
                                fontWeight: 700
                            }}>{Math.floor((props.stages?.catalog / props.totalEstimation) * 100)}%</Typography>
                    </Typography>
                    <Typography sx={{py: 0.5, display: 'flex', justifyContent: 'space-between'}}>
                        <Typography component={"span"}
                                    sx={{fontSize: '0.75rem', color: '#768698'}}>Estimation</Typography>
                        <Typography
                            component={"span"}
                            sx={{
                                fontSize: '0.75rem',
                                color: '#525983',
                                fontWeight: 700
                            }}>{Math.floor((props.stages?.estimation / props.totalEstimation) * 100)}%</Typography>
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default ProjectDonutChart;