import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import CategoryWidget from "./widgets/CategoryWidget";

const LeftContainer = (props: any) => {
    const [estimationCategories, setEstimationCategories] = useState<any>([]);
    const [miscCategory, setMiscCategory] = useState<any>({}); 

    useEffect(() => {
        let matchedSubCats:any = [];
        const updatedCategories = props.categories.map((category: any) => {
            let total: number = 0;
            let totalSkus: number = 0;
            const subCategories = category.subCategories.filter((subCategory: any) => {
                return props.estimations?.some((estimation: any) => {
                    if (estimation.materialSubCategoryId === subCategory.id) {
                        matchedSubCats.push(estimation.materialSubCategoryId);
                        total = total + parseFloat(estimation.total) + parseFloat(estimation?.domainTotal);
                        totalSkus = totalSkus + parseInt(estimation.totalSkus);
                        return subCategory;
                    }
                });
            });
            category.total = total;
            category.totalSkus = totalSkus;

            return {...category, subCategories};
        });
        setEstimationCategories(updatedCategories);
        
        let total: number = 0;
        let totalSkus: number = 0;
        let miscSubCategories: any = [];
        let miscCategory:any = {};

        props.estimations?.map((estimation: any) => {
            if(!matchedSubCats.includes(estimation.materialSubCategoryId)) {
                total = total + parseFloat(estimation.total);
                totalSkus = totalSkus + parseInt(estimation.totalSkus);
                let subcat:any = {};
                subcat.id =  estimation.materialSubCategoryId;
                subcat.name = estimation.materialSubCategoryId;

                miscSubCategories.push(subcat);
            }
        });
        if(miscSubCategories.length > 0) {
            miscCategory.id = "Mis1";
            miscCategory.name = "Miscellaneous";
            miscCategory.subCategories = miscSubCategories;
            miscCategory.total = total;
            miscCategory.totalSkus = totalSkus;
        }
        setMiscCategory(miscCategory);
    }, [props.estimations, props.categories, props.project]);

    return (
        <>
            {estimationCategories.map((category: any, categoryIndex: number) => {
                if (category.subCategories.length > 0) {
                    return (
                        <Box sx={{p: 1}} key={"left-category-container-"+categoryIndex}>
                            <CategoryWidget
                                showUpdatedCharges={props.showUpdatedCharges}
                                key={'category-widget-' + categoryIndex}
                                category={category}
                                categoryIndex={categoryIndex}
                                project={props.project}
                                estimations={props.estimations}
                            />
                        </Box>
                    )
                }
            })}
            {Object.keys(miscCategory).length > 0 &&
                <Box sx={{p: 1}} key={"left-category-container-misc1"}>
                            <CategoryWidget
                                key={'category-widget-misc1'}
                                category={miscCategory}
                                categoryIndex={'misc1'}
                                project={props.project}
                                estimations={props.estimations}
                            />
                        </Box>
            }
        </>
    )
}
export default LeftContainer;