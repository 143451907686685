import {createSlice} from "@reduxjs/toolkit";
import {
    approveEstimationRevision, cloneRevision,
    getEstimationRevisionDetails, getEstimationRevisionForNonRevisionView,
    getEstimationRevisions, getEstimationsForRevisions,
    getPartSummaryDetails,
    postDraftSection,
    removeItemsFromSummaryDetails,
    removeSummary
} from "./actions";


const initialState = {
    loading: false,
    draftSections: {},
    partEstimationSummary: {},
    savedPartSections: [],
    partPublishedSuccess: {},
    publishedSections: {},
    revision: {},
    revisions: null,
    revisionEstimations: null
};

const boqSlice = createSlice({
    name: "boq",
    initialState,
    reducers: {
        addSectionToSavedPartSections: (state: any, action) => {
            const {section, sectionPart} = action.payload;
            state.savedPartSections = state.savedPartSections.filter(
                (savedSection: any) => savedSection.id !== section.id
            );
            state.savedPartSections.push({...section, sectionPart});
        },
        addPublishedSection(state: any, action: any) {
            state.publishedSections.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postDraftSection.pending, (state: any) => {
                state.pending = true;
                state.error = "";
            })
            .addCase(postDraftSection.fulfilled, (state: any, action) => {
                state.pending = false;
                const updatedDetails = action.payload;
                state.draftSections = updatedDetails;
                state.error = "";
            })
            .addCase(postDraftSection.rejected, (state: any, action) => {
                state.pending = false;
                state.error = action.payload;
            })
            .addCase(getPartSummaryDetails.pending, (state: any) => {
                state.pending = true;
                state.error = "";
            })
            .addCase(getPartSummaryDetails.fulfilled, (state: any, action) => {
                state.pending = false;
                state.partEstimationSummary = action.payload ? action.payload : {};
                state.error = "";
            })
            .addCase(getPartSummaryDetails.rejected, (state: any, action) => {
                state.pending = false;
                state.error = action.payload;
            })
            .addCase(removeItemsFromSummaryDetails.pending, (state: any) => {
                state.pending = true;
                state.error = "";
            })
            .addCase(removeItemsFromSummaryDetails.fulfilled, (state: any, action) => {
                state.pending = false;
                state.summaryDetails = action.payload;
                state.error = "";
            })
            .addCase(removeItemsFromSummaryDetails.rejected, (state: any, action) => {
                state.pending = false;
                state.error = action.payload;
            })
            .addCase(removeSummary.pending, (state: any) => {
                state.pending = true;
                state.error = "";
            })
            .addCase(removeSummary.fulfilled, (state: any, action) => {
                state.pending = false;
                state.error = "";
            })
            .addCase(removeSummary.rejected, (state: any, action) => {
                state.pending = false;
                state.error = action.payload;
            })
            .addCase(getEstimationRevisions.pending, (state: any) => {
                state.pending = true;
                state.error = "";
            })
            .addCase(getEstimationRevisions.fulfilled, (state: any, action) => {
                state.pending = false;
                state.revisions = action.payload;
                state.error = "";
            })
            .addCase(getEstimationRevisions.rejected, (state: any, action) => {
                state.pending = false;
                state.error = action.payload;
            })
            .addCase(getEstimationsForRevisions.pending, (state: any) => {
                state.pending = true;
                state.error = "";
            })
            .addCase(getEstimationsForRevisions.fulfilled, (state: any, action) => {
                state.pending = false;
                state.revisionEstimations = action.payload;
                state.error = "";
            })
            .addCase(getEstimationsForRevisions.rejected, (state: any, action) => {
                state.pending = false;
                state.error = action.payload;
            })
            .addCase(getEstimationRevisionDetails.pending, (state: any) => {
                state.pending = true;
                state.error = "";
            })
            .addCase(getEstimationRevisionDetails.fulfilled, (state: any, action) => {
                state.pending = false;
                state.revision = action.payload;
                state.error = "";
            })
            .addCase(getEstimationRevisionDetails.rejected, (state: any, action) => {
                state.pending = false;
                state.error = action.payload;
            })
            .addCase(getEstimationRevisionForNonRevisionView.pending, (state: any) => {
                state.pending = true;
                state.error = "";
            })
            .addCase(getEstimationRevisionForNonRevisionView.fulfilled, (state: any, action) => {
                state.pending = false;
                state.revision = action.payload;
                state.error = "";
            })
            .addCase(getEstimationRevisionForNonRevisionView.rejected, (state: any, action) => {
                state.pending = false;
                state.error = action.payload;
            })
            .addCase(approveEstimationRevision.pending, (state: any) => {
                state.pending = true;
                state.error = "";
            })
            .addCase(approveEstimationRevision.fulfilled, (state: any, action) => {
                state.pending = false;
                state.error = "";
            })
            .addCase(approveEstimationRevision.rejected, (state: any, action) => {
                state.pending = false;
                state.error = action.payload;
            })
            .addCase(cloneRevision.pending, (state: any) => {
                state.pending = true;
                state.error = "";
            })
            .addCase(cloneRevision.fulfilled, (state: any, action) => {
                state.pending = false;
                state.error = "";
            })
            .addCase(cloneRevision.rejected, (state: any, action) => {
                state.pending = false;
                state.error = action.payload;
            });

    },
});

export const {addSectionToSavedPartSections, addPublishedSection} = boqSlice.actions;
export default boqSlice.reducer;
