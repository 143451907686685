import React from 'react';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    plugins: {
        datalabels: {
            display: true,
            color: '#ffffff', // Red color for the text
            formatter: (value: any, context: any) => {
                return value > 2 ? value : ''; 
            }
        },
        title: {
            display: false,
            text: '',
        },
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

const StackedBarChart = (props: any) => {
    const yAxisData = [
        {
            id: 1,
            label: 'NORTH',
            userGain: props.vendors?.north
        },
        {
            id: 2,
            label: 'SOUTH',
            userGain: props.vendors?.south,
        },
        {
            id: 3,
            label: 'EAST',
            userGain: props.vendors?.east
        },
        {
            id: 4,
            label: 'WEST',
            userGain: props.vendors?.west,
        }
    ];
    const xAxisData = [
        { id: 1, label: 'NORTH' },
        { id: 2, label: 'SOUTH' },
        { id: 3, label: 'EAST' },
        { id: 4, label: 'WEST' }
    ];
    const data = {
        labels: xAxisData.map((xAxis) => xAxis.label),
        datasets: [
            {
                label: 'Vendor',
                data: xAxisData.map((XAxisData) => props.totalVendors),
                backgroundColor: '#0366d6', // Darker shade of blue
            },
            {
                label: 'RC Vendor',
                data: yAxisData.map((yAxis) => yAxis.userGain),
                backgroundColor: '#8cc4e7', // Darker shade of light blue
            }
        ],
    };
    return <Bar options={options} data={data} />;
}

export default StackedBarChart;
