import {getStyle} from "../../../utils/ModalUtils";
import {useEffect, useState} from "react";
import Loader from "../../Loader";
import {api} from "../../../utils/Axios";
import {Box, Button, Checkbox, Grid, Modal, TextField, Typography,} from "@mui/material";
import ReadMore from "../../read-more/ReadMore";
import {getPriceInFormatted} from "../../../utils/Common";
import CloseIcon from "@mui/icons-material/Close";
import {openSnackbar} from "../../common/features/snackbarSlice";
import {ERROR_COLOR} from "../../../constants";
import {useAppDispatch} from "../../../app/hooks";
import EstimationSkuDetails from "./EstimationSkuDetails";
import ChangeEntityPrice from "./ChangeEntityPrice";
import SelectDeliveryArea from "./list-estimate/SelectDeliveryArea";
import CustomTooltip from "../../common/CustomTooltip";
import ECatalogLoader from "../../ecatalog/ECatalogLoader";

const EstimationSkuSearchList = (props: any) => {
    const {project, item, vendorEntities, brandEntities} = props;
    const style = getStyle(90, 85);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useAppDispatch();
    const [showDeliveryAreaDetails, setShowDeliveryAreaDetails] = useState<any>({});
    const [showViewDetails, setShowViewDetails] = useState<any>({});
    const [showChangePrice, setShowChangePrice] = useState<any>({});
    const [selectedSkus, setSelectedSkus] = useState<any>([]);
    const [skus, setSkus] = useState([]);
    const categoryId = props.category.id;
    const [showAllSelectedSks, setShowAllSelectedSks] = useState<any>(false);
    const [hasNext, setHasNext] = useState<any>(true);
    const [hasPrev, setHasPrev] = useState<any>(true);
    const [searchPage, setSearchPage] = useState<any>(0);
    const [searchApiCalled, setSearchApiCalled] = useState<any>(false);
    const [isChairCategory, setIsChairCategory] = useState<any>(false);
    const [showDescription, setShowDescription] = useState(true);
    const [eCatalogError, setECatalogError] = useState(false);


    useEffect(() => {
        let chekChairCategory = false;
        setShowDescription(true);
        props.category.subCategories.map((subcategory:any) => {
            console.log(subcategory.id + " :: "+subcategory.name+" - "+props.subCategoryId);
            if(subcategory.id == props.subCategoryId && (subcategory.name.toLowerCase() == 'chairs' || subcategory.name.toLowerCase() == 'carpet')) {
                chekChairCategory = true;
            }
        });
        setIsChairCategory(chekChairCategory);
        loadSelectedSkus();
    }, [props.appliedFilters, props.subCategoryId]);

    const handleToggleDescription = () => {
        setShowDescription(prev => !prev);
    };

    const saveSkuSelection = () => {
        props.updateApiLoading(true);
        setIsLoading(true);
        let errorFlag = false;
        const formSkus: any = [];
        let errorMessage = "";
        let selectedAssignedQty:number = 0;
        selectedSkus.forEach((selectedSku: any) => {
            if (!errorFlag) {
                let totalDeliveryAreaQuantity = 0;
                if (selectedSku.targetPrice == undefined || selectedSku.targetPrice <= 0) {
                    errorFlag = true;
                    errorMessage = "Please select planned COGS for selected sku(s)";
                }
                if (selectedSku.nsvPercentage == undefined || selectedSku.nsvPercentage <= 0) {
                    errorFlag = true;
                    errorMessage = "Please enter valid nsv percentage for selected sku(s)";
                }
                
                if (selectedSku.sellingPrice == undefined || selectedSku.sellingPrice <= 0) {
                    errorFlag = true;
                    errorMessage = "Please enter valid gsv for selected sku(s)";
                }
                if (selectedSku.quantity == undefined || selectedSku.quantity <= 0) {
                    errorFlag = true;
                    errorMessage = "Please select quantity for selected sku(s)";
                }
                if (props.tab == 'estimation') {
                    if (selectedSku.deliveryAreas == undefined || selectedSku.deliveryAreas == null || selectedSku.deliveryAreas == "") {
                        errorFlag = true;
                        errorMessage = "Please select delivery area for selected sku(s)";
                    } else {
                        selectedSku.deliveryAreas.map((deliveryAreaDetails: any) => {
                            totalDeliveryAreaQuantity += parseInt(deliveryAreaDetails.quantity);
                        });
                        if (totalDeliveryAreaQuantity != selectedSku.quantity) {
                            errorFlag = true;
                            errorMessage = "Delivery Area Quantity mismatch for selected sku(s)";
                        }
                    }
                }

                selectedAssignedQty += parseInt(selectedSku.quantity);

                formSkus.push({
                    id: selectedSku.id,
                    entityType: selectedSku?.selectedEntity?.entityName,
                    entityId: selectedSku?.selectedEntity?.entityId,
                    quantity: selectedSku.quantity,
                    sellingPrice: selectedSku.sellingPrice ? parseFloat(selectedSku.sellingPrice) : 0,
                    //targetPrice: (isChairCategory) ? (selectedSku.basicPrice ? selectedSku.basicPrice : 0) : parseFloat(selectedSku.targetPrice),
                    targetPrice: selectedSku.targetPrice ? parseFloat(selectedSku.targetPrice) : 0,
                    deliveryAreas: selectedSku.deliveryAreas,
                    nsvPercentage: selectedSku?.nsvPercentage ? Math.round(selectedSku.nsvPercentage) : 0,
                    itemCategoryId: selectedSku?.itemCategoryId,
                    itemId: selectedSku?.itemId,
                });
            }
        });

        if (!errorFlag) {
            let form = {
                subCategoryId: props.subCategoryId,
                projectId: props.project.id,
                skus: formSkus,
            };
            let url = `/procurement/e-catalog/update-catalog-item`
            if (props.tab == 'estimation') {
                url = `/procurement/estimation/update-estimation-item`;
            }

            api
                .post(
                    url,
                    form
                )
                .then((response) => {
                    return response.data;
                })
                .then((res) => {
                    setIsLoading(false);
                    props.updateApiLoading(false);
                    props.updateAssignedQty(selectedAssignedQty);
                })
                .catch((ex) => {
                    console.log(ex);
                    setIsLoading(false);
                    props.updateApiLoading(false);
                });
        } else {
            setIsLoading(false);
            props.updateApiLoading(false);
            dispatch(
                openSnackbar({
                    message: errorMessage,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    };

    const handleSkuSelection = (e: any, sku: any) => {
        const selected = e.target.checked;
        if (selected) {
            console.log("selected sku = ",  sku);
            setSelectedSkus((prevSelectedSkus: any) => [...prevSelectedSkus, sku]);
        } else {
            setSelectedSkus((prevSelectedSkus: any) =>
                prevSelectedSkus.filter((selectedSku: any) => selectedSku.id !== sku.id)
            );
        }
        const newShowDeliveryAreaDetails = {...showDeliveryAreaDetails, [sku.id]: !showDeliveryAreaDetails[sku.id]};

        setShowDeliveryAreaDetails(newShowDeliveryAreaDetails);
    };

    const handleQuantitySelection = (event: any, sku: any) => {
        sku.quantity = event.target.value;
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, quantity: event.target.value};
                }
                return selectedSku;
            })
        )
    };

    const setCogsPrice = (event: any, sku: any) => {
        sku.cogsPrice = parseFloat(event.target.value);
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, nsvPercentage: sku.nsv, targetPrice: parseFloat(sku.cogsPrice), sellingPrice: parseFloat(sku.gsvPrice)};
                }
                return selectedSku;
            })
        );
    }

    const handleCogsPrice = (event: any, sku: any) => {
        sku.cogsPrice = parseFloat(event.target.value);
        if (sku.cogsPrice != "" && sku.cogsPrice > 0) {
            if (sku.nsv > 0) {
                sku.gsvPrice = (sku.cogsPrice + (sku.cogsPrice * sku.nsv / 100)).toFixed(2);
            } else if (sku.gsvPrice > 0) {
                sku.nsv = ((parseFloat(sku.gsvPrice) - sku.cogsPrice) / sku.cogsPrice * 100).toFixed(2);
            }
        }
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, nsvPercentage: sku.nsv, targetPrice: parseFloat(sku.cogsPrice), sellingPrice: parseFloat(sku.gsvPrice)};
                }
                return selectedSku;
            })
        );
    };

    const setBasicPrice = (event: any, sku: any) => {
        sku.basicPrice = parseFloat(event.target.value);
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, nsvPercentage: sku.nsv, targetPrice: parseFloat(sku.basicPrice), sellingPrice: parseFloat(sku.gsvPrice)};
                }
                return selectedSku;
            })
        );
    }

    const handleBasicPrice = (event: any, sku: any) => {
        sku.basicPrice = parseFloat(event.target.value);
        if (sku.basicPrice != "" && sku.basicPrice > 0) {
            if (sku.nsv > 0) {
                sku.gsvPrice = (sku.basicPrice + (sku.basicPrice * sku.nsv / 100)).toFixed(2);
            } else if (sku.gsvPrice > 0) {
                sku.nsv = ((parseFloat(sku.gsvPrice) - sku.basicPrice) / sku.basicPrice * 100).toFixed(2);
            }
        }
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, nsvPercentage: sku.nsv, targetPrice: parseFloat(sku.basicPrice), sellingPrice: parseFloat(sku.gsvPrice)};
                }
                return selectedSku;
            })
        );
        console.log(selectedSkus);
    };

    const setGsvPrice = (event: any, sku: any) => {
        sku.gsvPrice = parseFloat(event.target.value);

        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, nsvPercentage: sku.nsv, targetPrice: sku.cogsPrice, sellingPrice: sku.gsvPrice};
                }
                return selectedSku;
            })
        );
    };

    const handleGsvPrice = (event: any, sku: any) => {
        sku.gsvPrice = parseFloat(event.target.value);

        if(isChairCategory) {
             sku.cogsPrice = sku.basicPrice;
        }

        if (sku.gsvPrice != "" && sku.gsvPrice > 0) {
            if (sku.nsv > 0) {
                sku.cogsPrice = (( sku.gsvPrice * 100 ) / ( 100 + parseInt(sku.nsv))).toFixed(2);

                sku.basicPrice = sku.cogsPrice;
            } else if (sku.cogsPrice > 0) {
                sku.nsv = ((sku.gsvPrice - parseFloat(sku.cogsPrice)) / sku.cogsPrice * 100).toFixed(2);
            }
        }
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, nsvPercentage: sku.nsv, targetPrice: sku.cogsPrice, sellingPrice: sku.gsvPrice};
                }
                return selectedSku;
            })
        );
    };

    const setNsv = (event: any, sku: any) => {
        sku.nsv = event.target.value;

        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, nsvPercentage: sku.nsv, targetPrice: parseFloat(sku.cogsPrice), sellingPrice: parseFloat(sku.gsvPrice)};
                }
                return selectedSku;
            })
        );
    };

    const handleNsv = (event: any, sku: any) => {
        sku.nsv = event.target.value;

        if(isChairCategory) {
             sku.cogsPrice = !isNaN(sku.basicPrice) ? parseFloat(sku.basicPrice) : 0;
        }

        if (sku.nsv != "" && sku.nsv > 0) {
            if (sku.cogsPrice > 0) {
                sku.gsvPrice = (sku.cogsPrice + (sku.cogsPrice* parseFloat(sku.nsv) / 100)).toFixed(2);
            } else if (sku.gsvPrice > 0) {
                sku.cogsPrice = (( parseFloat(sku.gsvPrice) * 100 ) / ( 100 + parseInt(sku.nsv))).toFixed(2);
                sku.basicPrice = sku.cogsPrice;
            }
        }
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, nsvPercentage: sku.nsv, targetPrice: parseFloat(sku.cogsPrice), sellingPrice: parseFloat(sku.gsvPrice)};
                }
                return selectedSku;
            })
        );
    };

    const handleTargetPrice = (event: any, sku: any) => {
        sku.targetPrice = event.target.value;
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, targetPrice: event.target.value};
                }
                return selectedSku;
            })
        );
    };

    const handleDeliveryArea = (sku: any, data: any) => {
        let totalQty = 0;
        data.map((deliveryArea: any) => {
            totalQty += parseInt(deliveryArea.quantity);
        });
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, deliveryAreas: data, quantity: totalQty};
                }
                return selectedSku;
            })
        );
        sku.deliveryAreas = data;
        sku.quantity = totalQty;
        const newShowDeliveryAreaDetails = {...showDeliveryAreaDetails, [sku.id]: !showDeliveryAreaDetails[sku.id]};
        setShowDeliveryAreaDetails(newShowDeliveryAreaDetails);
    };

    const handleDeliveryAreaSelection = (sku: any) => {
        const newShowDeliveryAreaDetails = {...showDeliveryAreaDetails, [sku.id]: !showDeliveryAreaDetails[sku.id]};

        setShowDeliveryAreaDetails(newShowDeliveryAreaDetails);
    }

    const loadOnlySelectedSkus = (event: any) => {
        if(showAllSelectedSks) {
            setShowAllSelectedSks(false);
            loadSelectedSkus();
        } else {
            setHasNext(false);
            setHasPrev(false);
            setShowAllSelectedSks(true);
            loadSelectedSkuDetails(selectedSkus);
        }
    }

    const goToPrevSearchPage = () => {
        if(hasPrev && !searchApiCalled) {
            let nextPage = searchPage - 1;
            if(nextPage < 0) {
                nextPage = 0;
            }
            setSearchPage(nextPage);
            setSearchApiCalled(true);
            loadSkus(selectedSkus, nextPage);
        }
    }

    const goToNextSearchPage = () => {
        if(hasNext && !searchApiCalled) {
            let nextPage = searchPage + 1;
            setSearchPage(nextPage);
            setSearchApiCalled(true);
            loadSkus(selectedSkus, nextPage);
        }
    }

    const loadSkus = (estimationSkus: any, page:any) => {
        setIsLoading(true);
        props.updateApiLoading(true);
        let chairCategory = false;

        props.category.subCategories.map((subcategory:any) => {
            if(subcategory.id == props.subCategoryId && (subcategory.name.toLowerCase() == 'chairs' || subcategory.name.toLowerCase() == 'carpet')) {
                chairCategory = true;
            }
        });

        const params = new URLSearchParams({'subcategory_id' : props.subCategoryId, 'pageNo': page, 'pageSize': '50'});
        if (Object.keys(props.appliedFilters).length > 0) {
            Object.entries(props.appliedFilters).map(([name, value]: any) => {
                params.append(name, value);
            });
        }
        let url = `/catalogue/item-category/search/${project.country}/${project.location}`;
        console.log("isChairCategory ", chairCategory);
        
        api.get(url, {
                params: params,
            })
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {

                response.skus.forEach((sku: any) => {
                    sku.nsv = parseFloat(sku.nsv).toFixed(2);
                    if(chairCategory) {
                        let chairBasicPrice = sku.basicPrice != null ? sku.basicPrice : 0;
                        let chairGsv = sku.gsvPrice != null ? sku.gsvPrice : 0;
                        if(chairBasicPrice > 0 && chairGsv > 0)  {
                            sku.nsv = ((chairGsv - parseFloat(chairBasicPrice)) / chairBasicPrice * 100).toFixed(2);
                        } else {
                            sku.nsv = 0;
                        }
                        console.log(chairBasicPrice + " : " +sku.nsv+ " : " +chairGsv);
                    }

                    sku.targetPrice = chairCategory ? (sku.cogsPrice != null ? sku.cogsPrice : "") : (sku.cogsPrice != null ? sku.cogsPrice : "");
                    sku.nsvPercentage = sku.nsv != null ? parseFloat(sku.nsv).toFixed(2) : "";
                    sku.quantity = "";
                    sku.deliveryAreas = [];
                    sku.selectedEntity = {};
                    sku.entityId = "";
                    estimationSkus.forEach((selectedSku: any) => {
                        if (selectedSku.id === sku.id) {
                            sku.cogsPrice = selectedSku.targetPrice;
                            sku.basicPrice = selectedSku.targetPrice;
                            sku.quantity = selectedSku.quantity;
                            sku.deliveryAreas = selectedSku.deliveryAreas;
                            //selectedSku.gsvPrice = sku.gsvPrice;
                            selectedSku.basicPrice = sku.basicPrice;

                            sku.gsvPrice = selectedSku.sellingPrice;
                            sku.nsv = selectedSku.nsvPercentage;


                            /*if (sku.hasOwnProperty("entityPrices") && sku.entityPrices != null) {
                                sku?.entityPrices.map((entityPrice: any) => {
                                    if (selectedSku.entityId == entityPrice.entityId) {
                                        selectedSku.selectedEntity = entityPrice;
                                        sku.selectedEntity = entityPrice;
                                        sku.entityId = selectedSku.entityId;
                                    }
                                });
                            }*/
                        }
                    });
                });
                setSkus(response.skus);
                setHasNext(response.hasNext);
                setHasPrev(response.hasPrev);
                setIsLoading(false);
                props.updateApiLoading(false);
                setSearchApiCalled(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
                setSearchApiCalled(false);
                props.updateApiLoading(false);
            });
    };

    const handleViewDetails = async (skuId: any) => {
        const newShowViewDetails = {...showViewDetails, [skuId]: !showViewDetails[skuId]};

        setShowViewDetails(newShowViewDetails);
    };

    const handlePriceChange = (sku: any) => {
        const newShowChangePrice = {...showChangePrice, [sku.id]: !showChangePrice[sku.id]};

        setShowChangePrice(newShowChangePrice);
    }

    const loadSelectedSkus = () => {
        setShowAllSelectedSks(false);
        //let url = `/procurement/e-catalog/get-item-details/${props.project.id}/${props.subCategoryId}/${item.id}`;
        //if (props.tab == 'estimation')
            //let url = `/procurement/estimation/get-selected-items/${props.project.id}/${props.subCategoryId}`;
        let url = `/procurement/e-catalog/get-subcategory-item-details/${props.project.id}/${props.subCategoryId}`;
        setIsLoading(true);
        props.updateApiLoading(true);
        api.get(url)
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                const estimationSkus: any = [];
                let assignedQty: number = 0;
                if (Array.isArray(response) && response.length > 0) {
                    response.forEach((sku: any) => {
                        let selectedSku = {...sku, id: sku.skuId};
                        estimationSkus.push(selectedSku);
                        assignedQty += sku.quantity
                    });
                    props.updateAssignedQty(assignedQty);

                    setSelectedSkus(estimationSkus);
                } else {
                    props.updateAssignedQty(assignedQty);
                    setSelectedSkus([]);
                }

                setSearchPage(0);
                loadSkus(estimationSkus, 0);
                //loadSelectedSkuDetails(estimationSkus);
            })
            .catch((ex) => {
                console.log(ex);
                setECatalogError(ex);
                setIsLoading(false);
                props.updateApiLoading(false);
            });
    };

    const loadSelectedSkuDetails = (selectedSkus: any) => {
        //let url = `/procurement/e-catalog/get-item-details/${props.project.id}/${props.subCategoryId}/${item.id}`;
        //if (props.tab == 'estimation')
            //let url = `/procurement/estimation/get-selected-items/${props.project.id}/${props.subCategoryId}`;
        let url = `/catalogue/sku/get-by-ids/india/south`;
        setIsLoading(true);
        props.updateApiLoading(false);
        
        let skuIds:any = [];
        let sku_check;
        selectedSkus.forEach((selectedSku: any) => {
            skuIds.push(selectedSku.id);
            sku_check = selectedSku.id;
        });

        let form = {
                "ids":skuIds
            };
        
        let chairCategory = false;

        props.category.subCategories.map((subcategory:any) => {
            if(subcategory.id == props.subCategoryId && (subcategory.name.toLowerCase() == 'chairs' || subcategory.name.toLowerCase() == 'carpet')) {
                chairCategory = true;
            }
        });

        api.post(url, form)
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                response.forEach((sku: any) => {
                    sku.nsv = parseFloat(sku.nsv).toFixed(2);
                    if(chairCategory) {
                        let chairBasicPrice = sku.basicPrice != null ? sku.basicPrice : 0;
                        let chairGsv = sku.gsvPrice != null ? sku.gsvPrice : 0;
                        if(chairBasicPrice > 0 && chairGsv > 0)  {
                            sku.nsv = ((chairGsv - parseFloat(chairBasicPrice)) / chairBasicPrice * 100).toFixed(2);
                        } else {
                            sku.nsv = 0;
                        }
                    }


                    sku.targetPrice = chairCategory ? (sku.cogsPrice != null ? sku.cogsPrice : "") : (sku.cogsPrice != null ? sku.cogsPrice : "");
                    sku.nsvPercentage = sku.nsv != null ? parseFloat(sku.nsv).toFixed(2) : "";

                    sku.quantity = "";
                    sku.deliveryAreas = [];
                    sku.selectedEntity = {};
                    sku.entityId = "";
                    selectedSkus.forEach((selectedSku: any) => {
                        if (selectedSku.id === sku.id) {
                            sku.cogsPrice = selectedSku.targetPrice;
                            sku.basicPrice = selectedSku.targetPrice;
                            sku.quantity = selectedSku.quantity;
                            sku.deliveryAreas = selectedSku.deliveryAreas;
                            //selectedSku.gsvPrice = sku.gsvPrice;
                            selectedSku.basicPrice = sku.basicPrice;

                            sku.gsvPrice = selectedSku.sellingPrice;
                            sku.nsv = selectedSku.nsvPercentage;


                            /*if (sku.hasOwnProperty("entityPrices") && sku.entityPrices != null) {
                                sku?.entityPrices.map((entityPrice: any) => {
                                    if (selectedSku.entityId == entityPrice.entityId) {
                                        selectedSku.selectedEntity = entityPrice;
                                        sku.selectedEntity = entityPrice;
                                        sku.entityId = selectedSku.entityId;
                                    }
                                });
                            }*/
                        }
                    });
                });
                setSkus(response);
                setIsLoading(false);
                props.updateApiLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
                props.updateApiLoading(false);
            });
    };

    const handleVendorSelection = (sku: any, entityPrice: any) => {
        sku.selectedEntity = entityPrice;
        const newShowChangePrice = {...showChangePrice, [sku.id]: !showChangePrice[sku.id]};
        setShowChangePrice(newShowChangePrice);
        selectedSkus.map((selectedSku: any) => {
            if (selectedSku.id == sku.id) {
                selectedSku.sellingPrice = entityPrice.sellingPrice;
                selectedSku.selectedEntity = entityPrice;
            }
        });
    }
    
    if (isLoading) {
        return <ECatalogLoader eCatalogError={eCatalogError} />
    }

    return (
        <Box>
            <Box>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    {item?.name}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body-1"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            <Box>
                                <Grid
                                    container
                                    alignItems={"stretch"}
                                    sx={{py: 2, backgroundColor: "info.light"}}
                                >
                                    <Grid item xs={props.tab == 'estimation' ? 3 : 3.5} sx={{textAlign: "center"}}>
                                        <Checkbox
                                                sx={{margin: '0', padding: '0', float: 'left', marginLeft: '20px'}}
                                                color={"info"}
                                                checked={showAllSelectedSks}
                                                onChange={(e: any) =>loadOnlySelectedSkus(e)}
                                            />
                                            <Box sx={{float:'left',padding: '3px 0 0 3px', display: 'inline-block'}}>
                                            <CustomTooltip 
                                            info={"Click to view selected SKUs only"}/>
                                            </Box>
                                        Item Sku Name
                                    </Grid>
                                    <Grid item xs={3} 
                                        sx={{px: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Typography variant="body1">Description/Specs</Typography>
                                        <Button variant={"text"} onClick={handleToggleDescription}
                                                sx={{fontSize: "0.7rem", p: 0, m: 0}}>
                                            {showDescription ? "Show Specs" : "Show Description"}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={1.25} sx={{textAlign: "center"}}>
                                        {isChairCategory ? "Basic Price": "Planned COGS"}
                                    </Grid>
                                    <Grid item xs={0.75} sx={{textAlign: "center"}}>
                                        NSV
                                    </Grid>
                                    <Grid item xs={1.25} sx={{textAlign: "center", px: 1}}>
                                        GSV
                                    </Grid>
                                    <Grid item sx={{textAlign: "center"}} xs={1.25}>
                                        Quantity
                                    </Grid>
                                    {props.tab == 'estimation' &&
                                        <Grid item xs={1.5} sx={{textAlign: "center"}}>
                                            Delivery Area
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                            {skus.map((sku: any, index: number) => {
                                sku.sellingPrice = sku.gsvPrice;
                                /*let entityName = "";
                                let totalEntities = sku?.entityPrices ? sku?.entityPrices.length : Object.keys(sku.selectedEntity).length;
                                if (totalEntities > 0) {
                                    if (Object.keys(sku?.selectedEntity).length == 0) {
                                        sku.entityPrices.forEach((entityPrice: any) => {
                                            if (sku.sellingPrice < entityPrice.sellingPrice) {
                                                sku.sellingPrice = entityPrice.sellingPrice;
                                                sku.selectedEntity = entityPrice;
                                            }
                                        });
                                    }

                                    vendorEntities.map((entity: any) => {
                                        if (entity.id == sku.selectedEntity.entityId) {
                                            entityName = entity.name;
                                        }
                                    });

                                    brandEntities.map((entity: any) => {
                                        if (entity.id == sku.selectedEntity.entityId) {
                                            entityName = entity.name;
                                        }
                                    });
                                }*/
                                return (
                                    <Box key={"sku-index-" + index}>
                                        <Grid
                                            container
                                            alignItems={"stretch"}
                                            key={`sku-estimation-${sku.id}-${index}`}
                                        >
                                            <Grid
                                                item
                                                xs={0.5}
                                                sx={{
                                                    border: 1,
                                                    borderColor: "divider",
                                                    pt: 2,
                                                    borderTop: 0,
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Checkbox
                                                    color={"info"}
                                                    checked={selectedSkus
                                                        .map((selectedSku: any) => selectedSku.id)
                                                        .includes(sku.id)}
                                                    onChange={(e: any) =>
                                                        handleSkuSelection(
                                                            e,
                                                            sku
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={props.tab == 'estimation' ? 2.5 : 3}
                                                sx={{
                                                    border: 1,
                                                    borderColor: "divider",
                                                    p: 1,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                }}
                                            >
                                                <Grid container>
                                                    <Grid item xs={4} sx={{pr: 1}}>
                                                        {sku.primaryImage != null && (
                                                            <Box>
                                                                <img
                                                                    src={sku.primaryImage}
                                                                    className={"sku-image"}
                                                                    style={{width: "100%"}}
                                                                    alt={sku.name}
                                                                />
                                                            </Box>
                                                        )}
                                                        {sku.primaryImage == null && (
                                                            <Box
                                                                sx={{
                                                                    width: "100%",
                                                                    height: "80px",
                                                                    backgroundColor: "secondary.light",
                                                                }}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Box>
                                                            <Typography
                                                                variant={"body1"}
                                                                sx={{fontSize: "0.9rem"}}
                                                            >
                                                                {sku.name}
                                                            </Typography>
                                                            <Box sx={{pt: 3}}>
                                                                <Typography
                                                                    variant={"body2"}
                                                                    sx={{fontSize: "0.8rem"}}
                                                                >
                                                                    Label - {sku.code}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={props.tab == 'estimation' ? 2.5 : 3}
                                                sx={{
                                                    border: 1,
                                                    borderColor: "divider",
                                                    p: 1,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                }}
                                            >
                                                {showDescription ? (
                                                    <ReadMore
                                                        text={sku.description}
                                                        maxChars={200}
                                                        className={"sku-desc"}
                                                    />
                                                ) : (
                                                    <Box>
                                                        {sku.options && sku.options.length > 0 ? (
                                                            sku.options.map((option: any, idx: number) => (
                                                                <Typography key={idx} variant={"body2"}
                                                                            sx={{fontSize: "0.8rem", padding: '0.4rem'}}>
                                                                    <strong>{option.name}:</strong> {option.value}
                                                                </Typography>
                                                            ))
                                                        ) : (
                                                            <Typography sx={{fontSize: "0.8rem", padding: '0.4rem'}}>
                                                                NA
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={1.25}
                                                sx={{
                                                    border: 1,
                                                    borderColor: "divider",
                                                    p: 1,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                }}
                                            >
                                                {isChairCategory && <Box
                                                    className={"center-aligned"}
                                                    sx={{height: "100%"}}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        disabled={!selectedSkus.map((selectedSku: any) => selectedSku.id).includes(sku.id)}
                                                        size={"small"}
                                                        type={"number"}
                                                        variant={"outlined"}
                                                        className={"inputBox"}
                                                        value={parseFloat(sku?.basicPrice)}
                                                        onChange={(e) => setBasicPrice(e, sku)}
                                                        onBlur={(e) => handleBasicPrice(e, sku)}
                                                        inputProps={{
                                                            type: "number"
                                                        }}
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "5px",
                                                                borderColor: "primary.light",
                                                                fontSize: "0.8rem"
                                                            },
                                                        }}
                                                    />
                                                </Box>}
                                                {!isChairCategory && <Box
                                                    className={"center-aligned"}
                                                    sx={{height: "100%"}}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        disabled={!selectedSkus.map((selectedSku: any) => selectedSku.id).includes(sku.id)}
                                                        size={"small"}
                                                        type={"number"}
                                                        variant={"outlined"}
                                                        className={"inputBox"}
                                                        value={parseFloat(sku?.cogsPrice)}
                                                        onChange={(e) => setCogsPrice(e, sku)}
                                                        onBlur={(e) => handleCogsPrice(e, sku)}
                                                        inputProps={{
                                                            type: "number"
                                                        }}
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "5px",
                                                                borderColor: "primary.light",
                                                                fontSize: "0.8rem"
                                                            },
                                                        }}
                                                    />
                                                </Box>}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={0.75}
                                                sx={{
                                                    border: 1,
                                                    borderColor: "divider",
                                                    p: 1,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                    textAlign: "right",
                                                }}
                                            >
                                                <Box
                                                    className={"center-aligned"}
                                                    sx={{height: "100%"}}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        disabled={!selectedSkus.map((selectedSku: any) => selectedSku.id).includes(sku.id)}
                                                        size={"small"}
                                                        type={"number"}
                                                        variant={"outlined"}
                                                        className={"inputBox"}
                                                        value={sku?.nsv}
                                                        onChange={(e) => setNsv(e, sku)}
                                                        onBlur={(e) => handleNsv(e, sku)}
                                                        inputProps={{
                                                            type: "number",
                                                        }}
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "5px",
                                                                borderColor: "primary.light",
                                                                fontSize: "0.8rem"
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={1.25}
                                                sx={{
                                                    border: 1,
                                                    borderColor: "divider",
                                                    p: 1,
                                                    // pt: 2,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                    textAlign: "right",
                                                }}
                                            >
                                                <Box
                                                    className={"center-aligned"}
                                                    sx={{height: "100%"}}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        disabled={!selectedSkus.map((selectedSku: any) => selectedSku.id).includes(sku.id)}
                                                        size={"small"}
                                                        type={"number"}
                                                        variant={"outlined"}
                                                        className={"inputBox"}
                                                        value={parseFloat(sku?.gsvPrice)}
                                                        onChange={(e) => setGsvPrice(e, sku)}
                                                        onBlur={(e) => handleGsvPrice(e, sku)}
                                                        inputProps={{
                                                            type: "number"
                                                        }}
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "5px",
                                                                borderColor: "primary.light",
                                                                fontSize: "0.8rem"
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={1.25}
                                                sx={{
                                                    border: 1,
                                                    borderColor: "divider",
                                                    p: 1,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                }}
                                            >
                                                <Box
                                                    className={"center-aligned"}
                                                    sx={{height: "100%"}}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        disabled={
                                                            !selectedSkus
                                                                .map((selectedSku: any) => selectedSku.id)
                                                                .includes(sku.id)
                                                        }
                                                        size={"small"}
                                                        type={"number"}
                                                        variant={"outlined"}
                                                        className={"inputBox"}
                                                        value={sku?.quantity}
                                                        onChange={(e) => handleQuantitySelection(e, sku)}
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "5px",
                                                                borderColor: "primary.light",
                                                                fontSize: "0.8rem",
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            {props.tab == 'estimation' &&
                                                <Grid
                                                    item
                                                    xs={1.25}
                                                    sx={{
                                                        border: 1,
                                                        borderColor: "divider",
                                                        p: 1,
                                                        borderLeft: 0,
                                                        borderTop: 0,
                                                    }}
                                                >
                                                    {(selectedSkus
                                                            .map((selectedSku: any) => selectedSku.id)
                                                            .includes(sku.id)) &&
                                                        <Box className={"center-aligned"}
                                                             sx={{height: "100%", flexDirection: 'column'}}>
                                                            {sku.deliveryAreas.length > 0 ? (
                                                                <Typography sx={{fontSize: '0.8rem'}}>
                                                                    Selected Delivery Areas
                                                                </Typography>
                                                            ) : (
                                                                <Typography sx={{fontSize: '0.8rem'}}>
                                                                    NA
                                                                </Typography>
                                                            )}
                                                            {!showDeliveryAreaDetails[sku.id] ? (
                                                                <Typography
                                                                    sx={{fontSize: '0.8rem', cursor: 'pointer', color: 'primary.main'}}
                                                                    onClick={() => handleDeliveryAreaSelection(sku)}>
                                                                    Show
                                                                </Typography>
                                                            ) : (
                                                                <Typography
                                                                    sx={{fontSize: '0.8rem', cursor: 'pointer', color: 'primary.main'}}
                                                                    onClick={() => handleDeliveryAreaSelection(sku)}>
                                                                    Hide
                                                                </Typography>
                                                            )
                                                            }
                                                        </Box>
                                                    }
                                                </Grid>
                                            }
                                            <Grid
                                                item
                                                xs={1}
                                                sx={{
                                                    border: 1,
                                                    borderColor: "divider",
                                                    p: 1,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                }}
                                            >
                                                <Box
                                                    className={"center-aligned"}
                                                    sx={{height: "100%"}}
                                                >
                                                    <Button onClick={() => handleViewDetails(sku.id)}>
                                                        {showViewDetails[sku.id] ? "Hide Details" : "View Details"}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        {showViewDetails[sku.id] && (
                                            <EstimationSkuDetails skuId={sku.id}/>
                                        )
                                        }
                                        {showChangePrice[sku.id] &&
                                            <ChangeEntityPrice
                                                sku={sku}
                                                vendorEntities={vendorEntities}
                                                brandEntities={brandEntities}
                                                handleVendorSelection={handleVendorSelection}
                                            />
                                        }
                                        {(props.tab == 'estimation' && showDeliveryAreaDetails[sku.id]) &&
                                            <SelectDeliveryArea
                                                sku={sku}
                                                handleDeliveryArea={handleDeliveryArea}
                                            />
                                        }
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                    <Box className="saveECatBtn" sx={{position: "fixed",bottom: 0,width: "93%",height: "8%",borderTop: 1,borderColor: 'divider', backgroundColor: '#fff'}}>
                        <Grid
                            container
                            sx={{px: 2, height: "100%"}}
                            className={"center-aligned"}
                        >
                            <Grid item xs/>
                                <Box sx={{width: '100px', display: 'flex'}}>
                                <Box sx={{ margin: '0 10px'}} onClick={goToPrevSearchPage}> <Typography sx={{fontSize: '1.4rem', fontWeight: '700', color: hasPrev ? '#999' : '#d5d5d5', cursor: hasPrev ? 'pointer': 'default'}}>{"<"}</Typography> </Box>
                                <Box sx={{ margin: '0 10px'}} onClick={goToNextSearchPage}> <Typography sx={{fontSize: '1.4rem', fontWeight: '700', color: hasNext ? '#999' : '#d5d5d5', cursor: hasNext ? 'pointer': 'default'}}>{">"}</Typography> </Box>
                                </Box>

                                <Grid item>
                                    <Box>
                                        <Button
                                            variant={"contained"}
                                            sx={{fontSize: "0.8rem"}}
                                            onClick={saveSkuSelection}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default EstimationSkuSearchList;