import {useParams} from "react-router-dom";
import {api} from "../../utils/Axios";
import React, {useEffect, useState} from "react";
import Loader from "../../components/Loader";
import {Box, Button, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Typography} from "@mui/material";
import PageHeading from "../../components/page-heading/PageHeading";
import {allReports} from "./config";
import {openSnackbar} from "../../components/common/features/snackbarSlice";
import {SUCCESS_COLOR} from "../../constants";
import {useAppDispatch} from "../../app/hooks";

const Reports = () => {
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [selectedReport, setSelectedReport] = useState<any>({});


    const downloadReport = () => {
        setIsLoading(true);
        api.get(selectedReport.api).then((response: any) => {
            return response.data;
        }).then((response: any) => {
            const currentTime = new Date().valueOf();
            const url = window.URL.createObjectURL(new Blob([response]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', selectedReport.id + "-" + currentTime + selectedReport.extention)
            document.body.appendChild(link)
            link.click();
            link.remove();
            dispatch(openSnackbar({message: 'Report successfully downloaded', backgroundColor: SUCCESS_COLOR}));
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const handleReportModuleChange = (event: any) => {
        const {name, value} = event.target;
        setSelectedReport({});
        Object.entries(allReports).map(([key, reports]: any) => {
            if (key == value) {
                setReportList(reports);
            }
        })
    }

    const handleReportNameChange = (event: any) => {
        const {name, value} = event.target;
        reportList.forEach((report: any) => {
            if (report.id == value) {
                setSelectedReport(report);
            }
        });
    }

    useEffect(() => {

    }, [id]);

    if (isLoading) {
        return <Loader/>
    }
    return (
        <Box className={"common-space-container"}>
            <PageHeading title={"Reports"}/>
            <Box className={"center-aligned"}>
                <Box sx={{width: '50%'}}>
                    <Grid container spacing={5}>
                        <Grid item xs={6}>
                            <InputLabel sx={{pb: 1, fontSize: "0.8rem"}}>Select Report Module</InputLabel>
                            <Select
                                fullWidth
                                displayEmpty
                                labelId="demo-simple-select-label"
                                name={"reportModule"}
                                onChange={handleReportModuleChange}
                                defaultValue={""}
                                style={{height: '40px'}}
                            >
                                <MenuItem disabled value="">
                                    <em style={{color: '#a9a9a9'}}>Select Report Module</em>
                                </MenuItem>
                                {Object.entries(allReports).map(([key, value]: any, index: number) => (
                                    <MenuItem key={"report-form-select-" + index}
                                              value={key}>{key.toUpperCase()}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel sx={{pb: 1, fontSize: "0.8rem"}}>Select Report</InputLabel>
                            <Select
                                fullWidth
                                displayEmpty
                                labelId="demo-simple-select-label-report-name"
                                name={"reportName"}
                                onChange={handleReportNameChange}
                                defaultValue={""}
                                style={{height: '40px'}}
                            >
                                <MenuItem disabled value="">
                                    <em style={{color: '#a9a9a9'}}>Select Report</em>
                                </MenuItem>
                                {reportList.map((report: any, index: number) => (
                                    <MenuItem key={"report-form-select-report-name" + index}
                                              value={report.id}>{report.name}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        {Object.keys(selectedReport).length > 0 &&
                            <Grid item xs={12}>
                                <Typography variant={"h4"} sx={{pb: 2}}>{selectedReport?.name}</Typography>
                                <Typography variant={"body2"}>{selectedReport?.description}</Typography>
                                {selectedReport.features.length > 0 &&
                                    <Box sx={{pt: 2}}>
                                        <Typography variant={"h5"} sx={{pb: 2}}>Key Features</Typography>
                                        <List sx={{listStyleType: 'disc', px: 4}}>
                                            {selectedReport.features.map((feature: any, index: number) => {
                                                return (
                                                    <ListItem sx={{display: 'list-item'}}>
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    sx={{fontWeight: 700}}>{feature.title}:</Typography>
                                                            }
                                                            secondary={
                                                                <Typography>{feature.description}</Typography>
                                                            }/>
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    </Box>
                                }
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
            {Object.keys(selectedReport).length > 0 &&
                <Box sx={{height: '100px'}} className={"center-aligned vertically-center-aligned"}>
                    <Button variant="contained" onClick={downloadReport}
                            sx={{margin: "0 16px"}}>
                        Download Report
                    </Button>
                </Box>
            }
        </Box>
    )
}

export default Reports;