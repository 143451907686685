import {a11yProps} from "../../../../utils/TabsUtils";
import {Box, Tab, Typography} from "@mui/material";
import {useDrag} from "react-dnd";
import {ItemTypes} from "../../../../modules/project/plan/tabs/boq-tabs/ItemTypes";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import React, {useEffect, useState} from "react";

const EstimationItemsTabWidget = (props: any) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [{isDragging}, subEstimationItemDrag] = useDrag({
        type: ItemTypes.ITEM,
        item: {type: ItemTypes.ITEM, id: props.estimationItem.id},
        canDrag: !isDisabled,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    useEffect(() => {
        let isAllItemsDragged = true;
        props.estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
            if (estimationItemSku?.disabled == false) {
                isAllItemsDragged = false;
            }
        });
        setIsDisabled(isAllItemsDragged);
    }, [props]);

    const handleTabClick = () => {
        if (props.onChange) {
            props.onChange(null, props.estimationItem.id);
        }
    };

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 0,
            backgroundColor: '#fff',
            cursor: isDisabled ? "not-allowed" : "move",
            opacity: isDisabled || isDragging ? 0.5 : 1,
        }}>
            <DragIndicatorIcon sx={{fontSize: "20px"}}/>
            <Tab
                sx={{
                    padding: "0 4px",
                    fontSize: "13px",
                    fontWeight: isDisabled ? 500 : 600,
                    minWidth: 0,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1,
                    minHeight: 0
                }}
                ref={subEstimationItemDrag}
                {...a11yProps(props.estimationItem.id, true)}
                value={props.itemIndex}
                onClick={handleTabClick}
                label={
                    <Typography className="tab-label" sx={{
                        // cursor: isDisabled ? "not-allowed" : "move",
                        color: '#000', backgroundColor: '#fff'
                    }}>{props.estimationItem.item.name ? props.estimationItem.item.name : props.estimationItem.item.id}</Typography>
                }
                className="tab-category"
            />
        </Box>
    )
}
export default EstimationItemsTabWidget;