import {createAsyncThunk} from "@reduxjs/toolkit";
import {api} from "../../../utils/Axios";


interface ApiResponse {
// Define the properties of the API response here
}


export const getEstimationDetails = createAsyncThunk<ApiResponse, string>('estimation/details-data', async (revisionId) => {
    try {
        const response = await api.get(
            `/procurement/estimation/details/${revisionId}`
        );
        return response.data
    } catch (error: any) {
        throw Error(error.message || 'Failed to load estimation details');
    }
})
