import { createSlice } from "@reduxjs/toolkit";
import { removeSkuPrices } from "./actions";

const initialState = {
        loading: false,
        removedResponse: {},
        error: ''
}
const skuSlice = createSlice({
        name: 'sku',
        initialState,
        reducers: {},
        extraReducers: (builder) => {
                builder.addCase(removeSkuPrices.pending, (state) => {
                        state.loading = true
                        state.error = ''
                })
                builder.addCase(removeSkuPrices.fulfilled, (state, action: any) => {
                        state.loading = false
                        state.removedResponse = action.payload
                        state.error = ''
                })
                builder.addCase(removeSkuPrices.rejected, (state, action: any) => {
                        state.loading = false
                        state.error = action.payload
                })
        }

})

export default skuSlice.reducer