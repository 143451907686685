import {createSlice} from "@reduxjs/toolkit";
import {loadCatalogReport, loadCategoryReport, loadItemCategories, updateCogsPrice} from "./action";

interface CatalogReport {
    id: string,
    name: string;
    totalSkus: number,
    rcSkus: number,
    withoutRcSkus: number,
    subCategories: any[]

}

interface CatalogReportState {
    loading: boolean;
    catalogueLoading: boolean;
    categoryReport: CatalogReport | null;
    subCategoryReport: CatalogReport[] | null;
    itemCategories: CatalogReport[] | null;
    catalogCount: any;
    error: string;
    isUpdatingCogs: boolean,
    updatedCogs: {},
    updatedCogsError: string
}


const initialState: CatalogReportState = {
    loading: false,
    catalogueLoading: false,
    categoryReport: null,
    subCategoryReport: null,
    itemCategories: [],
    catalogCount: {},
    error: '',
    isUpdatingCogs: false,
    updatedCogs: {},
    updatedCogsError: ""
};
const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // @ts-ignore
        builder
            .addCase(loadItemCategories.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(loadItemCategories.fulfilled, (state: any, action) => {
                state.loading = false;
                state.itemCategories = action.payload;
                state.error = '';
            })
            .addCase(loadItemCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
                    ? action.payload.toString()
                    : 'Failed to fetch item categories report';
            })
            .addCase(loadCategoryReport.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(loadCategoryReport.fulfilled, (state: any, action) => {
                state.loading = false;
                state.categoryReport = action.payload;
                state.error = '';
            })
            .addCase(loadCategoryReport.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
                    ? action.payload.toString()
                    : 'Failed to fetch categories report';
            })
            .addCase(loadCatalogReport.pending, (state) => {
                state.catalogueLoading = true;
                state.error = '';
            })
            .addCase(loadCatalogReport.fulfilled, (state: any, action) => {
                state.catalogueLoading = false;
                state.catalogCount = action.payload;
                state.error = '';
            })
            .addCase(loadCatalogReport.rejected, (state, action) => {
                state.catalogueLoading = false;
                state.error = action.payload
                    ? action.payload.toString()
                    : 'Failed to fetch catalog report';
            })
            .addCase(updateCogsPrice.pending, (state: any, action) => {
                state.isUpdatingCogs = true;
            })
            .addCase(updateCogsPrice.fulfilled, (state: any, action) => {
                state.isUpdatingCogs = false;
                state.updatedCogs = action.payload;
            })
            .addCase(updateCogsPrice.rejected, (state: any, action) => {
                state.isUpdatingCogs = false;
                state.updatedCogsError = action.payload;
            })
    }
});

// // Selectors
// export const selectCategories = (state: any) => state.categories.category.categories;
// export const selectSubCategories = (state: any) => state.categories.category.subCategories;
export const selectCategoryReport = (state: any) => state.catalog.categoryReport;
export const selectItemCategoryReport = (state: any) => state.catalog.itemCategoryReport;
export const selectCatalogCountReport = (state: any) => state.catalog.catalogCount;
// export const selectItems = (state: any) => state.categories.category.items;

export default catalogSlice.reducer;