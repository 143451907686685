import {useNavigate, useParams} from "react-router-dom";
import CreateUserComponent from "../../../components/users/CreateUserComponent";
import {useEffect, useRef, useState} from "react";

import {getVendorRoles, submitVendorForm} from "../../user/features/actions";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import Loader from "../../../components/Loader";
import {closeSnackbar, openSnackbar} from "../../../components/common/features/snackbarSlice";
import {api} from "../../../utils/Axios";
import {getBasicVendor} from "../features/actions";
import { ERROR_COLOR, SUCCESS_COLOR } from "../../../constants";
import { useCheckboxValues } from "../../../hooks/useCheckboxValues";

const CreateVendorUser = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state) => state.user.loading);
    const roles = useAppSelector((state) => state.user.vendorRoles);
    const isCreatedVendorUser = useAppSelector((state) => state.user.vendorFormResponse);
    const errorMessage = useAppSelector((state) => state.user.error);
    const timerId = useRef<NodeJS.Timeout | undefined>(undefined);
    const vendor = useAppSelector((state) => state.vendor.basicVendor)
    const { resetSelectedValues } = useCheckboxValues()

    const onSubmitHandler = async (form: any, callback: any) => {
        const vendorId: any = id;
        clearTimeout(timerId.current!);
        const response = await dispatch(submitVendorForm({vendorId, form, callback})).unwrap();
        if (response) {
            dispatch(openSnackbar({message: 'Vendor User created successfully', backgroundColor: SUCCESS_COLOR}));
            navigate(`/vendors/user/${id}`);
        } else if (errorMessage) {
            dispatch(openSnackbar({message: 'Oops. Something went wrong, please try again', backgroundColor: ERROR_COLOR}));
        }
    };

    useEffect(() => {
        dispatch(closeSnackbar());
        dispatch(getVendorRoles());
    }, []);

    useEffect(() => {
        let vendorId: any = id;
        dispatch(getBasicVendor(vendorId));
    }, [id]);


    useEffect(() => {
        resetSelectedValues()
    }, [])

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <CreateUserComponent
            pageName={vendor?.name + ": Create User"}
            roles={roles}
            onSubmitHandler={onSubmitHandler}
        />
    )
}
export default CreateVendorUser;