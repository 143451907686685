import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {ChangeEvent} from "react";

const RadioOptions = (props: any) => {
    const {formField} = props;
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.onChangeHandler(event);
    }
    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={formField.name}
                onChange={handleChange}
                defaultValue={formField?.value}
            >
                {
                    formField.options.map((option: any, index: number) => (
                        <FormControlLabel key={"radio-button-form-" + index + "-" + option.id} value={option.id}
                                          control={<Radio/>} label={option.label}/>
                    ))
                }
            </RadioGroup>
        </FormControl>
    )
}

export default RadioOptions;