export const pageConfig = [
    {field: 'id', headerName: '#', headerClassName: 'data-grid-header', sortable: false, flex: 1},
    {field: 'name', headerName: 'Brand Name', headerClassName: 'data-grid-header', flex: 1, maxWidth: 200},
    {
        field: 'contactPersonName',
        headerName: 'Contact Person Name',
        headerClassName: 'data-grid-header',
        flex: 1,
        maxWidth: 200
    },
    {field: 'city', headerName: 'City', headerClassName: 'data-grid-header', flex: 1, maxWidth: 200},
    {field: 'country', headerName: 'Country', headerClassName: 'data-grid-header', flex: 1, maxWidth: 200},
    {field: 'status', headerName: 'Active?', type: 'status', headerClassName: 'data-grid-header', flex: 1, maxWidth: 150},
    {
        field: 'action', headerName: 'Action',
        headerClassName: 'data-grid-header data-grid-right-aligned-header',
        headerAlign: 'right',
        align: 'right',
        sortable: false,
        flex: 1,
        show: 'button',
        buttons: [
            {
                type: 'view',
                title: 'View',
                variant: 'outlined',
                permissions: ['view-brands'],
                condition: (row: any) => true
            },
            {
                type: 'edit',
                title: 'Edit',
                permissions: ['edit-brands'],
                condition: (row: any) => true
            },
        ]
    }
]