import {Box, Divider, IconButton, Tooltip, Typography} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const CustomTooltip = (props: any) => {
    return (
        <Tooltip
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: 'common.white',
                        boxShadow: 1,
                        '& .MuiTooltip-arrow': {
                            color: 'common.white',
                            "&::before": {
                                backgroundColor: "common.white",
                                boxShadow: 1,
                            }
                        }
                    },
                },
            }}
            title={
                <Box sx={{px: 1, py: 1, color: '#000'}}>
                    {props.info}
                </Box>
            } arrow>
            <IconButton
                sx={{
                    p: 0,
                }}>
                <InfoOutlinedIcon sx={{fontSize: 15}}/>
            </IconButton>
        </Tooltip>
    );
};
export default CustomTooltip;




                            