import {useEffect, useState} from "react";
import {api} from "../../../utils/Axios";
import {useNavigate} from "react-router-dom";
import PageHeading from "../../../components/page-heading/PageHeading";
import {Box} from "@mui/material";
import ListPage from "../../../components/list-page/ListPage";
import {skuPageConfig} from "../../../components/skus/listing/PageConfig";
import SearchFilter from "../../../components/filter/SearchFilter";
import Loader from "../../../components/Loader";
import RemoveFilters from "../../../components/filter/RemoveFilters";
import {hasAccess} from "../../../utils/Access";

const Skus = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, rowChange] = useState([]);
    const [page, pageChange] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortColumn, setSortColumn] = useState("totalRc");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);
    const [appliedFilters, setAppliedFilters] = useState({});
    const hideColumns = {id: false};
    const navigate = useNavigate();

    const handelSearchFilter = (query: any) => {
        setSearchQuery(query);
    }

    const handleFilterChange = (appliedParams: any) => {
        setAppliedFilters(appliedParams);
    }

    const handleCallBack = (rowData: any, buttonData: any) => {
        if (buttonData.type === "anchor") {
            navigate(`/catalogue/skus/details/${rowData.id}`);
        }
    };

    const handleSort = (sortModel: any) => {
        setSortColumn(sortModel.field);
        setSortDirection(sortModel?.sort);
        pageChange(0);
    };

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    };

    const loadSkus = () => {
        setIsLoading(true);
        const params = new URLSearchParams({
            pageNo: page.toString(),
            pageSize: size.toString(),
            sort: sortDirection?.toUpperCase(),
            sortByColumn: sortColumn,
            searchQuery: searchQuery,
        });
        if (Object.keys(appliedFilters).length > 0) {
            Object.entries(appliedFilters).map(([name, value]: any) => {
                params.append(name, value);
            });
        }

        api
            .get("/catalogue/sku/list", {
                params: params,
            })
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                rowChange(response.content);
                setTotalElements(response.totalElements);
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadSkus();
    }, [sortColumn, sortDirection, page, size, searchQuery, appliedFilters]);

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <Box className={"common-space-container"}>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <PageHeading title={"Skus"} showButtons={false}/>
                <SearchFilter
                    handleFilterChange={handleFilterChange}
                    filters={filters}
                    appliedFilters={appliedFilters}
                    showFilters={true}
                    showSearch={true}
                    query={searchQuery}
                    handleSearchFilter={handelSearchFilter}
                />
            </Box>
            <Box>
                <RemoveFilters handleFilterChange={handleFilterChange} filters={filters}
                               appliedFilters={appliedFilters}/>
            </Box>

            <Box>
                <ListPage
                    rows={rows}
                    columns={hasAccess(["super-admin"]) ? skuPageConfig.adminColumns : skuPageConfig.columns}
                    page={page}
                    size={size}
                    totalElements={totalElements}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    handlePagination={handlePagination}
                    handleSort={handleSort}
                    handleCallBack={handleCallBack}
                    hideColumns={hideColumns}
                    rowHeight={92}
                />
            </Box>
        </Box>
    );
};

const filters = {
    'name': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Sku Name',
        label: 'Sku Name',
        name: 'name'
    },
    'code': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Sku code',
        label: 'Sku Code',
        name: 'code'
    },
    'uom': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By UOM',
        label: 'Sku UOM',
        name: 'uom'
    },
    'location': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Location',
        label: 'Location',
        name: 'location'
    },
    'RC_price': {
        element: 'radio',
        label: 'RC Price',
        name: 'RC_price',
        options: [
            {id: 'yes', label: 'Yes'},
            {id: 'no', label: 'No'},
        ],
    },
    'price': {
        element: 'slider',
        placeholder: 'Search Here',
        label: 'Price Range',
        name: 'price',
        info: '1 = 1000',
        range: [0, 1000000]
    },
};


export default Skus;
