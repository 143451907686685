import {createSlice} from "@reduxjs/toolkit";
import {getProject, getProjectDetails, getProjectDomainDetails} from "./project-action";

interface ApiResponse {
    id: string,
    name: string,
    location: string,
    country: string,
    status: string; // Add the name property
}

interface ProjectDetailsState {
    project: ApiResponse;
    loading: boolean;
    error: string | null;
    projectDetails: any
    domainDetails: any
}

const initialState: ProjectDetailsState = {
    project: {
        id: '',
        name: "",
        location: "",
        country: "",
        status: "",
    },
    loading: false,
    projectDetails: {},
    domainDetails: {},
    error: null,
};

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProject.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                getProject.fulfilled,
                (state: any, action) => {
                    state.loading = false;
                    state.project = action.payload;
                }
            )
            .addCase(getProject.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to get project details";
            })
            .addCase(getProjectDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                getProjectDetails.fulfilled,
                (state: any, action) => {
                    state.loading = false;
                    state.projectDetails = action.payload;
                }
            )
            .addCase(getProjectDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to get project details";
            })
            .addCase(getProjectDomainDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                getProjectDomainDetails.fulfilled,
                (state: any, action) => {
                    state.loading = false;
                    state.domainDetails = action.payload;
                }
            )
            .addCase(getProjectDomainDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to get project domain details";
            });
    },
});


export const projectDetails = (state: any) => state.project.projectDetails;
export const domainDetails = (state: any) => state.project.domainDetails;

export default projectSlice.reducer;