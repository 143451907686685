import {useEffect, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useAppDispatch} from "../../../app/hooks";
import {postBreakBidsValues} from "./features/actions";
import {openSnackbar} from "../../../components/common/features/snackbarSlice";
import {
    ERROR_COLOR,
    SAVE_BIDS_FAILURE_MESSAGE,
    SAVE_BIDS_SUCCESS_MESSAGE,
    SELECT_ALL_VALUES_ERROR_MESSAGE,
    SUCCESS_COLOR,
} from "../../../constants";
import {getPriceInFormatted} from "../../../utils/Common";
import {getStyle} from "../../../utils/ModalUtils";
import {getMinMaxPrices, interpolateColor} from "./utils";

const EventBreakBidsPopup = (props: any) => {
    const {eventId, rowsData, eventVendorsList, eventPackageDetails} = props;
    const style = getStyle(90, 85);
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [columnSelectedTotals, setColumnSelectedTotals] = useState<number[]>([]);
    const [eventPackages, setEventPackages] = useState<any>([]);
    const [bidVendors, setBidVendors] = useState<any>([]);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        if (props.showBidsPopup === true) {
            setOpen(true);
            if (eventVendorsList.length > 0) {
                let totalItemCount = 0;
                let priceAvailableVendors: any[] = [];
                const updatedEventPackageDetails = eventPackageDetails.map((packageDetails: any) => {
                    totalItemCount += packageDetails.status == "EVENT" ? packageDetails.items.length : 0;
                    const items = packageDetails.items.map((item: any) => {
                        let vendorList: any[] = [];
                        let prices: any[] = [];
                        eventVendorsList.map((eventDetails: any) => {
                            eventDetails.eventVendorDetails.map((vendorDetails: any) => {
                                if (vendorDetails.packageItemId == item.id) {
                                    let vendorPrice = vendorDetails.price + vendorDetails.domainCharges.total;
                                    if(props.showUpdatedCharges) {
                                        let categoryCode = "";
                                        props.categories.map((category: any) => {
                                            category.subCategories.map((subCategory: any) => {
                                                if(subCategory.id == item?.itemDetail?.itemCategory.materialSubCategoryId) {
                                                    categoryCode = category.code;
                                                }
                                            });
                                        });
                                        if (categoryCode.toLowerCase() == "ff") {
                                            vendorPrice = vendorPrice + ((vendorPrice * 2) / 100);
                                        } else {
                                            vendorPrice = vendorPrice + ((vendorPrice * 6) / 100);
                                        }
                                    }
                                    vendorList.push({...vendorDetails, eventVendorId: eventDetails.id, price: vendorPrice});
                                    if (!priceAvailableVendors.includes(eventDetails.vendor.id)) {
                                        priceAvailableVendors.push(eventDetails.vendor.id);
                                    }
                                    prices.push(vendorPrice);
                                }
                            });
                        });
                        let max, min = 0;
                        if (vendorList.length > 0) {
                            max = Math.max(...prices);
                            min = Math.min(...prices);
                        }
                        return {
                            ...item, eventVendors: vendorList, min, max
                        }
                    })
                    return {
                        ...packageDetails, items: items
                    }
                });
                setTotalItems(totalItemCount);
                setBidVendors(priceAvailableVendors);
                setEventPackages(updatedEventPackageDetails);
            }
        }
    }, [props.showBidsPopup]);

    const handleClose = () => {
        setOpen(false);
        props.handlePopupCallBack({event: "close"});
        setSelectedValues([]);
    };

    const handleBiddingCheckbox = (e: any, rowIndex: number, vendor: any) => {
        setSelectedValues((prevSelectedValues: any[]) => {
            const updatedValues = [...prevSelectedValues];
            updatedValues[rowIndex] = vendor.id;
            return updatedValues;
        });
    };

    const handleSaveBreakBids = async () => {
        const finalSelectedValue = selectedValues.filter(Boolean);
        if (finalSelectedValue.length === totalItems) {
            try {
                const response = await dispatch(
                    postBreakBidsValues({
                        eventVendorIds: selectedValues,
                        eventId: eventId,
                    })
                ).unwrap();
                if (response) {
                    dispatch(
                        openSnackbar({
                            message: SAVE_BIDS_SUCCESS_MESSAGE,
                            backgroundColor: SUCCESS_COLOR,
                        })
                    );
                    setOpen(false);
                    setSelectedValues([]);
                    props.handlePopupCallBack({event: "save"});
                }
            } catch (err) {
                console.error("Error", err);
                dispatch(
                    openSnackbar({
                        message: SAVE_BIDS_FAILURE_MESSAGE,
                        backgroundColor: ERROR_COLOR,
                    })
                );
            }
        } else {
            dispatch(
                openSnackbar({
                    message: SELECT_ALL_VALUES_ERROR_MESSAGE,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
        setSelectedValues([]);
    };

    useEffect(() => {
        const totals: number[] = [];
        eventPackages.map((eventPackage: any) => {
            eventPackage.items.map((item: any, rowIndex: number) => {
                let colTotal = 0;
                item.eventVendors.map((eventVendor: any) => {
                    if(selectedValues.includes(eventVendor.id)) {
                        colTotal += (eventVendor?.price || 0) * (item.quantity || 1);
                    }
                });
                totals.push(colTotal);
            });
        });
        // setColumnSelectedTotals(totals);
    }, [selectedValues]);

    const columnTotals = eventVendorsList.map((vendor: any, colIndex: number) => {
        return eventPackageDetails.reduce((total: number, eventPackage: any) => {
            return eventPackage.items.reduce((pkgTotal: number, packageItem: any, rowIndex: number) => {
                const eventDetail = vendor.eventVendorDetails[rowIndex];
                return pkgTotal + (eventDetail?.price + eventDetail.domainCharges.total || 0) * (packageItem.quantity || 1);
            }, total);
        }, 0);
    });

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header} className="sticky-header">
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    Break Bids
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body} style={{height: '84%'}}>
                        <Box sx={style.bodyContainer}>
                            <TableContainer
                                component={"div"}
                                sx={{border: "1px solid", borderColor: "divider", height: '100%', overflow: 'auto'}}
                            >
                                <Table aria-label="simple table" sx={{tableLayout: 'fixed'}}>
                                    <TableHead
                                        sx={{
                                            backgroundColor: "info.contrastText",
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 10
                                        }}>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: 700, width: 100}} align={"left"}>
                                                Sr. No.
                                            </TableCell>
                                            <TableCell sx={{fontWeight: 700, width: 150}} align={"left"}>
                                                Sku Name
                                            </TableCell>
                                            <TableCell sx={{fontWeight: 700, width: 100}} align={"left"}>
                                                Quantity
                                            </TableCell>
                                            {eventVendorsList.map((vendorDetails: any, idx: number) => {
                                                if (bidVendors.includes(vendorDetails.vendor.id)) {
                                                    return (
                                                        <TableCell sx={{fontWeight: 700, width: 180}}
                                                                   key={"break-bids-popup-event-vendor-name-" + idx}
                                                                   align={"left"}>
                                                            {vendorDetails?.vendor?.name}
                                                        </TableCell>
                                                    )
                                                }
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{zIndex: 1}}>
                                        {eventPackages.map((eventPackage: any, packageIndex: number) => {
                                            return (
                                                (eventPackage && eventPackage.status == "EVENT") &&
                                                eventPackage.items.map((packageItem: any, rowIndex: number) => {
                                                    return (
                                                        <TableRow key={rowIndex}>
                                                            <TableCell align="left" sx={{width: 200}}>
                                                                {rowIndex + 1}
                                                            </TableCell>
                                                            <TableCell align="left" sx={{width: 200}}>
                                                                {packageItem.itemDetail.sku.name}
                                                            </TableCell>
                                                            <TableCell align="left" sx={{width: 200}}>
                                                                {packageItem.quantity}
                                                            </TableCell>
                                                            {packageItem.eventVendors.map((vendor: any, vendorIndex: number) => {
                                                                const color = interpolateColor(packageItem.min, packageItem.max, vendor.price);
                                                                return (
                                                                    <TableCell key={vendorIndex} align="left"
                                                                               sx={{
                                                                                   color: vendor.price ? color : 'darkgray',
                                                                                   fontWeight: 900,
                                                                                   width: 200
                                                                               }}>
                                                                        {vendor ? <FormControlLabel
                                                                            key={"checkbox-form-" + vendorIndex}
                                                                            label={
                                                                                vendor ? getPriceInFormatted(vendor.price, '') : "NA"
                                                                            }
                                                                            control={
                                                                                vendor && vendor?.price &&
                                                                                <Checkbox
                                                                                    checked={
                                                                                        selectedValues && selectedValues[rowIndex]
                                                                                            ? selectedValues[rowIndex] ===
                                                                                            vendor?.id
                                                                                            : false
                                                                                    }
                                                                                    name={vendor && vendor?.price}
                                                                                    value={vendor && vendor?.id}
                                                                                    onChange={(e) =>
                                                                                        handleBiddingCheckbox(
                                                                                            e,
                                                                                            rowIndex,
                                                                                            vendor
                                                                                        )
                                                                                    }
                                                                                />
                                                                            }
                                                                        /> : <Typography sx={{
                                                                            fontSize: '14px',
                                                                            fontWeight: 500
                                                                        }}>NA</Typography>}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    )
                                                })
                                            )
                                        })}
                                    </TableBody>
                                    <TableFooter
                                        sx={{
                                            position: 'sticky',
                                            bottom: -1,
                                            backgroundColor: 'background.paper',
                                            zIndex: 9999,
                                            boxShadow: '0px 1px 1px rgba(50, 50, 50, 0.75)'
                                        }}>
                                        <TableRow>
                                            <TableCell sx={{p: 1, px: 2, border: 0}}/>
                                            {/*<TableCell*/}
                                            {/*    colSpan={2}*/}
                                            {/*    align="left"*/}
                                            {/*    sx={{*/}
                                            {/*        fontSize: "14px",*/}
                                            {/*        fontWeight: 700,*/}
                                            {/*        color: 'common.black',*/}
                                            {/*        py: 1,*/}
                                            {/*        px: 2,*/}
                                            {/*        border: 0*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    Selected Total:*/}
                                            {/*</TableCell>*/}
                                            {/*{columnSelectedTotals.map((total: any, idx: any) => (*/}
                                            {/*    <TableCell*/}
                                            {/*        key={idx}*/}
                                            {/*        align="left"*/}
                                            {/*        sx={{fontSize: "14px", fontWeight: 600, py: 1, px: 2, border: 0}}*/}
                                            {/*    >*/}
                                            {/*        {getPriceInFormatted(total, "")}*/}
                                            {/*    </TableCell>*/}
                                            {/*))}*/}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{py: 1, px: 2, border: 0}}/>
                                            <TableCell colSpan={2} align="left" sx={{
                                                fontSize: '14px',
                                                fontWeight: 700,
                                                color: 'common.black',
                                                py: 1, px: 2, border: 0
                                            }}>Overall Total: </TableCell>
                                            {columnTotals.map((total: any, idx: any) => (
                                                <TableCell key={idx} align="left"
                                                           sx={{
                                                               fontSize: '14px',
                                                               fontWeight: 600,
                                                               py: 1,
                                                               px: 2,
                                                               border: 0
                                                           }}>
                                                    {getPriceInFormatted(total, '')}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box sx={style.footer} className="sticky-footer">
                        <Grid
                            container
                            sx={{px: 2, height: "100%"}}
                            className={"center-aligned"}
                        >
                            <Grid item xs/>
                            <Grid item>
                                <Button variant="outlined" onClick={handleClose} sx={{marginRight: '4px'}}>
                                    Cancel
                                </Button>
                                <Button variant="contained" onClick={handleSaveBreakBids} sx={{marginLeft: '4px'}}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default EventBreakBidsPopup;
