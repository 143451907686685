import {IconButton, InputLabel, Tooltip, Typography} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const LabelDetail = (props: any) => {
    const {formField} = props;
    return (
        <>
            <InputLabel id="demo-simple-select-helper-label" required={formField?.required} sx={{pb: formField.subLabel ? 0 : 1}}>
                {formField.label}
                {
                    (formField.info != undefined && formField.info != "") &&
                    <Tooltip sx={{py: 0}} title={formField.info} placement="top-start" arrow>
                        <IconButton
                            sx={{
                                p: 0.5,
                                // '&:hover': {backgroundColor: 'secondary.main'}
                            }}>
                            <InfoOutlinedIcon sx={{fontSize: 15}}/>
                        </IconButton>
                    </Tooltip>
                }
            </InputLabel>
            {formField.subLabel &&
                <InputLabel id="demo-simple-select-helper-sub0label" sx={{pb: 1, fontSize: '0.8rem'}}>
                    {formField.subLabel}
                </InputLabel>
            }
        </>
    )
}

export default LabelDetail;
