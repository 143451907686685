import {Box, Typography} from "@mui/material";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {useState} from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

function DoughnutChart(props) {
    const [chartData, setChartData] = useState({
        labels: props.Data.map((data) => data.label),
        datasets: [
            {
                label: '',
                data: props.Data.map((data) => data.userGain),
                backgroundColor: props.firstColorSet ? [
                    "#2ECC71",  
                    "#BCC3C3", 
                    "#6C5B9A",  
                    "#5AD0A6",  
                    "#4A6D91",  
                    "#6A3D8E",
                ] : [
                    "#87CEEB",
                    "#6A3D8E",  
                ],
                borderColor: 'transparent',
                borderWidth: 0
            }
        ]
    });

    return (
        <Box className="chart-container" sx={{position: 'relative'}}>
            <Typography style={{
                textAlign: "center",
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginLeft: props.mleft,
                width: '100px',
                fontSize: '1rem',
                fontWeight: 600,
                marginTop: props.mtop
            }}>{props.title}</Typography>
            <Doughnut
                id={"sm-doughnutchart"}
                data={chartData} options={{
                cutout: props.cutout,
                plugins: {
                    datalabels: {
                        display: true,
                        color: '#ffffff',
                        formatter: (value, context) => {
                            return value >= 1 ? value : ''; 
                        }
                    },
                    legend: {
                        display: props.showLegend,
                        position: 'right',
                        align: 'center',
                        labels: {
                            usePointStyle: true
                        }
                    }
                }
            }}/>
        </Box>
    );
}

export default DoughnutChart;