import {Checkbox, FormControlLabel} from '@mui/material';
import {useCheckboxValues} from '../../../hooks/useCheckboxValues';

const CheckboxOptions = (props: any) => {
    const {formField} = props;
    const {selectedCheckboxValue} = useCheckboxValues();
    return (
        <>
            {formField.options.map((option: any, index: number) => {
                return (
                    <FormControlLabel
                        key={'checkbox-form-' + index}
                        label={option.label}
                        control={
                            <Checkbox
                                checked={selectedCheckboxValue.some(
                                    (obj) => {
                                        return obj === option.id
                                    })}
                                onChange={(e: any) => props.onChangeHandlerMultiple(e, option)}
                                name={formField.name}
                                value={option.id}
                            />
                        }
                    />
                );
            })}
        </>
    );
};
export default CheckboxOptions;
