import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Box, Typography } from '@mui/material'
import Variant4 from '../../assets/Variant4A.png'
import Variant5 from '../../assets/Variant5A.png'
import Variant6 from '../../assets/Variant6A.png'

function LoginCarousel(props:any)
{
    const indicatorIcons = ['','','']
    return (
        <Carousel sx={{width:'70%', minWidth:'300px', maxWidth: '700px', margin: '0 auto'}} navButtonsAlwaysInvisible={true} 
            indicatorIconButtonProps={{
            style: {
              width: "60px",
              height: "5px",
              margin: "7px",
              fontWeight: "400",
              color: "rgba(27, 33, 37, 0.23)",
              background: "#cadcfd",
              borderRadius: "3px"
            }
          }}
          activeIndicatorIconButtonProps={{
            style: {
              fontSize: "15px",
              color: "#2779F5",
              width: "60px",
              height: "5px",
              margin: "7px",
              background: "#4e76f8",
              boxShadow: "0px 2px 5px rgb(0 0 0 / 25%)",
              borderRadius: "3px"
            }
          }}
            IndicatorIcon={indicatorIcons}
            >
            <Paper sx={{width: "100%", minHeight:'75vh', boxShadow:'unset', position: 'relative', background: 'transparent!important'}}>
                <Box sx={{width: '100%', margin: '0 auto', textAlign: 'center', position: 'absolute', top: '0', left: '0'}}>
                    <Typography sx={{fontSize: '1.5rem', fontWeight: '300', margin: '10px 0 20px'}}>One Stop for managing <b>Catalogue</b> and <b>Estimations</b></Typography>
                    <Typography sx={{fontSize: '1rem', fontWeight: '300', margin: '10px 0'}}>Our in-house platform for end to end procurement services</Typography>
                    <Typography sx={{fontSize: '1rem', fontWeight: '300', margin: '10px 0'}}>with Machine Learning and Artificial Intelligence enabled</Typography>
                </Box>
                <Box sx={{height:"3vh"}}></Box>
                <Box 
                component="img" 
                src={Variant6}
                sx={{width: '110%'}}
                >
                </Box>
                <Box sx={{height:"2vh"}}></Box>
            </Paper>
            <Paper sx={{width: "100%", minHeight:'75vh', boxShadow:'unset', position: 'relative', background: 'transparent!important'}}>
                <Box sx={{width: '100%', margin: '0 auto', textAlign: 'center', position: 'absolute', top: '0', left: '0'}}>
                    <Typography sx={{fontSize: '1.5rem', fontWeight: '300', margin: '10px 0 10px'}}>Create and Run <b>Events</b> Or</Typography>
                    <Typography sx={{fontSize: '1.5rem', fontWeight: '300', margin: '10px 0 20px'}}>Create <b>PR</b> for Packages</Typography>
                    <Typography sx={{fontSize: '1rem', fontWeight: '300', margin: '10px 0'}}>Run events and create PR on the same platform</Typography>
                </Box>
                <Box sx={{height:"3vh"}}></Box>
                <Box 
                component="img" 
                src={Variant5}
                sx={{width: '110%'}}
                >
                </Box>
                <Box sx={{height:"2vh"}}></Box>
            </Paper>
            <Paper sx={{width: "100%", minHeight:'75vh', boxShadow:'unset', position: 'relative', background: 'transparent!important'}}>
                <Box sx={{width: '100%', margin: '0 auto', textAlign: 'center', position: 'absolute', top: '0', left: '0'}}>
                    <Typography sx={{fontSize: '1.5rem', fontWeight: '300', margin: '10px 0 10px'}}><b>Dashboards</b> designed to keep you</Typography>
                    <Typography sx={{fontSize: '1.5rem', fontWeight: '300', margin: '10px 0 20px'}}>on top of Everything</Typography>
                    <Typography sx={{fontSize: '1rem', fontWeight: '300', margin: '10px 0'}}>Your day is simplified with various dashboards that</Typography>
                    <Typography sx={{fontSize: '1rem', fontWeight: '300', margin: '10px 0'}}>is available at your fingertips</Typography>
                </Box>
                <Box sx={{height:"3vh"}}></Box>
                <Box 
                component="img" 
                src={Variant4}
                sx={{width: '110%'}}
                >
                </Box>
                <Box sx={{height:"2vh"}}></Box>
            </Paper>
        </Carousel>
    )
}

export default LoginCarousel;